<template>
  <div>
    <Headers></Headers>
    <main class="mainWrp">
      <section class="globalWrp">
        <aside class="titleSectionWrp">
          <router-link
            class="backLink"
            :to="{
              name: 'TermsSelector',
              params: { countryId: country.id.toLowerCase() },
            }"
            >{{ $t("back") }}</router-link
          >
          <h2>{{ $t("legals") }}</h2>
        </aside>
        <main class="one-whole heightScroll">
          <div class="one-whole">
            <div class="headerTerms one-whole">
              <img
                src="@/assets/images/global/banners/touch-down/touch-down-pringles_466x276_mx.jpg"
                alt="Touch down Pringles®"
              />
              <div class="titleTermsWrp one-whole">
                <p class="year">2021</p>
                <h4 class="titleTerms">
                  TOUCH DOWN PRINGLES®
                </h4>
                <h5>TÉRMINOS Y CONDICIONES</h5>
              </div>
            </div>
            <!-- Insertar section terminos -->
            <section class="terms">
              <div class="terms-wrp">

                <div class="wrappTerms">
                  <h1 class="not-visible">Términos y condiciones de la promoción “Touch Down Pringles®”</h1>

                  <p>Los términos y condiciones descritos a continuación (las &ldquo;<strong>Bases</strong>&rdquo;) establecen las condiciones y requisitos bajo los cuales se regirá la actividad promocional &ldquo;Touch  Down Pringles&rdquo; (en lo sucesivo la &ldquo;Promoción&rdquo;). Lo aquí dispuesto es obligatorio para todos los participantes de la  promoción, por lo que se deberá dar lectura total a las presentes Bases.  La Promoción se ejecutará a través de la  cuenta de Instagram de Pringles (@pringles_LATAM).</p>

                  <p><strong>I. ASPECTOS GENERALES DE LA PROMOCIÓN.</strong></p>

                  <p><strong>Nombre:</strong> &ldquo;TOUCH DOWWN PRINGLES&rdquo;</p>
                  <p><strong>Cobertura</strong>: Promoción válida solo en el estado de Jalisco., en lo sucesivo el &ldquo;Territorio&rdquo;.</p>
                  <p><strong>Vigencia:</strong> 21 de enero del 2021 a las 12:00 am hasta 31 de enero de 2021 a las 17:00 pm.</p>
                  <p><strong>Naturaleza</strong>: La promoción no involucra el azar, es una promoción de destreza.</p>
                  <p><strong>Organizador</strong>: Grupo Pauta Creativa S.A. de C.V. (el &ldquo;Organizador&rdquo;).</p>
                  <p><strong>Domicilio del Organizador</strong>:  Berlín 318 Colonia del Carmen, Coyoacán, C.P. 04100, en Ciudad de México,  México</p>
                  <p><strong>Patrocinador:</strong> Kellogg Company México, S. de R.L. de C.V (el &ldquo;Patrocinador&rdquo;).</p>
                  <p><strong>Domicilio del Organizador</strong>:  KM 1, Carretera a Campo Militar S/N, Colonia San Antonio de la Punta,  Querétaro, Qro, México. C.P. 76135.</p>

                  <p><strong>II. DEFINICIONES</strong></p>

                  <p>Sin perjuicio de cualesquiera  otros términos que puedan encontrarse aquí definidos, para efectos de estas  Bases se entenderá por:</p>
                  <p><strong>Acreedor al  incentivo.-</strong> Es el Usuario que publique la  fotografía conforme a los presentes Términos y Condiciones. </p>
                  <p><strong>Dinámica</strong>.- Se compone de las diversas actividades que permitirán a los Participantes poder ganar el incentivo que se determina en este documento.</p>
                  <p><strong>Incentivo.-</strong> Bien  que se ofrece en la Promoción y que se describe en la sección V. de estas  Bases.</p>
                  <p><strong>Mecánica  de la promoción. -</strong> Conjunto de lineamientos y  pasos a seguir para participar en la Dinámica y aspirar a obtener el incentivo. </p>
                  <p><strong>Participaciones.</strong>- Se entiende por &ldquo;Participación&rdquo; cada vez que un usuario envíe  su fotografía a la cuenta de Instagram @pringles_LATAM.</p>
                  <p><strong>Productos Participantes.-</strong> Los  productos descritos en el inciso IV.</p>
                  <p><strong>Usuario.- </strong>Persona  que cuenta con perfil en Instagram, que postea una fotografía de acuerdo a la mecánica de la promoción y etiquetando a @pringles_LATAM.</p>
                  <p><strong>Incentivos totales.- </strong>El total de Incentivos para la Promoción es A) 10 kits a total promoción (cada kit  incluye 1 Jersey original, 1 hielera  brandeada, 6 tarros brandeados,1 asador brandeado, 1 botanero brandeado y 3  latas de Pringles de 125 gr de sabores variados.</p>

                  <p><strong>III. REQUISITOS DE ELEGIBILIDAD PARA  PARTICIPAR EN LA PROMOCIÓN.</strong></p>
                    
                  <p>Podrá participar en la Promoción cualquier persona física que tenga la disposición de  participar y reúna todos y cada uno de los siguientes requisitos:</p>
                  <ol class="letter" type="A">
                      <li>Contar con legal residencia en los Estados Unidos Mexicanos.</li>
                      <li>Contar con mayoría de edad, mayor de 18 años.</li>
                      <li>Seguir al perfil @pringles_LATAM en la  plataforma tecnológica Instagram.</li>
                      <li>Publicar en la plataforma tecnológica Instagram una fotografía mostrándonos como  disfrutas tu pasión por el fútbol americano con Pringles® y etiquetando a @pringles_LATAM y utiliza el  #touchdowncaemejorconpringles<br>
                      *Los  usuarios deben contar con equipo compatible y una cuenta de Instagram (IG) para  que la marca pueda revisar esta publicación.</li>
                      <li>Los 10 participantes con el mayor número de likes al cierre del 31 de enero de  2021 a las 17:00 hrs. Serán ganadores de los  incentivos descritos en el inciso V, mismos que se anunciarán el día 2 de febrero de 2021 en la plataforma tecnológica Instagram (publicación en la cuenta de la marca).</li>
                  </ol>
                  <p>El contacto a los ganadores será vía mensaje directo a cada uno de los  participantes en la plataforma tecnológica Instagram (inbox directo)</p>
                  <p>El  participante se obliga expresamente a garantizar la exactitud y veracidad de  todos los datos otorgados.</p>
                  <p>El Organizador se exime de cualquier responsabilidad por errores en la información  compartida por el Participante que retrasen, obstaculicen o impidan, total o  parcialmente, posibilidad de redimir el incentivo objeto de la Promoción.</p>
                  <p>No podrán participar todas aquellas personas que sean familiares hasta el tercer  grado de un empleado o empleados del Organizador, subsidiarias y/o agencias  &ldquo;partners&rdquo; así como de cualquiera de las empresas que formen parte del mismo  grupo de intereses económicos al que pertenece el Organizador y las empresas  vinculadas en la ejecución de la Promoción.</p>

                  <p><strong>IV. PRODUCTOS  PARTICIPANTES.</strong></p>

                  <p>Los productos participantes serán Pringles® en todas  sus presentaciones.</p>

                  <p><strong>V. INCENTIVOS DE LA PROMOCIÓN</strong></p>

                  <p>Los incentivos totales que participan en la promoción  son:</p>

                  <p>Existirán un total de  10 (diez) incentivos a total promoción disponibles para los participantes que  resulten acreedores al incentivo, siempre y cuando cumplan con cada una de las  condiciones y requisitos indicados dentro del presente, los cuales se describen  a continuación:</p>

                  <ol class="letter" type="A">
                    <li>10 kits a total promoción (cada kit incluye  1 Jersey original, 1 hielera brandeada, 6 tarros brandeados,1 asador brandeado,  1 botanero brandeado y 3 latas de Pringles de 125 gr de sabores variados. <br>
                      <br>
                      Con  un valor de $11,900.00 (once mil novecientos pesos 00/100 MN)</li>
                  </ol>
                  
                  <p>El Organizador no otorga  garantía alguna sobre los Incentivos objeto de la Promoción. Los bienes que  integran los Incentivos están sujetos exclusivamente a las garantías que  otorgue el productor, de conformidad con la legislación aplicable. </p>
                  <p>El Organizador se reserva  el derecho de cambiar/ajustar la cantidad y/o tipo de incentivos durante la  Promoción en cualquier momento como mejor convenga a sus intereses y a los de  la Promoción, cuando por causas ajenas a su voluntad no sea posible asignar los  Incentivos declarados.</p>

                  <p><strong>VI. MECÁNICA DE LA PROMOCIÓN</strong>.</p>

                  <p><strong>Dinámica</strong>. - La presente  Promoción es mediante una mecánica de sube tu foto y participa. Los ganadores  serán de acuerdo a los siguientes pasos:</p>
                  <ol class="decimal" type="1">
                    <li>Seguir al perfil Pringles®  en la plataforma tecnológica Instagram a @pringles_LATAM.</li>
                    <li>Publicar dentro de la  vigencia de la presente Promoción en la en la plataforma tecnológica Instagram  una fotografía mostrándonos como disfrutas tu pasión por el fútbol americano  con Pringles®, etiquetando a @pringles_LATAM y utiliza el  #touchdowncaemejorconpringles</li>
                    <li>Pídeles a tus amigos  que voten por ti.</li>
                    <li>Los 10 participantes  que tenga más Likes en su fotografía al cierre del 31 de enero de 2021 a las  17:00 hrs serán los ganadores de los Kits  Pringles para ver a su equipo favorito desde casa.</li>
                  </ol>
                  <p>*Los acreedores a los  incentivos serán seleccionados acorde al número de likes obtenidos en su  publicación.</p>

                  <p><strong>VII. CRITERIOS DE EVALUACIÓN: </strong></p>

                  <p>Los acreedores al  incentivo serán determinados de acuerdo a los siguientes criterios:</p>
                  <ul class="bullets">
                    <li>La publicación deberá mencionar  a Pringles Latam, etiquetar a @pringles_latam y utilizar el #touchdowncaemejorconpringles,  para hacer valida la participación.</li>
                    <li>La publicación deberá  incluir a Pringles como elemento principal, así como elementos relacionados al  futbol americano.</li>
                    <li>El ganador se  determinará acorde al número de likes obtenidos hasta el día 31 de enero de  2021 a las 17:00 pm.</li>
                  </ul>

                  <p><strong>VIII. DETERMINACIÓN DE ACREEDOR AL  INCENTIVO</strong></p>

                  <p>La presente promoción  tendrá 10 (diez) acreedores al incentivo a total promoción, los cuales podrán  recibir, uno de los incentivos descritos en el punto V de este documento.   Al finalizar el periodo de participación, el  Organizador validará todas las publicaciones participantes para determinar los  posibles acreedores.</p>
                  <p> En caso de existir  empate, un jurado seleccionará a los ganadores. Se dará notificación a los  participantes potenciales a los incentivos vía Mensaje Directo para validar su  documentación oficial y así validar que es acreedor al incentivo  correspondiente. </p>

                  <p><strong> IX. RESTRICCIONES Y LIMITACIONES</strong></p>

                  <ul class="bullets">
                    <li>El usuario deberá ser mayor de edad con identificación oficial vigente a la fecha de redención del incentivo.</li>
                    <li>No válido ni acumulable con otras promociones.</li>
                    <li>Limitado a un incentivo por acreedor y únicamente a 10 (diez) incentivos a total promoción.</li>
                    <li>Solo podrán participar personas que residan en la República Mexicana.</li>
                    <li>En caso de que un usuario participe mediante un perfil falso, éste será descalificado de LA PROMOCIÓN. Entiéndase por DESCALIFICADO que no se entregará ningún incentivo y no podrá seguir participando.</li>
                  </ul>
                  <p>El Organizador se reserva  de manera expresa el derecho de descalificar a cualquier participante del cual  se tenga duda o sospecha que se encuentra interviniendo en el correcto  desarrollo de LA PROMOCIÓN de acuerdo con estas bases.</p>
                  <p>El Organizador y sus  filiales se reservan el derecho de limitar o prohibir la participación en esta  promoción a cualquier persona, en el caso de presumir la existencia de fraude o  alteración en LA PROMOCIÓN, incluyendo de manera enunciativa, más no  limitativa, la mecánica de la misma, los medios de pago, y cualesquiera otras  que presuman la existencia de fraude o alteración.</p>
                  <p>El Organizador se reserva  el derecho a descalificar de la promoción a cualquier persona que ponga en  riesgo la integridad y buen desarrollo de la misma, tal es el caso de aquellos  denominados &ldquo;hackers&rdquo; (término utilizado para referirse a personas con grandes  conocimientos en informática y telecomunicaciones que son empleados con  objetivos personales y/o de lucro, el cual puede ser doloso e ilegal) o los  denominados &ldquo;caza promociones&rdquo; que sean o hayan sido detectados como tal  (definido como tal a todo aquel participante que actúa solo o conjuntamente con  recursos económicos, materiales o informativos de forma desleal frente a otros  participantes para obtener el beneficio de la promoción sin importar que los  mecanismos que usa sean poco ortodoxos, éticos morales o incluso ilegales),  aquellas en que participen bits, cuentas automatizadas y/o cualesquiera que  presupongan un software automatizado con el fin de adquirir ventaja sobre los  otros participantes.</p>
                  <p>Se descalificará a  cualquier consumidor participante que en general no cumpla con las condiciones  establecidas en este documento.</p>
                  <p>Las decisiones que se  adopten respecto a LA PROMOCIÓN serán finales y no se podrán apelar, en ninguna  circunstancia.</p>
                  <p>Los usuarios  participantes entienden y aceptan que está prohibido violar o intentar violar,  de cualquier forma, LA PROMOCIÓN, incluyendo de manera enunciativa más no  limitativa, proporcionar datos incompletos o falsos, o intentar interferir con  el servicio a cualquier usuario. El Organizador se reserva expresamente el  derecho de descalificar a cualquier participante del cual se tenga duda o  sospecha de que está actuando en contra de las reglas oficiales. Asimismo, se  reserva el derecho de prohibir su participación en futuras promociones.</p>
                  <p>Si por alguna razón no  fuese posible desarrollar LA PROMOCIÓN en los términos estipulados en los  presentes términos y condiciones, con motivo de fallas técnicas o cualquier  otra causa fuera del control del Organizador, ésta se reserva el derecho de  modificar o posponer a su discreción LA PROMOCIÓN, en cuyo caso, se notificará  de dicha circunstancia a los participantes mediante ajuste en los Términos y  Condiciones.</p>
                  <p>El acreedor deberá seguir  todas las direcciones e instrucciones del Organizador  quien es el responsable de la entrega del  incentivo o le será negado.</p>
                  <p>El incentivo no es  intercambiable ni canjeable por dinero en efectivo, ni por cualquier otro incentivo.</p>
                  <p>Promoción válida únicamente durante la vigencia (del 21 de enero a las 12:00 am hasta el 31  de enero de 2021 17:00 horas).</p>
                  <p>El consumidor  participante se obliga a proporcionar con veracidad la información solicitada  por el Organizador, en la inteligencia de que en caso de que la información  proporcionada por el usuario sea falsa o incompleta, el Organizador se reserva  el derecho de suspender o cancelar su participación.</p>
                  <p>El Organizador se reserva  el derecho de ejercer las acciones que correspondan en la vía judicial,  incluyendo de manera enunciativa mas no limitativa, ante las autoridades  civiles, penales y/o administrativas contra cualquier consumidor del que se  tenga sospecha que se encuentra interviniendo de cualquier manera el correcto  funcionamiento del sitio.</p>
                  <p>El Organizador; sus  subsidiarias y/o afiliadas no aceptan ninguna responsabilidad por cualquier  falla técnica o un funcionamiento defectuoso o cualquier otro problema con la  red que esté conectado en línea al sistema, servidores, o proveedores de otro  tipo, que puede ser el resultado de cualquier contenido o entrada en la promoción que no esté correctamente registrado.</p>
                  <p>El Organizador no se hace  cargo de los gastos que generen, suministro de energía eléctrica, servicio de  telecomunicaciones, programas y software y hardware, reproductores musicales,  ni recomienda o promueve marca alguna de insumos tecnológicos y cualquier otro  requisito de participación.</p>
                  <p>De así requerirse, todo  participante otorga una licencia para el uso de su nombre y fotografías para la  elaboración de materiales de comunicación digital que el Organizador juzgue  convenientes sin remuneración adicional alguna distinta a la del incentivo  recibido de acuerdo con lo establecido en las presentes bases.</p>
                  <p>La participación en esta  promoción implica la aceptación de la totalidad de sus bases y mecánica de la  misma, las que son inapelables, el Organizador; es la única habilitada para  interpretarlas.</p>

                  <p><strong>X. RESTRICCIONES SOBRE LOS INCENTIVOS</strong></p>

                  <p>El consumidor declarado acreedor  al incentivo deberá proporcionar los datos que el Organizador le solicite con motivo de la entrega del incentivo, obligándose este a proporcionar datos  verídicos, reservándose el Organizador el derecho de ejecutar acción legal en  contra de aquel consumidor que no proporcione veracidad en sus datos.</p>
                  <p>No se entregará el  incentivo a terceros, ni podrá redimirse por personas distintas al acreedor.</p>
                  <p>En caso de no lograr  contactar al acreedor al incentivo, el Organizador, se entenderá por no  aceptado el incentivo, y/o sus filiales y subsidiarias, de cualquier  responsabilidad.</p>
                  <p>La responsabilidad de El  Organizador concluye al momento de la entrega de cada uno de los incentivos a  sus correspondientes participantes.</p>
                  <p>El Organizador no será  responsable por el uso que le brinde el participante a cualquiera de los  incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra  índole que puedan ser interpuestos en relación con un reclamo de cualquier tipo  de responsabilidad, por hechos acontecidos durante o como consecuencia de la  entrega del mismo, así como, relacionados con la calidad, garantías por daños,  pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                  <p>No son elegibles para ser  acreedores a los incentivos de LA PROMOCIÓN los empleados y/o socios de Grupo  Pauta Creativa, S.A. de C.V., así como los miembros de su familia, mencionando  de forma enunciativa más no limitativa, como &ldquo;familia&rdquo; al cónyuge, a los  padres, a los hijos y a los hermanos.</p>
                  
                  <p><strong>XI. FECHA DE LA PUBLICACIÓN DE ACREEDORES AL INCENTIVO</strong></p>

                  <p>Los participantes que  resulten acreedores a los incentivos serán informados una vez que se haya  validado la veracidad de sus datos de acuerdo con el siguiente calendario.</p>
                  
                  <div class="globalTableWrp">
                    <table class="globalTable">
                      <tbody>
                        <tr>
                          <th>PERIODO DE PARTICIPACIÓN</th>
                          <th>PUBLICACIÓN DE ACREEDORES</th>
                        </tr>
                          <tr>
                          <td><strong>21 al 31 de enero 2021</strong></td>
                          <td><strong>02 de febrero 2021</strong></td>
                          </tr>
                      </tbody>
                    </table>
                  </div>

                  <p>Los acreedores al  incentivo serán publicados en la cuenta de Instagram de Pringles Latam (@pringles_latam)</p>

                  <p><strong> XII.  ENTREGA DE INCENTIVOS</strong></p>

                  <ol class="decimal" type="1">
                    <li>Se contactará a los Participantes, vía Instagram  por mensaje directo, esto sucederá hasta máximo 72 horas hábiles posteriores a  la publicación de ganadores. En este contacto, se le hará saber que es un  posible acreedor al incentivo de acuerdo con los términos y condiciones  mencionados en el punto VI Selección de Acreedores al Incentivo y se le  solicitará un teléfono de contacto para seguir el proceso.</li>
                    <li>A partir del contacto en donde se  notifique que es acreedor al incentivo, El participante contará con 24 horas  para proporcionar y confirmar sus datos generales (nombre completo, dirección,  número de celular y correo electrónico). En caso de que el posible acreedor no  responda dentro del tiempo establecido, se entenderá como incentivo no aceptado,  liberando de cualquier responsabilidad a EL ORGANIZADOR.</li>
                    <li>Es responsabilidad exclusiva de los  Participantes proporcionar información correcta, veraz y completa durante la  interacción con EL ORGANIZADOR. En caso de especificar un correo electrónico  y/o teléfono erróneo o incompleto y esto imposibilite al Organizador a  contactarlo, el Participante perderá su derecho a recibir y/o reclamar  cualquier incentivo.  EL ORGANIZADOR  podrá disponer del incentivo conforme a lo que mejor convenga a sus intereses y  a los de LA PROMOCIÓN.</li>
                    <li>Para efecto de reclamación del incentivo,  el acreedor deberá entregar de manera digital los documentos que se describen a  continuación: copia de identificación oficial vigente, copia de comprobante de  domicilio, y se le proporcionará un recibo de conformidad digital, mismo que  deberá enviar firmado. El acreedor deberá dar respuesta acreditando su  identidad en un lapso no mayor de 24 (veinticuatro) horas a partir de que se  haga dicho requerimiento. En caso de especificar un correo electrónico y/o  teléfono erróneo o incompleto y esto imposibilite al Organizador a contactarlo,  el Participante perderá su derecho a recibir y/o reclamar cualquier  incentivo.  EL ORGANIZADOR podrá disponer  del incentivo conforme a lo que mejor convenga a sus intereses y a los de LA  PROMOCIÓN.</li>
                    <li>Es responsabilidad exclusiva de los  Participantes proporcionar una dirección correcta para la entrega del incentivo  vía paquetería y contar con disponibilidad para la entrega del Incentivo de que  se trate.</li>
                    <li>Los incentivos correspondientes serán  entregados de la siguiente manera; Los acreedores deberán enviar de manera  digital la documentación requerida al correo electrónico que proporcione EL  ORGANIZADOR. Una vez recibida y validada la información EL ORGANIZADOR enviará  al acreedor su incentivo a través de mensajería especializada a la dirección  que éste le proporcione. En caso de no lograr hacer la entrega en el domicilio  indicado por el acreedor al incentivo porque éste no se encuentre o no se logre  identificar el domicilio, EL ORGANIZADOR realizará 1 intento adicional. En caso  de que no se localice a nadie en el domicilio o el domicilio esté equivocado o  ilocalizable, el participante perderá su derecho de recibir el incentivo,  liberando de cualquier responsabilidad al Organizador.</li>
                    <li>EL ORGANIZADOR podrá solicitar al  Participante acreedor que llene, firme y/o envié un comprobante de recibo de  conformidad del Incentivo, mismo que el Participante acreedor deberá  proporcionar en el plazo que se le solicite.</li>
                    <li>Los Incentivos son intransferibles, por lo  que acreedor al incentivo deberá recibir su Incentivo personalmente. Los  Incentivos no serán canjeables, endosables, sustituibles ni redimibles. En  consecuencia, en ninguna circunstancia el acreedor al incentivo podrá solicitar  al Organizador, que los Incentivos sean entregados a una tercera persona ni les  sean canjeados por dinero en efectivo ni por ningún otro bien.</li>
                    <li>EL ORGANIZADOR de la presente Promoción no  se hace responsable de cualquier eventualidad, relacionada con el disfrute de  cualquiera de los Incentivos, cuando dicha eventualidad se deba a causas ajenas  al Organizador. Cualquier gasto adicional como consecuencia del disfrute del  Incentivo, no está cubierto por LA PROMOCIÓN ni EL ORGANIZADOR, en caso de  pérdida de cualquiera de los incentivos parcial o totalmente, será  responsabilidad exclusiva del Participante acreedor al incentivo.</li>
                  </ol>

                  <p><strong>XIII. AUTORIZACIÓN</strong></p>

                  <p>Los participantes de esta  promoción, autorizan expresa e irrevocablemente a Grupo Pauta Creativa, S.A.  C.V. y/o cualquier otra empresa que ésta determine, a difundir en los medios  que Grupo Pauta Creativa, S.A. C.V. estime conveniente, su nombre y apellido  completo, país y ciudad de origen, fotografías y uso de imagen o retrato,  videos y en general todos aquellos datos que pudieran requerirse con motivo de la  difusión de esta promoción, renunciando expresa e irrevocablemente, desde la  aceptación de las bases, a cualquier tipo de compensación económica,  remuneración, regalía o retribución alguna por dicho uso.</p>

                  <p><strong>XIV. PRIVACIDAD Y PROTECCIÓN DE DATOS  PERSONALES</strong></p>

                  <p>El Organizador pone a  disposición de los consumidores y participantes de LA PROMOCIÓN su aviso de  privacidad:</p>
                  <p><u>IDENTIDAD DEL RESPONSABLE DEL TRATAMIENTO</u></p>
                  <p>Con motivo del  cumplimiento de la Ley Federal de Protección de Datos Personales en Posesión de  los Particulares (en adelante &ldquo;LFPDPPP&rdquo;) y su reglamento, Grupo Pauta Creativa,  S.A. de C.V., (en adelante &ldquo;PAUTA CREATIVA&rdquo;), será la responsable de la  obtención, resguardo, tratamiento y/o en su caso de la remisión y/o  transferencia de sus datos personales (en adelante &ldquo;DATOS&rdquo;), con domicilio en:  Berlín, número 318, Colonia Del Carmen, Alcaldía Coyoacán, Ciudad de México,  México, C.P. 04100.</p>
                  <p><u>OBTENCIÓN Y FINALIDADES DEL TRATAMIENTO DE  LA INFORMACIÓN</u>.</p>
                  <p>Los DATOS serán obtenidos  de usted, a través de los registros de participación y/o por cualquier medio  electrónico, óptico, sonoro, físico, visual, u otra tecnología regulada por la  LFPDPPP. Los DATOS que se recabarán serán los siguientes: (i) datos de  identificación; (ii) datos de contacto. PAUTA CREATIVA utilizará sus DATOS para  llevar a cabo la administración de su participación en La Promoción. PAUTA  CREATIVA tratará sus DATOS con las debidas medidas de seguridad  administrativas, técnicas y físicas suficientes y/o necesarias para cumplir con  las obligaciones establecidas en la LFPDPPP y su reglamento.</p>
                  <p><u>TRANSFERENCIA Y/O REMISIÓN DE DATOS  PERSONALES</u>.</p>
                  <p>Con motivo de La  Promoción, PAUTA CREATIVA podrá transferir y/o remitir sus DATOS al  Patrocinador, filiales, subsidiarias y/o terceros nacionales o extranjeros, ya  sean personas físicas o morales con las que sostenga una relación jurídica,  comercial y/o contractual, señalando que no se requerirá de su consentimiento  para realizar la(s) transferencia(s) y/o remisión(es) necesaria(s), cuando se  presente alguno de los supuestos establecidos en los artículos 14 y 37 de la  LFPDPPP, los datos obtenidos únicamente se podrán utilizar con el propósito de  ejecutar la actividad promocional.</p>
                  <p><u>EJERCICIO DE SUS DERECHOS ARCO</u></p>
                  <p>Usted puede solicitar a  PAUTA CREATIVA en cualquier momento, el Acceso, Rectificación, Cancelación u  Oposición (DERECHOS ARCO), respecto de sus DATOS, así como revocar su  consentimiento para el tratamiento de dichos DATOS, por medio de un correo  electrónico a la dirección: privacidad@pautacreativa.com.mx. Así mismo, usted  podrá limitar el uso y/o divulgación de sus DATOS a través del medio antes  señalado.</p>
                  <p><u>CAMBIOS AL AVISO DE PRIVACIDAD</u></p>
                  <p>Cualquier cambio, por  disposición legal, regulatoria y/o corporativa de PAUTA CREATIVA, que se  realice a este aviso de privacidad, será dado a conocer vía correo electrónica  a los titulares de los DATOS.</p>
                  <p><u>AUTORIDAD REGULADORA</u></p>
                  <p>El Instituto Nacional de  Transparencia, Acceso a la Información y Protección de Datos Personales (INAI),  es la autoridad competente para garantizar el acceso y protección de sus DATOS.  Se le informa que, de ser necesario, puede acudir ante el INAI para hacer  efectivos sus derechos.</p>

                  <p><strong>XV. MODIFICACIONES A LOS TÉRMINOS Y  CONDICIONES.</strong></p>

                  <p>El Organizador se reserva  el derecho de ampliar o modificar en cualquier momento los presentes términos y  condiciones, por lo que el participante deberá consultar las bases y  condiciones constantemente y hasta el término de LA PROMOCIÓN.</p>

                  <p><strong>XVI. JURISDICCIÓN APLICABLE</strong></p>

                  <p>Cualquier controversia  suscitada por la promoción y sus complementarias, se regirán por las presentes  bases y como complemento para lo establecido en éstas aplicará la jurisdicción  a elección del Organizador, sujetándose todas las partes interesadas a las  mismas, renunciando al que por beneficio de su domicilio presente o futuro pudiese  corresponderles.</p>

                  <p><strong>XVII. CONTACTO</strong></p>

                  <p>Para la difusión de la  información relacionada con LA PROMOCIÓN, el único medio autorizado por El  Organizador es mensaje directo vía Instagram @pringles_latam.</p>
	              </div>

              </div>
            </section>
          </div>
        </main>
      </section>
    </main>
    <Footers></Footers>
  </div>
</template>
<script>
import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footers,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("setBodyClass", "global");
  },
  metaInfo() {
    return {
      title: 'Touch down Pringles® | Términos y Condiciones',
      meta: [
        {
          name: 'description',
          content: 'Consulta los Términos y Condiciones de la promoción Touch down Pringles®.'
        },
        {
          name: 'keyword',
          content: ''
        },
      ]
    }
  },
}
</script>