<template>
  <div>
    <Headers></Headers>
    <main class="mainWrp">
      <section class="globalWrp">
        <aside class="titleSectionWrp">
          <router-link
            class="backLink"
            :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}"
          >{{ $t('back')}}</router-link>
          <h2>{{ $t('legals')}}</h2>
        </aside>
        <main class="one-whole heightScroll">
          <div class="one-whole">
            <div class="headerTerms one-whole">
              <img
                src="@/assets/images/global/banners/gana-una-recarga/466x276-gana-una-recarga.jpg"
                alt="Gana tiempo de aire con Pringles®"
              />
              <div class="titleTermsWrp one-whole">
                <p class="year">2020</p>
                <h4 class="titleTerms">GANA TIEMPO DE AIRE CON PRINGLES®</h4>
                <h5>TÉRMINOS Y CONDICIONES</h5>
              </div>
            </div>
            <!-- Insertar section terminos -->
            <section class="terms">
              <div class="terms-wrp">
                <div class="wrappTerms">
                  <p>Al participar en la promoción &ldquo;Gana Tiempo de Aire con Pringles®&rdquo; (en lo sucesivo la &ldquo;Promoción&rdquo;), el participante declara haber leído, entendido y aceptar totalmente a los Términos y condiciones aquí establecidas (en adelante las &ldquo;Bases&rdquo;).</p>
                  <p>Responsable de la promoción: Corporación Tarasca, Sociedad Anónima, con domicilio en 12 calle 1-25 Edificio Géminis Diez, Torre Sur, 16o. nivel, oficina 1607, zona 10, Ciudad de Guatemala, Guatemala.</p>
                  <p>Patrocinadora de la promoción: Alimentos Kellogg de Panamá, Sociedad de Responsabilidad Limitada con domicilio en Juan Díaz, Calle Llano Bonito, Complejo Santa María Business District, Edificio Argos (Night Fall) 6° Piso, Panamá, Panamá.</p>
                  <p>Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al teléfono 1 800 835 7465 de lunes a viernes de 8:00 a 17:00hrs.</p>
                  <p>
                    <strong>I.- COBERTURA:</strong> República de Honduras, en todos los establecimientos en que se comercialicen los productos participantes.
                  </p>
                  <p>
                    <strong>II.- PARTICIPANTES:</strong>
                  </p>
                  <p>Se entenderá como &ldquo;Participante&rdquo; al público consumidor en general, que tenga la disposición de participar en la Promoción y reúna todos y cada uno de los siguientes requisitos:</p>
                  <ul class="bullets">
                    <li>Ser mayor de edad con plena capacidad de ejercicio y contar con identificación oficial con fotografía vigente (Documento Personal de Identificación).</li>
                    <li>Y tener legal residencia en la República de Honduras</li>
                    <li>Aceptar las presentes Bases.</li>
                    <li>Comprar los Productos Pringles® marcadas con la Promoción (en lo sucesivo Los &ldquo;Productos Participantes&rdquo;) durante la Vigencia de la Promoción y que estén a la venta en la República de Honduras</li>
                    <li>Dar lectura total y aceptar las presentes Bases.</li>
                    <li>Registrar los códigos únicos que se encuentran impresos en la membrana (la tapa de papel que cubre el envase) de cada lata de Pringles® identificada con la Promoción en el sitio web: www.promopringles.com siguiendo las instrucciones correspondientes de acuerdo a la mecánica descrita en éste documento. Se deberán conservar las membranas con los códigos marcados a fin de poder realizar cualquier aclaración.</li>
                    <li>Obtener un registro de participante en el número de WhatsApp, proporcionar datos verídicos, y completar la mecánica de la promoción.</li>
                  </ul>
                  <p>
                    <strong>III.- VIGENCIA DE LA PROMOCIÓN:</strong>
                  </p>
                  <p>Los códigos de la promoción tendrán vigencia del 1 de octubre de 2020 al 31 de marzo de 2021 o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes (la &ldquo;Vigencia&rdquo;). Los códigos ganadores de tiempo de aire reciben la recarga de manera electrónica, la cual se acreditará al saldo del número de teléfono prepago con el que participaron.</p>
                  <p>
                    <strong>IV.- INCENTIVOS:</strong>
                  </p>
                  <ol class="letter" type="a">
                    <li>Incentivos de Canje al Registro</li>
                  </ol>
                  <p>Total: Hasta 8.000 beneficios de canje de acuerdo al número de productos participantes registrados, consistentes en:</p>
                  <ul class="bullets">
                    <li>Hasta 375 recargas de tiempo de aire según demanda y del operador de telefonía celular que utilicen los participantes.</li>
                    <li>7,625 juegos digitales en línea</li>
                  </ul>
                  <div class="globalTableWrp">
                    <table border="0" cellpadding="0" cellspacing="0" class="globalTable">
                      <tr>
                        <th>Recarga de tiempo de aire de L.100.00 de Claro</th>
                        <th>Recarga de tiempo de aire de L.145.00 de Tigo</th>
                        <th>Juego digital en línea</th>
                      </tr>
                      <tr>
                        <td>Según participación</td>
                        <td>Según participación</td>
                        <td>7,625 juegos</td>
                      </tr>
                    </table>
                  </div>
                  <ol class="letter" type="a" start="2">
                    <li>Registro para participación: Los participantes al registrar los códigos de los productos identificados con la Promoción podrán ganar uno de los siguientes incentivos, hasta agotar existencias de acuerdo con las bases aquí descritas:</li>
                  </ol>
                  <ul class="bullets">
                    <li>
                      Recarga de tiempo de aire por el valor descrito a continuación según el operador de telefonía celular local que le provee del servicio:
                      <ul class="bullets">
                        <li>Claro = L. 100.00</li>
                        <li>Tigo = L. 145.00</li>
                      </ul>
                    </li>
                    <li>1 juego digital en línea.</li>
                  </ul>
                  <p>
                    <strong>V.- PRODUCTO PARTICIPANTES:</strong>
                  </p>
                  <p>Los Productos Participantes son los siguientes siempre y cuando se encuentren marcados con la promoción:</p>
                  <ul class="bullets">
                    <li>Pringles® Original 149g</li>
                    <li>Pringles® Crema y Especias 149g</li>
                    <li>Pringles® Queso 149g</li>
                    <li>Pringles® Original 37g</li>
                    <li>Pringles® Crema y Especias 40g</li>
                    <li>Pringles® Queso 40g</li>
                  </ul>
                  <p>
                    <strong>VI.- MECÁNICA DE PARTICIPACIÓN:</strong>
                  </p>
                  <p>Pasos:</p>
                  <ol class="decimal">
                    <li>El participante deberá comprar durante la vigencia de la Promoción productos Pringles® participantes marcadas con la promoción en los establecimientos en que se comercialicen.</li>
                    <li>En cada lata y detrás de la membrana (tapa de papel), la persona encontrará un código único.</li>
                    <li>El participante deberá enviar desde el número prepago al que requiere que se acredite la recarga el código como mensaje al número de WhatsApp +502 3340-6500</li>
                    <li>
                      El participante recibirá mensaje de respuesta con la notificación del premio que ha ganado el cual puede ser una de las siguientes opciones mientras hayan existencias:
                      <ul class="bullets">
                        <li>Recarga de tiempo de aire de L. 100.00 en el caso de que su operador de telefonía celular sea Claro.</li>
                        <li>Recarga de tiempo de aire de L. 145.00 en el caso de que su operador de telefonía celular sea Tigo</li>
                        <li>Juego digital en línea</li>
                      </ul>
                    </li>
                    <li>Si el participante resulta ganador de una recarga de tiempo de aire, ésta se acreditará al saldo del número de teléfono celular con el que participó, es decir con el que envió el código único, (aplica solamente a planes prepago).</li>
                    <li>Si el participante resulta ganador de un juego digital en línea, recibirá como mensaje de respuesta, la respectiva notificación junto a un enlace que le dará acceso al micrositio en donde podrá jugar en línea el juego de su elección luego de registrar su país de residencia y el número de celular con el cual participó.</li>
                    <li>En caso de no ser válido el código, se le comunicará por medio de mensaje de respuesta y se le invitará a seguir participando.</li>
                  </ol>
                  <p>Limitaciones:</p>
                  <ul class="bullets">
                    <li>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de la Patrocinadora, de la Responsable ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                    <li>Limitado hasta agotar existencias de los 8,000 incentivos.:</li>
                    <li>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a la Responsable.</li>
                    <li>El Participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir la aplicación WhatsApp; (ii) acepta ser el único responsable (frente a la Responsable, la Patrocinadora y a otros terceros) de toda la actividad que realice en el uso de la aplicación para efectos de participar en la Promoción, y mantendrá indemne a la Responsable, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</li>
                  </ul>
                  <p>
                    <strong>VII.- CONDICIONES GENERALES:</strong>
                  </p>
                  <p>a. CÓDIGOS NULOS. -</p>
                  <ul class="bullets">
                    <li>Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica.</li>
                    <li>Copias o facsímiles de membranas con los códigos originales.</li>
                    <li>Aquellos que al momento de ser presentados a validación, presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados sobre-armados o laminados superpuestos en su interior o superficies, una vez que sean requeridos para verificar la autenticidad de la participación.</li>
                    <li>La Responsable y la Patrocinadora se reservan el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes.</li>
                    <li>Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                  </ul>
                  <p>b. VALOR UNITARIO DE PARTICIPACIÓN. -</p>
                  <p>No tendrá ningún costo adicional para el Participante, siempre y cuando se sujete a la mecánica de participación descrita en las presentes Bases.</p>
                  <p>c. DATOS PERSONALES (AVISO DE PRIVACIDAD). -</p>
                  <p>La información personal que Los Participantes proporcionen con el fin de recibir alguno de los incentivos de la Promoción, quedarán incorporados a una base de datos que conservará La Patrocinadora, ante la que cualquier Usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, la Responsable y la Patrocinadora quedarán liberadas respecto de las entregas o beneficios por otorgar a los Participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                  <p>La Patrocinadora utilizará la información proporcionada para la realización de la Promoción con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece la Patrocinadora, así como con prestadores de servicios contratados por la Patrocinadora para la ejecución de la actividad hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial de la Patrocinadora.</p>
                  <p>
                    Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor
                    <a
                      href="mailto:consumer-affairs.la@kellogg.com"
                    >consumer-affairs.la@kellogg.com</a> o llamando al teléfono 1 800 835 7465
                  </p>
                  <p>
                    <strong>VIII.- LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR:</strong>
                  </p>
                  <p>La Responsable y la Patrocinadora, no serán responsable de modificaciones en el estado de salud a consecuencia de la participación en la Mecánica por parte del Participante.</p>
                  <p>La Responsable y la Patrocinadora, podrán en cualquier momento suspender La Promoción o la entrega del incentivo sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier disposición emitida por cualquier autoridad jurisdiccional, así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten los intereses de la Responsable o de la Patrocinadora. Si por causas ajenas a la Responsable o a la Patrocinadora se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los Participantes que resultaran ser acreedores para disfrutar del incentivo o de la actividad programada, el organizador, se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que pusieran en peligro la integridad física de cualquiera de los Participantes que resultaran ser acreedores. En todos estos casos la Responsable o la Patrocinadora publicará a través de medios impresos y/o a través de su página web los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</p>
                  <p>No se podrá canjear el incentivo por otro de cualquier tipo y/o dinero en efectivo. Ninguno de los incentivos estará sujetos de comercialización alguna, de manera enunciativa más no limitativa, venta, permuta, arrendamiento, sustitución o dación en pago.</p>
                  <p>Si existen indicios suficientes, a criterio de la Responsable y la Patrocinadora, que señalen que los participantes obtuvieron sus códigos por medios ilegales o distintos a los establecidos en estas bases, podrá cancelarse la participación de ese Participante, y en su caso, dar aviso a las autoridades correspondientes.</p>
                  <p>La responsabilidad de la Responsable y la Patrocinadora concluye al momento de la entrega de cada uno de los incentivos a los correspondientes Participantes que resulten ser acreedores.</p>
                  <p>La Responsable y la Patrocinadora, no serán responsables por el uso que el Participante haga respecto a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación con un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                  <p>El Participante que resulte ser acreedor a un incentivo deberá seguir todas las direcciones e instrucciones de la Responsable y la Patrocinadora, en caso de incumplimiento de cualquiera de estas el Participante será descalificado.</p>
                  <p>La Responsable y la Patrocinadora no garantiza la disponibilidad y/o continuidad del funcionamiento de la aplicación WhatsApp ni tampoco garantiza el acceso efectivo a la misma, siendo esto responsabilidad exclusiva de las prestadoras de servicios de mensajería. En el mismo sentido, la Responsable no garantiza que los Participantes utilicen la aplicación y/o la Promoción de conformidad con estas Bases y Condiciones ni que lo hagan de forma prudente y diligente. Al participar en la Promoción el Participante que resultara siendo acreedor acepta expresamente haber leído y entendido a la perfección las Bases y estar de acuerdo con las mismas.</p>
                  <p>La Responsable y la Patrocinadora se reservan el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica e incentivos, siempre que las mismas estén justificadas o no perjudiquen a los participantes, y se comuniquen a éstos debidamente.</p>
                  <p>
                    <strong>IX.- JURISDICCIÓN APLICABLE:</strong>
                  </p>
                  <p>Cualquier controversia suscitada por La Promoción se regirá por las presentes Bases y como complemento para lo establecido en éstas será aplicable la jurisdicción de República de Honduras, sujetándose todas las partes interesadas a las mismas renunciando al que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                  <p>
                    <strong>X.- AVISO DE PRIVACIDAD:</strong>
                  </p>
                  <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es Responsable por cuenta de la Patrocinadora de la Promoción.</p>
                  <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de esta, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto.</p>
                  <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Alimentos Kellogg de Panamá, Sociedad de Responsabilidad Limitada con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán directamente como La Patrocinadora de la Promoción por conducto de los medios de comunicación que se han señalado anteriormente en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                  <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopringles.com la información consistente en Datos Personales proporcionada por los participantes será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </section>
    </main>
    <Footers></Footers>
  </div>
</template>
<script>
import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footers,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("setBodyClass", "global");
  },
  metaInfo() {
    return {
      title: 'Gana tiempo de aire con Pringles® | Términos y condiciones'
    }
  },
};
</script>