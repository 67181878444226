<template>
    <section class="redBg">
        <div>
            <!--<div class="leftSelect">
                <figure >
                    <img src="@/assets/images/global/selectorImg.png" width="638" height="584" alt="produtos Pringles" class="expandOpen">
                </figure>
            </div>
            <div class="rightSelect">
                <div class="wrappRight">
                    <img class="logoMob" src="@/assets/images/logoPringles.png"  alt="Pringles" width="100" height="125">
                    <form @submit.prevent="selectCountry">
                        <label><span class="red1">Selecciona tu país</span> <br />
                            <span class="red2">Selecione seu país</span><br />
                            <span class="red3">Choose your country</span></label>
                        <select class="selectedCountry" role="listbox" title="Selecciona tu país" @change="handleChange" v-model="country">
                            <option value="AR">Argentina</option>
                            <option value="BR">Brasil</option>
                            <option value="CO">Colombia</option>
                            <option value="CR">Costa Rica</option>
                            <option value="EC">Ecuador</option>
                            <option value="SV">El Salvador</option>
                            <option value="GT">Guatemala</option>
                            <option value="HN">Honduras</option>
                            <option value="MX">México</option>
                            <option value="NI">Nicaragua</option>
                            <option value="PA">Panamá</option>
                            <option value="PE">Perú</option>
                            <option value="PR">Puerto Rico</option>
                            <option value="DO">República Dominicana</option>
                        </select>
                        <button type="submit">Continuar</button>
                    </form>
                    <img src="@/assets/images/global/selectorImg.png" width="638" height="584" alt="produtos Pringles" class="cans">
                </div>
            </div>
            <footer class="footSelector">
                <div class="dereitos"> ® ™ © Derechos Reservados Kellogg Europe Trading Limited 2021</div>
                <div class="legal"> COME BIEN / COME BEM
                </div>
            </footer>-->
        </div>
        <h1 class="not-visible">{{ $t('selectHomeH1')}}</h1>
    </section>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            idCountry: 'null',
            country: 'AR',
            countryName: 'Argentina',
            country_id: localStorage.getItem('country')
        }
    },
    //Nuevo codigo Redirigir A pais por Ip
    async beforeCreate() {
        const paises = ['AR', 'BR', 'CO', 'CR', 'EC', 'SV', 'GT', 'HN', 'MX', 'NI', 'PA', 'PE', 'PR', 'DO', 'BO', 'CARICOM', 'PY', 'UY'];
        var country = "UY";
        var countryName = "Paraguay";
        var data = null;
        try {
            data = await axios
                .get('https://ipapi.co/json/');
        } catch(err) {
            /*console.log(err);*/
            //alert("err: " + data.data);
        }
        if (data.data.country == undefined) {
            /*console.log("Pais no encontrado");*/
            //alert("undefined: " + data.data);
        }
        else {
            //alert("Ipap: " + data.data.country);
            if (paises.includes(data.data.country)) {
                country = data.data.country;
                countryName = data.data.country_name;
            }
        }
        //Para ver si es el resetear contraseña
        const selector = localStorage.getItem('resetpassword');
        //Eliminamos cookies de pais
        let cookiesKey = ['country', 'countryName', 'countryArea', 'language']
        cookiesKey.map((value) => {
            return localStorage.removeItem(value)
        });
        this.$store.dispatch('deleteCookie', 'countrylanguaje')
        //Guardamos el nuevo pais en localstorange y cookies
        localStorage.setItem('country', country);
        localStorage.setItem('countryName', countryName);

        this.$store.commit('setCountry', { country: country })
        this.$store.commit('setCountryName', countryName)

        this.$store.dispatch('setSiteCountryArea', country)

        let countryId = country.toLowerCase()

        if (selector) {
            this.$store.commit('setResetId', selector)
            window.location.href = '/music/login#resetpassword?' + selector

        } else {
            if (countryId) {
                //if (countryId !== 'uy' && countryId !== 'py')
                //{
                    if (country === 'TT') {
                        this.$store.dispatch('setLanguageLocale', 'en')
                        localStorage.setItem('language', 'en')
                        document.cookie = 'countrylanguaje=en-TT'

                    } else if (country === 'BR') {
                        document.cookie = 'countrylanguaje=pt-BR'
                        this.$store.dispatch('setLanguageLocale', 'pt')
                        localStorage.setItem('language', 'pt')
                    }
                    else {
                        this.$store.dispatch('setLanguageLocale', 'es')
                        localStorage.setItem('language', 'es')
                    }
                    //Aquí esta haciendo el render
                    this.$router.push({ name: 'promoHome', params: { countryId } })
                //} else {
                //    if (country === 'PY') {
                //        window.location.href = 'https://www.pringlesgamer.com.co/PY'
                //    }
                //    //else if (country === 'UY') {
                //    //    window.location.href = 'https://www.pringlesgamer.com.co/UY'
                //    //}
                //}

            }
        }
    },
    //FIn nuevo codigo
    mounted() {
        /*let footerBye = document.querySelector('footer .wrappFoot')
        footerBye.style.display = "none"
        if( this.country ) {
            document.body.classList = ''
            this.$store.dispatch('setBodyClass', 'global')
        } else if(!this.country) {
            this.$store.dispatch('setBodyClass', '')
        }
        if(window.location.href === window.location.protocol + '//' + window.location.host + '/'){
            this.removeCookies()
        }*/
    },
    methods: {
        /*selectCountry() {
            const selector = localStorage.getItem('resetpassword')

            this.removeCookies()

            localStorage.setItem('country', this.country)
            localStorage.setItem('countryName', this.countryName)

            this.$store.commit('setCountry', { country: this.country })
            this.$store.commit('setCountryName', this.countryName)

            //this.$store.dispatch('setLanguageLocale', this.country)
            this.$store.dispatch('setSiteCountryArea', this.country)


            let countryId = this.country.toLowerCase()

            if (selector) {

                this.$store.commit('setResetId', selector)
                window.location.href = '/music/login#resetpassword?' + selector

            } else {

                if(countryId){
                    
                    if(countryId !== 'uy' && countryId !== 'py'){
                        if (this.country === 'TT') {
                            this.$store.dispatch('setLanguageLocale', 'en')
                            localStorage.setItem('language', 'en')
                            document.cookie = 'countrylanguaje=en-TT'
                            //this.$router.push({ name: 'homeDeezerTT' })

                        } else if(this.country === 'BR'){
                            //console.log('brasil cookie')
                            document.cookie = 'countrylanguaje=pt-BR'
                            this.$store.dispatch('setLanguageLocale', 'pt')
                            localStorage.setItem('language', 'pt')
                        }
                        else {
                            this.$store.dispatch('setLanguageLocale', 'es')
                            localStorage.setItem('language', 'es')
                            //this.$router.push({ name: 'homeDeezer' })
                        }

                        this.$router.push({ name: 'promoHome', params: { countryId } })
                    } else{
                        
                        if (this.country === 'UY') {
                            window.location.href = 'https://www.pringlesgaming.com/uy'
                        }
                        else if (this.country === 'PY') {
                            window.location.href = 'https://www.pringlesgaming.com/py'
                        }
                    }
                    
                } 
            }

        },
        removeCookies() {
            let cookiesKey = ['country', 'countryName', 'countryArea', 'language']

            cookiesKey.map((value) => {
                return localStorage.removeItem(value)
            });

            this.$store.dispatch('deleteCookie', 'countrylanguaje')
        },
        handleChange(e) {
            let element = e.target
            this.country = element.value
            this.countryName = element[element.selectedIndex].text
            //console.log(this.countryName)
            this.$store.commit('setCountryName', this.country.name)
            this.$store.commit('setCountry', this.country)
            
        }*/
    },
    metaInfo() {
        /*localStorage.setItem('country', this.country)
        localStorage.setItem('countryName', this.countryName)

        this.$store.commit('setCountry', { country: this.country })
        this.$store.commit('setCountryName', this.countryName)
        let countryId = this.country.toLowerCase()

        if(countryId){
        
            const country_i = localStorage.getItem('country').toLowerCase()
        
            if (country_i === 'do' ||
                country_i === 'mx' ||
                country_i === 'co' ||
                country_i === 'ec' ||
                country_i === 'pe' ||
                country_i === 'cr' ||
                country_i === 'sv' ||
                country_i === 'gt' ||
                country_i === 'hn' ||
                country_i === 'ni' ||
                country_i === 'pa' ||
                country_i === 'py' ||
                country_i === 'uy' ||
                country_i === 'ar') {
                return {
                    title: 'Selecciona tu país y conoce las promociones. | Pringles® Internacional',
                    meta: [{
                            name: 'description',
                            content: 'Selecciona tu país, conoce todas las promociones que tenemos para ti y descubre que con Pringles® todo cae mejor.'
                        },
                        {
                            name: 'keyword',
                            content: ''
                        },
                    ]
                }
            } else if (country_i === 'tt'){
                return {
                    title: 'Choose your country to learn more about the promotions.',
                    meta: [{
                            name: 'description',
                            content: 'Choose your country to learn more about our promotions and discover that with Pringles®everything is better.'
                        },
                        {
                            name: 'keyword',
                            content: ''
                        },
                    ]
                }
            } else if (country_i === 'br'){
                return {
                    title: 'Selecione seu país e conheça as promoções. Pringles® Internacional.',
                    meta: [{
                            name: 'description',
                            content: 'Selecione seu país, conheça todas as promoções que temos para você e descubra porque tudo fica melhor com Pringles®.'
                        },
                        {
                            name: 'keyword',
                            content: ''
                        },
                    ]
                }
            }
        }*/

    }
}
</script>
