<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms.jpg"  alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMO PRINGLES® XMAS</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <h1 class="not-visible">Términos y Condiciones de Snackea, juega y repite Pringles®.</h1>
                               
                                <p>Los términos y condiciones descritos a continuación delimitan y aclaran las condiciones bajo las cuales se regirá la actividad promocional “Pringles® XMAS 2018”. Lo aquí dispuesto es obligatorio para todos los participantes. Se entenderá que todo participante, al decidir participar en la actividad, conoce y acepta las condiciones y limitaciones establecidas en el presente reglamento; y acepta expresamente el uso de sus datos personales en los términos que más adelante se indica.</p>
                                <p>KELLOGG ECUADOR C. LTDA. ECUAKELLOGG, portadora del RUC 099135727001, es la responsable de la Actividad Promocional, y es quien podrá modificar en cualquier momento el contenido del presente documento. Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al +(593) 4 3707440</p>
                                <p>Cualquier violación a los procedimientos o sistemas establecidos para la realización de la actividad promocional implicará la inmediata revocación de los premios. Los premios se otorgarán únicamente si los ganadores cumplen estrictamente con los términos, condiciones y requisitos previstos en este reglamento.</p>
                                <p>Para participar en la presente promoción denominada “Pringles® XMAS 2018” (en lo sucesivo la “Promoción”), se deberá cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de los mismos, así como del Aviso de Privacidad para el tratamiento de datos personales (en conjunto en adelante las “Bases”).</p>
                                <p>Para efectos de estas Bases se entenderá por:</p>
                                <h3>DEFINICIONES</h3>
                                <p><strong>1. Cobertura Geográfica</strong>La presente promoción será válida en Ecuador (en lo sucesivo el “Territorio” y/o “Ecuador”)</p>
                                <p><strong>2. Sitio WEB de la promoción:</strong>www.promopringles.com (en lo sucesivo El Sitio o página WEB), al cual se podrá acceder desde el banner que estará disponible en www.pringles.com</p>
                                <p><strong>3. La Cuenta</strong> Al Registro de Participante que lo identificará y distinguirá como Usuario respecto de los demás participantes de la promoción. Su administración es personal y responsabilidad del usuario.</p>
                                <p><strong>4. Registro de Participante.</strong> Se integra de los datos personales proporcionados voluntariamente por el Usuario dentro del Sitio WEB y que componen la Cuenta, información que el participante autoriza utilizar para efectos de la promoción y que consisten en:</p>
                                <ul>
                                    <li>Nombre</li>
                                    <li>Apellido</li>
                                    <li>Fecha de nacimiento</li>
                                    <li>Teléfono</li>
                                    <li>E-mail</li>
                                    <li>Contraseña</li>
                                </ul>
                                <p></p>
                                <p>5. Este registro se integra con el nombre completo, fecha de nacimiento, teléfono, correo electrónico y contraseña o clave de acceso, así como en caso de resultar ganador, con la nacionalidad y domicilio, autorizando el uso de los mismos para efectos de la promoción.</p>
                                <p><strong>6. Vigencia</strong> Periodo en el cual se llevará a cabo toda la actividad promocional. La vigencia de la promoción es desde las 00:00:01 del 1 de octubre del 2018 hasta las 23:59:59 del 31 de enero de 2019 para participar de la promoción o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes.</p>
                                <p>Con cinco periodos de participación:</p>
                                <table class="globalTable">
                                    <tr>
                                        <th>Periodo</th>
                                        <th>Fechas</th>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>01/10/2018 - 24/10/2018</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>25/10/2018 - 18/11/2018</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>19/11/2018 - 13/12/2018</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>14/12/2018 - 9/01/2019</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>10/01/2019 - 31/01/2019</td>
                                    </tr>
                                </table>
                                <p><strong>7. Participante</strong> Persona física con 18 años cumplidos, residente en territorio ecuatoriano, que cumpla con los requisitos establecidos en las Bases, no incurra en algún supuesto de descalificación, haya ingresado al Sitio WEB, proporcionando voluntaria, verídica y de forma correcta la información que se le solicite y, por lo tanto, cuente con el Registro de Participante que le permite acceder a la Cuenta, así como que haya cumplido con las condiciones que exige la Dinámica y en caso de resultar ganador, completar su registro conforme a las presentes bases.</p>
                                <p><strong>8. Mecánica de la promoción</strong> La promoción consta de los siguientes pasos:<br></p>
                                <ul>
                                    <li>1. Encuentra códigos únicos impresos al interior de los empaques de los productos participantes Pringles®.</li>
                                    <li>2. Ingresa a www.pringles.com, busca el banner de la promoción que te direccionará al micrositio www.promopringles.com, crea tu registro o ingresa a tu cuenta.</li>
                                    <li>3. Elige el tipo de premio por el que participarás en la promoción.<br>a) Tema PS Exclusivo<br>b) Cupón $5 dlls PS Store<br>c) Cupón $9.99 dlls PS Plus<br>d) Consola<br></li>
                                </ul>
                                <p></p>
                                <p>- Registro por incentivos de canje:</p>
                                <p>Si el premio que elegiste se encuentra entre los premios a), b) o c), deberás registrar el o los códigos únicos requeridos, para validación, de acuerdo con el tipo de premio según la siguiente tabla:</p>
                                <table class="globalTable" width="531">
                                    <tr>
                                        <td>Tema Exclusivo</td>
                                        <td>Cupón $5 dollars PS Store</td>
                                        <td>Cupón $9.99 dollars PS Plus</td>
                                    </tr>
                                    <tr>
                                        <td>1 producto participante de cualquier gramaje</td>
                                        <td>2 productos participantes de 149g</td>
                                        <td>3 productos participantes de 149g</td>
                                    </tr>
                                </table>
                                <p>Si uno o más de los códigos ingresados no son válidos, se te comunicará y se te invitará a seguir participando; sin embargo, en caso de que la validación sea exitosa, el consumidor obtendrá un PDF con el cupón para redención del incentivo elegido o el Tema Exclusivo, según sea el caso. Dicho cupón solo podrá ser canjeado a partir del 15 de octubre de 2018 en el PS Store de Ecuador: <a href="https://store.playstation.com/es-ec/home/games" target="_blank">https://store.playstation.com/es-ec/home/games</a></p>
                                <p>- Registro para participación</p>
                                <p>En el caso de que decidas participar por la el premio d) consola deberás:</p>
                                <ul>
                                    <li>• Consultar durante los periodos de participación los números predeterminados para concursar*, así como el contador de los folios de participación que se han ido registrando para calcular el momento en que registrará su código e intentar ser uno de los 5 ganadores. El contador de los folios se reinicia en cada periodo de participación.</li>
                                    <li>• Registra el código único de 1 producto participante para validación, en caso de ser válido se generará un folio de participación. En caso de no ser válido se te comunicará y se te invitará a seguir participando.</li>
                                    <li>• Si el folio de participación es uno de los 5 números predeterminados para concursar que habrá durante la vigencia de la promoción, el sistema en automático arrojará una ventana emergente para que inicies la fase de Trivia, en la que deberás contestar correctamente 3 preguntas de cultura general de opción múltiple.</li>
                                    <li>• Serás uno de los Ganadores, si te encuentras entre los 5 Participantes que contesten correctamente las preguntas de la Trivia.</li>
                                    <li>• Si contestas incorrectamente, no podrás ser acreedor al premio y se dará por terminada tu participación, pero se te enviará un Tema PS Exclusivo al correo que registraste.</li>
                                    <li>• En caso de que no se llegue a la totalidad de premios asignados para el periodo de participación de acuerdo al anexo de Números Predeterminados, debido a la falta de participaciones o registros o por no responder correctamente a la fase de trivia, se asignarán los incentivos a los folios de participación que más se acerquen al número predeterminado de forma descendente en cada caso que aplique, para realizar la fase de trivia y tener al final de la promoción todos los incentivos otorgados.</li>
                                    <li>• Publicación de ganadores: La publicación de los nombres ganadores se darán a conocer una vez que se haya validado su identidad y participación través del micrositio www.promopringles.com.</li>
                                </ul>
                                <p>*Números predeterminados (Ver anexo A): Los números predeterminados a concursar se establecen previo al inicio de la promoción, por periodo de participación, y serán publicados en el sitio web de la promoción www.promopringles.com, por lo que NO podrán ser modificados o alterados. Asimismo, en el micrositio de la promoción se va a colocar un contador en donde irán apareciendo en tiempo real los folios de participación que se han ido registrando vía web, para que el participante haciendo uso de su voluntad elija el momento de su registro para alcanzar el número predeterminado y poder concursar por la(s) consola(s).</p>
                                <p>La obtención de incentivos por Participante está limitada a:</p>
                                <table class="globalTable" width="531">
                                    <tr>
                                        <td>Tema Exclusivo</td>
                                        <td>Cupón $5 dollars PS Store</td>
                                        <td>Cupón 1 mes de suscripción PS Plus</td>
                                    </tr>
                                    <tr>
                                        <td>ilimitado</td>
                                        <td>10 incentivos por participante</td>
                                        <td>10 incentivos por participante</td>
                                    </tr>
                                </table>
                                <p><strong>9. Premios </strong>Los participantes que resulten ganadores podrán acceder a uno de los siguientes premios:</p>
                                <p>a) Premios de Canje al Registro</p>
                                <p>Total: 5.207 (cinco mil ciento doscientos siete) beneficios de canje de acuerdo al número de productos participantes registrados, consistentes en:</p>
                                <ul>
                                    <li>Tema PS Exclusivo</li>
                                    <li>Crédito de $5US para PS Store online</li>
                                    <li>Crédito de $9.99 para PS Plus</li>
                                </ul>
                                <p></p>
                                <table class="globalTable" width="531">
                                    <tr>
                                        <th colspan="4">Canje al Registro </th>
                                    </tr>
                                    <tr>
                                        <td>Cupón $5 dlls PS Store</td>
                                        <td>Cupón $9.99 dlls PS Plus</td>
                                        <td>Tema Exclusivo</td>
                                    </tr>
                                    <tr>
                                        <td>101 cupones</td>
                                        <td>101 cupones</td>
                                        <td>5,000 cupones tema</td>
                                    </tr>
                                </table>
                                <p>b) Registro para participación:</p>
                                <p>Consola PlayStation 4 Slim Ultimate Player 1TB Edition que incluye 1 palanca, con un valor comercial de $ 539. La promoción comprende la entrega de 5 consolas, 1 a cada ganador.</p>
                                <p>Con 1 año de Garantía a través de Sony Consumer Support.</p>
                                <p><strong>10. Lista de productos participantes</strong><br>Los productos que participarán en la Promoción dentro del Territorio serán los siguientes:<br>Pringles® Original 149g<br>Pringles® Crema y Cebolla 149g<br>Pringles® Queso 149g<br></p>
                                <p>Para efectos de la Promoción, los productos participantes antes mencionados deberán estar marcados con la promoción y contener los códigos respectivos. Dichos productos y/o marcas tienen el registro y/o licencia de su respectivo titular Pringles S.A.R.L.</p>
                                <p><strong>11. Organizadora de la promoción: </strong>KELLOGG ECUADOR C. LTDA. ECUAKELLOGG. DIRECCION: Av. Juan Tanca Marengo, Km 6.5.</p>
                                <p><strong>12. Obligaciones del participante:</strong></p>
                                <ul>
                                    <li>• Tener 18 años de edad con plena capacidad de ejercicio legal y contar con identificación oficial vigente con fotografía y en caso de extranjeros, contar con documento que acredite la legal residencia en Ecuador, expedido por autoridad competente.</li>
                                    <li>• Dar lectura total a las presentes Bases.</li>
                                    <li>• La participación en la promoción da cuenta de la aceptación expresa a las Bases de la misma.</li>
                                    <li>• Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción.</li>
                                    <li>• Conservar en todo momento los códigos únicos que se encuentra impresos en la membrana (la tapa de papel que cubre el envase de cada lata de Pringles®) de los Productos Participantes. Las membranas que contienen el código serán verificadas, validadas y/o entregadas a la Organizadora para confirmar su derecho a obtener el premio y no se actualice algún supuesto de descalificación.</li>
                                    <li>• Aceptar ser el único responsable (frente al Organizador, al Auspiciante y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</li>
                                </ul>
                                <p></p>
                                <p><strong>13. Obligaciones del acreedor/ganador del premio:</strong></p>
                                <ul>
                                    <li>• Completar su registro en el sitio conforme se indica más adelante en las presentes bases.</li>
                                    <li>• Enviar la documentación, proporcionar la información y/o firmar los documentos que les sean requeridos conforme a las presentes bases.</li>
                                </ul>
                                <p></p>
                                <p><strong>14. Motivos de descalificación e impedimento para ser acreedor/ganador del premio :</strong></p>
                                <ul>
                                    <li>• No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                    <li>• Cualquier información, empaque, código promocional o documento proporcionado por el Participante que sea falso.</li>
                                    <li>• Cualquier indicio de que se trata de una solicitud de participación no auténtica o si hay alguna mínima evidencia de que un participante o un tercero relacionado intenta dañar, sacar provecho indebido, o de alguna manera minar la operación legítima de la actividad promocional será descalificado de participar y removido de la actividad. El participante, en estas circunstancias será sometido a las autoridades competentes para los procedimientos civiles y/o penales correspondientes. Dicha determinación estará a discreción de Kellogg’s.</li>
                                    <li>• Se use o desarrolle cualquier tipo de sistema o programa informático que permita realizar la participación de forma automatizada o que modifique los resultados obtenidos. Ya sea directamente o a través de un tercero: utilizando software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; acceder al Sitio Web utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web;</li>
                                    <li>• Descargar, copiar, atacar o intentar alterar o manipular parte o todo el contenido del Sitio y/o se reproduzcan o modifiquen los Códigos Participantes originales de cualquier forma.</li>
                                    <li>• Los previstos en los demás apartados de las presentes Bases.</li>
                                    <li>• Realizar o ejecutar cualquier otro acto o actividad que pudiera ser considerada fraudulenta o inadecuada conforme a la legislación aplicable.</li>
                                    <li>• En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a La Organizadora.</li>
                                </ul>
                                <p></p>
                                <p><strong>15. Entrega de Consolas: </strong>Una vez el participante sea acreedor del premio consistente en la Consola, la Organizadora de la promoción, notificará o entregará el premio de la forma como se describe a continuación:</p>
                                <p>1) Los participantes que resulten acreedores a un Incentivo, serán contactados por la Organizadora o por el tercero que al efecto designe, al correo electrónico proporcionado; solicitándoles completar dicho registro o proporcionando los siguientes datos: nacionalidad y dirección para gestionar el envío en caso de ser acreedor al incentivo.</p>
                                <p>El participante que no responda al correo electrónico enviado para contacto dentro de los siguientes 20 días hábiles a que éste se realice, o en su caso, no complete su registro y/o no proporcione o entregue los empaques registrados, la documentación y/o información que se le solicite con motivo de la presente promoción, perderá derecho a reclamar el incentivo, liberando de cualquier responsabilidad a la Organizadora y otorgándole la facultad de disponer del incentivo como mejor convenga a sus intereses; lo mismo aplica para aquellos incentivos que al término de la vigencia de la promoción no sean asignados por no haberse completado la mecánica de la promoción conforme a las presentes bases.</p>
                                <p>2) Posterior a esto le llegará un correo confirmando el envío del incentivo según los plazos que determine la Organizadora.</p>
                                <p>3) Para la entrega del incentivo es indispensable que el participante entregue los empaques al momento de recibir el premio, así como la documentación que le sea requerida.</p>
                                <p>4) Una vez que se haga la entrega de cualquiera de los Incentivos mencionados mediante la carta recibo, acuse o documento respectivo con el ganador, la Organizadora no se hará responsable por robo o cualquier situación relacionada con el Incentivo entregado al Acreedor al mismo.</p>
                                <p>5) La Organizadora se reserva el derecho de no entregar o redimir los incentivos en caso de comprobar la existencia de conductas fraudulentas por parte de los Participantes que resulten Acreedores a los Incentivos (por ejemplo: alteración de identificaciones, falsedad o alteración de datos e/o información) sin que sea necesario acreditar ante autoridad alguna evidencia de dicha conducta fraudulenta del supuesto Acreedor al Incentivo.</p>
                                <p>6) Para poder recibir los incentivos, en su caso el participante deberá exhibir original o enviar una copia de identificación oficial con fotografía, a fin de acreditar su identidad y firmar la totalidad de documentos que la Organizadora le solicite en su caso, al entregar los incentivos.</p>
                                <p>7) La falta de veracidad o errores en la información que impida o no permita identificar al participante, libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer de los incentivos respectivos para reintegrarlos al inventario disponible de incentivos a beneficio del resto de participantes.</p>
                                <p>8) No se considerará fidedigna la identificación que presente alteraciones en fotografía, en datos generales o que no coincidan con los rasgos físicos del participante, por lo que la Organizadora de la promoción se reserva el derecho de entregar los incentivos a quien presente identificación oficial vigente sin este tipo de alteraciones o errores.</p>
                                <p>9) Es importante señalar que los Incentivos no son transferibles, comercializables o canjeables por dinero en efectivo o algún otro producto o servicio, por lo que los participantes que resulten ganadores no podrán solicitar incentivo distinto a los señalados, en cualquier caso, no teniendo derecho a ejercer reclamación, procedimiento o acción alguna por este concepto.</p>
                                <p><strong>16. Condiciones generales:</strong></p>
                                <ul>
                                    <li>• Los premios NO serán sustituidos por dinero en efectivo, ni por ningún otro premio.</li>
                                    <li>• Los premios son personales y no pueden ser trasferidos a terceros.</li>
                                    <li>• Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica; y, copias o facsímiles de membranas con los códigos originales.</li>
                                    <li>• Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                    <li>• La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes. No serán válidos aquellos códigos que presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados sobre-armados o laminados superpuestos en su interior o superficies.</li>
                                    <li>• En caso de que los ganadores no completen el proceso de asignación de premios en el plazo indicado por La Organizadora, los premios quedarán a disposición de la Organizadora para asignarlos a los ganadores suplentes que cumplan con estas Bases, en caso de que por tiempo no sea posible asignarlo a un ganador suplente, los premios serán utilizados como mejor convenga a los intereses de la Organizadora.</li>
                                    <li>• La Organizadora suspenderá de forma inmediata el Juego Promocional, sin asumir responsabilidad alguna, si se llegaren a detectar delitos, fraudes o cualquier irregularidad, o si se presentara alguna circunstancia de fuerza mayor o caso fortuito. En tal caso, se comunicará al público en general de los cambios a que haya lugar a través de la página web www.promopringles.com.</li>
                                    <li>• En caso que cualquier Participante tuviese algún problema dentro del Sitio, deberá de comunicarse de inmediato a los teléfonos de la Organizadora y reportarlo para que se atienda su requerimiento a la brevedad, dichos teléfonos serán publicados dentro del Sitio. Cualquier error u omisión no reportada, podría no ser atendida por La Responsable de la Promoción. En caso de que el Participante no reporte la anomalía o error dentro de las siguientes 48 horas contadas a partir de la hora en que intente registrar una participación, podría ocasionar una falta de registro de su participación y/o la oportunidad de recibir el incentivo que pudiera corresponderle, lo cual será responsabilidad del propio participante, liberando de cualquier responsabilidad a la Organizadora.</li>
                                    <li>• La Organizadora no será responsable por fallas técnicas en el sistema de captura de datos, ni de la ejecución o desempeño de la dinámica de participación en La Promoción, ya que esto depende tanto de los Participantes como de terceros ajenos.</li>
                                    <li>• Cualquier cambio en el contenido de las Bases de La Promoción, se comunicará y publicará en el Sitio.</li>
                                    <li>• La Organizadora de la Promoción podrá en cualquier momento suspender la Promoción o la entrega de incentivos sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten su ejecución o a sus intereses, así como cualquier acto y/o amenaza que ponga en riesgo la seguridad de los participantes y La Organizadora. Si por causas ajenas a la Organizadora de la Promoción se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para recibir el incentivo, ésta se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo al que participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que hicieran inejecutables las entregas respectivas de incentivos. En todos estos casos la Organizadora de la Promoción publicará a través del Sitio los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo. Ninguno de los incentivos estará sujeto a comercialización o cesión alguna. Cualquier incentivo en tal supuesto da derecho a la Organizadora de la Promoción a su revocación o cancelación respectiva.</li>
                                    <li>• La Organizadora de la Promoción no será responsable por el uso que se le brinde a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos con relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la redención del citado incentivo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</li>
                                    <li>• Los acreedores al incentivo liberan de toda responsabilidad a la Organizadora de la Promoción conforme a lo establecido en estas Bases, al momento de recibir el incentivo.</li>
                                    <li>• La Organizadora de la Promoción emprenderá las acciones legales pertinentes contra aquellos usuarios que pretendan defraudar, engañar o burlar las reglas y procedimientos de la promoción.</li>
                                </ul>
                                <p></p>
                                <p>17. Autorización de usos de datos personales y de imagen:</p>
                                <ul>
                                    <li>• Al participar en la actividad promocional se autoriza expresamente la utilización, publicación y reproducción, sin limitación o restricción alguna, de la imagen y datos personales, así como el uso y la publicación del nombre y domicilio de quien resulte ganador, en cualquier tipo de publicidad, promoción, publicación, a través de cualquier medio, con fines comerciales o informativos por parte de Kellogg’s.</li>
                                    <li>• Los participantes y/o ganadores no tendrán derecho a recibir contraprestación alguna por los hechos descritos en el presente numeral.</li>
                                    <li>• Así mismo se autoriza el envío de información comercial de Kellogg.</li>
                                    <li>• El Participante al formar parte de La Promoción, autoriza expresamente a la Organizadora de la Promoción y se obliga a suscribir cualquier documento que resulte necesario en favor de éstas, que tenga como finalidad fijar y difundir su imagen, nombre y carácter de acreedor de cualquier incentivo, así como su imagen y/o voz para la elaboración de material fotográfico o testimonial de la Promoción y su difusión en los medios de comunicación conocidos o por conocerse que la Organizadora de la Promoción juzgue convenientes incluyendo las denominadas redes sociales. Los Participantes acreedores por haber recibido el incentivo como contraprestación se obligan a no revocar tal autorización.</li>
                                    <li>• Los testimoniales a que se hacen referencia en el párrafo anterior y en su caso, cualquier obra producida derivado de la presente actividad promocional serán propiedad de la Organizadora de la Promoción, las cuales podrá difundir y utilizar de la manera que más convenga a sus intereses, sin que esto implique pago alguno a dichos participantes por su aparición en cualquier medio de comunicación.</li>
                                    <li>• En consideración a las autorizaciones otorgadas conforme a los párrafos anteriores, los participantes ceden en favor de la Organizadora de manera gratuita, es decir sin contraprestación a cambio alguna, de manera indefinida todos los derechos tangibles e intangibles de propiedad intelectual e/o industrial que en su caso pudieran corresponderles sobre los materiales generados, para su explotación, difusión, comunicación al público y puesta a disposición del público en todos los territorios y con la frecuencia de repetición que convenga a sus intereses y/o a cualquiera de sus empresas controladoras, subsidiarias, afiliadas, licenciatarias, representantes legales, cesionarios y/o cualquier entidad relacionada con o que éstas indiquen, en los que estén contenidos su nombre, voz y/o imagen, así como cualquier interpretación y/o ejecución artística o parte de las mismas, utilizando los medios técnicos y tecnológicos conocidos en la actualidad y los que pudieran desarrollarse en el futuro, y para cualquier tipo de aplicación o medio de difusión conforme convenga a sus intereses, autorizando además a efectuar cambios, modificaciones, adiciones y supresiones de dichos materiales sin limitación alguna.</li>
                                </ul>
                                <p></p>
                                <p><strong>18. Aviso de privacidad:</strong></p>
                                <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.kellogg.com.co la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg, con fines exclusivos de realizar la Promoción. Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 01 800 00 KELLOGG (5355644).</p>
                                <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopringles.com, la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                <p><strong>19. Modificación de los términos y condiciones:</strong></p>
                                <p>En caso de resultar necesario, y/o a discreción de Kellogg’s, ésta empresa se reserva el derecho de ampliar y/o aclarar el alcance de los presentes Términos y Condiciones, y/o cualquiera de los procedimientos, los cuales serán comunicados a través de www.promopringles.com.</p>
                                <p><strong>20. Sanciones:</strong></p>
                                <p>Los Participantes aceptan que cualquier incumplimiento de las obligaciones contenidas en estas condiciones y restricciones, facultan al Organizador, para el inicio de las acciones legales a que haya lugar. Los Participantes aceptan indemnizar, defender y mantener indemne al Organizador y sus socios, accionistas, personal y compañías afiliadas ante cualquier responsabilidad, pérdida, reclamación y gasto, incluyendo honorarios y gastos de abogados, si es resultado de la violación de estos términos.</p>
                                <p><strong>21. Jurisdicción y ley aplicable:</strong></p>
                                <p>Cualquier controversia suscitada con motivo de la Promoción, se regirá por las presentes Bases y como complemento para lo establecido en éstas, aplicará la jurisdicción de Ecuador, sujetándose todas las partes interesadas en la misma, incluyendo a los Participantes y en su caso, acompañante, quienes renuncian a cualquier otra jurisdicción a la que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                <p>Los Participantes reconocen y aceptan que la ley aplicable para cualquier controversia que surja con relación a la Actividad será la de la República de Ecuador, y renuncian a su derecho a iniciar cualquier tipo de reclamación en otra jurisdicción.</p>
                                <table class="globalTable" width="531">
                                    <tr>
                                        <th colspan="4">ANEXO A NÚMEROS PREDETERMINADOS.</th>
                                    </tr>
                                    <tr>
                                        <td># Incentivo</td>
                                        <td>SEMANA</td>
                                        <td>PERIODO</td>
                                        <td>REGISTRO GANADOR DEL PERIODO</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>01/10/2018 - 24/10/2018</td>
                                        <td>162</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>25/10/2018 - 18/11/2018</td>
                                        <td>126</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>3</td>
                                        <td>19/11/2018 - 13/12/2018</td>
                                        <td>321</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>4</td>
                                        <td>14/12/2018 - 9/01/2019</td>
                                        <td>215</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>5</td>
                                        <td>10/01/2019 - 31/01/2019</td>
                                        <td>918</td>
                                    </tr>
                                </table>
                            </div>
                        </section>      
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>

