<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>

                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms-aniversary.jpg" alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMO PRINGLES® 50 ANIVERSARIO</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <!-- <h2 class="terms-title">Términos y Condiciones 50 aniversario</h2> -->
                                <p>Al participar en la promoción “50 Aniversario” (en lo sucesivo la “Promoción”), el participante declara haber leído, entendido y sujetarse totalmente a los Términos y condiciones aquí establecidas (en adelante las “Bases”).</p>
                                <h4>I.- PARTICIPANTES:</h4>
                                <p>Se entenderá como “Participante” al público consumidor en general, que tenga la disposición de participar en la Promoción y reúna todos y cada uno de los siguientes requisitos:</p>
                                <ul>
                                    <li>Ser mayor de edad, contar con una identificación oficial vigente y tener legal residencia en la República Mexicana.</li>
                                    <li>Aceptar las presentes Bases.</li>
                                    <li>Comprar dos Productos Pringles® 124g marcadas con la Promoción (en lo sucesivo Los “Productos”) durante la Vigencia de la Promoción y que estén a la venta en la República Mexicana.</li>
                                    <li>Proporcionar durante la Vigencia identificación oficial y datos para registro de ganadores y firma de recibo de conformidad.</li>
                                    <li>Registrar los códigos únicos que se encuentran impresos en la membrana (la tapa de papel que cubre el envase) de cada lata de Pringles® en el sitio web: <a href="https://www.pringles.com" target="_blank">www.pringles.com</a>, después llamar al número 01 800 00 5355644 (Call Center) y siguiendo las instrucciones correspondientes. El call center confirmará y validará los códigos y tomará sus datos para el envío del incentivo (1 playera por ganador). Se deberán conservar las membranas con los códigos marcados a fin de poder realizar cualquier aclaración.</li>
                                </ul>
                                <h4>II.- VIGENCIA DE LA PROMOCIÓN:</h4>
                                <p>Del 1 de agosto al 30 de septiembre del 2018 o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes (la “Vigencia”).</p>
                                <h4>III.- MECÁNICA DE LA PROMOCIÓN:</h4>
                                <p>Pasos:</p>
                                <ul>
                                    <li>1. El participante deberá comprar 2 latas de Pringles® 124g marcadas con la promoción en los Establecimientos participantes.</li>
                                    <li>2. En cada lata y detrás de la membrana (tapa de papel), la persona encontrará un código único. </li>
                                    <li>3. El participante deberá ingresar en el sitio web: <a href="https://www.pringles.com" target="_blank">www.pringles.com</a> y buscar el banner de la promoción. Dicho banner lo redigirá al micrositio www.promopringles.com para realizar el canje de códigos (el sitio web).</li>
                                    <li>4. En ella deberá crear una cuenta para lo cual registrar los siguientes datos:
                                        <ul>
                                            <li>Nombre</li>
                                            <li>Apellido</li>
                                            <li>Fecha de nacimiento</li>
                                            <li>Teléfono</li>
                                            <li>E-Mail</li>
                                            <li>Contraseña </li>
                                        </ul>
                                    </li>
                                    <li>5. Una vez iniciada sesión el Participante deberá registra los códigos únicos (uno de cada lata) para validación. En caso de no ser válido uno de los dos códigos o ambos, se le comunicará y se le invitará a seguir participando.</li>
                                    <li>6. En caso de que la validación sea exitosa, se generará un número de folio.</li>
                                    <li>7. Con su número de folio el participante deberá llamará al número 01 800 00 5355644 (Call Center), donde le pedirán mencione el número de folio que generó en la validación de la página web.</li>
                                    <li>8. Se hará una rápida verificación del folio y en caso de validarse exitosamente se le solicitarán los siguientes datos necesarios para proceder a la entrega del incentivo:
                                    <ul>
                                            <li>Domicilio de entrega del incentivo</li>
                                        </ul>
                                    </li>
                                    <li>9. En un lapso de 7 días hábiles, recibirá su incentivo en el domicilio proporcionado.
                                        <p>Limitaciones:</p>
                                        <ul>
                                            <li> Limitado a un máximo de 1 (un) incentivo por participante.</li>
                                            <li> No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                            <li>Limitado hasta agotar existencias de los 3,000 incentivos.</li>
                                            <p>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del premio a La Organizadora.</p>
                                        </ul>
                                        <p>El Participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; (ii) no accederá al Sitio Web ni participará en la Promoción utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), ni podrá permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web; (iii) acepta ser el único responsable (frente al Organizador, al Auspiciante y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</p>
                                    </li>
                                    <p>Entrega del Incentivo:</p>
                                    <p>Los consumidores que resulten ganadores (previa confirmación al llamar al Call Center), se les enviará mediante servicio de paquetería de un tercero ajeno a la organizadora, el incentivo al domicilio proporcionado en los siguientes 7 días hábiles.</p>
                                </ul>
                                <h4>V. PRODUCTOS PARTICIPANTES:</h4>
                                <p>Los Productos Participantes son los siguientes siempre y cuando se encuentren marcados con la promoción:</p>
                                <ul>
                                    <li>Pringles® Original 124g </li>
                                    <li>Pringles® Crema y Especias 124g </li>
                                    <li>Pringles® Queso 124g </li>
                                </ul>
                                <h4>VI. TERRITORIO Y ESTABLECIMIENTOS PARTICIPANTES:</h4>
                                <p>La Promoción será válida en todo el territorio de la República Mexicana y en todas las tiendas en que se comercialicen los productos participantes.</p>
                                <h4>VII. INCENTIVOS OFRECIDOS:</h4>
                                <p>3,000 playeras con logo vintage de Pringles® bajo las siguientes especificaciones:</p>
                                <table class="globalTable" border="1">
                                    <tr>
                                        <th colspan="4">
                                            <p>INCENTIVOS</p>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>TALLA</td>
                                        <td>HOMBRE</td>
                                        <td>MUJER</td>
                                        <td>TOTAL</td>

                                    </tr>
                                    <tr>
                                        <td>CHICA</td>
                                        <td>497</td>
                                        <td>502</td>
                                        <td>999</td>

                                    </tr>
                                    <tr>
                                        <td>MEDIANA</td>
                                        <td>498</td>
                                        <td>501</td>
                                        <td>999</td>
                                    </tr>
                                    <tr>
                                        <td>GRANDE</td>
                                        <td>501</td>
                                        <td>501</td>
                                        <td>1,002</td>
                                    </tr>
                                    <tr>
                                        <td>TOTAL</td>
                                        <td></td>

                                        <td></td>
                                        <td>3,000</td>

                                    </tr>
                                </table>
                                <p>Cada playera tiene un valor comercial aproximado de $150.00 MXN.
                                    <br>La organizadora no ofrece garantía alguna de forma directa con respecto de los incentivos.</p>
                                <h4>VIII. RECLAMACIÓN Y ENTREGA DE INCENTIVOS:</h4>
                                <ul>
                                    <li>1. La mensajería acude al domicilio del ganador y hace la entrega previa identificación de la persona que recibe (a cualquier persona que se acredite como familiar del ganador).</li>
                                    <li>2. En caso de no encontrarse el ganador la mensajería deja un aviso desde la primera visita con los números telefónicos de la mensajería, para que el ganador pueda comunicarse y concerte cita. La mensajería acude al domicilio del ganador hasta en 3 ocasiones (se deja un aviso por cada visita).</li>
                                    <li>3. En caso de cumplirse las 3 visitas y no localizar al ganador o que este no se comunique a los teléfonos indicados, el paquete se deja en servicio ocurre (el paquete se encontrará en las instalaciones de la mensajería y el ganador tendrá que acudir a las mismas para recogerlo) por 5 días, concluido este plazo, el incentivo se regresará al Organizador y el ganador perderá cualquier derecho de reclamar el incentivo con posterioridad.</li>
                                </ul>
                                <p>Para la entrega de los incentivos, los participantes deberán firmar de recibido el paquete, de conformidad con las políticas y medidas de seguridad implementadas por la empresa de paquetería. El premio se entregará invariablemente en el domicilio que el ganador haya especificado en su llamada al Call Center, por lo que será su entera responsabilidad asegurarse de que los datos de ubicación sean correctos. El proceso de entrega será el siguiente:</p>
                                <ul>
                                    <li>1.- La entrega de incentivos se realizará dentro de los siguientes 7 días hábiles de la confirmación por parte del call center.</li>
                                    <li>2.- La mensajería acudirá al domicilio del ganador en un máximo de 3 ocasiones para intentar hacer entrega del incentivo. Para completar la entrega será indispensable que quien reciba el incentivo presente una identificación oficial con fotografía.</li>
                                    <li>3.- En caso de no encontrarse el ganador la mensajería dejará un aviso desde la primera visita con los números telefónicos de la mensajería, para que el ganador pueda comunicarse y concertar cita para la siguiente entrega.</li>

                                    <p>La falta de veracidad en la información proporcionada y/o la falta de presentación de identificación oficial con fotografía que impida o no permita identificar al Participante con derecho a reclamar algún incentivo libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer del incentivo respectivo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                    <p>En caso de que el Participante no provea su información personal de manera precisa, de forma tal que sea imposible para la empresa de paquetería encontrar su domicilio, perderá su derecho a recibirlo y le dará derecho a la Organizadora de utilizarlo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                    <p>La Organizadora se reserva el derecho de realizar la sustitución de los incentivos ofrecidos para canje por artículos similares de calidad y características, cuando por cuestiones ajenas a su control, el inventario o existencia de los mismos, no pueda ser previsto. </p>
                                    <p>Una vez recibido el Incentivo, los Participantes serán los únicos responsables de cualquier asunto relacionado con los bienes adquiridos, liberando de cualquier responsabilidad a Kellogg Company México, S. de R.L. de C.V., empleados, filiales y subsidiarias, ya sea por robo y/o pérdida del premio, entre otros.</p>
                                    <p>Para cualquier aclaración o información referente a esta promoción, comunicarse al 5624 2105 del D.F. y Área Metropolitana o al 01 800 00 53 55 644 del interior de la República Mexicana.</p>
                                </ul>
                                <h4>IX. CONDICIONES GENERALES:</h4>
                                <ul>
                                    <li>
                                        <h5>a. CÓDIGOS NULOS.-</h5>
                                        <ul>
                                            <li>Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica.</li>
                                            <li>Copias o facsímiles de membranas con los códigos originales.</li>
                                            <li>Aquellos que al momento de ser presentados a validación, presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados, sobre-armados o laminados superpuestos en su interior o superficies, una vez que sean requeridos para verificar la autenticidad de la participación.</li>
                                            <li>La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes.</li>
                                            <li>Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>b. VALOR UNITARIO DE PARTICIPACIÓN.-</h5>
                                        <p>Será gratuito el registro y la participación en las dinámicas, no tendrá ningún costo adicional para el participante, siempre y cuando se sujete a la mecánica de participación descrita en las presentes Bases.</p>
                                    </li>
                                    <li>
                                        <h5>c. DATOS PERSONALES (AVISO DE PRIVACIDAD).-</h5>
                                        <p>La información personal que Los Participantes proporcionen con el fin de recibir alguno de los incentivos de la Promoción, quedarán incorporados a una base de datos que conservará La Organizadora, ante la que cualquier Usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, La Organizadora quedará liberada respecto de las entregas o beneficios por otorgar a los Participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                                        <p>La Organizadora utilizará la información proporcionada para la realización de la promoción “50 Aniversario” con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece La Organizadora, así como con prestadores de servicios contratados por La Organizadora para la ejecución de la actividad hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial de La Organizadora. </p>
                                        <p>Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 01 800 00 KELLOGG (5355644).</p>
                                    </li>
                                    <h4>X. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR:</h4>
                                    <p>La Organizadora, no será responsable de modificaciones en el estado de salud a consecuencia de la participación en la Mecánica por parte del Participante. </p>
                                    <p>La Organizadora, podrá en cualquier momento suspender La Promoción o la entrega del incentivo sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier disposición emitida por cualquier autoridad jurisdiccional, así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten los intereses de la Organizadora. Si por causas ajenas a la Organizadora se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para disfrutar del incentivo o de la actividad programada, el organizador, se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que pusieran en peligro la integridad física de cualquiera de los acreedores. En todos estos casos La Organizadora publicará a través de medios impresos y/o a través de su página web los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</p>
                                    <p>No se podrá canjear el incentivo por otro de cualquier tipo y/o dinero en efectivo. Ninguno de los incentivos estarán sujetos de comercialización alguna, de manera enunciativa más no limitativa, venta, permuta, arrendamiento, sustitución o dación en pago.</p>
                                    <p>Si existen indicios suficientes, a criterio de la Organizadora, que señalen que los participantes obtuvieron sus códigos por medios ilegales o distintos a los establecidos en estas bases, podrá cancelarse la participación de ese Participante, y en su caso, dar aviso a las autoridades correspondientes.</p>
                                    <p>La responsabilidad de La Organizadora concluye al momento de la entrega de cada uno de los incentivos a sus correspondientes acreedores.</p>
                                    <p>La Organizadora, no será responsable por el uso que se le brinde el Participante a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                                    <p>Los incentivos serán entregados siempre y cuando se haya validado la identidad del acreedor correspondiente.</p>
                                    <p>El acreedor al incentivo deberá seguir todas las direcciones e instrucciones de La Organizadora, en caso de incumplimiento de cualquiera de estas el Participante será descalificado.</p>
                                    <p>El Organizador no garantiza la disponibilidad y/o continuidad del funcionamiento del Sitio Web ni tampoco garantiza el acceso efectivo al mismo, siendo esto responsabilidad exclusiva de las prestadoras de servicios de Internet. En el mismo sentido, el Organizador no garantiza que los Participantes utilicen el Sitio Web y/o la Promoción de conformidad con estas Bases y Condiciones ni que lo hagan de forma prudente y diligente. Al participar en la Promoción el acreedor acepta expresamente haber leído y entendido a la perfección las Bases y estar de acuerdo con las mismas.</p>
                                    <p>La Organizadora se reserva el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica e incentivos, siempre que las mismas estén justificadas o no perjudiquen a los participantes, y se comuniquen a éstos debidamente.</p>
                                    <h4>XI. JURISDICCIÓN APLICABLE:</h4>
                                    <p>Cualquier controversia suscitada por La Promoción se regirá por las presentes Bases y como complemento para lo establecido en éstas será aplicable la jurisdicción de la Ciudad de México, sujetándose todas las partes interesadas a las mismas renunciando al que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                    <h4>XII. RESPONSABLE Y ORGANIZADORA DE LA PROMOCIÓN:</h4>
                                    <p>Kellogg Company México S. de R.L. de C.V. (La Organizadora)</p>
                                    <p>Carretera Campo Militar KM1, Diagonal Carretera al Campo Militar 1, San Antonio de la Punta, 76200 Santiago de Querétaro, Qro. CP 76135 01 800 00 KELLOGG (5355644, 56242105)</p>
                                    <h4>XIII. AVISO DE PRIVACIDAD:</h4>
                                    <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                    <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto.</p>
                                    <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg Company México, S. de R.L. de C.V., con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán directamente como La Organizadora de la Promoción por conducto de los medios de comunicación que se han señalado anteriormente en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                    <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio <a href="http://www.kelloggs.com.mx/es_MX/home.html" target="_blank">www.kellogg.com.mx</a> la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                </ul>
                            </div>
                            <h1 class="not-visible">Términos y Condiciones de la promoción Pringles® 50 años. </h1>
                        </section>

                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
    import Headers from '@/components/partials/header.vue'
    import Footers from '@/components/partials/footer.vue'
    import {
        mapState
    }
    from 'vuex'
    export default {
        components: {
            Headers,
            Footers,
        },
        computed: {
            ...mapState(['country'])
        },
        mounted() {
            this.$store.dispatch('setBodyClass', 'global')
        },
        metaInfo() {
            return {
                title: 'Promoción Pringles® | Términos y Condiciones'
            }
        },
    }
</script>