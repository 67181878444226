
<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms-aniversary.jpg"  alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMO PRINGLES® 50 ANIVERSARIO</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <p>Para participar en la presente promoción “Pringles® 50 Aniversario” (en lo sucesivo la “Promoción”), se deberá dar lectura íntegra a las siguientes reglas y cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de las mismas, así como del Aviso de Privacidad o en su caso, políticas para el tratamiento de datos personales (en conjunto en adelante las “Reglas”).</p>
                                <h4>I.-ELEGIBILIDAD:</h4>
                                <p>Se entenderá como “Participante” al público consumidor en general, que tenga la disposición de participar en la Promoción y reúna todos y cada uno de los siguientes Requisitos:</p>
                                <ul>
                                    <li>Ser mayores de edad con plena capacidad de ejercicio y contar con identificación oficial con fotografía vigente (Documento Personal de Identificación).</li>
                                    <li>Comprar dos (2) productos Pringles® 124 gramos/ 5.2oz. en cualquiera de los tres (3) sabores participantes que son: A. Original, B. Queso y C. Crema y Cebolla (en lo sucesivo Los Productos) durante la vigencia de la promoción, marcados con la promoción y que estén en venta en República de El Salvador.</li>
                                    <li>Dar lectura total y aceptar las presentes Reglas.</li>
                                    <li>Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción.</li>
                                    <li>El Participante deberá conservar en todo momento los empaques y facturas de los Productos Participantes a Promocionarse que contengan los códigos promocionales registrados durante la Promoción, los cuales son válidos para registro en una sola ocasión. Dichos empaques serán verificados, validados y/o entregados a la Organizadora para confirmar su derecho a obtener el incentivo y no se actualice algún supuesto de descalificación.</li>
                                    <li>Completar su registro en el sitio conforme se indica más adelante en las presentes reglas, en caso de resultar acreedor a alguno de los incentivos y para efectos de su redención.</li>
                                    <li>Enviar la documentación, proporcionar la información y/o firmar los documentos que les sean requeridos conforme a las presentes reglas.</li>
                                </ul>
                                <h4>II.- VIGENCIA DE LA PROMOCIÓN:</h4>
                                <p>La promoción comenzará del 1 de agosto al 30 de noviembre del 2018 o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes (la “Vigencia”).</p>
                                <h4>III.- MECÁNICA DE LA PROMOCIÓN:</h4>
                                <p>La participación del público en general en esta Promoción, implica el conocimiento y aceptación de las presentes bases y mecánica señaladas y el cumplimiento de requisitos establecidos en las mismas, así como la aceptación de los términos y condiciones establecidos en el Aviso de Privacidad o políticas para el tratamiento de datos personales que más adelante se indica. La presente promoción será dada a conocer mediante los empaques de los productos participantes, televisión, redes sociales, internet, incluyendo el sitio <a href="https://www.pringles.com" target="_blank">www.pringles.com</a>, así como publicidad impresa difundida en los establecimientos participantes, periódicos y/o revistas.</p>
                                <p>Para poder participar en la presente promoción, los consumidores deberán seguir los siguientes pasos:</p>
                                <p>El Participante deberá registrar los códigos únicos que se encuentran impresos en la membrana (la tapa de papel que cubre el envase) de cada lata de Pringles® promocional, ingresando a <a href="https://www.pringles.com" target="_blank">www.pringles.com</a>, en donde se les redirigirá al micrositio <a href="https://www.pringles.com" target="_blank">www.pringles.com</a> para poder realizar el intercambio. Se deberán de conservar las membranas con los códigos marcados a fin de poder realizar cualquier aclaración.</p>
                                <ul>
                                    <li>En el micrositio deberá crear una cuenta para lo cual registrar los siguientes datos:<ul>
                                            <li>Nombre de usuario</li>
                                            <li>Fecha de nacimiento</li>
                                            <li>Teléfono</li>
                                            <li>E-Mail</li>
                                            <li>Contraseña para el sitio</li>
                                        </ul>
                                    </li>
                                    <li>Una vez iniciada sesión el Participante deberá registrar los códigos únicos (uno de cada lata) para validación. En caso de no ser válido uno de los dos códigos o ambos, se le comunicará y se le invitará a seguir participando.</li>
                                    <li>En caso de que la validación sea exitosa, el Participante podrá escoger el género y talla del inventario del incentivo en existencia.</li>
                                    <li>Una vez que realice su elección se generará un número de folio.</li>
                                    <li>Una vez iniciada sesión el Participante deberá registra los códigos únicos (uno de cada lata) para validación. En caso de no ser válido uno de los dos códigos o ambos, se le comunicará y se le invitará a seguir participando.</li>
                                    <li>Semanalmente Bright Solutions se comunicará con los Participantes para solicitar el folio y coordinar de recogido del incentivo (oficinas de BRIGHT SOLUTIONS, con domicilio Calle el Talapo, Lomas de Altamira 1, casa #116-C San Salvador, El Salvador, correo electrónico: r.alfaro@brightsolutions.com.sv, teléfono (503) 7841-4635 en un horario acordado de 10:00am a 6:00pm)</li>
                                    <p>Limitaciones:</p>
                                    <ul>
                                        <li>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                        <li>Limitado hasta agotar existencias de los 480 incentivos.</li>
                                        <p>El Participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; (ii) no accederá al Sitio Web ni participará en la Promoción utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), ni podrá permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web; (iii) acepta ser el único responsable (frente al Organizador, al Auspiciante y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</p>
                                    </ul>
                                    <p>IV. ENTREGA DEL INCENTIVO</p>
                                    <p>La organizadora contratará a un tercero (Agencia Bright Solutions, en la ciudad de San Salvador) que notificará a los ganadores semanalmente mediante llamada telefónica y email a las direcciones provistas por el participante en la forma de registro. Los Auspiciadores y/ o Administrador no se hacen responsables por números incorrectos o participaciones incompletas. Luego de la notificación, el ganador tendrá quince (15) días calendario para recoger su incentivo a las oficinas de BRIGHT SOLUTIONS, con domicilio en Calle el Talapo, Lomas de Altamira 1, casa #116-C San Salvador, El Salvador.</p>
                                    <p>Del ganador no poder buscar su incentivo en los quince (15) días luego de ser notificado, perderá su incentivo, el cual volverá a ser parte del inventario de incentivos. El ganador debe acudir a buscar su incentivo con identificación válida con foto y cumplir con todos los requisitos mencionados en estas Reglas.</p>
                                </ul>
                                <h4>V. PRODUCTOS PARTICIPANTES:</h4>
                                <p>Los Productos Participantes son los siguientes siempre y cuando se encuentren marcados con la promoción:</p>
                                <table class="globalTable">
                                    <tr>
                                        <th>Marca</th>
                                        <th>Presentación / Gramaje</th>
                                    </tr>
                                    <tr>
                                        <td>Pringles® Original</td>
                                        <td>124g / 5.28 oz</td>
                                    </tr>
                                    <tr>
                                        <td>Pringles® Crema y Cebolla</td>
                                        <td>124g / 5.2 oz</td>
                                    </tr>
                                    <tr>
                                        <td>Pringles® Queso</td>
                                        <td>124g / 5.2 oz</td>
                                    </tr>
                                </table>
                                <h4>VI. TERRITORIO Y ESTABLECIMIENTOS PARTICIPANTES:</h4>
                                <p>La Promoción será válida en la República de El Salvador.</p>
                                <h4>VII. INCENTIVOS OFRECIDOS:</h4>
                                <p>1. CATÁLOGO Y ENTREGA DE INCENTIVOS:</p>
                                <table class="globalTable" border="1">
                                    <tbody>
                                        <tr>
                                            <th>CANTIDAD</th>
                                            <th>DESCRIPCIÓN DE INCENTIVO</th>
                                        </tr>
                                        <tr>
                                            <td>525</td>
                                            <td>Playeras con logo Retro de Pringles® bajo las siguientes especificaciones y con un valor comercial aproximado de $4.44</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="globalTable" border="1">
                                    <p>El Salvador</p>
                                    <tr>
                                        <td>Hombre</td>
                                        <td>Small<br>Medium<br>Large</td>
                                        <td>100<br>100<br>88</td>
                                    </tr>
                                    <tr>
                                        <td>Mujer</td>
                                        <td>Small<br>Medium<br>Large</td>
                                        <td>105<br>34<br>98</td>
                                    </tr>
                                    <p>Total: 525</p>
                                </table>
                                <p>La Organizadora se reserva el derecho de no canjear, entregar o redimir los incentivos en caso de comprobar la existencia de conductas fraudulentas por parte de los Participantes que resulten Acreedores a los Incentivos (por ejemplo: alteración de identificaciones, falsedad o alteración de datos e/o información) sin que sea necesario acreditar ante autoridad alguna evidencia de dicha conducta fraudulenta del supuesto Acreedor al Incentivo.</p>
                                <p>Para poder recibir los incentivos, en su caso el participante deberá exhibir original o enviar una copia de identificación oficial (Documento Personal de Identificación) al correo electrónico r.alfaro@brightsolutions.com.sv a fin de acreditar su identidad y firmar la totalidad de documentos que la Organizadora le solicite en su caso, al entregar los incentivos.</p>
                                <p>La falta de veracidad o errores en la información que impida o no permita identificar al participante, libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer de los incentivos respectivos para reintegrarlos al inventario disponible de incentivos a beneficio del resto de participantes.</p>
                                <p>No se considerará fidedigna la identificación que presente alteraciones en fotografía o en datos generales o que no coincidan con los rasgos físicos del participante, por lo que La Organizadora de la promoción se reserva el derecho de entregar los incentivos a quien presente identificación oficial vigente.</p>
                                <p>Es importante señalar que los incentivos no son transferibles, comercializables o canjeables por dinero en efectivo o algún otro producto o servicio, por lo que los participantes que resulten ganadores no podrán solicitar incentivo o incentivo distinto a los señalados, en cualquier caso, no teniendo derecho a ejercer reclamación, procedimiento o acción alguna por este concepto.</p>
                                <p>La organizadora no ofrece garantía alguna con respecto de los incentivos.</p>
                                <h4>VIII. RECLAMACIÓN Y ENTREGA DE INCENTIVOS:</h4>
                                <ul>
                                    <li>1. Si los códigos son válidos, se le dará una notificación al consumidor indicándole el lugar para recoger el premio dentro de la Ciudad de San Salvador.</li>
                                    <li>2. En caso de que no recogiera su premio en los próximos 5 días hábiles se le volverá a realizar una notificación.</li>
                                    <li>3. Si el participante no recogiera su premio en un lapso de 15 días hábiles totales, desde la primera notificación, o no se encontrase al ganador con los datos proporcionados en su registro, el premio tendrá vigencia nuevamente para un nuevo participante.</li>
                                    <li>4. En caso de que el Participante no provea su información personal de manera precisa, de forma tal que sea imposible contactar a la persona, éste perderá su derecho a recibirlo y le dará derecho a la Organizadora de utilizarlo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</li>
                                    <li>5. La Organizadora se reserva el derecho de realizar la sustitución de los incentivos ofrecidos para canje por artículos similares de calidad y características, cuando por cuestiones ajenas a su control, el inventario o existencia de los mismos, no pueda ser previsto.</li>
                                    <li>6. Una vez recibido el Incentivo, los Participantes serán los únicos responsables de cualquier asunto relacionado con los bienes adquiridos, liberando de cualquier responsabilidad a Kellogg de Centro América S.A., Bright Solutions, empleados, filiales y subsidiarias, ya sea por robo y/o pérdida del incentivo, entre otros.</li>
                                </ul>
                                <h4>IX. CONDICIONES GENERALES:</h4>
                                <ul>
                                    <li>
                                        <h5>a. CÓDIGOS NULOS.-</h5>
                                        <ul>
                                            <li>Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica.</li>
                                            <li>Copias o facsímiles de membranas con los códigos originales.</li>
                                            <li>Aquellos que, al momento de ser presentados a validación, presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados, sobre-armados o laminados superpuestos en su interior o superficies, una vez que sean requeridos para verificar la autenticidad de la participación.</li>
                                            <li>La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes.</li>
                                            <li>Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <h5>b. DATOS PERSONALES (AVISO DE PRIVACIDAD). -</h5>
                                        <p>La información personal que Los Participantes proporcionen con el fin de recibir alguno de los incentivos de la Promoción, quedarán incorporados a una base de datos que conservará La Organizadora, ante la que cualquier Usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, La Organizadora quedará liberada respecto de las entregas o beneficios por otorgar a los Participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                                        <p>La Organizadora utilizará la información proporcionada para la realización de la promoción “Pringles® 50 Aniversario” con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece La Organizadora, así como con prestadores de servicios contratados por La Organizadora para la ejecución de la actividad hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial de La Organizadora.</p>
                                    </li>
                                    <h4>X. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR:</h4>
                                    <p>La Organizadora, no será responsable de modificaciones en el estado de salud a consecuencia de la participación en la Mecánica por parte del Participante.</p>
                                    <p>La Organizadora, podrá en cualquier momento suspender La Promoción o la entrega del incentivo sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier disposición emitida por cualquier autoridad jurisdiccional, así como sí ocurre alguna circunstancia de caso fortuito o fuerza mayor o eventos que afecten los intereses de la Organizadora. Si por causas ajenas a la Organizadora se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para disfrutar del incentivo o de la actividad programada, el organizador, se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que pusieran en peligro la integridad física de cualquiera de los acreedores. En todos estos casos La Organizadora publicará a través de medios impresos y/o a través de su página web los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</p>
                                    <p>No se podrá canjear el incentivo por otro de cualquier tipo y/o dinero en efectivo. Ninguno de los incentivos estará sujeto de comercialización alguna, de manera enunciativa más no limitativa, venta, permuta, arrendamiento, sustitución o dación en pago.</p>
                                    <p>Si existen indicios suficientes, a criterio de la Organizadora, que señalen que los participantes obtuvieron sus códigos por medios ilegales o distintos a los establecidos en estas bases, podrá cancelarse la participación de ese Participante, y en su caso, dar aviso a las autoridades correspondientes.</p>
                                    <p>La responsabilidad de La Organizadora concluye al momento de la entrega de cada uno de los incentivos a sus correspondientes acreedores.</p>
                                    <p>La Organizadora, no será responsable por el uso que se le brinde el Participante a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                                    <p>Los incentivos serán entregados siempre y cuando se haya validado la identidad del acreedor correspondiente.</p>
                                    <p>El acreedor al incentivo deberá seguir todas las direcciones e instrucciones de La Organizadora, en caso de incumplimiento de cualquiera de estas el Participante será descalificado.</p>
                                    <p>El Organizador no garantiza la disponibilidad y/o continuidad del funcionamiento del Sitio Web ni tampoco garantiza el acceso efectivo al mismo, siendo esto responsabilidad exclusiva de las prestadoras de servicios de Internet. En el mismo sentido, el Organizador no garantiza que los Participantes utilicen el Sitio Web y/o la Promoción de conformidad con estas Bases y Condiciones ni que lo hagan de forma prudente y diligente. Al participar en la Promoción el acreedor acepta expresamente haber leído y entendido a la perfección las Reglas y estar de acuerdo con las mismas.</p>
                                    <p>La Organizadora se reserva el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica e incentivos, siempre que las mismas estén justificadas o no perjudiquen a los participantes, y se comuniquen a éstos debidamente.</p>
                                    <h4>XI. JURISDICCIÓN APLICABLE:</h4>
                                    <p>Cualquier controversia suscitada por La Promoción se regirá por las presentes Reglas y como complemento para lo establecido en éstas será aplicable la jurisdicción de la República de El Salvador, sujetándose todas las partes interesadas a las mismas renunciando al que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                    <h4>XII. RESPONSABLE Y ORGANIZADORA DE LA PROMOCIÓN:</h4>
                                    <p>Kellogg de Centroamérica, S.A será la “Organizadora” de la promoción con domicilio en Boulevard Los Próceres 18 calle 24-69, Zona 10, Edificio Empresarial Zona Pradera, Torre III, Nivel 8, Oficina 801, de la Ciudad de Guatemala Teléfono 1 800 835 7465 de lunes a viernes de 8:00 a 17:00 horas.</p>
                                    <p>BRIGHT SOLUTIONS, fungirá como administrador de la promoción, atendiendo cualesquiera preguntas y reclamaciones relativas con la presente promoción. Dichas preguntas y/o reclamaciones de incentivos deberán dirigirse al Administrador de la Promoción/Sorteo: “Pringles® 50 Aniversario” por escrito a la dirección Calle el Talapo, Lomas de Altamira 1, casa #116-C San Salvador, El Salvador, teléfono (503) 7841-4635 o enviar un correo electrónico a r.alfaro@brightsolutions.com.sv</p>
                                    <h4>XIII. AVISO DE PRIVACIDAD:</h4>
                                    <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                    <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto.</p>
                                    <p>El Participante al formar parte de La Promoción, autoriza expresamente a la Organizadora de la Promoción y se obliga a suscribir cualquier documento que resulte necesario en favor de éstas, que tenga como finalidad fijar y difundir su imagen, nombre y carácter de acreedor de cualquier incentivo, así como su imagen y/o voz para la elaboración de material fotográfico o testimonial de La Promoción y su difusión en los medios de comunicación conocidos o por conocerse que la Organizadora de la Promoción juzgue convenientes. Los Participantes acreedores por haber recibido el incentivo como contraprestación se obligan a no revocar tal autorización.</p>
                                    <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg de Centro América S.A., con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán directamente como La Organizadora de la Promoción por conducto de los medios de comunicación que se han señalado anteriormente en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                    <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el <a href="https://www.pringles.com" target="_blank">www.pringles.com</a> la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                </ul>
                            </div>
                            <h1 class="not-visible">Términos y Condiciones de la promoción Pringles® 50 años.</h1>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>
