<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/snackea-pro/466x276-snackea-pa.jpg" alt="Snackea como un Pro con Pringles®">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">SNACKEA COMO UN PRO CON PRINGLES®</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
									<h1 class="not-visible">Términos y Condiciones de la promoción “Snackea como un Pro con Pringles®”</h1>

									<p>Los términos y condiciones descritos a continuación  (las &ldquo;Bases&rdquo;) establecen las condiciones y requisitos bajo los cuales se regirá  la actividad promocional organizada por BRIGHT SOLUTIONS PANAMÁ S de RL patrocinada  por Alimentos Kellogg de Panamá, S. de R.L. denominada &ldquo;SNACKEA COMO UN PRO CON  PRINGLES®&rdquo; (en  lo sucesivo la &ldquo;Promoción&rdquo;). Lo aquí dispuesto es obligatorio para todos los  participantes de la Promoción, por lo que se deberá dar lectura total a las  presentes Bases. La participación en la Promoción se interpretará como la  aceptación de las Bases, así como del Aviso de Privacidad para el tratamiento  de datos personales. Para el efecto se ha designado a la entidad BRIGHT  SOLUTIONS PANAMÁ S de RL como Organizador   para desarrollar y llevar a cabo la ejecución de la Promoción Comercial.</p>
									<p>EL  ORGANIZADOR será el responsable de gestionar los trámites y permisos legales  que se requieran para la ejecución de la actividad.</p>

									<p><strong>1. ASPECTOS GENERALES DE LA PROMOCIÓN:</strong></p>

									<p>Nombre: &ldquo;SNACKEA COMO UN PRO CON PRINGLES®&rdquo;.<br>
									Territorio:<br>
									PANAMA<br>
									Vigencia:<br>
									Inicio  07 de Agosto de 2021 12:00 am<br>
									Finaliza  04 de Octubre de 2021 11:59 pm<br>
									Naturaleza:  Sorteo en forma virtual.<br>
									Organizador:  BRIGHT SOLUTIONS PANAMÁ S de RL<br>
									Patrocinador:  Alimentos Kellogg de Panamá, S. de R.L.</p>

									<p><strong>2. ESTABLECIMIENTOS PARTICIPANTES:</strong></p>

									<p>Todos  los establecimientos comerciales autorizados legalmente en el país participante  que emitan por la compra del producto el documento fiscal o tributario  (factura) correspondiente y que cumpla con los requisitos legales del país aplicables a dicho comprobante.</p>

									<p><strong>3. PRODUCTOS PARTICIPANTES:</strong></p>

									<p>Los  productos participantes en la Promoción son Productos PRINGLES® en cualquier  presentación, incluyendo:</p>
									<p><strong>Pringles®:</strong></p>
									<ul class="bullets">
										<li>37gr</li>
										<li>40 gr&nbsp;</li>
										<li>71 gr</li>
										<li>77 gr</li>
										<li>112gr</li>
										<li>124gr</li>
										<li>130gr</li>
										<li>137gr</li>
										<li>149gr</li>
										<li>158gr</li>
										<li>194gr</li>
										<li>203gr</li>
										<li>5 pack 95gr  y 105gr</li>
										<li>12pack de  252gr&nbsp;o 258gr (muelitas).</li>
									</ul>
									<p>Que sean adquiridos en alguno de los Establecimientos Participantes.</p>

									<p><strong>4. REQUISITOS DE ELEGIBILIDAD PARA PARTICIPAR EN LA PROMOCIÓN:</strong></p>

									<p>Podrá  participar en la Promoción cualquier persona física que tenga la disposición de  participar, compre los Productos Participantes y reúna todos y cada uno de los  siguientes requisitos (el &ldquo;Participante&rdquo; o los &ldquo;Participantes&rdquo;):</p>
									<ol class="decimal">
										<li>Ser mayor de edad presentando cédula de identidad.</li>
										<li>Contar  con número telefónico de contacto.</li>
										<li>Cumplir  con la mecánica de la Promoción.</li>
									</ol>
									<p>No podrán participar todas aquellas personas que sean familiares hasta el cuarto grado  de un empleado o empleados del Organizador ni del Patrocinador, ni subsidiarias  y/o agencias &ldquo;partners&rdquo; así como de cualquiera de las empresas que formen parte  del mismo grupo de intereses económicos al que pertenece el Organizador y el  Patrocinador y las empresas vinculadas en la ejecución de la Promoción.</p>

									<p><strong>5. MECÁNICA DE LA PROMOCIÓN:</strong></p>
									
									<p>Para participar en la Promoción, los Participantes deberán cumplir con la siguiente  mecánica de la Promoción y los requisitos de elegibilidad previstos en las  presentes Bases.</p>
									<ol class="decimal">
										<li>Comprar cualquiera de los  Productos Participantes PRINGLES® en cualquiera de los Establecimientos que  emitan factura de comprobante de compra de los productos indicados  anteriormente.</li>
										<li>Enviar por Whatsapp al número abajo indicado una foto legible de cada una de las facturas que cumplan con los requisitos legales y tenga número de correlativo y/o número de identificación tributaria del Establecimiento Participante, donde se muestre la compra de los Productos Participantes y su cedula de identidad para verificar que el participante sea mayor de edad. El Participante tendrá derecho a una participación por cada factura, independientemente de cuantos Productos Participantes vayan incluidos en cada factura.<br>
										<br>
										Panama al número: (507) 6180-8589<br>
										<br>
										</li>
										<li>Las facturas  participantes deberán ser emitidas entre el 07 de Agosto de 2021 hasta el día 03  de octubre de 2021.</li>
										<li>El  Organizador verificará si la factura es válida y si el participante cumple  con todos los requisitos aplicables, se procederá a la verificación visual de  documentos para cerciorarse de que cumpla con los mismos en el momento que se  envíen al whatsapp.<br>
										<br>
										Una vez verificada visualmente dicha  información dentro del plazo otorgado, se le confirmará como participante de la  promoción.<br>
										<br>
										En caso de que el código seleccionado  no cumpla con cualquiera de los requisitos, se le descartará automáticamente participante,  el  proceso que tomará un máximo de 24 horas. En  el ínterin, el Organizador responderá en la conversación de Whatsapp<br><br>
										</li>
										<li>Verificada la validez de la factura y  documento de identidad, se enviará un código único de participación, el cual  entrará al siguiente sorteo a realizarse luego de recibido el código único de  participación. El sorteo será realizado por medio de tómbola electrónica para  optar al premio, siempre que cumpla con los requisitos previamente descritos.</li>
										<li>Se le enviará al Participante por Whatsapp las Bases de la Promoción donde deberá aceptar los mismos enviando la palabra “SI” o “ACEPTO” para poder ser elegible como participante.</li>
										<li>Se le notificará al Participante la fecha del sorteo de la Promoción en el que participará su código único de participación.<br>
										<br>
										<p> Las fechas en  que se realizarán los sorteos son:</p>
										<strong>PANAMA:</strong><br>
											<p>16 de agosto de 2021.<br><br>
											23 de agosto de 2021.<br><br>
											30 de agosto de 2021.<br><br>
											6 de septiembre de 2021.<br><br>
											13 de septiembre de 2021.<br><br>
											20 de septiembre de 2021.<br><br>
											27 de septiembre de 2021.<br><br>
											4 de octubre de 2021.<br><br></p>
											
										A las 10:00am en la siguiente dirección:<br>
										<br>
										<div class="globalTableWrp">
											<table border="0" cellpadding="0" cellspacing="0" class="globalTable">
												<tr>
													<th>PANAMÁ</th>
													<td>Ciudad  de Panamá, corregimiento de San Francisco, Calle 73 este PH Firenze, apto 19C.</td>
												</tr>
											</table>
										</div>
										<br>
										<p>Encargada: Fabiana Alvarez Acosta P.091427999<br>
											<br>
											Para lo cual se utilizará un sistema electrónico donde  la persona encargada de realizar el sorteo ingresará la base de datos de Excel  recopilada mediante whatsapp con los códigos asignados a cada participante en  el cual se elegirá al ganador aleatoriamente mediante el sistema wheelofnames.com/  y se contactará a cada participante posteriormente para notificar si ha sido ganador  en el sorteo o para darle las gracias por su participación. </p>
										</li>
										<li>En caso el Participante sea notificado que ha sido seleccionado como ganador al azar, se le contactará para la entrega de premio en un plazo de 3 días hábiles a partir de que sea contactado.</li>
										<li>La línea de Whatsapp, estará abierta durante el período de la Promoción con un agente exclusivo para cualquier duda o comentario del Participante, referente a la Promoción.</li>
									</ol>
									
									<p><strong>6. PREMIOS:</strong></p>

									<p><strong>PANAMÁ</strong></p>
									<ol class="decimal">
										<li>USD$500 en efectivo cada semana a partir del 16 de agosto</li>
									</ol>

									<p><strong>7. SELECCIÓN DE GANADOR Y ENTREGA DE PREMIO</strong></p>

									<ol class="decimal">
										<li>Se  realizará un sorteo en las fechas establecidas cumpliendo con la normativa  aplicable de todos los códigos únicos de participación que se hayan emitido en  el período correspondiente de la promoción.   </li>
										<li>Una  vez determinado el código ganador, se procederá a la selección de un ganador  principal y dos suplentes en caso de que el primero no pueda ser contactado, a  los cuales se les asignará un orden de prelación según sean seleccionados.</li>
										<li>La  notificación se hará vía Whatsapp al mismo número de teléfono desde el cual  enviaron las facturas para participar en la Promoción.</li>
										<li>Se  le notificará al código seleccionado para el premio de la Promoción, que es un  ganador y recibirá el premio.  </li>
										<li>Cada  Participante ganador deberá firmar el recibo y finiquito de conformidad previo  a la entrega del premio.</li>
										<li>Una  vez entregado el Premio, el Participante que haya resultado ganador será el  único responsable del destino del Premio, liberando de toda responsabilidad a  El Organizador, Patrocinador, sus filiales, subsidiarias y contratistas.  Asimismo, si se suspendiera, modificara, alterara o de cualquier forma se viera  imposibilitados el Participante ganador a disfrutar del premio por causas no  imputables al Organizador o al Patrocinador, éstos quedarán libres de toda  responsabilidad frente al Participante al respecto.</li>
										<li>El  Organizador ni el Patrocinador serán responsables por el uso que le brinde el  Participante ganador al premio recibido, ni por procesos judiciales,  extrajudiciales o de cualquier otra índole que puedan ser interpuestos en  relación a un reclamo de cualquier tipo de responsabilidad, por hechos  acontecidos durante o como consecuencia de la entrega del mismo, así como  relacionados con la calidad, garantías por daños, pérdidas o perjuicios  ocasionados por el uso y disfrute del Premio.</li>
										<li>Los  impuestos en el territorio que fueren aplicables serán cubiertos por el Organizador.</li>
										<li>Los  premios estarán disponibles en un plazo máximo de 3 días contados  a partir de la fecha de confirmación de que el Participante fue confirmado como  Ganador, después de la fecha no podrá hacerse reclamación alguna.</li>
									</ol>

									<p><strong>8. FECHA DE ENTREGA DEL PREMIO:</strong></p>

									<p>La notificación se realizará el día de la fecha del sorteo por vía Whatsapp. </p>
									<p>La entrega del premio se realizará 3 días hábiles a partir de que se realiza el  sorteo en la siguiente dirección:</p>
									<div class="globalTableWrp">
										<table border="0" cellpadding="0" cellspacing="0" class="globalTable">
											<tr>
												<th>PANAMÁ</th>
												<td>Ciudad de Panamá, corregimiento de Juan Diaz, Costa del este Torre Ancon, Av Centenario local 19, Madera Negra Studio.</td>
											</tr>
										</table>
									</div>

									<p><strong>9. CONDICIONES Y LIMITACIONES:</strong></p>

									<p>El Organizador se reserva el derecho de verificar el cumplimiento de estas Bases por parte de los Participantes, quedando exento de cualquier obligación (incluyendo la entrega del premio o cualquier otra) ante cualquier persona que haya participado o que haya pretendido participar en la Promoción sin cumplir con las condiciones de elegibilidad, la mecánica o cualesquiera otros términos y condiciones aquí previstos. Al efecto, queda sobreentendido que los Participantes aceptan que todas las decisiones que adopte el Organizador sobre cualquier cuestión no prevista en estas Bases serán finales.</p>

									<p><strong>10. CRITERIOS DE DESCALIFICACION:</strong></p>

									<p>El Organizador podrá no entregar el Premio si considera que: a) se incumplió  alguno de los requisitos establecidos para participar y para obtener el premio  conforme a estas Bases; b) los datos del Participante y/o proporcionados por el  Participante son falsos o están incompletos; y/o (c) existiera algún motivo  razonable para presumir la existencia de fraude o cualquier otro acto ilícito o  no autorizado para la obtención del Premio objeto de la Promoción. El  Participante acreedor al Premio deberá seguir todas las direcciones e instrucciones de El Organizador, en caso de incumplimiento de cualquiera de  éstas, el participante será descalificado.</p>
									<p>El  Organizador se reserva el derecho de proceder por las vías legales en contra de  cualquiera de los Participantes que haya cometido un acto ilícito en relación  con la Promoción, pudiendo intentar toda clase de recursos o acciones que sean  legalmente aplicables.</p>

									<p><strong>11. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL:</strong></p>

									<p>Al participar y ganar en la Promoción, el Participante autoriza expresa e  irrevocablemente al Organizador, Patrocinador, sus filiales y subsidiarias, así  como a los clientes de éstas relacionados con la Promoción, a difundir en los  medios que determinen, estime conveniente sin límite territorial a fijar, a  reproducir, difundir su nombre y apellido completo, país y ciudad de origen,  fotografías y/o uso de imagen o retrato, videos y/o voz, y en general todos  aquellos datos que pudieran requerirse con motivo de la difusión de la  Promoción, renunciando expresa e irrevocablemente desde la aceptación de las Bases  a cualquier tipo de compensación económica, remuneración, regalía o retribución  alguna por dicho uso, siempre que los fines sean publicitarios y divulgativos  en relación a la ley en vigor, para lo que mediante la aceptación de las  presentes bases se emiten las autorizaciones que resulten necesarias de los  participantes.</p>

									<p><strong>12. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR Y PATROCINADOR:</strong></p>

									<p>El  Organizador y el Patrocinador podrá en cualquier momento suspender La Promoción  o la entrega del Premio sin responsabilidad alguna, si dicha suspensión  responde en acato de cualquier mandato de autoridad con facultades al efecto,  así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos  que impidan la ejecución de Promoción. En todos estos  casos,&nbsp;La Organizador  publicará a través de medios impresos y/o a través de su página web los  motivos en que se fundamenta la realización de cualquiera de los actos  establecidos en el presente párrafo.</p>
									<p>La  responsabilidad del Organizador y del Patrocinador solamente comprende la  Promoción en los términos y condiciones aquí previstos por lo que no asume  responsabilidad alguna respecto de cualquier otra actividad promocional de  productos de la marca Pringles® de cualquier plataforma asociada con  actividades promocionales de dichas marcas.</p>
									<p>La  responsabilidad del Organizador y del Patrocinador concluyen al momento de la  entrega del Premio a sus correspondientes acreedores.</p>

									<p><strong>13. MODIFICACIONES:</strong></p>

									<p>El Organizador se reserva el derecho a realizar modificaciones o añadir anexos  sucesivos sobre su mecánica y Premio, siempre que las mismas estén justificadas  o no perjudiquen a los participantes y se comuniquen a éstos debidamente  conforme a la regulación aplicable.</p>

									<p><strong>14. DUDAS Y COMENTARIOS:</strong></p>

									<p>Para  cualquier aclaración o información referente a la Promoción se pueden consultar  las Bases en: Link del minisitio que se encontrará alojado en el dominio <a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a> o comunicándose por Whatsapp al número indicado en la promoción.</p>

									<p><strong>15. LEGISLACIÓN APLICABLE:</strong></p>

									<p>Estas Bases y la relación que se  genere entre cualquier Participante y el Organizador será regida y concertada  con total sujeción a las leyes aplicables de la República de cada sorteo y a la  jurisdicción de los tribunales competentes, renunciando el Participante y el  Organizador a cualquier otra jurisdicción que pudiera ser aplicable en función  de su domicilio o por cualquier otra razón.</p>
								</div>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Snackea con Pringles®. | Términos y condiciones Panamá.',
			meta: [
				{
					name: 'description',
					content: 'Snackea con Pringles®. Conoce todos los detalles sobre las reglas, legislación y terminología aplicables en nuestros Términos y condiciones.'
				},
			]
        }
    },
}
</script>