<template>
     <footer class="footSelector">
         <div class="dereitos">
             <a :href="setPrivacyLink()" target="_blank">{{ $t('privacy') }}</a>
             <!--a :href="termsLink()">{{ $t('avisoLegal') }}</a> | {{ $t('derechosReservados')}}</div-->
             <a :href="termsLink()" target="_blank">{{ $t('avisoLegal') }}</a>
             <a href="#">Kellogg Europe Trading Limited 2021</a>
             <a nohref="nohref" class="ot-sdk-show-settings">Preferencias de cookies</a>
         </div>
            <!-- <div class="legal"> COME BIEN / COME BEM</div> -->
    </footer>
    
</template>
<script>
import { mapState } from 'vuex'
export default {
    methods:{
        termsLink(){
            // let termUrl = 'terms' + this.country.id
            let termUrl

            if( this.country.area === 'andino' ){
                termUrl = 'https://www.kelloggs.com.co/es_CO/terminos-y-condiciones.html'
            } else if( this.country.area === 'caricam' ) {
                termUrl = 'https://www.kelloggs.com.gt/es_GT/terminos-y-condiciones.html'
                if( this.country.id === 'MX'){
                    termUrl = 'https://www.kelloggs.com.mx/es_MX/terminos-y-condiciones.html'
                }
            } else if( this.country.area === 'br'){
                termUrl = 'https://www.kelloggs.com.br/pt_BR/termos-e-condicoes.html'
            } else if( this.country.area === 'tt'){
                termUrl = ''
            }
            return termUrl
        },
        setPrivacyLink(){
            let privacyUrl;
            
            if( this.country.area === 'andino' ){
                privacyUrl = 'https://www.kelloggs.com.mx/es_MX/politicas-de-privacidad.html'
            } else if( this.country.area === 'caricam' ) {
                privacyUrl = 'https://www.kelloggs.com.mx/es_MX/politicas-de-privacidad.html'
                if( this.country.id === 'MX'){
                    // console.log('aqui mx')
                    privacyUrl = 'https://www.kelloggs.com.mx/es_MX/politicas-de-privacidad.html'
                }
            } else if( this.country.area === 'br'){
                privacyUrl = 'https://www.kelloggs.com.br/pt_BR/aviso-de-privacidade.html'
            } else if( this.country.area === 'pr'){
                privacyUrl = 'https://www.kelloggs.com.mx/es_MX/politicas-de-privacidad.html'
            }
            return privacyUrl
            //https://www.kelloggs.com.br/pt_/politica-de-privacidade.html
        },
        classActive(type) {
            if (type === this.$route.name || this.$route.name && this.$route.name.indexOf(type) !== -1 ) {
                return 'active-url'
            }

            return ''
        },
    },

    mounted(){
        // console.log(this.country.area)
        this.setPrivacyLink()
    },

    computed: {
        ...mapState(['validityText', 'country']),
    },
}
</script>

