import moment from 'moment'

const formatDay = {}

function changeFormatDay(day) {
    return moment(day).format('DD')
}

formatDay.install = function (Vue) {
    Vue.filter('format-days', (day) => {
        if (day) {
            return changeFormatDay(day)
        }

        return ''
    })
}

export default formatDay