
<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/rumbo/466x276_peru.png"  alt="Pringles® Rumbo a la Rumba">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2020</p>
                                <h4 class="titleTerms">PRINGLES® "RUMBO A LA RUMBA"</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <h1 class="not-visible">Términos y Condiciones de Pringles® Rumbo a la rumba.</h1>

                                <h3><span class="lb">DISPOSICIONES PRELIMINARES:</span></h3>

                                <p>Los términos y condiciones descritos a continuación delimitan y aclaran las condiciones bajo las cuales se regirá la actividad promocional <strong>“RUMBO A LA RUMBA” (la “promoción”).</strong> Lo aquí dispuesto es obligatorio para todos los participantes. Se entenderá que todo participante al decidir participar en la actividad conoce y acepta las condiciones y limitaciones establecidas en el presente reglamento; y acepta expresamente el uso de sus datos personales en los términos que más adelante se indican en el apartado 13, aviso de privacidad.</p>

                                <p>G.W. YICHANG &amp; CÍA S.A. con domicilio en Calle 31, N° 125, Urb. Corpac, Distrito de San Isidro, Provincia y Departamento de Lima, es la responsable de la actividad promocional para el territorio y es quien podrá modificar en cualquier momento el contenido del presente documento. Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse a la línea PERÚ 0800 00900 o a través de la dirección de correo electrónico consumer-affairs.la@kellogg.com.</p>

                                <p>Cualquier violación a los procedimientos o sistemas establecidos para la realización de la promoción implicará la inmediata revocación de los incentivos. Los incentivos se otorgarán únicamente a los ganadores si cumplen estrictamente con los términos, condiciones y requisitos previstos en este reglamento.</p>

                                <p>Para participar en la promoción, se deberá cumplir totalmente con los requisitos y condiciones aquí establecidas, lo cual implicará la comprensión y aceptación de los mismos, así como del aviso de privacidad para el tratamiento de datos personales (conjuntamente en adelante las “bases”).</p>
                                
                                <h3><span class="lb">TÉRMINOS Y CONDICIONES APLICABLES A LA PROMOCIÓN:</span></h3>

                                <p>Las siguientes definiciones son aplicables a la promoción:</p>

                                <p><strong>1. Cobertura geográfica</strong>: La presente promoción será válida en Lima Metropolitana y Callao, Perú (en lo sucesivo el “territorio”).</p>

                                <p><strong>2. Registro de participante</strong>: Se integra de los datos personales proporcionados voluntariamente por el usuario dentro del WhatsApp 989 258 098, información que el participante autoriza utilizar para efectos de la promoción y que consisten en:</p>

                                <ul class="bullets">
                                    <li>Nombres y apellidos</li>
                                    <li>Fecha de nacimiento</li>
                                    <li>Número de DNI</li>
                                    <li>Teléfono</li>
                                    <li>E-mail</li>
                                </ul>

                                <p><strong>3. Vigencia:</strong> Periodo en el cual se llevará a cabo toda la actividad promocional.</p>

                                <p>La vigencia de la promoción es desde las 00:00:00 del 20 de Enero de 2020 hasta las 23:59:59 del 15 de Marzo de 2020.</p>

                                <p><strong>4. Participante.</strong> Persona natural con 18 años cumplidos, residente en el territorio, que cumpla con los requisitos establecidos en las bases, que no incurra en algún supuesto de descalificación y haya enviado la información al WhatsApp de la promoción proporcionando de forma voluntaria, verídica y correcta la información que se solicita y, por lo tanto, cuente con el registro de participante, así como que haya cumplido con las condiciones que exige la dinámica de la promoción y en caso de resultar ganador, completar su registro conforme a las presentes bases.</p>
                                
                                <p><strong>5. Incentivos</strong></p>
                                <p>Total: ocho (08) Box en la discoteca In de Punta Hermosa para 12 personas con consumo de S/.2,250 los días sábados. Las fechas de los sorteos y del uso del premio se detalla en la siguiente tabla:</p>

                                <table class="globalTable">
                                    <tbody>
                                        <tr>
                                            <th>Semana</th>
                                            <th>Fechas de participación</th>
                                            <th>Sorteo</th>
                                            <th>Premios</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                1
                                            </td>
                                            <td>
                                                del 20 al 26 de Enero
                                            </td>
                                            <td>
                                                Lunes 27 de Enero 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 1ero de Febrero
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                2
                                            </td>
                                            <td>
                                                del 27 de Enero al 2 de Febrero
                                            </td>
                                            <td>
                                                Lunes 3 de Febrero 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 8 de Febrero
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                3
                                            </td>
                                            <td>
                                                del 3 al 9 de Febrero
                                            </td>
                                            <td>
                                                Lunes 10 de Febrero 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 15 de Febrero
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                4
                                            </td>
                                            <td>
                                                del 10 al 16 de Febrero
                                            </td>
                                            <td>
                                                Lunes 17 de Febrero 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 22 de Febrero
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                5
                                            </td>
                                            <td>
                                                del 17 al 23 de Febrero
                                            </td>
                                            <td>
                                                Lunes 24 de Febrero 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 29 de Febrero
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                6
                                            </td>
                                            <td>
                                                del 24 de Febrero al 1 de Marzo
                                            </td>
                                            <td>
                                                Lunes 2 de Marzo 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 7 de Marzo
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                7
                                            </td>
                                            <td>
                                                del 2 al 8 de Marzo
                                            </td>
                                            <td>
                                                Lunes 9 de Marzo 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 14 de Marzo
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                8
                                            </td>
                                            <td>
                                                del 9 al 15 de Marzo
                                            </td>
                                            <td>
                                                Lunes 16 de Marzo 5pm
                                            </td>
                                            <td>
                                                1 Box en discoteca IN el 21 de Marzo
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>La Organizadora se pondrá en contacto con los ganadores para coordinar el uso del premio. <br>
                                Limitado a la obtención de un máximo de un (01) pack por participante.</p>

                                <p>Y un (01) paquete de viaje para 4 personas a Punta Cana en el Hotel Iberostar para las fechas del 01 al 05 de Abril del 2020. El paquete incluye boletos aéreos Lima/Punta Cana/Lima, traslado aeropuerto/hotel/aeropuerto, 4 noches de alojamiento en hotel con sistema todo incluido, impuestos aéreos y tarjetas de asistencia por 05 días. Solo canjeable en la agencia Costa Pacífico, con dirección Calle Mercedes Gallagher de Parks 397 Oficina 201, San Miguel, Lima. No se permite cambio de fecha. No se permite el cambio del valor de este premio por dinero en efectivo. No es transferible ni válido para la acumulación de millas aéreas. La actividad promocional para ganarse este incentivo se realizará a través de un (01) sorteo el lunes 16 de Marzo de 2020 a las 5:00 p.m. en las oficinas de G.W. YIGHANG &amp; CÍA S.A., con dirección en Calle 31 N° 125, Urb. Corpac, Distrito de San Isidro, Provincia y Departamento de Lima; en presencia de un notario público. Para dicho sorteo se considerarán todas las participaciones registradas desde el inicio de la promoción y no se excluirán a los ganadores de los Box.</p>

                                <p>La organizadora no es productora, vendedora o expendedora de los incentivos de la promoción. Estos incentivos son adquiridos por la organizadora de terceros, por lo cual la organizadora no ofrece garantía alguna respecto de los mismos ni es responsable por la calidad, idoneidad y/o fallas de estos, debiendo los ganadores dirigir sus reclamaciones directamente contra In Punta Hermosa S.A.C., con dirección en Carretera Panamericana Sur Mza. K Lote 1 Martín Olaya (Alt. Km 43.5 Antigua Panamericana Sur), Distrito de Punta Hermosa, Provincia y Departamento de Lima, en caso de los Box y contra Costa Pacífico Travel S.A.C., con dirección en Calle Mercedes Gallagher de Parks 397 Oficina 201, Distrito de San Miguel, Provincia y Departamento de Lima, en caso del paquete de viaje. Así lo reconocen y aceptan los participantes.</p>

                                <p><strong>6. Mecánica de la promoción</strong>. La promoción consta de los siguientes pasos:</p>

                                <ol class="decimal">
                                    <li>El participante deberá comprar una (01) lata de Pringles de 124 g, 137 g, 149 g, 158 g o two pack de Pringles®, el cual contiene dos (02) latas de 124 g.</li>
                                    <li>Enviar una foto de la boleta de compra y sus datos (nombres, apellidos, fecha de nacimiento, número de DNI e e-mail) al WhatsApp 989 258 098.</li>
                                    <li>Participar en el sorteo de los nueve (09) incentivos.</li>
                                    <li>Los sorteo se realizará mediante la página web <a href="http://www.random.org">www.random.org</a>. El sorteo de los Box para la Discoteca In de Punta Hermosa se realizará todos los lunes según cuadro adjunto líneas arriba y el sorteo del paquete de viaje a Punta Cana se realizará el Lunes 16 de Marzo del 2020.</li>
                                    <li>Los ganadores de los Box serán contactados el día del sorteo a partir de las 5pm, tendrán hasta el día siguiente para confirmar la aceptación del premio y hasta el día jueves de dicha semana para enviar la lista de sus invitados. De lo contrario perderán su premio.
                                
                                        En el caso del paquete de viaje a Punta Cana, se contactará al ganador el Lunes 16 de Marzo a partir de las 5pm, tendrá hasta Miércoles 18 de Marzo para confirmar la aceptación de su premio y hasta el sábado 21 de Marzo para acercarse a la agencia de viajes Costa Pacífico con los datos de los pasajeros para emisión de los documentos respectivos para su premio; caso contrario perderá el premio.
                                        
                                        Los horarios de atención de la agencia son de Lunes a Viernes de 9am a 6pm y los Sábados de 9am a 1pm.
                                    </li>

                                    <li>Se publicarán a los ganadores vía e-mail.</li>

                                    <li>El participante que no envíe la información de contacto solicitada en forma completa, de tal manera que no pueda ser debidamente identificado, no será considerado ganador.</li>
                                </ol>

                                <p><strong>7. Lista de productos participantes (los “productos participantes”):</strong></p>

                                <p>Los productos participantes son los siguientes:</p>

                                <table class="globalTable">
                                    <tbody>
                                        <tr>
                                            <th>
                                                Producto
                                            </th>
                                            <th>
                                                Cantidad de opciones
                                            </th>
                                        </tr>
                                        <tr>
                                            <td>
                                                Original 124 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Queso 124 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Crema y Cebolla 124 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                BBQ 124 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Pizza 124 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Wavy Sweet &amp; Tangy BBQ 137 g
                                            </td>
                                            <td>
                                                2 opciones
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Wavy Fire Roasted Jalapeño 137 g
                                            </td>
                                            <td>
                                                2 opciones
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Original 149 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Queso 149 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Crema y Cebolla 149g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Cheddar &amp; Sour Cream 158 g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Jalapeño 158g
                                            </td>
                                            <td>
                                                1 opción
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Two Pack (Original 124 g + Queso 124 g)
                                            </td>
                                            <td>
                                                3 opciones
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Two Pack (Original 124 g + Crema y Cebolla 124 g)
                                            </td>
                                            <td>
                                                3 opciones
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p><strong>8. Establecimientos participantes</strong></p>

                                <p>Los establecimientos donde podrás comprar los productos para participar en la promoción (los “<strong>establecimientos participantes</strong>”) serán los siguientes:</p>

                                <ul class="bullets">
                                    <li>WONG</li>
                                    <li>METRO</li>
                                    <li>TOTTUS</li>
                                    <li>PLAZA VEA</li>
                                    <li>VIVANDA</li>
                                    <li>MAKRO</li>
                                    <li>PRIMAX</li>
                                    <li>REPSOL</li>
                                    <li>PECSA</li>
                                    <li>TAMBO</li>
                                    <li>OXXO</li>
                                </ul>
                                
                                <p><strong>9. Organizadora de la promoción: </strong>W. YICHANG &amp; CÍA S.A., empresa con RUC N° 20100030838 (la “<strong>organizadora</strong>”).</p>

                                <p><strong>10. Obligaciones del participante:</strong></p>

                                <ul class="bullets">
                                    <li>Tener 18 años de edad con plena capacidad de ejercicio legal.</li>
                                    <li>Dar lectura total a las presentes bases.</li>
                                    <li>La participación en la promoción da cuenta de la aceptación y conformidad expresa a las bases de la misma.</li>
                                    <li>Aceptar ser el único responsable (frente a la organizadora y a otros terceros) de toda la actividad que realice en el WhatsApp de la promoción, y mantener indemne a la organizadora y a Kellogg’s, como a sus empleados, accionistas, socios, filiales y a sus representantes, por este concepto.</li>
                                </ul>

                                <p><strong>11. Obligaciones del acreedor/ganador del incentivo:</strong></p>

                                <ul class="bullets">
                                    <li>Completar su registro a través del WhatsApp de la promoción conforme se indica en las presentes bases.</li>
                                </ul>

                                <p><strong>12. Motivos de descalificación e impedimento para ser acreedor/ganador del incentivo:</strong></p>

                                <p>Darán motivo a descalificación inmediata:</p>

                                <ul class="bullets">
                                    <li>La participación de cualquier menor de 18 años.</li>
                                    <li>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta tercer grado de la organizadora ni de las empresas relacionadas con la operación o implementación de la promoción.</li>
                                    <li>Cualquier información o documento proporcionado por el participante que sea falso.</li>
                                    <li>Cualquier indicio de que se trata de una solicitud de participación no auténtica o si hay alguna mínima evidencia de que un participante o un tercero relacionado intenta dañar, sacar provecho indebido, o de alguna manera minar la operación legítima de la actividad promocional. El participante en estas circunstancias será sometido a las autoridades competentes para los procedimientos civiles y/o penales correspondientes. Dicha determinación estará a discreción de la organizadora y/o de Kellogg’s®.</li>
                                    <li>Usar o desarrollar cualquier tipo de sistema o programa informático que permita realizar la participación de forma automatizada o que modifique los resultados obtenidos.</li>
                                    <li>Los previstos en los demás apartados de las presentes bases.</li>
                                    <li>Realizar o ejecutar cualquier otro acto o actividad que pudiera ser considerada fraudulenta o inadecuada conforme a la legislación aplicable.</li>
                                    <li>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores, colaboradores o prestadores de servicios de la promoción. En dicho supuesto los ganadores deberán hacer devolución del incentivo a la organizadora, bajo apercibimiento de la toma de acciones legales por parte la organizadora y/o de Kellogg’s®.</li>
                                </ul>
                                
                                <p><strong>13. Condiciones generales:</strong></p>

                                <ul class="bullets">
                                    <li>Los incentivos NO serán sustituidos por dinero en efectivo, ni por ningún otro incentivo.</li>
                                    <li>Los incentivos son personales y no pueden ser trasferidos a terceros.</li>
                                    <li>En caso de que los ganadores no completen el proceso de asignación de incentivos en el plazo indicado por la organizadora, los incentivos quedarán a disposición de la organizadora para ser utilizados como mejor le convenga a sus intereses.</li>
                                    <li>La organizadora se reserva el derecho de suspender de forma inmediata la promoción, sin asumir responsabilidad alguna, si se llegasen a detectar delitos, fraudes o cualquier irregularidad, o si se presentara alguna circunstancia de fuerza mayor o caso fortuito. En tal caso, se comunicará oportunamente al público en general de los cambios a que haya lugar a través de las páginas web <a href="http://www.pringles.com" target="_blank">pringles.com</a> y <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a>.</li>
                                    <li>La organizadora no será responsable por fallas técnicas en el sistema de captura de datos ni de la ejecución o desempeño de la dinámica de participación en la promoción, ya que esto depende tanto de los participantes como de terceros.</li>
                                    <li>Cualquier cambio en el contenido de las bases de la promoción, se comunicará y publicará en los sitios <a href="http://www.pringles.com" target="_blank">pringles.com</a> y <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a>.</li>
                                    <li>La organizadora de la promoción podrá en cualquier momento suspender la promoción o la entrega de incentivos sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten su ejecución, así como cualquier acto y/o amenaza que ponga en riesgo la seguridad de los participantes y/o a la organizadora. Si por causas ajenas a la organizadora la promoción se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para recibir el incentivo, ésta se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar del mismo valor al incentivo al que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que hicieran inejecutables las entregas respectivas de incentivos. En todos estos casos la organizadora de la promoción publicará a través de los sitios <a href="http://www.pringles.com" target="_blank">pringles.com</a> y <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a> los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</li>
                                    <li>La organizadora de la promoción no será responsable por el uso que se le brinde a cualquiera de los incentivos, ni por procesos judiciales, administrativos, extrajudiciales o de cualquier otra índole que puedan ser interpuestos con relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la redención de los incentivos, así como los relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute de los incentivos.</li>
                                    <li>Los acreedores a los incentivos liberan de toda responsabilidad a la organizadora de la promoción conforme a lo establecido en estas bases, al momento de recibir el mismo.</li>
                                    <li>La organizadora de la promoción emprenderá las acciones legales pertinentes contra aquellos usuarios que pretendan defraudar, engañar o burlar las reglas y procedimientos de la promoción.</li>
                                </ul>

                                <p><strong>14. Aviso de privacidad:</strong></p>
                                
                                <p>El responsable de recabar la información consistente en datos personales de los consumidores participantes de esta promoción es G.W. YICHANG &amp; CÍA S.A..</p>
                                
                                <p>La finalidad de recabar los datos personales de los consumidores participantes en esta promoción es únicamente la de su individualización e identificación. Ello con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho a los mismos. Por la naturaleza de los datos personales recabados no se efectuará ninguna transferencia de estos a terceros.</p>
                                
                                <p>Cualquier cambio del presente aviso de privacidad será comunicado por los sitios <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a>. La información consistente en datos personales proporcionada por los participantes será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>

                                <p>Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de atención al consumidor <a href="mailto:servicioalconsumidor@yichang.com.pe">servicioalconsumidor@yichang.com.pe</a>. En este sentido, los participantes reconocen y aceptan la forma como la organizadora utilizará y custodiará los datos considerados como personales, en los términos de la Ley N° 29733, Ley de protección de datos personales.</p>

                                <p><strong>15. Modificación de los términos y condiciones:</strong></p>

                                <p>En caso de resultar necesario, y/o a discreción de la organizadora o de Kellogg’s®, esta empresa se reserva el derecho de ampliar y/o aclarar el alcance de los presentes términos y condiciones, y/o cualquiera de los procedimientos, los cuales serán comunicados a través de <a href="http://www.pringles.com" target="_blank">www.pringles.com</a> y <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a>.</p>

                                <p><strong>16. Sanciones:</strong></p>

                                <p>Los participantes aceptan que cualquier incumplimiento de las obligaciones contenidas en estas condiciones y restricciones faculta a la organizadora para el inicio de las acciones legales a que haya lugar. Los participantes aceptan indemnizar y mantener indemne a Kellogg’s® y a la organizadora, así como a sus socios, accionistas, personal y compañías afiliadas ante cualquier daño, pérdida, reclamación y/o costo, incluyendo honorarios y gastos de abogados que puedan ocasionar como resultado de la violación de estos términos.</p>

                               <p><strong>17. Jurisdicción y ley aplicable:</strong></p>
                               
                               <p>Cualquier controversia suscitada con motivo de la promoción se regirá por las presentes bases en concordancia con la ley peruana. En ese sentido, las partes renuncian a cualquier otra jurisdicción a la que por beneficio de su domicilio presente o futuro pudiese corresponderles, sometiéndose expresamente a la jurisdicción peruana.</p>

                               <p><strong>18. Publicación:</strong></p>

                               <p>Estos términos estarán publicados en <a href="http://www.pringles.com" target="_blank">www.pringles.com</a> y <a href="http://www.yichang.com.pe" target="_blank">www.yichang.com.pe</a>, así como en el link sobre las políticas de tratamiento de datos personales de manera completa.</p>

                            </div>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Rumbo a la Rumba con Pringles® | Términos y Condiciones',
            meta: [
                {
                    name: 'description',
                    content: 'Consulta los Términos y Condiciones de la promoción Rumbo a la Rumba con Pringles®.'
                }
            ]
        }
    },
}
</script>