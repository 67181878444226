<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/deezer/466x276_es.jpg" alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2019</p>
                                <h4 class="titleTerms">PROMO PRINGLES® DEEZER</h4>
                                <h5>ACTIVIDAD PROMOCIONAL</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
                                    <h2 class="hidden">Términos</h2>
                                    <!-- <h2>ACTIVIDAD PROMOCIONAL Pringles® Music 2019</h2> -->
                                    <p><strong>DISPOSICIONES PRELIMINARES:</strong></p>
                                    <p>Los términos y condiciones descritos a continuación delimitan y aclaran las condiciones bajo las cuales se regirá la actividad promocional “Pringles® Music 2019”. Lo aquí dispuesto es obligatorio para todos los participantes. Se entenderá que todo participante, al decidir participar en la actividad, conoce y acepta las condiciones y limitaciones establecidas en el presente reglamento; y acepta expresamente el uso de sus datos personales en los términos que más adelante se indican.</p>
                                    <p>KELLOGG DE COLOMBIA, S.A. con domicilio en: Calle 26 # 92-32 Edificio G4 – 5, Oficina 502, es la responsable de la Actividad Promocional, para territorio colombiano, y es quien podrá modificar en cualquier momento el contenido del presente documento. Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al 01 800 9520686, en un horario de atención de lunes a viernes de 8 am a 8 pm y sábados de 8 am a 3 pm, hora colombiana.</p>
                                    <p>Cualquier violación a los procedimientos o sistemas establecidos para la realización de la actividad promocional implicará la inmediata revocación de los incentivos. Los incentivos se otorgarán únicamente si los ganadores cumplen estrictamente con los términos, condiciones y requisitos previstos en este reglamento. </p>
                                    <p>Para participar en la presente promoción denominada “Pringles® MUSIC 2019” (en lo sucesivo la “Promoción”), se deberá cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de los mismos, así como del Aviso de Privacidad para el tratamiento de datos personales (en conjunto en adelante las “Bases”). </p>
                                    <p><strong>TÉRMINOS Y CONDICIONES APLICABLES A LA ACTIVIDAD PROMOCIONAL:</strong></p>
                                    <p>Las siguientes definiciones son aplicables a la actividad promocional. </p>
                                    <p><strong>1. Cobertura Geográfica:</strong> La presente promoción será válida en la República de Colombia (en lo sucesivo el “Territorio”).</p>
                                    <p><strong>2. Sitio WEB de la promoción: </strong> www.promopringles.com (en lo sucesivo El Sitio o página WEB).</p>
                                    <p><strong>3. La Cuenta:</strong> Al Registro de Participante que lo identificará y distinguirá como Usuario respecto de los demás participantes de la promoción. Su administración es personal y responsabilidad del usuario.</p>
                                    <p><strong>4. Registro de Participante.</strong> Se integra de los datos personales proporcionados voluntariamente por el Usuario dentro del Sitio WEB y que componen la Cuenta, información que el participante autoriza utilizar para efectos de la promoción y que consisten en:</p>
                                    <ul>
                                        <li>Nombre </li>
                                        <li>Apellido</li>
                                        <li>Fecha de nacimiento</li>
                                        <li>Teléfono</li>
                                        <li>E-mail</li>
                                        <li>Contraseña</li>
                                    </ul>
                                    <p><strong>5. Vigencia.</strong> Periodo en el cual se llevará a cabo toda la actividad promocional. </p>
                                    <p>La vigencia de la promoción es desde las 00:00:01 del 1 de Abril de 2019 hasta las 23:59:59 del 31 de Agosto de 2019 para participar de la promoción o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda primero.</p>
                                    <p><strong>6. Participante.</strong> Persona física con 18 años cumplidos, residente en el Territorio, que cumpla con los requisitos establecidos en las Bases, no incurra en algún supuesto de descalificación, haya ingresado al Sitio WEB, proporcionando voluntaria, verídica y de forma correcta la información que se le solicite y, por lo tanto, cuente con el Registro de Participante que le permite acceder a la Cuenta, así como que haya cumplido con las condiciones que exige la Dinámica y en caso de resultar ganador, completar su registro conforme a las presentes Bases. </p>
                                    <p><strong>7. Incentivos</strong> Total: 4,000 cupones canjeables, cada uno por 2 meses de suscripción premium a Deezer con un valor de $29.800 pesos colombianos.</p>
                                    <p>El cupón será canjeado en la aplicación de Deezer durante la vigencia de la promoción y hasta el 31 de agosto de 2019. El cupón que no haya sido redimido o canjeado el 31 de agosto de 2019 no podrá ser redimido. </p>
                                    <ul>
                                        <li>Beneficio disponible para usuarios gratuitos de Deezer o nuevos usuarios que nunca se hayan beneficiado del período de prueba o una promoción similar otorgada por Deezer. </li>
                                        <li>Nuevos suscriptores requieren de una tarjeta crédito o débito válida y se convertirán automáticamente en un suscriptor pago al finalizar el período de 2 meses gratis, salvo que se cancele su suscripción antes de que inicie el periodo pago;</li>
                                        <li>Limitado a 1 incentivo (suscripción Deezer) por persona/usuario.</li>
                                        <li>Deezer será el único responsable del servicio.</li>
                                    </ul>
                                    <p><strong>8. Mecánica de la promoción.</strong> La promoción consta de los siguientes pasos: </p>
                                    <p>1. El participante deberá comprar 2 latas de Pringles® 149g marcadas con la promoción.</p>
                                    <p>2. En cada lata marcada con la promoción y detrás de la membrana (tapa de papel), el participante encontrará un código único. </p>
                                    <p>3. Ingresa al sitio web www.promopringles.com, crea tu registro o ingresa a tu cuenta.</p>
                                    <p>4. Una vez iniciada la sesión, el Participante deberá registra los dos códigos únicos (uno de cada lata) para validación. En caso de no ser válido uno de los dos códigos o ambos, se le comunicará y se le invitará a seguir participando.</p>
                                    <p>5. En caso de que la validación sea exitosa, se te enviará a tu correo registrado, el cupón con el que podrás canjear por el incentivo en la página.</p>
                                    <p>6. Publicación de ganadores: La publicación de los nombres ganadores se darán a conocer una vez que se haya validado su identidad y participación a través del sitio web www.promopringles.com cada 15 días a partir de la fecha de inicio de la promoción.</p>
                                    <p><strong>9. Lista de productos participantes:</strong> Los productos que participarán en la Promoción dentro del Territorio serán los siguientes:</p>
                                    <ul>
                                        <li>Pringles® Original 149g </li>
                                        <li>Pringles® Crema y Cebolla 149g </li>
                                        <li>Pringles® Queso 149g </li>
                                    </ul>
                                    <p>Para efectos de la Promoción, los productos participantes antes mencionados deberán estar marcados con la promoción y contener los códigos respectivos.</p>
                                    <p><strong>10. Organizadora de la promoción:</strong> KELLOGG DE COLOMBIA, S.A.</p>
                                    <p><strong>11. Obligaciones del participante:</strong> </p>
                                    <ul>
                                        <li>Tener 18 años de edad con plena capacidad de ejercicio legal y contar con identificación oficial vigente con fotografía.</li>
                                        <li>Dar lectura total a las presentes Bases. </li>
                                        <li>La participación en la promoción da cuenta de la aceptación expresa a las Bases de la misma.</li>
                                        <li>Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción.</li>
                                        <li>Conservar en todo momento el código único que se encuentra impreso en la membrana (la tapa de papel que cubre el envase de cada lata de Pringles®) de los Productos Participantes. La membrana que contiene el código será verificada, validada y/o entregada a la Organizadora para confirmar su derecho a obtener el incentivo y no se verifique la existencia de alguna causal de descalificación.</li>
                                        <li>Aceptar ser el único responsable (frente al Organizador y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</li>
                                    </ul>
                                    <p><strong>12. Obligaciones del acreedor/ganador del incentivo:</strong></p>
                                    <ul>
                                        <li>Completar su registro en el sitio conforme se indica más adelante en las presentes bases.</li>
                                    </ul>
                                    <p><strong>13. Motivos de descalificación e impedimento para ser acreedor/ganador del incentivo:</strong></p>
                                    <ul>
                                        <li>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                        <li>Cualquier información, empaque, código promocional o documento proporcionado por el Participante que sea falso.</li>
                                        <li>Cualquier indicio de que se trata de una solicitud de participación no auténtica o si hay alguna mínima evidencia de que un participante o un tercero relacionado intenta dañar, sacar provecho indebido, o de alguna manera minar la operación legítima de la actividad promocional será descalificado de participar y removido de la actividad. El participante, en estas circunstancias será sometido a las autoridades competentes para los procedimientos civiles y/o penales correspondientes. Dicha determinación estará a discreción de la Organizadora.</li>
                                        <li>Se use o desarrolle cualquier tipo de sistema o programa informático que permita realizar la participación de forma automatizada o que modifique los resultados obtenidos. Ya sea directamente o a través de un tercero: utilizando software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; acceder al Sitio Web utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web.</li>
                                        <li>Descargar, copiar, atacar o intentar alterar o manipular parte o todo el contenido del Sitio y/o se reproduzcan o modifiquen los Códigos Participantes originales de cualquier forma.</li>
                                        <li>Los previstos en los demás apartados de las presentes Bases.</li>
                                        <li>Realizar o ejecutar cualquier otro acto o actividad que pudiera ser considerada fraudulenta o inadecuada conforme a la legislación aplicable.</li>
                                        <li>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a La Organizadora.</li>
                                    </ul>
                                    <p><strong>14. Condiciones generales:</strong></p>
                                    <ul>
                                        <li>Los incentivos NO serán sustituidos por dinero en efectivo, ni por ningún otro incentivo. </li>
                                        <li>Los incentivos son personales y no pueden ser trasferidos a terceros.</li>
                                        <li>Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica; y, copias o facsímiles de membranas con los códigos originales.</li>
                                        <li>Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                        <li>La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes. No serán válidos aquellos códigos que presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados, sobre-armados o laminados superpuestos en su interior o superficies.</li>
                                        <li>En caso de que los ganadores no completen el proceso de asignación de incentivos en el plazo indicado por La Organizadora, los incentivos quedarán a disposición de la Organizadora para asignarlos a los ganadores suplentes que cumplan con estas Bases, en caso de que por tiempo no sea posible asignarlo a un ganador suplente, los incentivos serán utilizados como mejor convenga a los intereses de la Organizadora.</li>
                                        <li>La Organizadora suspenderá de forma inmediata la Promoción, sin asumir responsabilidad alguna, si se llegaren a detectar delitos, fraudes o cualquier irregularidad, o si se presentara alguna circunstancia de fuerza mayor o caso fortuito. En tal caso, se comunicará al público en general de los cambios a que haya lugar a través de la página web www.promopringles.com</li>
                                        <li>En caso que cualquier Participante tuviese algún problema dentro del Sitio, deberá de comunicarse de inmediato a los teléfonos de la Organizadora y reportarlo para que se atienda su requerimiento a la brevedad, dichos teléfonos serán publicados dentro del Sitio. Cualquier error u omisión no reportada, podría no ser atendida por La Organizadora de la Promoción. En caso de que el Participante no reporte la anomalía o error dentro de las siguientes 48 horas contadas a partir de la hora en que intente registrar una participación, podría ocasionar una falta de registro de su participación y/o la oportunidad de recibir el incentivo que pudiera corresponderle, lo cual será responsabilidad del propio participante, liberando de cualquier responsabilidad a la Organizadora. </li>
                                        <li>La Organizadora no será responsable por fallas técnicas en el sistema de captura de datos, ni de la ejecución o desempeño de la dinámica de participación en La Promoción, ya que esto depende tanto de los Participantes como de terceros ajenos.</li>
                                        <li>Cualquier cambio en el contenido de las Bases de La Promoción, se comunicará y publicará en el Sitio.</li>
                                        <li>La Organizadora de la Promoción podrá en cualquier momento suspender la Promoción o la entrega de incentivos sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten su ejecución o a sus intereses, así como cualquier acto y/o amenaza que ponga en riesgo la seguridad de los participantes y La Organizadora. Si por causas ajenas a la Organizadora de la Promoción se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para recibir el incentivo, ésta se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo al que participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que hicieran inejecutables las entregas respectivas de incentivos. En todos estos casos la Organizadora de la Promoción publicará a través del Sitio los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo. Ninguno de los incentivos estará sujeto a comercialización o cesión alguna. Cualquier incentivo en tal supuesto da derecho a la Organizadora de la Promoción a su revocación o cancelación respectiva.</li>
                                        <li>La Organizadora de la Promoción no será responsable por el uso que se le brinde a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos con relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la redención del citado incentivo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</li>
                                        <li>Los acreedores al incentivo liberan de toda responsabilidad a la Organizadora de la Promoción conforme a lo establecido en estas Bases, al momento de recibir el incentivo. </li>
                                        <li>La Organizadora de la Promoción emprenderá las acciones legales pertinentes contra aquellos usuarios que pretendan defraudar, engañar o burlar las reglas y procedimientos de la promoción.</li>
                                    </ul>
                                    <p><strong>15. Aviso de privacidad:</strong></p>
                                    <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                    <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                    <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopringles.com la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                    <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg, con fines exclusivos de realizar la Promoción. Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 01 800 9520686.</p>
                                    <p><strong>16. Modificación de los términos y condiciones: </strong></p>
                                    <p>En caso de resultar necesario, y/o a discreción de Kellogg’s®, ésta empresa se reserva el derecho de ampliar y/o aclarar el alcance de los presentes Términos y Condiciones, y/o cualquiera de los procedimientos, los cuales serán comunicados a través de www.promopringles.com</p>
                                    <p><strong>17. Sanciones: </strong>Los Participantes aceptan que cualquier incumplimiento de las obligaciones contenidas en estas condiciones y restricciones, facultan al Organizador, para el inicio de las acciones legales a que haya lugar. Los Participantes aceptan indemnizar, defender y mantener indemne al Organizador y sus socios, accionistas, personal y compañías afiliadas ante cualquier responsabilidad, pérdida, reclamación y gasto, incluyendo honorarios y gastos de abogados, si es resultado de la violación de estos términos. </p>
                                    <p><strong>18. Jurisdicción y ley aplicable:</strong> Cualquier controversia suscitada con motivo de la Promoción, se regirá por las presentes Bases y como complemento para lo establecido en éstas, aplicará la jurisdicción de la República de Colombia, sujetándose todas las partes interesadas en la misma, incluyendo a los Participantes y en su caso, acompañante, quienes renuncian a cualquier otra jurisdicción a la que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                    <p>Los Participantes reconocen y aceptan que la ley aplicable para cualquier controversia que surja con relación a la Actividad será la de la República de Colombia, y renuncian a su derecho a iniciar cualquier tipo de reclamación en otra jurisdicción. </p>
                                </div>
                            </div>
                            <h1 class="not-visible">Términos y Condiciones de la promoción La música cae mejor con Pringles®.</h1>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>