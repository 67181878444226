<template>
  <div>
    <Headers></Headers>
    <main class="mainWrp">
      <section class="globalWrp">
        <aside class="titleSectionWrp">
          <router-link
            class="backLink"
            :to="{
              name: 'TermsSelector',
              params: { countryId: country.id.toLowerCase() },
            }"
            >{{ $t("back") }}</router-link
          >
          <h2>{{ $t("legals") }}</h2>
        </aside>
        <main class="one-whole heightScroll">
          <div class="one-whole">
            <div class="headerTerms one-whole">
              <img
                src="@/assets/images/global/banners/arma-la-casera/466x276-arma-la-casera.jpg"
                alt="Por si se arma la casera con Pringles®"
              />
              <div class="titleTermsWrp one-whole">
                <p class="year">2020</p>
                <h4 class="titleTerms">
                  POR SI SE ARMA LA CASERA CON PRINGLES®
                </h4>
                <h5>TÉRMINOS Y CONDICIONES</h5>
              </div>
            </div>
            <!-- Insertar section terminos -->
            <section class="terms">
              <div class="terms-wrp">
                <div class="wrappTerms">
                  <p>
                    Los términos y condiciones descritos a continuación (las
                    “Bases”) establecen las condiciones y requisitos bajo los
                    cuales se regirá la actividad promocional “POR SI SE ARMA LA
                    CASERA CON PRINGLES®” (en lo sucesivo la “Promoción”). Lo
                    aquí dispuesto es obligatorio para todos los participantes
                    de la promoción, por lo que se deberá dar lectura total a
                    las presentes Bases. La Promoción se ejecutará a través del
                    registro del ticket de compra y/o lata de productos
                    participantes, las bases podrán ser consultadas por los
                    Participantes a través de www.promopringles.com
                  </p>
                  <p><strong>I. ASPECTOS GENERALES DE LA PROMOCIÓN.</strong></p>
                  <p>
                    <strong>Nombre: </strong>&quot;POR SI SE ARMA LA CASERA CON
                    PRINGLES®&quot;<br />
                    <strong>Cobertura:</strong> Nivel Nacional.<br />
                    <strong>Vigencia:</strong> 2º de noviembre al 31 de
                    diciembre de 2020 (la “Vigencia”).<br />
                    <strong>Naturaleza:</strong> La promoción no involucra el
                    azar; es una promoción de acumulación.<br />
                    <strong>Organizador:</strong> Smile Marketing, S. de R.L. de
                    C.V. (el “Organizador” u “Organizadora”,
                    indistintamente).<br />
                    <strong>Domicilio del Organizador:</strong> Libramiento Fray
                    Junípero Serra #2450 No. Int. #205. Col. Juriquilla.
                    Querétaro, Querétaro. CP 76230.
                  </p>
                  <p><strong>II. DEFINICIONES.</strong></p>
                  <p>
                    Sin perjuicio de cualesquiera otros términos que puedan
                    encontrarse aquí definidos, para efectos de estas Bases se
                    entenderá por:
                  </p>
                  <p>
                    <strong>Acreedor al incentivo.-</strong> Es el Usuario que
                    compre los productos participantes y envíe su ticket de
                    compra y/o lata conforme a los presentes Términos y
                    Condiciones.
                  </p>
                  <p>
                    <strong>Datos:</strong> Los datos básicos del participante
                    que deberá de enviar junto con una foto del ticket de compra
                    de productos y/o lata al número Telefónico al 442 610 9962
                    vía WhatsApp.<br />
                    Ticket: El recibo de compra de la tienda participante, el
                    cual deberá de mencionar la compra de cualquiera de los
                    productos participantes.
                  </p>
                  <ul class="bullets">
                    <li>
                      Pringles de 37 a 40g en sus sabores original, crema y
                      cebolla, queso cheddar, chile y limón, extra hot &amp;
                      pizza
                    </li>
                    <li>
                      Pringles de 67 a 71g en sus sabores: original, crema y
                      cebolla y queso cheddar
                    </li>
                    <li>Pringles Original 124g</li>
                    <li>Pringles Crema y Cebolla 124g</li>
                    <li>Pringles Queso 124g</li>
                    <li>Pringles Chile y Limón 124g</li>
                    <li>Pringles Extra Hot 124g</li>
                    <li>Pringles Wavy Jalapeño 112g</li>
                    <li>Pringles Wavy Cheddar 112g</li>
                    <li>Pringles Wavy BBQ 112g</li>
                    <li>Pringles Cheddar Sour Cream 158g</li>
                    <li>Pringles Memphis BBQ 158g</li>
                    <li>Pringles BBQ 158g</li>
                    <li>Pringles Jalapeño 158g</li>
                    <li>Pringles Ranch 158g</li>
                    <li>Pringles Salt &amp; Vinegar 158g</li>
                    <li>Pringles Baked Potato 158g</li>
                    <li>Pringles Honey Mustard 158g</li>
                    <li>Pringles Original Reduced Fat 158g</li>
                    <li>Pringles Pickles 158g</li>
                    <li>Pringles Original Bonus Pack 149g</li>
                    <li>Pringles Crema y Cebolla Bonus Pack 149g</li>
                    <li>Pringles Queso Bonus Pack 149g</li>
                  </ul>
                  <p>
                    <strong>Dinámica.-</strong> Se compone de las diversas
                    actividades que permitirán a los Participantes poder ganar
                    alguno de los incentivos que se determinan en este
                    documento.
                  </p>
                  <p>
                    <strong>Incentivos.</strong>- Bienes que se ofrecen en la
                    Promoción y que se describen en la sección V. de estas
                    Bases.
                  </p>
                  <p>
                    <strong>Mecánica de la promoción.-</strong> Conjunto de
                    lineamientos y pasos a seguir para participar en la Dinámica
                    y aspirar a obtener el incentivo.
                  </p>
                  <p>
                    <strong>Participaciones.-</strong> Se entiende por
                    “Participación” cada vez que un usuario registre por
                    WhatsApp el Ticket de compra de cualquiera de los productos
                    participantes.
                  </p>
                  <p>
                    <strong>Productos Participantes.-</strong> Los productos
                    especificados en la Sección IV (“Productos Participantes”)
                    de estas bases.
                  </p>
                  <p>
                    <strong>Lata.-</strong> Producto Participante de la
                    Promoción.
                  </p>
                  <p>
                    <strong>Tiendas Participantes.-</strong> Los
                    establecimientos comerciales hypermercados, supermercados y
                    tiendas de conveniencia.
                  </p>
                  <p>
                    <strong>Usuario.-</strong> Persona que cuenta con un Ticket
                    y/o lata para realizar el registro vía WhatsApp.
                  </p>
                  <p>
                    <strong>Incentivos totales.-</strong> 1,100 premios mismos
                    que se desglosan de la siguiente manera:
                  </p>
                  <ul class="bullets">
                    <li>
                      Lugar 1 al 25: 25 bocinas - Bosé revolve plus, Bocina
                      portátil con sonido 360, resistente al agua - $6,599.00
                    </li>
                    <li>
                      Lugar 26 al 60: 35 bocinas - Bosé sound link color ii
                      Altavoz portátil con exterior de silicona suave,
                      resistente al agua - $3,200.00
                    </li>
                    <li>
                      Lugar 61 al 110: 50 pantallas - Echo show 8” - Pantalla
                      inteligente con HD. Reproductor de música, video,
                      videollamadas y compatible con el sistema Alexa.-
                      $3,200.00
                    </li>
                    <li>
                      Lugar 111 al 150 - 40 pantallas - Echo show 5” - Pantalla
                      compacta de 5.5&quot;. Reproductor de música, video,
                      videollamadas y compatible con el sistema Alexa. -
                      $2,000.00
                    </li>
                    <li>
                      Lugar 151 al 1,100: 950 membresias de - Spotify 3 meses -
                      $300.00 - Membresía Spotify 3 meses de acceso ilimitado a
                      la plataforma de música Spotify.
                    </li>
                  </ul>
                  <p>
                    <strong
                      >III. REQUISITOS DE ELEGIBILIDAD PARA PARTICIPAR EN LA
                      PROMOCIÓN.</strong
                    >
                  </p>
                  <ol class="decimal">
                    <li>
                      Podrá participar en la Promoción cualquier persona física
                      que tenga la disposición de participar, compre los
                      Productos Participantes y reúna todos y cada uno de los
                      siguientes requisitos (el “Participante” o los
                      “Participantes”):

                      <ol class="letter" type="a">
                        <li>
                          Ser mayor de edad con plena capacidad de ejercicio
                          legal.
                        </li>
                        <li>Contar con legal residencia en México.</li>
                        <li>
                          Contar con una identificación oficial con fotografía,
                          que acredite la fecha de nacimiento e identidad
                          (credencial para votar, pasaporte, cartilla del
                          servicio militar liberada, cédula profesional, etc.,
                          expedida por institución gubernamental del
                          Territorio).
                        </li>
                        <li>
                          Proporcionar la información que más adelante se
                          detalla.
                        </li>
                      </ol>
                    </li>
                    <li>
                      No podrán participar todas aquellas personas que sean
                      familiares hasta el tercer grado de un empleado o
                      empleados del Organizador, subsidiarias y/o agencias
                      “partners” así como de cualquiera de las empresas que
                      formen parte del mismo grupo de intereses económicos al
                      que pertenece el Organizador y las empresas vinculadas en
                      la ejecución de la Promoción.<br />
                    </li>
                    <li>
                      El participante se obliga expresamente a garantizar la
                      exactitud y veracidad de todos los datos otorgados. El
                      Organizador se exime de cualquier responsabilidad por
                      errores en la información compartida por el Participante
                      que retrasen, obstaculicen o impidan, total o
                      parcialmente, posibilidad de entregar el incentivo objeto
                      de la Promoción.
                    </li>
                    <li>
                      El Organizador se reserva el derecho de verificar en
                      cualquier momento que cada uno de los participantes cumpla
                      con las condiciones de elegibilidad para participar en la
                      Promoción, quedando exento de cualquier obligación
                      (incluyendo la entrega de Incentivos o cualquier otra)
                      frente a cualquier persona que haya participado o que haya
                      pretendido participar en la Promoción sin cumplir con las
                      condiciones de elegibilidad.
                    </li>
                  </ol>
                  <p>
                    No podrán participar todas aquellas personas que sean
                    familiares hasta el tercer grado de un empleado o empleados
                    del Organizador, subsidiarias y/o agencias “partners” así
                    como de cualquiera de las empresas que formen parte del
                    mismo grupo de intereses económicos al que pertenece el
                    Organizador y las empresas vinculadas en la ejecución de la
                    Promoción.
                  </p>
                  <p><strong>IV. PRODUCTOS PARTICIPANTES.</strong></p>
                  <p>
                    1. Los Productos Participantes son los productos de la marca
                    Pringles® de las siguientes presentaciones:
                  </p>
                  <ul class="bullets">
                    <li>
                      Pringles de 37 a 40g en sus sabores original, crema y
                      cebolla, queso cheddar, chile y limón, extra hot &amp;
                      pizza
                    </li>
                    <li>
                      Pringles de 67 a 71g en sus sabores: original, crema y
                      cebolla y queso cheddar
                    </li>
                    <li>Pringles Original 124g</li>
                    <li>Pringles Crema y Cebolla 124g</li>
                    <li>Pringles Queso 124g</li>
                    <li>Pringles Chile y Limón 124g</li>
                    <li>Pringles Extra Hot 124g</li>
                    <li>Pringles Wavy Jalapeño 112g</li>
                    <li>Pringles Wavy Cheddar 112g</li>
                    <li>Pringles Wavy BBQ 112g</li>
                    <li>Pringles Cheddar Sour Cream 158g</li>
                    <li>Pringles Memphis BBQ 158g</li>
                    <li>Pringles BBQ 158g</li>
                    <li>Pringles Jalapeño 158g</li>
                    <li>Pringles Ranch 158g</li>
                    <li>Pringles Salt &amp; Vinegar 158g</li>
                    <li>Pringles Baked Potato 158g</li>
                    <li>Pringles Honey Mustard 158g</li>
                    <li>Pringles Original Reduced Fat 158g</li>
                    <li>Pringles Pickles 158g</li>
                    <li>Pringles Original Bonus Pack 149g</li>
                    <li>Pringles Crema y Cebolla Bonus Pack 149g</li>
                    <li>Pringles Queso Bonus Pack 149g</li>
                  </ul>
                  <p><strong>V.TIENDAS PARTICIPANTES.</strong></p>
                  <p>
                    Los Productos Participantes podrán ser adquiridos en
                    establecimientos comerciales hypermercados, supermercados y
                    tiendas de conveniencia debidamente y legalmente
                    constituidos en toda la República Mexicana y que vendan
                    dichos productos.
                  </p>
                  <p><strong>VI. DINÁMICA DE LA PROMOCIÓN.</strong></p>
                  <p>
                    La presente Promoción es mediante una mecánica de compra de
                    productos participantes, registra y gana, no interviene el
                    azar. La dinámica es la siguiente:
                  </p>
                  <ol class="letter" type="a">
                    <li>
                      El participante deberá de comprar cualquiera de los
                      productos participantes de la “Promoción”, tomarle una
                      foto al ticket y/o la/las latas (“Productos
                      Participantes”) y enviarla por medio de WhatsApp al No.
                      442 610-9962 junto con los siguientes datos: Nombre
                      completo, foto del Ticket o en caso de ser lata foto de:
                      fecha de caducidad y lote, y el monto de compra.
                    </li>
                    <li>
                      Las personas que hayan acumulado los mayores montos en
                      compras y se muestren en los primeros lugares de la tabla
                      de posiciones establecida en la página
                      <a
                        href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                        target="_blank"
                        >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                      >
                      (en lo sucesivo “landing page”), serán los ganadores.
                    </li>
                  </ol>
                  <p>
                    Los ganadores serán de acuerdo a la siguiente tabla de
                    posiciones:
                  </p>
                  <ul class="bullets">
                    <li>
                      Lugar 1 al 25: 25 bocinas - Bosé revolve plus, Bocina
                      portátil con sonido 360, resistente al agua – Con un costo
                      de $6,599.00
                    </li>
                    <li>
                      Lugar 26 al 60: 35 bocinas - Bosé sound link color ii
                      Altavoz portátil con exterior de silicona suave,
                      resistente al agua - $3,200.00
                    </li>
                    <li>
                      Lugar 61 al 110: 50 pantallas - Echo show 8” - Pantalla
                      inteligente con HD. Reproductor de música, video,
                      videollamadas y compatible con el sistema Alexa.-
                      $3,200.00
                    </li>
                    <li>
                      Lugar 111 al 150 - 40 pantallas - Echo show 5” - Pantalla
                      compacta de 5.5&quot;. Reproductor de música, video,
                      videollamadas y compatible con el sistema Alexa. -
                      $2,000.00
                    </li>
                    <li>
                      Lugar 151 al 1,100: 950 membresias de - Spotify 3 meses -
                      $300.00 - Membresía Spotify 3 meses de acceso ilimitado a
                      la plataforma de música Spotify.
                    </li>
                  </ul>
                  <p>
                    En caso de empate, se usará el criterio para determinar al
                    ganador, del participante que se haya registrado primero,
                    considerando día, hora minuto y segundo. El ranking de los
                    premios será en el orden de acuerdo al total de la compra
                    acumulada.
                  </p>
                  <ol class="letter" type="a" start="3">
                    <li>
                      El Organizador se pondrá en contacto con los participantes
                      preseleccionados ganadores y les solicitará sigan los
                      siguientes pasos: 1.-Acceder a la página
                      <a
                        href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                        target="_blank"
                        >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                      >
                      (landing page), 2.Llenar el formulario con su nombre,
                      teléfono y correo electrónico, 3. Aceptar los Términos y
                      Condiciones y 4. Manifestar su conformidad en los términos
                      que ahí se señalan.
                    </li>
                  </ol>
                  <p>
                    Será indispensable que el participante siga las
                    instrucciones previamente señaladas para ser validado como
                    ganador.
                  </p>
                  <ol class="letter" type="a" start="4">
                    <li>
                      La selección de ganadores, se realizará de acuerdo con las
                      bases y criterios de participación establecidos en los
                      Términos y Condiciones de la Promoción, los cuales serán
                      publicados en la página
                      <a href="http://www.promopringles.com" target="_blank"
                        >www.promopringles.com</a
                      >
                      y la consulta de los ganadores será en la landing
                      <a
                        href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                        target="_blank"
                        >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                      >. Los participantes preseleccionados ganadores serán
                      contactados vía WhatsApp por El Organizador dentro de los
                      14 días siguientes al finalizar la promoción, y se le
                      pedirá confirmación de nombre completo y que sigan los
                      pasos señalados en el punto inmediato anterior.
                    </li>
                  </ol>
                  <p>
                    En caso de que los Incentivos totales no se agoten al
                    finalizar la Promoción, el Organizador podrá disponer de
                    éstos como mejor convenga para los intereses de la
                    Promoción.
                  </p>
                  <p><strong>VIII. ENTREGA DE INCENTIVOS.</strong></p>
                  <p>
                    El Organizador se encargará de recaudar la información de
                    los ganadores. Una vez obtenida, se le enviará, a los
                    primeros 150 ganadores vía paquetería, el premio
                    correspondiente. A los 950 ganadores restantes se les
                    entregará su código ganador a través de WhatsApp al número
                    de teléfono proporcionado por el ganador.
                  </p>
                  <p>
                    El participante después de obtener la calidad de ganador
                    (que le será notificada por el organizador vía whatsapp),
                    tendrá 7 días naturales para acceder a la landing
                    <a
                      href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                      target="_blank"
                      >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                    >, llenar el formulario y manifestar su conformidad con los
                    términos y condiciones, de no ser así, se dará por no
                    aceptado el premio y El Organizador podrá disponer del mismo
                    como mejor le convenga.
                  </p>
                  <p>
                    Todas las personas que obtengan la calidad de ganador,
                    deberán guardar los empaques de los productos con los que
                    participaron, ya que se les podrían solicitar en un momento
                    dado.
                  </p>
                  <p>
                    La fecha límite para entrega de los incentivos será el 25 de
                    enero de 2021.
                  </p>
                  <p>
                    Una vez realizada la entrega del Incentivo, el ganador será
                    el único responsable de cualquier asunto relacionado con el
                    mismo, liberando de toda responsabilidad a El Patrocinador y
                    El Organizador, así como a filiales y subsidiarias.
                  </p>
                  <p>
                    Los ganadores del concurso no podrán transferir, negociar o
                    canjear el Incentivo por efectivo ni por otros bienes o
                    servicios que el Patrocinador tenga.
                  </p>
                  <p>¿Cómo se comunica la promoción?</p>
                  <p>
                    Esta promoción se comunicará a través de la página de
                    Facebook:
                    <a
                      href="https://www.facebook.com/PringlesLA"
                      target="_blank"
                      >https://www.facebook.com/PringlesLA</a
                    >.
                  </p>
                  <p><strong>IX. RESTRICCIONES Y LIMITACIONES.</strong></p>
                  <ol class="decimal">
                    <li>
                      El Organizador no se hará responsable por cualquier falla
                      o eventualidad en la Participación derivadas de una mala
                      conexión de internet del Participante.
                    </li>
                    <li>
                      El Participante deberá de contar con un teléfono
                      inteligente con la aplicación WhatsApp y acceso a internet
                      para poder participar.
                    </li>
                    <li>
                      El participante podrá participar solamente una vez en la
                      Promoción por lo que sólo se otorgará un Incentivo por
                      Participante, durante toda la vigencia de la promoción.
                    </li>
                    <li>
                      Los Incentivos en ningún caso serán sustituidos por dinero
                      en efectivo, ni por ningún otro incentivo. Los ganadores
                      del concurso no podrán transferir, negociar o canjear el
                      incentivo por efectivo ni por otros bienes o servicios que
                      tenga el Patrocinador.
                    </li>
                    <li>
                      El Organizador ni el Patrocinador se hacen responsables
                      por ningún daño y/o accidente que pudiera surgir al
                      utilizar cualquiera de los incentivos otorgados.
                    </li>
                  </ol>
                  <p><strong>X. SUPUESTOS DE DESCALIFICACIÓN.</strong></p>
                  <p>
                    Sin perjuicio de que la participación en esta Promoción
                    implica la aceptación de estas Bases, según sean modificadas
                    por el Organizador, así como de las decisiones que se
                    adopten sobre cualquier cuestión prevista o no prevista en
                    ellas, el Organizador se reserva el derecho a descalificar a
                    cualquier persona que a su criterio:
                  </p>
                  <ol class="decimal">
                    <li>
                      Incumpla cualquiera de los requisitos señalados en las
                      presentes Bases.
                    </li>
                    <li>
                      Viole cualquier regla y/o especificación de estas Bases.
                    </li>
                    <li>
                      Proporcione datos no verídicos durante la Promoción,
                      conforme éstos sean requeridos por el Organizador.
                    </li>
                    <li>
                      Ataque o intente alterar o manipular al sistema
                      informático de la Promoción.
                    </li>
                    <li>
                      No permita validar su identidad mediante acciones u
                      omisiones en el proceso.
                    </li>
                    <li>
                      Lleve a cabo acciones que atenten contra el orden público,
                      el honor de personas, los usos y las buenas costumbres, o
                      los valores y la reputación de las marcas asociadas a esta
                      Promoción y sus titulares o licenciatarios.
                    </li>
                    <li>
                      Ponga en riesgo la integridad y buen desarrollo de la
                      Promoción, como en el caso de “hackers” (término utilizado
                      para referirse a personas con grandes conocimientos en
                      informática y telecomunicaciones que son empleados con
                      objetivos personales con fines de lucro, el cual puede o
                      no ser maligno o ilegal) o “caza promociones” (definido
                      como todo aquel participante que actúa solo o
                      conjuntamente con recursos económicos, materiales o
                      informativos de forma desleal frente a los otros
                      Participantes para obtener el beneficio de la promoción
                      sin importar que los mecanismos que usa sean poco éticos,
                      morales o incluso ilegales).
                    </li>
                  </ol>
                  <p>
                    En caso de que el Organizador descalifique una
                    participación, no se genera responsabilidad de ninguna
                    índole para el Organizador y/o el Patrocinador ni sus
                    funcionarios, administradores ni personal de este frente al
                    Participante ni frente a terceros. En caso de
                    descalificación de un Participante por cualquier motivo, el
                    Participante tendrá la obligación de devolver el o los
                    Incentivos obtenidos y el Organizador conservará el derecho
                    de poder realizar la asignación del Incentivo al que éste
                    hubiera sido acreedor, realizando una nueva selección de los
                    Participantes o disponer de éste a su conveniencia.
                  </p>
                  <p>
                    El Organizador se reserva el derecho de actuar legalmente en
                    contra de cualquiera de los Participantes que haya cometido
                    un acto ilícito en relación con la Promoción, pudiendo
                    intentar toda clase de recursos o acciones que sean
                    legalmente aplicables.
                  </p>
                  <p><strong>XI. CONDICIONES GENERALES.</strong></p>
                  <ol class="decimal">
                    <li>
                      El Organizador de la Promoción podrá en cualquier momento
                      suspender la Promoción o la entrega de los Incentivos sin
                      responsabilidad alguna, si dicha suspensión es en acato de
                      cualquier orden administrativa o judicial y/o si se dieren
                      circunstancias de caso fortuito o fuerza mayor o eventos
                      que afecten su ejecución, así como cualquier acto y/o
                      amenaza que ponga en riesgo la seguridad de los
                      participantes y/o del Organizador y/o del Patrocinador.
                    </li>
                    <li>
                      Si por causas ajenas a el Organizador de la Promoción se
                      suspendiera, modificara, alterara o de cualquier forma se
                      vieran imposibilitados los acreedores para recibir el
                      Incentivo, ésta se reserva el derecho de otorgar
                      opcionalmente algún incentivo igual o similar al incentivo
                      al que participante se hizo acreedor. De igual forma se
                      procederá en los casos que existieran actos políticos,
                      sociales o de salud que hicieran inejecutables las
                      entregas respectivas de incentivos.
                    </li>
                    <li>
                      En todos estos casos, el Organizador de la Promoción
                      publicará a través de la página (landing page)
                      <a href="http://www.promopringles.com" target="_blank"
                        >www.promopringles.com</a
                      >
                      y/o
                      <a
                        href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                        target="new"
                        >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                      >
                      (landing los motivos en que se fundamenta la realización
                      de cualquiera de los actos establecidos en el presente
                      párrafo.
                    </li>
                    <li>
                      El Organizador de la Promoción no será responsable por
                      hechos acontecidos durante o como consecuencia de la
                      entrega de cada Incentivo, así como relacionados con la
                      calidad, garantías por daños, pérdidas o perjuicios
                      ocasionados por el uso y disfrute de cada Incentivo y los
                      productos que lo integran.
                    </li>
                    <li>
                      El Organizador de la Promoción emprenderá las acciones
                      legales pertinentes contra aquellos Participantes que
                      pretendan defraudar, engañar o burlar las reglas y
                      procedimientos de la Promoción.
                    </li>
                  </ol>
                  <p>
                    Para cualquier aclaración o información referente a esta
                    promoción, comunicarse al (442) 260 9126 de la Ciudad de
                    México y Área Metropolitana o del interior de la República
                    Mexicana.
                  </p>
                  <p>
                    <strong
                      >XII. CONSIDERACIONES Y RESERVAS DE DERECHO DEL
                      ORGANIZADOR.</strong
                    >
                  </p>
                  <p>
                    Durante toda la vigencia de la Promoción, el Organizador se
                    reserva la facultad de, a su libre arbitrio, eliminar de sus
                    redes sociales y/o páginas web y/o aplicaciones cualquier
                    comentario o forma de interacción que no cumpla con los
                    requisitos previstos en este documento y/o que de cualquier
                    manera afecten a la moral, al orden público y/o a las buenas
                    costumbres.
                  </p>
                  <p>
                    Asimismo, toda forma de interacción que contenga elementos,
                    imágenes y/o frases obscenas y/o que de cualquier manera
                    perjudiquen o puedan llegar a causar un perjuicio de
                    cualquier índole al resto de los usuarios, a quienes
                    interactúen de cualquier manera en la Promoción y/o a
                    terceros; así como aquellos elementos, imágenes y/o frases
                    obscenas y/o que de cualquier manera perjudiquen o puedan
                    llegar a causar un perjuicio de cualquier índole al
                    Organizador o a cualquiera de los productos de y/o marcas
                    licenciadas a favor del Organizador, podrán ser
                    automáticamente descalificadas por Organizador, sin que ello
                    conlleve responsabilidad de ninguna índole para el
                    Organizador ni sus funcionarios, administradores ni personal
                    frente al Usuario ni frente a terceros.
                  </p>
                  <p>
                    Cualquier intento o ejecución de aplicación de programas o
                    sistemas que pretendan atacar o vulnerar la Promoción, podrá
                    ser perseguido con las acciones legales pertinentes que a
                    criterio del organizador sean suficientes y acarrea la
                    inmediata descalificación y anulación de participación del
                    consumidor Participante que incurra en tal conducta.
                  </p>
                  <p>
                    Toda decisión del Organizador será inapelable, definitiva y
                    sin posibilidad de empate. Además de que podrá cambiar la
                    mecánica de participación en cualquier momento.
                  </p>
                  <p>
                    Estas bases se rigen por la legislación vigente en los
                    Estados Unidos Mexicanos y se sujetan a la jurisdicción de
                    los tribunales competentes de la Ciudad de México.
                  </p>
                  <p>
                    <strong>LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR.</strong>
                    El Organizador podrá en cualquier momento suspender La
                    Promoción o la entrega del incentivo sin responsabilidad
                    alguna, si dicha suspensión responde en acato de cualquier
                    disposición emitida por cualquier autoridad jurisdiccional,
                    así como si se dieren circunstancias de caso fortuito o
                    fuerza mayor o eventos que afecten los intereses de El
                    Organizador.
                  </p>
                  <p>
                    Si por causas ajenas a El Organizador se suspendiera,
                    modificara, alterara o de cualquier forma se vieran
                    imposibilitados los acreedores para disfrutar del incentivo
                    o de la actividad programada, El Organizador se reserva el
                    derecho de otorgar opcionalmente algún incentivo igual o
                    similar al incentivo al que el participante se hizo
                    acreedor. De igual forma se procederá en los casos que
                    existieran actos políticos, sociales o de salud que pusieran
                    en peligro la integridad física de cualquiera de los
                    acreedores.
                  </p>
                  <p>
                    En todos estos casos, El Organizador publicará a través de
                    su página (landing page) los motivos en que se fundamenta la
                    realización de cualquiera de los actos establecidos en el
                    presente párrafo.
                  </p>
                  <p>
                    Ninguno de los incentivos serán sujetos de comercialización
                    alguna de manera enunciativa, más no limitativa, venta,
                    permuta, arrendamiento, sustitución o dación en pago.
                  </p>
                  <p>
                    La responsabilidad de El Organizador concluye al momento de
                    la entrega de cada uno de los incentivos a sus
                    correspondientes acreedores.
                  </p>
                  <p>
                    El Organizador no será responsable por el uso que le brinde
                    el participante a cualquiera de los incentivos, ni por
                    procesos judiciales, extrajudiciales o de cualquier otra
                    índole que puedan ser interpuestos en relación con un
                    reclamo de cualquier tipo de responsabilidad, por hechos
                    acontecidos durante o como consecuencia de la entrega del
                    mismo, así como relacionados con la calidad, garantías por
                    daños, pérdidas o perjuicios ocasionados por el uso y
                    disfrute del incentivo.
                  </p>
                  <p>
                    Los incentivos serán entregados en la forma establecida por
                    El Organizador, siempre y cuando el participante haya
                    cumplido con los Términos y Condiciones de la Promoción y
                    siempre que no se haya identificado alguna irregularidad en
                    su participación.
                  </p>
                  <p>
                    El acreedor al incentivo deberá seguir todas las direcciones
                    e instrucciones de El Organizador, en caso de incumplimiento
                    de cualquiera de éstas, el participante será descalificado.
                  </p>
                  <p>
                    Al participar en La Promoción, el acreedor acepta
                    expresamente haber leído y entendido a la perfección los
                    presentes términos y condiciones y estar de acuerdo con los
                    mismos.
                  </p>
                  <p>
                    El Organizador se reserva el derecho a realizar
                    modificaciones o añadir anexos sucesivos sobre su mecánica e
                    incentivos, siempre que las mismas estén justificadas o no
                    perjudiquen a los participantes y se comuniquen a éstos
                    debidamente.
                  </p>
                  <p>
                    El Organizador se reserva el derecho de verificar el
                    cumplimiento de estas Bases por parte de los Participantes,
                    quedando exento de cualquier obligación (incluyendo la
                    entrega de incentivos o cualquier otra) ante cualquier
                    persona que haya participado o que haya pretendido
                    participar en la Promoción sin cumplir con las condiciones
                    de elegibilidad, la mecánica o cualesquiera otros términos y
                    condiciones aquí previstos. Al efecto:
                  </p>
                  <p><strong>Acumulación</strong></p>
                  <ol class="letter" type="a">
                    <li>
                      Se entenderá por acumulación a aquella que corresponda a
                      tickets y/o latas enviados desde un mismo número de
                      teléfono. Los tickets de compra y/o latas no pueden
                      repetirse. Es decir, el mismo ticket y/o lata no puede ser
                      considerado 2 veces.
                    </li>
                    <li>
                      Los Participantes deberán guardar los originales de los
                      tickets de compra y/o las latas. Aquellos tickets
                      originales y digitales que, al momento de ser presentados
                      para su validación, presenten cualquier tipo de
                      falsificación, alteración y/o enmendadura voluntaria o
                      involuntaria, según corresponda, serán nulos.
                    </li>
                    <li>
                      No serán válidos los tickets que ya se hayan registrado
                      una vez para participar en la Promoción (reuso), así como
                      aquellos que no cumplan con la condición de compra de los
                      Productos Participantes.
                    </li>
                  </ol>
                  <p>
                    <strong>ACEPTACIÓN.-</strong> Los participantes aceptan que
                    todas las decisiones que adopten EL ORGANIZADOR sobre
                    cualquier cuestión no prevista en las mismas serán finales.
                  </p>
                  <p>
                    <strong>ACCIONES.-</strong> Todas las personas participantes
                    renuncian a cualquier acción de reclamo civil,
                    administrativo, penal, laboral, y a cualquier otra acción de
                    índole judicial en contra de EL ORGANIZADOR y EL
                    PATROCINADOR, sus representantes legales, ejecutivos,
                    empleados, sus filiales y/o sus subsidiarias y todas las
                    marcas de las cuales éstas son titulares. Los participantes
                    reconocen y aceptan que la ley aplicable para cualquier
                    controversia que surja con relación a esta promoción será
                    mencionada en el punto XV del presente y renuncian a su
                    derecho a iniciar cualquier tipo de reclamación en otra
                    jurisdicción.
                  </p>
                  <p>
                    <strong
                      >XIII. AUTORIZACIÓN DE USOS DE DATOS PERSONALES Y DE
                      IMAGEN.</strong
                    >
                  </p>
                  <ol class="decimal">
                    <li>
                      Al participar en la Promoción se autoriza expresamente la
                      utilización, publicación y reproducción, sin limitación o
                      restricción alguna, el uso y la publicación del nombre de
                      quien resulte ganador, en cualquier tipo de publicidad,
                      promoción, publicación, a través de cualquier medio, con
                      fines comerciales o informativos por parte del Organizador
                      y/o Patrocinador. Los participantes y/o ganadores no
                      tendrán derecho a recibir contraprestación alguna por los
                      hechos descritos en el presente numeral.
                    </li>
                    <li>
                      Así mismo autoriza el envío de publicidad, información
                      comercial o promocional por parte del Patrocinador.
                    </li>
                    <li>
                      El Participante al formar parte de la Promoción, autoriza
                      expresamente al Organizador y Patrocinador de la
                      Promoción, y se obliga a suscribir cualquier documento que
                      resulte necesario en favor de éstos, que tenga como
                      finalidad fijar y difundir su imagen, nombre y carácter de
                      acreedor de cualquier incentivo, así como su imagen y/o
                      voz para la elaboración de material fotográfico o
                      testimonial de la Promoción y su difusión en los medios de
                      comunicación conocidos o por conocerse que el Organizador
                      y Patrocinador de la Promoción juzgue convenientes
                      incluyendo las denominadas redes sociales. Los
                      Participantes acreedores por haber recibido el incentivo
                      como contraprestación se obligan a no revocar tal
                      autorización. Los testimoniales a que se hacen referencia
                      en el párrafo anterior y en su caso, cualquier obra
                      producida derivado de la presente actividad promocional
                      serán propiedad del Organizador de la Promoción, las
                      cuales podrá difundir y utilizar de la manera que más
                      convenga a sus intereses, sin que esto implique pago
                      alguno a dichos participantes por su aparición en
                      cualquier medio de comunicación.
                    </li>
                    <li>
                      En consideración a las autorizaciones otorgadas conforme a
                      los párrafos anteriores, los participantes ceden en favor
                      del Organizador y el Patrocinador de manera gratuita, es
                      decir sin contraprestación a cambio alguna, de manera
                      indefinida todos los derechos tangibles e intangibles de
                      propiedad intelectual e/o industrial que en su caso
                      pudieran corresponderles sobre los materiales generados,
                      para su explotación, difusión, comunicación al público y
                      puesta a disposición del público en todos los territorios
                      y con la frecuencia de repetición que convenga a sus
                      intereses y/o a cualquiera de sus empresas controladoras,
                      subsidiarias, afiliadas, licenciatarias, representantes
                      legales, cesionarios y/o cualquier entidad relacionada con
                      o que éstas indiquen, en los que estén contenidos su
                      nombre, voz y/o imagen, así como cualquier interpretación
                      y/o ejecución artística o parte de las mismas, utilizando
                      los medios técnicos y tecnológicos conocidos en la
                      actualidad y los que pudieran desarrollarse en el futuro,
                      y para cualquier tipo de aplicación o medio de difusión
                      conforme convenga a sus intereses, autorizando además a
                      efectuar cambios, modificaciones, adiciones y supresiones
                      de dichos materiales sin limitación alguna.
                    </li>
                  </ol>
                  <p><strong>XIV. AVISO DE PRIVACIDAD.</strong></p>
                  <p>
                    El presente aviso de privacidad se encontrará disponible
                    durante la vigencia de la promoción en la página (landing
                    page)
                    <a href="http://www.promopringles.com" target="_blank"
                      >www.promopringles.com</a
                    >
                    y/o
                    <a
                      href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                      target="_blank"
                      >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                    >
                  </p>
                  <p>
                    a. IDENTIDAD DEL RESPONSABLE DEL TRATAMIENTO<br />
                    Con motivo del cumplimiento de la Ley Federal de Protección
                    de Datos Personales en Posesión de los Particulares (en
                    adelante “LFPDPPP”) y su reglamento, SMILE MARKETING S DE RL
                    DE CV., (en adelante “SMILE MARKETING”), será la responsable
                    de la obtención, resguardo, tratamiento y/o en su caso de la
                    remisión y/o transferencia de sus datos personales (en
                    adelante “DATOS”), con domicilio en:<br />
                    Libramiento Fray Junípero Serra #2450 No. Int. #205. Col.
                    Juriquilla. Querétaro, Querétaro. CP 76230.
                  </p>
                  <p>
                    b. OBTENCIÓN Y FINALIDADES DEL TRATAMIENTO DE LA
                    INFORMACIÓN.<br />
                    Los DATOS serán obtenidos de usted, a través de los talleres
                    y/o por cualquier medio electrónico, óptico, sonoro, físico,
                    visual, u otra tecnología regulada por la LFPDPPP. Los DATOS
                    que se recabarán serán los siguientes: Datos de
                    identificación y contacto como, de manera enunciativa más no
                    limitativa, (i)Nombre completo y número telefónico.,
                    (ii)Domicilio, (iii) Clave única de Registro de Población
                    (CURP)
                  </p>
                  <p>
                    SMILE MARKETING utilizará sus DATOS para llevar a cabo la
                    administración de su participación en La Promoción. SMILE
                    MARKETING tratará sus DATOS con las debidas medidas de
                    seguridad administrativas, técnicas y físicas suficientes
                    y/o necesarias para cumplir con las obligaciones
                    establecidas en la LFPDPPP y su reglamento.
                  </p>
                  <p>
                    c. TRANSFERENCIA Y/O REMISION DE DATOS PERSONALES.<br />
                    Con motivo de La Promoción, SMILE MARKETING podrá transferir
                    y/o remitir sus DATOS a filiales, subsidiarias y/o terceros
                    nacionales o extranjeros, ya sean personas físicas o morales
                    con las que sostenga una relación jurídica, comercial y/o
                    contractual, señalando que no se requerirá de su
                    consentimiento para realizar la(s) transferencia(s) y/o
                    remisión(es) necesaria(s), cuando se presente alguno de los
                    supuestos establecidos en los artículos 14 y 37 de la
                    LFPDPPP, los datos obtenidos únicamente se podrán utilizar
                    con el propósito de ejecutar la actividad promocional.
                  </p>
                  <p>
                    d. EJERCICIO DE SUS DERECHOS ARCO<br />
                    Usted puede solicitar a SMILE MARKETING en cualquier
                    momento, el Acceso, Rectificación, Cancelación u Oposición
                    (DERECHOS ARCO), respecto de sus DATOS, así como revocar su
                    consentimiento para el tratamiento de dichos DATOS, por
                    medio de un correo electrónico a la dirección:
                    contacto@smilemarketing.mx. Así mismo, usted podrá limitar
                    el uso y/o divulgación de sus DATOS a través del medio antes
                    señalado.
                  </p>
                  <p>
                    e. CAMBIOS AL AVISO DE PRIVACIDAD<br />
                    Cualquier cambio por disposición legal, regulatoria y/o
                    corporativa, que se realice a este aviso de privacidad, será
                    dado a conocer través de la página (landing page)
                    <a href="http://www.promopringles.com" target="_blank"
                      >www.promopringles.com</a
                    >
                    y/o
                    <a
                      href="https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html"
                      target="_blank"
                      >https://www.smile.marketing/porsisearmalacaseraconpringles/terminos-y-condiciones.html</a
                    >
                    a los titulares de los DATOS.
                  </p>
                  <p>
                    f. AUTORIDAD REGULADORA<br />
                    El Instituto Nacional de Transparencia, Acceso a la
                    Información y Protección de Datos Personales (INAI), es la
                    autoridad competente para garantizar el acceso y protección
                    de sus DATOS, así mismo se le informa que de ser necesario,
                    puede acudir ante el INAI para hacer efectivos sus derechos.
                    Para mayor información te sugerimos visitar su página
                    oficial de Internet
                    <a href="http://www.inai.org.mx" target="_blank"
                      >www.inai.org.mx</a
                    >
                  </p>
                  <p>
                    <strong
                      >XV. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES.</strong
                    >
                  </p>
                  <p>
                    En caso de resultar necesario, y/o a discreción de la
                    Organizadora, ésta se reserva el derecho de ampliar,
                    modificar y/o aclarar el alcance de los presentes Términos y
                    Condiciones, y/o cualquiera de los procedimientos, los
                    cuales serán comunicados a través de la página (landing
                    page) www.promopringles.comy en el boletín impreso quincenal
                    de Farmacias Guadalajara.
                  </p>
                  <p><strong>XVI. SANCIONES.</strong></p>
                  <p>
                    Los Participantes aceptan que cualquier incumplimiento de
                    las obligaciones contenidas en estas condiciones y
                    restricciones, facultan al Organizador, para el inicio de
                    las acciones legales a que haya lugar. Los Participantes
                    aceptan indemnizar, defender y mantener indemne al
                    Organizador, al Patrocinador y sus socios, accionistas,
                    personal y compañías afiliadas ante cualquier
                    responsabilidad, pérdida, reclamación y gasto, incluyendo
                    honorarios y gastos de abogados, si es resultado de la
                    violación de estos términos.
                  </p>
                  <p><strong>XVII. JURISDICCIÓN Y LEY APLICABLE.</strong></p>
                  <p>
                    Estas Bases y la relación que se genere entre cualquier
                    Participante y el Organizador será regida y concertada con
                    total sujeción a las leyes aplicables en los Estados Unidos
                    Mexicanos y a la jurisdicción de los tribunales competentes
                    de la Ciudad de México, renunciando el Participante y el
                    Organizador a cualquier otra jurisdicción que pudiera ser
                    aplicable en función de su domicilio o por cualquier otra
                    razón.
                  </p>
                  <p>
                    PROMOCIÓN “POR SI SE ARMA LA CASERA CON PRINGLES®&quot;” (LA
                    PROMOCIÓN). PROMOCIÓN CONTRATADA POR KELLOGG COMPANY MÉXICO,
                    S. DE R.L. DE C.V. (EL PATROCINADOR) A CARGO DE SMILE
                    MARKETING, S. DE R.L. DE C.V. (LA ORGANIZADORA), PROMOCIÓN
                    DE REGISTRAR, ACUMULAR Y GANAR. VIGENTE DEL 02 DE NOVIEMBRE
                    DEL 2020 AL 31 DE DICIEMBRE DE 2020. PARA CUALQUIER
                    ACLARACIÓN O INFORMACIÓN REFERENTE A ESTA PROMOCIÓN,
                    COMUNICARSE AL (442) 260 9126. PROMOCIÓN REGISTRADA ANTE
                    PROFECO.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </section>
    </main>
    <Footers></Footers>
  </div>
</template>
<script>
import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footers,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("setBodyClass", "global");
  },
  metaInfo() {
    return {
      title: 'Por si se arma la casera con Pringles® | Términos y Condiciones',
      meta: [
        {
          name: 'description',
          content: 'Consulta los Términos y Condiciones de la promoción Por si se arma la casera con Pringles®.'
        },
        {
          name: 'keyword',
          content: ''
        },
      ]
    }
  },
}
</script>