<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms.jpg"  alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMO PRINGLES® XMAS</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                
                                
                                <h4><span class="lb">DISPOSICIONES PRELIMINARES:</span></h4>
                                <p>Los términos y condiciones descritos a continuación delimitan y aclaran las condiciones bajo las cuales se regirá la actividad promocional “Pringles® XMAS 2018”. Lo aquí dispuesto es obligatorio para todos los participantes. Se entenderá que todo participante, al decidir participar en la actividad, conoce y acepta las condiciones y limitaciones establecidas en el presente reglamento; y acepta expresamente el uso de sus datos personales en los términos que más adelante se indican.</p>
                                <p>Kellogg Company México, S. de R.L. de C.V., con domicilio en: KM 1, Carretera a Campo Militar S/N, Colonia San Antonio de la Punta 1, es la responsable de la Actividad Promocional, para territorio mexicano, y es quien podrá modificar en cualquier momento el contenido del presente documento. Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al 5624 2105 desde la CDMX y Área Metropolitana, 01 800 00 KELLOGG (5355644) Interior de la República Mexicana, en un horario de atención de lunes a viernes de 8 am a 8 pm y sábados de 8 am a 3 pm.</p>
                                <p>Cualquier violación a los procedimientos o sistemas establecidos para la realización de la actividad promocional implicará la inmediata revocación de los incentivos. Los incentivos se otorgarán únicamente si los ganadores cumplen estrictamente con los términos, condiciones y requisitos previstos en este reglamento.</p>
                                <p>Para participar en la presente promoción denominada “Pringles® XMAS 2018” (en lo sucesivo la “Promoción”), se deberá cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de los mismos, así como del Aviso de Privacidad para el tratamiento de datos personales (en conjunto en adelante las “Bases”).</p>
                                <h4><span class="lb">TÉRMINOS Y CONDICIONES APLICABLES A LA ACTIVIDAD PROMOCIONAL:</span></h4>
                                <p>Las siguientes definiciones son aplicables a la actividad promocional.</p>
                                <ul>
                                    <li><strong>1. Cobertura Geográfica:</strong> La presente promoción será válida en México (en lo sucesivo el “Territorio” y/o “México”).</li>
                                    <li><strong>2. Sitio WEB de la promoción:</strong> <a href="https://www.promopringles.com/" target="blank">www.promopringles.com</a> (en lo sucesivo El Sitio o página WEB), al cual se podrá acceder desde el banner que estará disponible en <a href="https://www.pringles.com/mx/es/home.html" target="_blank">www.pringles.com</a></li>
                                    <li><strong>3. La Cuenta.</strong> Al Registro de Participante que lo identificará y distinguirá como Usuario respecto de los demás participantes de la promoción. Su administración es personal y responsabilidad del usuario.</li>
                                    <li><strong>4. Registro de Participante.</strong> Se integra de los datos personales proporcionados voluntariamente por el Usuario dentro del Sitio WEB y que componen la Cuenta, información que el participante autoriza utilizar para efectos de la promoción y que consisten en:<ul>
                                            <li>Nombre</li>
                                            <li>Apellido</li>
                                            <li>Fecha de nacimiento</li>
                                            <li>Teléfono</li>
                                            <li>E-mail</li>
                                            <li>Contraseña</li>
                                        </ul>
                                    </li>
                                    <li><strong>5. Vigencia.</strong> Periodo en el cual se llevará a cabo toda la actividad promocional.<br>La vigencia de la promoción es desde las 00:00:01 del 1 de octubre del 2018 hasta las 23:59:59 del 31 de enero de 2019 para participar de la promoción o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes.<br>Con dos periodos de participación:<table class="globalTable" width="531" height="165" border="1">
                                            <tr>
                                                <td>1er periodo</td>
                                                <td>Del 1 de octubre al 15 de diciembre de 2018.</td>
                                            </tr>
                                            <tr>
                                                <td>2do periodo</td>
                                                <td>Del 16 de diciembre de 2018 al 31 de enero de 2019.</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li><strong>6. Participante.</strong> Persona física con 18 años cumplidos, residente en territorio mexicano, que cumpla con los requisitos establecidos en las Bases, no incurra en algún supuesto de descalificación, haya ingresado al Sitio WEB, proporcionando voluntaria, verídica y de forma correcta la información que se le solicite y, por lo tanto, cuente con el Registro de Participante que le permite acceder a la Cuenta, así como que haya cumplido con las condiciones que exige la Dinámica y en caso de resultar ganador, completar su registro conforme a las presentes bases.</li>
                                    <li><strong>7. Mecánica de la promoción.</strong> La promoción consta de los siguientes pasos:<br>
                                        <ul>
                                            <li>1. Encuentra códigos únicos impresos al interior de los empaques de los productos participantes Pringles®.</li>
                                            <li>2. Ingresa a <a href="https://www.pringles.com/mx/es/home.html" target="_blank">www.pringles.com</a>, busca el banner de la promoción que te direccionará a <a href="https://www.promopringles.com/" target="blank">www.promopringles.com</a>, crea tu registro o ingresa a tu cuenta.</li>
                                            <li>Elige el tipo de incentivo por el que participarás en la promoción.<ul>
                                                    <li>a) Tema PS Exclusivo</li>
                                                    <li>b) Cupón $5 dólares americanos PS Store</li>
                                                    <li>c) Cupón para 1 mes de suscripción a PS Plus</li>
                                                    <li>d) Consola</li>
                                                </ul>
                                                <p><u>Registro por incentivos de canje:</u></p>
                                                <p>Si el incentivo que elegiste se encuentra entre los incentivos a), b) o c), deberás registrar el o los códigos únicos requeridos, para validación, de acuerdo con el tipo de incentivo según la siguiente tabla:</p>
                                                <table class="globalTable" width="531" height="165" border="1">
                                                    <tr>
                                                        <td>Tema Exclusivo</td>
                                                        <td>Cupón $5 PS Store</td>
                                                        <td>Cupón 1 mes de suscripción PS Plus</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1 producto participante cualquier gramaje</td>
                                                        <td>2 productos participantes de cualquier gramaje</td>
                                                        <td>3 productos participantes de cualquier gramaje</td>
                                                    </tr>
                                                </table>
                                                <p>Si uno o más de los códigos ingresados no son válidos, se te comunicará y se te invitará a seguir participando; sin embargo, en caso de que la validación del código sea exitosa, se te enviará a tu correo registrado, el cupón con el que podrás canjear el incentivo elegido.</p>
                                                <p><u>Registro para participación:</u></p>
                                                <p>En el caso de que decidas participar por la el incentivo d) consola deberás:</p>
                                                <ul>
                                                    <li>• Consultar durante los periodos de participación los números predeterminados para concursar*, así como el contador de los folios de participación que se han ido registrando para calcular el momento en que registrará su código e intentar ser uno de los ganadores. El contador de los folios se reinicia en cada periodo de participación.</li>
                                                    <li>• Registra el código único de 1 producto participante para validación, en caso de ser válido se generará un folio de participación. En caso de no ser válido se te comunicará y se te invitará a seguir participando.</li>
                                                    <li>• Si el folio de participación es uno de los 5 números predeterminados para concursar durante cada periodo, el sistema arrojará una ventana emergente para que inicies la fase de Trivia, en la que deberás contestar correctamente 3 preguntas de cultura general de opción múltiple.</li>
                                                    <li>• Serás uno de los Ganadores, si te encuentras entre los Participantes que contesten correctamente las preguntas de la Trivia.</li>
                                                    <li>• Si contestas incorrectamente, no podrás ser acreedor al incentivo y se dará por terminada tu participación, pero se te enviará un Tema PS Exclusivo al correo que registraste.</li>
                                                    <li>• En caso de que no se llegue a la totalidad de incentivos asignados para el periodo de participación de acuerdo al anexo de Números Predeterminados, debido a la falta de participaciones o registros o por no responder correctamente a la fase de trivia, se asignarán los incentivos a los folios de participación que más se acerquen al número predeterminado de forma descendente en cada caso que aplique, para realizar la fase de trivia y tener al final de la promoción todos los incentivos otorgados.</li>
                                                    <li>• Publicación de ganadores: La publicación de los nombres ganadores se darán a conocer una vez que se haya validado su identidad y participación a través del micrositio <a href="https://www.promopringles.com/" target="blank">www.promopringles.com</a>, en las siguientes fechas:</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <table class="globalTable" width="531" height="165" border="1">
                                            <tr>
                                                <td>1er periodo</td>
                                                <td>2 de enero de 2019</td>
                                            </tr>
                                            <tr>
                                                <td>2do periodo</td>
                                                <td>20 de febrero de 2019</td>
                                            </tr>
                                        </table>
                                        <p>La obtención de incentivos por Participante está limitada a:</p>
                                        <table class="globalTable" width="531" height="165" border="1">
                                            <tr>
                                                <td>Tema Exclusivo</td>
                                                <td>Cupón $5 PS Store</td>
                                                <td>Cupón 1 mes de suscripción PS Plus</td>
                                            </tr>
                                            <tr>
                                                <td>ilimitado</td>
                                                <td>10 incentivos por participante</td>
                                                <td>10 incentivos por participante</td>
                                            </tr>
                                        </table>
                                    </li>
                                    <li><strong>8. Incentivos.</strong> Los participantes que resulten ganadores podrán acceder a uno de los siguientes incentivos:<p>a) Incentivos de Canje al Registro.</p>
                                        <p>Total: 101,864 (ciento un mil ochocientos sesenta y cuatro) beneficios de canje de acuerdo al número de productos participantes registrados, consistentes en:</p>
                                        <ul>
                                            <li>• Tema PS Exclusivo</li>
                                            <li>• Crédito de $5 dólares americanos para PS Store online</li>
                                            <li>• Crédito de suscripción para PS Plus por 1 mes con un valor de $9 dólares americanos.</li>
                                        </ul>
                                        <table class="globalTable" width="531" height="165" border="1">
                                            <tr>
                                                <th colspan="4">Canje al Registro</th>
                                            </tr>
                                            <tr>
                                                <td>Cupón $5 dlls PS Store</td>
                                                <td>Cupón Suscripción PS Plus</td>
                                                <td>Tema Exclusivo</td>
                                            </tr>
                                            <tr>
                                                <td>937 cupones</td>
                                                <td>937 cupones</td>
                                                <td>100,000 cupones</td>
                                            </tr>
                                        </table>
                                        <p>b) Registro para participación:</p>
                                        <p>10 Consolas PlayStation 4 HW, 1TB que incluye Control DualShock 4, Cable HDMI, Cable de Alimentación, Auricular monoaural con cable, Cable USB de carga, 3 Videojuegos y una suscripción por 3 meses para PS Plus, con un valor comercial de $290 dólares americanos cada una. La promoción comprende la entrega de 10 consolas, 1 a cada ganador. Con 1 año de Garantía a través de Sony Consumer Support.</p>
                                    </li>
                                    <li><strong>9. Lista de productos participantes:</strong>
                                        <p>Los productos que participarán en la Promoción dentro del Territorio serán los siguientes:</p>
                                        <ul>
                                            <li>Pringles® Original 149g</li>
                                            <li>Pringles® Crema y Cebolla 149g</li>
                                            <li>Pringles® Queso 149g</li>
                                            <li>Pringles® Original 37g</li>
                                            <li>Pringles® Crema y Cebolla 40g</li>
                                            <li>Pringles® Queso 40g</li>
                                        </ul>
                                        <p>Para efectos de la Promoción, los productos participantes antes mencionados deberán estar marcados con la promoción y contener los códigos respectivos.</p>
                                    </li>
                                    <li><strong>10. Organizadora de la promoción:</strong> KELLOGG COMPANY MEXICO, S. DE R.L. DE C.V.</li>
                                    <li><strong>11. Obligaciones del participante:</strong>
                                        <ul>
                                            <li>• Tener 18 años de edad con plena capacidad de ejercicio legal y contar con identificación oficial vigente con fotografía y en caso de extranjeros, contar con documento que acredite la legal residencia en México, expedido por autoridad competente.</li>
                                            <li>• Dar lectura total a las presentes Bases.</li>
                                            <li>• La participación en la promoción da cuenta de la aceptación expresa a las Bases de la misma.</li>
                                            <li>• Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción.</li>
                                            <li>• Conservar en todo momento los códigos únicos que se encuentra impresos en la membrana (la tapa de papel que cubre el envase de cada lata de Pringles®) de los Productos Participantes. Las membranas que contienen el código serán verificadas, validadas y/o entregadas a la Organizadora para confirmar su derecho a obtener el incentivo y no se actualice algún supuesto de descalificación.</li>
                                            <li>• Aceptar ser el único responsable (frente al Organizador y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</li>
                                        </ul>
                                    </li>
                                    <li><strong>12. Obligaciones del acreedor/ganador del incentivo:</strong>
                                        <ul>
                                            <li>• Completar su registro en el sitio conforme se indica más adelante en las presentes bases.</li>
                                            <li>• Enviar la documentación, proporcionar la información y/o firmar los documentos que les sean requeridos conforme a las presentes bases.</li>
                                        </ul>
                                    </li>
                                    <li><strong>13. Motivos de descalificación e impedimento para ser acreedor/ganador del incentivo:</strong>
                                        <ul>
                                            <li>• No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                            <li>• Cualquier información, empaque, código promocional o documento proporcionado por el Participante que sea falso.</li>
                                            <li>• Cualquier indicio de que se trata de una solicitud de participación no auténtica o si hay alguna mínima evidencia de que un participante o un tercero relacionado intenta dañar, sacar provecho indebido, o de alguna manera minar la operación legítima de la actividad promocional será descalificado de participar y removido de la actividad. El participante, en estas circunstancias será sometido a las autoridades competentes para los procedimientos civiles y/o penales correspondientes. Dicha determinación estará a discreción de Kellogg’s®.</li>
                                            <li>• Se use o desarrolle cualquier tipo de sistema o programa informático que permita realizar la participación de forma automatizada o que modifique los resultados obtenidos. Ya sea directamente o a través de un tercero: utilizando software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; acceder al Sitio Web utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web.</li>
                                            <li>• Descargar, copiar, atacar o intentar alterar o manipular parte o todo el contenido del Sitio y/o se reproduzcan o modifiquen los Códigos Participantes originales de cualquier forma.</li>
                                            <li>• Los previstos en los demás apartados de las presentes Bases.</li>
                                            <li>• Realizar o ejecutar cualquier otro acto o actividad que pudiera ser considerada fraudulenta o inadecuada conforme a la legislación aplicable.</li>
                                            <li>• En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a La Organizadora.</li>
                                        </ul>
                                    </li>
                                    <li><strong>14. Entrega de Consolas:</strong>
                                        <p>Una vez el participante sea acreedor del incentivo la Organizadora de la promoción, notificará o entregará el incentivo de la forma como se describe a continuación:</p>
                                        <p>1) Los participantes que hayan completado la mecánica serán contactados por la Organizadora o el tercero que al efecto designe, para solicitar la información correspondiente para la validación de su identidad y en su caso realizar la entrega de su incentivo. Para que tal efecto deberá presentar y entregar, la siguiente documentación:</p>
                                        <ul>
                                            <li>• Copia de la Identificación oficial vigente con fotografía por ambos lados (INE, Pasaporte o Cédula Profesional).</li>
                                            <li>• Copia del Comprobante de domicilio (con antigüedad no mayor a tres meses).</li>
                                            <li>• Copia del CURP y RFC con homoclave.</li>
                                            <li>• Firmar el recibo de conformidad y carta de uso de imagen.</li>
                                            <li>• Entregar la membrana en la que conste el Código Único mediante el cual el participante resultó Ganador y el folio que se genere al momento del registro.</li>
                                        </ul>
                                        <p>Todos los Ganadores tendrán 5 días hábiles, a partir de la solicitud de información para su entrega.En caso de no realizar la entrega completa de la documentación solicitada el Participante perderá el derecho a reclamar su incentivo y la Organizadora dispondrá de los incentivos como mejor convenga a sus intereses.</p>
                                    </li>
                                    <p>2) Para la entrega de la Consola, los participantes deberán firmar de recibido el paquete, de conformidad con las políticas y medidas de seguridad implementadas por la empresa de paquetería. El incentivo se entregará invariablemente en el domicilio que el ganador haya especificado en la información solicitada por la Organizadora, por lo que será su entera responsabilidad asegurarse de que los datos de ubicación sean correctos. El proceso de entrega será el siguiente:</p>
                                    <p>a) La entrega de incentivos se realizará dentro de los siguientes 10 días hábiles de la validación de la identidad y participación por parte de la Organizadora.</p>
                                    <p>b) La mensajería acudirá al domicilio del ganador en un máximo de 3 ocasiones para intentar hacer entrega del incentivo. Para completar la entrega será indispensable que quien reciba el incentivo presente una identificación oficial con fotografía.</p>
                                    <p>c) En caso de no encontrarse el ganador la mensajería dejará un aviso desde la primera visita con los números telefónicos de la mensajería, para que el ganador pueda comunicarse y concertar cita para la siguiente entrega.</p>
                                    <p>La falta de veracidad en la información proporcionada y/o la falta de presentación de identificación oficial con fotografía que impida o no permita identificar al Participante con derecho a reclamar algún incentivo libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer del incentivo respectivo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                    <p>En caso de que el Participante no provea su información personal de manera precisa, de forma tal que sea imposible para la empresa de paquetería encontrar su domicilio, perderá su derecho a recibirlo y le dará derecho a la Organizadora de utilizarlo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                    <p>La Organizadora se reserva el derecho de no entregar o redimir los incentivos en caso de comprobar la existencia de conductas fraudulentas por parte de los Participantes que resulten Acreedores a los Incentivos (por ejemplo: alteración de identificaciones, falsedad o alteración de datos e/o información) sin que sea necesario acreditar ante autoridad alguna evidencia de dicha conducta fraudulenta del supuesto Acreedor al Incentivo.</p>
                                    <p>Para poder recibir los incentivos, en su caso el participante deberá exhibir original o enviar una copia de identificación oficial con fotografía, a fin de acreditar su identidad y firmar la totalidad de documentos que la Organizadora le solicite en su caso, al entregar los incentivos.</p>
                                    <p>No se considerará fidedigna la identificación que presente alteraciones en fotografía, en datos generales o que no coincidan con los rasgos físicos del participante, por lo que la Organizadora de la promoción se reserva el derecho de entregar los incentivos a quien presente identificación oficial vigente sin este tipo de alteraciones o errores.</p>
                                    <p>Es importante señalar que los Incentivos no son transferibles, comercializables o canjeables por dinero en efectivo o algún otro producto o servicio, por lo que los participantes que resulten ganadores no podrán solicitar incentivo distinto a los señalados, en cualquier caso, no teniendo derecho a ejercer reclamación, procedimiento o acción alguna por este concepto.</p>
                                    <li><strong>15. Condiciones generales:</strong>
                                        <ul>
                                            <li>• Los incentivos NO serán sustituidos por dinero en efectivo, ni por ningún otro incentivo.</li>
                                            <li>• Los incentivos son personales y no pueden ser trasferidos a terceros.</li>
                                            <li>• Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica; y, copias o facsímiles de membranas con los códigos originales.</li>
                                            <li>• Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                            <li>• La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes. No serán válidos aquellos códigos que presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados, sobre-armados o laminados superpuestos en su interior o superficies.</li>
                                            <li>• En caso de que los ganadores no completen el proceso de asignación de incentivos en el plazo indicado por La Organizadora, los incentivos quedarán a disposición de la Organizadora para asignarlos a los ganadores suplentes que cumplan con estas Bases, en caso de que por tiempo no sea posible asignarlo a un ganador suplente, los incentivos serán utilizados como mejor convenga a los intereses de la Organizadora.</li>
                                            <li>• La Organizadora suspenderá de forma inmediata la Promoción, sin asumir responsabilidad alguna, si se llegaren a detectar delitos, fraudes o cualquier irregularidad, o si se presentara alguna circunstancia de fuerza mayor o caso fortuito. En tal caso, se comunicará al público en general de los cambios a que haya lugar a través de la página web <a href="https://www.promopringles.com/" target="_blank">www.promopringles.com</a></li>
                                            <li>• En caso que cualquier Participante tuviese algún problema dentro del Sitio, deberá de comunicarse de inmediato a los teléfonos de la Organizadora y reportarlo para que se atienda su requerimiento a la brevedad, dichos teléfonos serán publicados dentro del Sitio. Cualquier error u omisión no reportada, podría no ser atendida por La Responsable de la Promoción. En caso de que el Participante no reporte la anomalía o error dentro de las siguientes 48 horas contadas a partir de la hora en que intente registrar una participación, podría ocasionar una falta de registro de su participación y/o la oportunidad de recibir el incentivo que pudiera corresponderle, lo cual será responsabilidad del propio participante, liberando de cualquier responsabilidad a la Organizadora.</li>
                                            <li>• La Organizadora no será responsable por fallas técnicas en el sistema de captura de datos, ni de la ejecución o desempeño de la dinámica de participación en La Promoción, ya que esto depende tanto de los Participantes como de terceros ajenos.</li>
                                            <li>• Cualquier cambio en el contenido de las Bases de La Promoción, se comunicará y publicará en el Sitio.</li>
                                            <li>• La Organizaora de la Promoción podrá en cualquier momento suspender la Promoción o la entrega de incentivos sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten su ejecución o a sus intereses, así como cualquier acto y/o amenaza que ponga en riesgo la seguridad de los participantes y La Organizadora. Si por causas ajenas a la Organizadora de la Promoción se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para recibir el incentivo, ésta se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo al que participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que hicieran inejecutables las entregas respectivas de incentivos. En todos estos casos la Organizadora de la Promoción publicará a través del Sitio los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo. Ninguno de los incentivos estará sujeto a comercialización o cesión alguna. Cualquier incentivo en tal supuesto da derecho a la Organizadora de la Promoción a su revocación o cancelación respectiva.</li>
                                            <li>• La Organizadora de la Promoción no será responsable por el uso que se le brinde a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos con relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la redención del citado incentivo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</li>
                                            <li>• Los acreedores al incentivo liberan de toda responsabilidad a la Organizadora de la Promoción conforme a lo establecido en estas Bases, al momento de recibir el incentivo.</li>
                                            <li>• La Organizadora de la Promoción emprenderá las acciones legales pertinentes contra aquellos usuarios que pretendan defraudar, engañar o burlar las reglas y procedimientos de la promoción.</li>
                                        </ul>
                                    </li>
                                    <li><strong>16. Autorización de usos de datos personales y de imagen:</strong>
                                        <p>• Al participar en la actividad promocional se autoriza expresamente la utilización, publicación y reproducción, sin limitación o restricción alguna, de la imagen y datos personales, así como el uso y la publicación del nombre y domicilio de quien resulte ganador, en cualquier tipo de publicidad, promoción, publicación, a través de cualquier medio, con fines comerciales o informativos por parte de Kellogg’s®.</p>
                                        <p>• Los participantes y/o ganadores no tendrán derecho a recibir contraprestación alguna por los hechos descritos en el presente numeral.</p>
                                        <p>• Así mismo se autoriza el envío de información comercial de Kellogg.</p>
                                        <p>• El Participante al formar parte de La Promoción, autoriza expresamente a la Organizadora de la Promoción y se obliga a suscribir cualquier documento que resulte necesario en favor de éstas, que tenga como finalidad fijar y difundir su imagen, nombre y carácter de acreedor de cualquier incentivo, así como su imagen y/o voz para la elaboración de material fotográfico o testimonial de la Promoción y su difusión en los medios de comunicación conocidos o por conocerse que la Organizadora de la Promoción juzgue convenientes incluyendo las denominadas redes sociales. Los Participantes acreedores por haber recibido el incentivo como contraprestación se obligan a no revocar tal autorización.</p>
                                        <p>• Los testimoniales a que se hacen referencia en el párrafo anterior y en su caso, cualquier obra producida derivado de la presente actividad promocional serán propiedad de la Organizadora de la Promoción, las cuales podrá difundir y utilizar de la manera que más convenga a sus intereses, sin que esto implique pago alguno a dichos participantes por su aparición en cualquier medio de comunicación.</p>
                                        <p>• En consideración a las autorizaciones otorgadas conforme a los párrafos anteriores, los participantes ceden en favor de la Organizadora de manera gratuita, es decir sin contraprestación a cambio alguna, de manera indefinida todos los derechos tangibles e intangibles de propiedad intelectual e/o industrial que en su caso pudieran corresponderles sobre los materiales generados, para su explotación, difusión, comunicación al público y puesta a disposición del público en todos los territorios y con la frecuencia de repetición que convenga a sus intereses y/o a cualquiera de sus empresas controladoras, subsidiarias, afiliadas, licenciatarias, representantes legales, cesionarios y/o cualquier entidad relacionada con o que éstas indiquen, en los que estén contenidos su nombre, voz y/o imagen, así como cualquier interpretación y/o ejecución artística o parte de las mismas, utilizando los medios técnicos y tecnológicos conocidos en la actualidad y los que pudieran desarrollarse en el futuro, y para cualquier tipo de aplicación o medio de difusión conforme convenga a sus intereses, autorizando además a efectuar cambios, modificaciones, adiciones y supresiones de dichos materiales sin limitación alguna.</p>
                                    </li>
                                    <li><strong>17. Aviso de privacidad:</strong>
                                        <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                        <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                        <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.kellogg.com.mx la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                        <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg, con fines exclusivos de realizar la Promoción. Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 01 800 00 KELLOGG (5355644).</p>
                                        <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopringles.com, la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                    </li>
                                    <li><strong>16. Autorización de usos de datos personales y de imagen:</strong>
                                        <p>En caso de resultar necesario, y/o a discreción de Kellogg’s®, ésta empresa se reserva el derecho de ampliar y/o aclarar el alcance de los presentes Términos y Condiciones, y/o cualquiera de los procedimientos, los cuales serán comunicados a través de <a href="https://www.promopringles.com/" target="_blank">www.promopringles.com</a></p>
                                    </li>
                                    <li><strong>19. Sanciones:</strong> Los Participantes aceptan que cualquier incumplimiento de las obligaciones contenidas en estas condiciones y restricciones, facultan al Organizador, para el inicio de las acciones legales a que haya lugar. Los Participantes aceptan indemnizar, defender y mantener indemne al Organizador y sus socios, accionistas, personal y compañías afiliadas ante cualquier responsabilidad, pérdida, reclamación y gasto, incluyendo honorarios y gastos de abogados, si es resultado de la violación de estos términos.</li>
                                    <li><strong>20. Jurisdicción y ley aplicable:</strong>
                                        <p>Cualquier controversia suscitada con motivo de la Promoción, se regirá por las presentes Bases y como complemento para lo establecido en éstas, aplicará la jurisdicción de la Ciudad de México, sujetándose todas las partes interesadas en la misma, incluyendo a los Participantes y en su caso, acompañante, quienes renuncian a cualquier otra jurisdicción a la que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                        <p>Los Participantes reconocen y aceptan que la ley aplicable para cualquier controversia que surja con relación a la Actividad será la de la República Mexicana, y renuncian a su derecho a iniciar cualquier tipo de reclamación en otra jurisdicción.</p>
                                    </li>
                                    <table class="globalTable" width="531" height="165" border="1">
                                        <tr>
                                            <th colspan="4">ANEXO “A” NÚMEROS PREDETERMINADOS.</th>
                                        </tr>
                                        <tr>
                                            <td>Número de Consola</td>
                                            <td>PERIODO</td>
                                            <td># REGISTRO PARTICIPANTE TRIVIA</td>
                                            <td># REGISTRO SUSTITUTO</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>1</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2</td>
                                                    </tr>
                                                    <tr>
                                                        <td>3</td>
                                                    </tr>
                                                    <tr>
                                                        <td>4</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">5</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>1er periodo</td>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>167</td>
                                                    </tr>
                                                    <tr>
                                                        <td>249</td>
                                                    </tr>
                                                    <tr>
                                                        <td>540</td>
                                                    </tr>
                                                    <tr>
                                                        <td>544</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">1335</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>170</td>
                                                    </tr>
                                                    <tr>
                                                        <td>252</td>
                                                    </tr>
                                                    <tr>
                                                        <td>543</td>
                                                    </tr>
                                                    <tr>
                                                        <td>547</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">1338</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>6</td>
                                                    </tr>
                                                    <tr>
                                                        <td>7</td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">10</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>2do periodo</td>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>52</td>
                                                    </tr>
                                                    <tr>
                                                        <td>856</td>
                                                    </tr>
                                                    <tr>
                                                        <td>880</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1281</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">1289</td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td>
                                                <table class="innerTable">
                                                    <tr>
                                                        <td>55</td>
                                                    </tr>
                                                    <tr>
                                                        <td>859</td>
                                                    </tr>
                                                    <tr>
                                                        <td>883</td>
                                                    </tr>
                                                    <tr>
                                                        <td>1284</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="bordernone">1292</td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </table>
                                </ul>
                            </div>
                            <h1 class="not-visible">Términos y Condiciones de la promoción Pringles® XMAS 2018.</h1>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>
