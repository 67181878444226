<template>
    <div id="app">
        <div id="error_wp"></div>
        <div>
            <!--<modal-br v-if="isBrazil"></modal-br>
            <modal v-if="!isBrazil"></modal>-->
            <!--<header v-if="isPromo">
                <header-deezer v-if="!isBrazil"></header-deezer>
                <header-br v-if="isBrazil"></header-br>
            </header>-->
            <router-view />
            <!--<footer v-if="isPromo">
                <footer-deezer v-if="!isBrazil"></footer-deezer>
                <footer-br v-if="isBrazil"></footer-br>
            </footer>-->
        </div>
    </div>
</template>
<script>
/*
import HeaderDeezer from '@/components/music/partials/header.vue'
import FooterDeezer from '@/components/music/partials/footer.vue'
import HeaderBR from '@/components/br/partials/header.vue'
import FooterBR from '@/components/br/partials/footer.vue'
import Modal from '@/components/music/modal/Modal.vue'
import ModalBR from '@/components/modal/Modal.vue'*/

import { mapState } from 'vuex'
//import LoadScript from 'vue-plugin-load-script';

//const router = new VueRouter({ routes, mode, linkActiveClass });


export default {
    components: {
        /*'header-deezer': HeaderDeezer,
        'footer-deezer': FooterDeezer,
        'header-br': HeaderBR,
        'footer-br': FooterBR,
        'modal': Modal,
        'modal-br': ModalBR,*/
    },

    watch: {
        /*
         * Hide modal menu everytime that change of view
         */
        $route() {
            this.$store.commit('toggleMenuMobile', false)
        },
        
    },
    beforeCreate() {
        if(window.location.href.indexOf('mx?utm')>=0){            
            localStorage.setItem('country', 'MX')
            localStorage.setItem('countryName', 'México')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'MX' })
            this.$store.commit('setCountryName', 'México')
            this.$store.dispatch('setSiteCountryArea', 'MX')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('ar?utm')>=0){            
            localStorage.setItem('country', 'AR')
            localStorage.setItem('countryName', 'Argentina')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'AR' })
            this.$store.commit('setCountryName', 'Argentina')
            this.$store.dispatch('setSiteCountryArea', 'AR')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('do?utm')>=0){            
            localStorage.setItem('country', 'DO')
            localStorage.setItem('countryName', 'República Dominicana')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'DO' })
            this.$store.commit('setCountryName', 'República Dominicana')
            this.$store.dispatch('setSiteCountryArea', 'DO')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('hn?utm')>=0){            
            localStorage.setItem('country', 'HN')
            localStorage.setItem('countryName', 'Honduras')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'HN' })
            this.$store.commit('setCountryName', 'Honduras')
            this.$store.dispatch('setSiteCountryArea', 'HN')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('ni?utm')>=0){            
            localStorage.setItem('country', 'NI')
            localStorage.setItem('countryName', 'Nicaragua')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'NI' })
            this.$store.commit('setCountryName', 'Nicaragua')
            this.$store.dispatch('setSiteCountryArea', 'NI')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('pr?utm')>=0){            
            localStorage.setItem('country', 'PR')
            localStorage.setItem('countryName', 'Puerto Rico')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'PR' })
            this.$store.commit('setCountryName', 'Puerto Rico')
            this.$store.dispatch('setSiteCountryArea', 'PR')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('sv?utm')>=0){            
            localStorage.setItem('country', 'SV')
            localStorage.setItem('countryName', 'El Salvador')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'SV' })
            this.$store.commit('setCountryName', 'El Salvador')
            this.$store.dispatch('setSiteCountryArea', 'SV')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('cr?utm')>=0){            
            localStorage.setItem('country', 'CR')
            localStorage.setItem('countryName', 'Costa Rica')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'CR' })
            this.$store.commit('setCountryName', 'Costa Rica')
            this.$store.dispatch('setSiteCountryArea', 'CR')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('pa?utm')>=0){
            localStorage.setItem('country', 'PA')
            localStorage.setItem('countryName', 'Panamá')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'PA' })
            this.$store.commit('setCountryName', 'Panamá')
            this.$store.dispatch('setSiteCountryArea', 'PA')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('ec?utm')>=0){
            localStorage.setItem('country', 'EC')
            localStorage.setItem('countryName', 'Ecuador')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'EC' })
            this.$store.commit('setCountryName', 'Ecuador')
            this.$store.dispatch('setSiteCountryArea', 'EC')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('pe?utm')>=0){
            localStorage.setItem('country', 'PE')
            localStorage.setItem('countryName', 'Perú')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'PE' })
            this.$store.commit('setCountryName', 'Perú')
            this.$store.dispatch('setSiteCountryArea', 'PE')
            this.$store.dispatch('setLanguageLocale', 'es')
        }else if(window.location.href.indexOf('br?utm')>=0){
            localStorage.setItem('country', 'BR')
            localStorage.setItem('countryName', 'Brasil')
            localStorage.setItem('language', 'pt')
            this.$store.commit('setCountry', { country: 'BR' })
            this.$store.commit('setCountryName', 'Brasil')
            this.$store.dispatch('setSiteCountryArea', 'BR')
            this.$store.dispatch('setLanguageLocale', 'pt')
        }
        else if (window.location.href.indexOf('bo?utm') >= 0) {
            localStorage.setItem('country', 'BO')
            localStorage.setItem('countryName', 'Bolivia')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'BO' })
            this.$store.commit('setCountryName', 'Bolivia')
            this.$store.dispatch('setSiteCountryArea', 'BO')
            this.$store.dispatch('setLanguageLocale', 'es')
        }
        else if (window.location.href.indexOf('uy?utm') >= 0) {
            localStorage.setItem('country', 'UY')
            localStorage.setItem('countryName', 'Uruguay')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'UY' })
            this.$store.commit('setCountryName', 'Uruguay')
            this.$store.dispatch('setSiteCountryArea', 'UY')
            this.$store.dispatch('setLanguageLocale', 'es')
        }
        else if (window.location.href.indexOf('py?utm') >= 0) {
            localStorage.setItem('country', 'PY')
            localStorage.setItem('countryName', 'Paraguay')
            localStorage.setItem('language', 'es')
            this.$store.commit('setCountry', { country: 'PY' })
            this.$store.commit('setCountryName', 'Paraguay')
            this.$store.dispatch('setSiteCountryArea', 'PY')
            this.$store.dispatch('setLanguageLocale', 'es')
        }
    },
    computed: {
        ...mapState(['country', 'folioTimeout', 'isBrazil', 'isOpen', 'isCaricam', 'isLogin', 'isPromo', 'globalName']),
        checkRouterPromo() {
            if (this.$route.name === 'promo' || this.$route.name === 'home') {
                return false
            }
            return true
        }
    },
    methods: {
        redirectWithoutSession(viewName) {
            const viewsWithSession = ['myTickets', 'winners', 'addTicket', 'myTicketsBR', 'winnersBR', 'addTicketBR']

            if (viewsWithSession.indexOf(viewName) > -1) {
                this.$router.push({ name: 'home' })
            }
        },

    },
    created() {
        const country = this.$store.state.country.id
        const language = this.$store.state.country.language
        if (country) {
            //this.$store.dispatch('setLanguage', country)
            //if (this.isOpen) {
                /*this.$store.dispatch('setSession')
                    .then(({ isLogin }) => {
                        if (!isLogin) {
                            this.redirectWithoutSession(this.$route.name)
                        } else {
                                if(this.isCaricam){
                                this.$store.dispatch('getCurrentFolio')
                                this.$store.dispatch('setFolioTimeout')
                            }
                        }
                    })
                    .catch(({ response }) => {
                        const { status } = response
                        const sessionViews = ['winners', 'myTickets', 'addTicket']
                        const countryId = localStorage.getItem('country')

                        if (status === 403 && sessionViews.indexOf(this.$route.name) > -1) {
                            if (countryId === 'BR') {
                                this.$router.push({ name: 'homeBR' })
                            } else {
                                this.$router.push({ name: 'homeDeezer' })
                            }
                        }
                    })*/

                /**/
            //}
            this.$store.dispatch('setSiteCountryArea', this.country.id)
            this.$store.dispatch('setLanguageLocale', language)
        } else {
            this.$router.push({ name: 'init' })
        }
        this.$loadScript("https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js").then(() => {
            // Código en caso de que tu script cargue
            this.$loadScript("/js/cc.js").then(() => {
        }).catch(() => {
            // Código en caso de que la carga de tu script fallé
        });
        }).catch(() => {
            // Código en caso de que la carga de tu script fallé
        });
    },
    mounted() {
        //this.$store.dispatch('setBodyClass', this.globalName)
        if(this.isLogin && this.isCaricam){
            this.$store.dispatch('getCurrentFolio')
            this.$store.dispatch('setFolioTimeout')
        }

        /*Vue.use(VueGtm, {
          id: 'GTM-MB6VF27' 
        });
*/
    }
}
</script>
<style>
</style>
