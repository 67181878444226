<template>
    <div>
        <headers></headers>
        <main class="mainWrp">
            <section class="globalWrp slideBlock">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    <form>
                        <select @change="handleChange" v-model="year">
                            <option value="-1">{{ $t('all')}}</option>
                            <option v-for="(item, index) in current_years" :value="item" :key="index">
                                {{item}}
                            </option>
                        </select>
                    </form>
                </aside>
                <main class="TermsCarousel">
                    <swiper class="carouselWrp" :options="swiperOption" ref="swiper">
                        <swiper-slide class="slideWrp" v-for="(item, index) in terms" :key="index">
                            <a :href="blank(item)" :target="item.target">
                                <figure>
                                    <img :src="require(`@/assets/images/global/banners/${item.image}`)" :alt="item.alt" style="height: 336px;">
                                </figure>
                                <div class="termInfo">
                                    <h3>{{item.title}}</h3>
                                    <h4>{{item.description}}</h4>
                                    <p>{{item.period}}</p>
                                    <ul class="btnsWrp">
                                        <li v-if="item.url!=''">
                                            <span class="icon eye"></span>
                                            <a :href="blank(item)" :target="item.target">{{ $t('learnMore')}}</a>
                                        </li>
                                        <li v-else>
                                            <span class="icon eye"></span>
                                            <a :href="'../' +country.id.toLowerCase()+ item.url">{{ $t('learnMore')}}</a>
                                        </li>
                                        <!-- <li>
        <span class="icon download"></span>
        <a :href="item.pdf" target="_blank">DESCARGAR PDF</a>
    </li> -->
                                    </ul>
                                </div>
                            </a>
                        </swiper-slide>
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                    <!--button class="arrow disabled-prev "></button>
                <ul class="carouselWrp">
                    <li class="slideWrp">
                        <span class="termImg"></span>
                        <div class="termInfo">
                            <h3>SNACKEA, JUEGA, REPITE</h3>
                            <h4>Términos y condiciones</h4>
                            <p>Vigencia del 15 de octubre del 2018 al 15 de enero del 2019</p>
                            <ul class="btnsWrp">
                                <li>
                                    <span class="icon eye"></span>
                                    <a href="">CONOCE MÁS</a>    
                                </li>
                                <li>
                                    <span class="icon download"></span>
                                    <a href="">DESCARGAR PDF</a>    
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="slideWrp">
                        <span class="termImg"></span>
                        <div class="termInfo">
                            <h3>SNACKEA, JUEGA, REPITE</h3>
                            <h4>Términos y condiciones</h4>
                            <p>Vigencia del 15 de octubre del 2018 al 15 de enero del 2019</p>
                            <ul class="btnsWrp">
                                <li>
                                    <span class="icon eye"></span>
                                    <a href="">CONOCE MÁS</a>    
                                </li>
                                <li>
                                    <span class="icon download"></span>
                                    <a href="">DESCARGAR PDF</a>    
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
                <button class="arrow next"></button-->
                </main>
            </section>
        </main>
        <h1 class="not-visible">{{ $t('tycHomeH1')}} </h1>
        <Footers></Footers>
    </div>
</template>
<script>
import 'swiper/dist/css/swiper.css';
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            terms: [],
            allTerms: [],
            countries_data: [],
            current_years: [],
            year: '-1',
            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 20,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                keyboard: {
                    enabled: true,
                },
                breakpoints: {
                    480: {
                        slidesPerView: 1,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    mounted() {
        this.setImages()
        this.$store.dispatch('setBodyClass', 'global')

        
    },
    computed: {
        ...mapState(['country'])
    },
    components: {
        Headers,
        Footers,
        swiper,
        swiperSlide
    },
    methods: {
        setImages() {
            this.getTerms()
        },
        getTerms() {
            import(`@/assets/json/countries_data.json`)
                .then(({ default: countries }) => {

                    this.countries_data = [...countries]
                    let country_id = this.country.id.toLowerCase()

                    countries.forEach((entry) => {

                        if (entry.value === country_id) {
                            this.terms = [...entry.data]
                            this.allTerms = [...entry.data]
                        }

                    });                 

                    const years = this.terms.map(term => term.year);
                    const unique_years = [...new Set(years)];

                    this.current_years = [...unique_years]
                    /**/
                    /*const current_country = countries.filter(country => country.value === country_id ).map(country => country.data);

                    console.log(current_country, this.terms )*/

                    //this.terms = current_country
                    //this.allTerms = current_country

                })
                .catch(() => {

                    this.terms = []
                    this.loading = false
                })
        },
        blank(item){
            //console.log(this.terms)
            let termTarget

            if( item.target === '_self' ){
                termTarget = '../' + this.country.id.toLowerCase()+ item.url
            } else if(item.target === '_blank' ) {
                termTarget = item.url
            }
            return termTarget
        },
        handleChange(e) {
            const current_terms = this.allTerms
            let element = e.target
            let selected_year = element.value

            this.terms = []
            
            if( selected_year === '-1' ) {

                this.terms = this.allTerms

            } else {

                current_terms.forEach((entry) => {

                    if (entry.year === selected_year) {
                        
                        this.terms.push(entry)
                    }

                });
            }
            
        }
    },
    metaInfo() {
        const country_i = localStorage.getItem('country').toLowerCase()

        if (country_i === 'do' ||
            country_i === 'mx' ||
            country_i === 'co' ||
            country_i === 'ec' ||
            country_i === 'pe' ||
            country_i === 'cr' ||
            country_i === 'sv' ||
            country_i === 'gt' ||
            country_i === 'hn' ||
            country_i === 'ni' ||
            country_i === 'pa' ||
            country_i === 'pr' ||
            country_i === 'ar') {
            return {
                title: 'Conoce todo los detalles de las promociones | Pringles® Internacional',
                meta: [{
                        name: 'description',
                        content: '¿Quieres conocer los detalles de las promociones y dinámicas? Encuentra todos los Términos y Condiciones aquí.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        } else if (country_i === 'tt'){
            return {
                title: 'Learn more about the promotions | Pringles ® International',
                meta: [{
                        name: 'description',
                        content: '¿Do you want to know the details of the promotions and dynamics? Find all the Terms and Conditions here.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        } else if (country_i === 'br'){
            return {
                title: 'Conheça todos os detalhes das promoções. Pringles® Internacional',
                meta: [{
                        name: 'description',
                        content: 'Quer conhecer os detalhes das promoções e dinâmicas? Encontre aquí os Termos e Condições.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        }
    }
}
</script>
