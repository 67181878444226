<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/snackea/2021/466x276-snackea-pa.jpg" alt="Snackea con Pringles® y Cheez it®">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">ACTIVIDAD PROMOCIONAL <br> "SNACKEA CON PRINGLES® Y CHEEZ IT®"</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
    								<h1 class="not-visible">Términos y condiciones de Snakea con Pringles® y Cheez It®.</h1>
            
    								<p>Los términos y condiciones descritos a continuación  (las &ldquo;Bases&rdquo;) establecen las condiciones y requisitos bajo los cuales se regirá  la actividad promocional patrocinada por Alimentos Kellogg denominada &ldquo;SNACKEA  CON PRINGLES® Y CHEEZ IT®&rdquo; (en lo sucesivo la &ldquo;Promoción&rdquo;). Lo aquí dispuesto  es obligatorio para todos los participantes de la Promoción, por lo que se  deberá dar lectura total a las presentes Bases. La participación en la  Promoción se interpretará como la aceptación de las Bases, así como del Aviso  de Privacidad para el tratamiento de datos personales. Para el efecto se ha  designado a la entidad Penta Agencia BTL, Sociedad Anónima y sus subsidiarias  en Centroamérica, Panamá y República Dominicana como Organizador  para desarrollar y llevar a cabo la ejecución  de la Promoción Comercial.</p>
									<p>EL  ORGANIZADOR será el responsable de gestionar los trámites y permisos legales  que se requieran para la ejecución de la actividad.</p>

									<p><strong>MECÁNICA</strong></p>
    								<p>Para  esta promoción comercial podrán participar todas las personas ya sean  nacionales o extranjeros, que compren alguno de los productos participantes y  que cumplan con los siguientes requisitos:</p>
									<ol class="decimal">
									<li>Contar con número telefónico de  contacto y que la persona tenga activa o con data la aplicación de Whattssap.</li>
									<li>Debe comprar cualquiera de los productos participantes PRINGLES® Y CHEEZ IT®* en cualquiera de los  establecimientos que emitan factura de comprobante de compra de los productos  indicados anteriormente.</li>
									<li>Deberá enviar por Whatsapp  una foto legible de cada una de la factura legal con número de correlativo y/o  número de identificación tributaria del Establecimiento Participante, donde se  muestre la compra de los Productos Participantes. El Participante tendrá  derecho a una participación por cada factura, independientemente de cuantos  Productos Participantes vayan incluidos en cada factura.</li>
									<li>La persona deberá añadir a sus  contactos, sólo para enviar mensajes vía whatsapp el número de teléfono: +<u>502-55263624.</u> (El número de teléfono es de la ciudad de  Guatemala sede de la empresa productora de los productos PRINGLES, pero sólo  para enviar los chats, no se cobra recargo por ser una aplicación gratuita). </li>
									<li>Se  tomarán en cuenta las facturas participantes las que correspondan a las compras  de los productos desde que se anuncia la  aprobación de la promoción comercial hasta el día 15 de abril de 2021, facturas  de compras con fecha anterior serán anuladas o canceladas para su  participación.</li>
									<li>Las  únicas facturas participantes son aquellas que en su descripción especifique la  compra con algún código de los productos participantes.  Por ese motivo una vez se envíe la factura de  compra el organizador procederá a verificar si la factura es válida, proceso  que tomará hasta un máximo de 24 horas, por lo que una vez validada se le  enviará un mensaje de que esta participando de la promoción con su código de  participación.</li>
									<li>La  persona una vez envíe la foto de la factura deberán ingresar al Link que se le  enviará para que lea los términos y condiciones y deberá colocar SI ACEPTO si  quiere continuar con la participación. </li>
									<li>Verificada la validez de la  factura,  se enviará un código único de participación, el cual, en la fecha abajo  establecida luego de transcurrido el período de vigencia de la Promoción,  participará en la selección por sorteo por medio de tómbola electrónica para  optar al premio. </li>
									<li>A la persona se le  notificará vía whatsapp las fechas de las tómbolas las cuales serán  electrónicas en presencia de un Funcionario de la Junta de Control de Juegos y  de Un Notario Público quien dará Fe.</li>
									</ol>
									<p><strong> PRODUCTO PARTICIPANTE:</strong></p>
									<p>Los productos participantes en la Promoción son Productos PRINGLES® Y CHEEZ IT®* en cualquier presentación, incluyendo:</p>
									<p><strong>PRINGLES®</strong></p>
									<ul class="bullets">
										<li>37gr</li>
										<li>40 gr&nbsp;</li>
										<li>71 gr</li>
										<li>77 gr</li>
										<li>112gr</li>
										<li>124gr</li>
										<li>130gr</li>
										<li>137gr</li>
										<li>149gr</li>
										<li>158gr</li>
										<li>194gr</li>
										<li>203gr</li>
										<li>5 pack 95gr y 105gr</li>
										<li>12pack de 252gr&nbsp;o 258gr (muelitas).</li>
									</ul>
									<p><strong>Cheez&nbsp;It&nbsp;Cheddar Jack:</strong></p>
									<ul type="disc" class="bullets">
										<li>351</li>
										<li>85g</li>
										<li>42g</li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Duoz Parmesan:</strong></p>
									<ul type="disc" class="bullets">
										<li>351g</li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Duoz Caramel Pop Corn:</strong></p>
									<ul type="disc" class="bullets">
										<li>246g </li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Duoz Bacon:</strong></p>
									<ul type="disc" class="bullets">
										<li>351g </li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;White Cheddar:</strong></p>
									<ul type="disc" class="bullets">
										<li>85g </li>
										<li>42g </li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Original:</strong></p>
									<ul type="disc" class="bullets">
										<li>42g </li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Grooves Zesty Cheddar Ranch:</strong></p>
									<ul type="disc" class="bullets">
										<li>170g </li>
									</ul>

									<p><strong>Cheez&nbsp;It&nbsp;Grooves Sharp White Cheddar:</strong></p>
									<ul type="disc" class="bullets">
										<li>170g </li>
									</ul>

									<p><strong>FECHA DE LA PROMOCIÓN COMERCIAL, LUGAR Y HORA DEL SORTEO</strong></p>
									<p>La promoción comercial se llevará a cabo a partir  de la aprobación de la resolución  hasta el día 15 de abril de 2021.</p>
									<p>El día del sorteo se  escogerán al azar por medio de un sorteo electrónico a las personas ganadoras,  a la persona se le llamará  telefónicamente  y se le preguntará si cuenta con la factura original de compra el cual fue el  que envío por foto vía whattssap,  este  requisito es primordial para poder reclamar el premio y presentar su cédula o  documento de identidad valido y vigente, en caso de que la persona no tenga en  sus manos la factura original la cual fue la que envío por foto vía whattssap,  se escogerá otro ganador que si cumpla con este requisito para reclamar el  premio Los sorteos serán en las siguientes fechas:</p>
									<p>Sorteo 1: 5 de marzo de 2021 – 3pm<br>
									Sorteo 2: 19 de marzo de 2021 – 3pm<br>
									Sorteo 3: 5  de abril de 2021 – 3pm<br>
									Sorteo 4: 16  de abril de 2021 – 3pm </p>
	
									<p>LUGAR DEL SORTEO: Dirección:  Ave. La Rotonda PH Financial Park, Oficina 12C,  Costa del Este, Ciudad de Panamá, Panamá,  persona de contacto: Camilo Lobo </p>
									<p><strong>PREMIOS DE LA TOMBOLA:</strong></p>
									<div class="globalTableWrp">
										<table border="0" cellspacing="0" cellpadding="0" class="globalTable">
											<tr>
												<th>Sorteo de 5 de Marzo</th>
												<th>Sorteo de 19 marzo</th>
												<th>Sorteo de 5 de abril</th>
												<th>Sorteo de 16 de abril</th>
											</tr>
											<tr>
												<td>Una Membresia de Spotify por un (1) año con    valor de US$77.04 incluido el ITBMS</td>
												<td>Una Membresia de Spotify por un (1) año con    valor de US$77.04 incluido el ITBMS</td>
												<td>Una Membresia de Spotify por un (1) año con    valor de US$77.04 incluido el ITBMS</td>
												<td>Dos Membresia de Spotify por un (1) año con    valor de US$77.04 incluido el ITBMS</td>
											</tr>
											<tr>
												<td>&nbsp;</td>
												<td>Una (1) Cámara Go Pro 7 con valor de US$363.80    incluido el ITBMS</td>
												<td>Una (1) cámara Go pro 7 con valor de US$363.80    incluido el ITBMS</td>
												<td>Una (1)cámara Go pro 7 con valor de US$363.80    incluido el ITBMS</td>
											</tr>
											<tr>
												<td>&nbsp;</td>
												<td>&nbsp;</td>
												<td>Un (1) Iphone 12 Pro de 128 GB con valor    de US$1,401.70 incluido el ITBMS</td>
												<td>Un (1) Iphone 12 Pro de 128 GB con valor    de US$1,401.70 incluido el ITBMS</td>
											</tr>
											<tr>
												<td>Doscientos cincuenta (250) recargas de    US$1.00</td>
												<td>Doscientos cincuenta (250) recargas de    US$1.00</td>
												<td>Doscientos cincuenta (250) recargas de    US$1.00</td>
												<td>Doscientos cincuenta (250) recargas de    US$1.00</td>
											</tr>
										</table>
									</div>
	
									<p>Mil (1000) Recargas de US$ 1.00 (Un Dólar), dando  un total de US$1,000.00:</p>
									<p>Para los premios de las recargas de Un dólar  (US$1.00), el día de los sorteos se sacarán los 250 ganador, y el premio se  hará efectivo instantáneamente, por lo que para la efectividad de la entrega de  las recargas se hará por medio de un listado con el número de teléfono ganador  y la foto del código del producto con el listado de las recargas.</p>
									<p>Las personas  que sean ganadoras de las recargas de un dólar (US$1.00) de celular, dicha  recarga se hará de manera automática al mismo número de teléfono de donde salió  el mensaje de Whatsapp por lo que la persona no tiene que apersonarse a las  oficinas de <strong>JAQUEMATTE SOLUCIONES</strong>   para hacerlo afectivo el premio, por lo que se tendrá como comprobante  de entrega del premio las mismas recargas a los números de teléfonos que  salieron elegibles como ganadores.</p>
									<p><strong><em>EL VALOR TOTAL DE LOS PREMIOS OFRECIDOS EN ESTA  PROMOCIÓN ES DE US$ CINCO MIL DOSCIENTOS OCHENTA DOLARES (US$5,280.00) más el  IT.B.M.S. </em></strong></p>
									<p><strong>CONDICIONES PARA EL PREMIO DEL SORTEO:</strong></p>
									<p>Para retirar los premios la persona deberá tener a  mano la factura original de compra, el documento de identidad (Cédula,  Pasaporte vigente, o carnet de residente vigente, de ser menor de edad copia de  cédula juvenil y copia de la cédula del familiar), firmar un finiquito de  entrega del premio.</p>
									<p>En caso de que la persona ganadora no pueda retirar  su premio deberá enviar una nota autorización con copia de su cédula.</p>
									<p>En caso de los menores de edad para retirar los  premios deberá ser el padre, la madre o el tutor por lo que deberá aportar la  copia de cédula juvenil o en su defecto copia de un certificado de Nacimiento.</p>

									<p><strong>CONDICIONES DE LA PROMOCIÓN:</strong></p>
									<ol class="decimal">
										<li>Los premios no podrán ser canjeados por dinero en  efectivo, por lo que la persona recibirá los productos ofrecidos como premio.</li>
										<li>Se realizará un sorteo cumpliendo con la normativa  aplicable de todos los códigos únicos de participación que se hayan emitido en  el período de vigencia de la promoción.   </li>
										<li>La notificación se hará vía Whatsapp al mismo  número de teléfono desde el cual enviaron las facturas para participar en la  Promoción.</li>
										<li>Se le notificará al código seleccionado para el  premio de la Promoción, que es un posible ganador y que será elegible a recibir  el premio si cumple con los todos los requisitos aplicables, en cuyo caso  deberá enviar vía Whatsapp copia de su identificación para ser validado al  momento de la entrega del premio. No aplica para recargas celular de Un dólar  (US$1,00) que serán automáticamente generadas. </li>
										<li>De recibir confirmación que el posible ganador  cumple con todos los requisitos aplicables, se procederá a la verificación  visual de identificación del participante para cerciorarse de que cumpla con  los mismos en el plazo antes establecido. </li>
										<li>Los documentos deberán ser enviados por fotografía  o un scan vía Whatsapp a los encargados del Organizador para la verificación y  aceptación de los mismos. </li>
										<li>Una vez verificada visualmente dicha información,  se le confirmará como el ganador. </li>
										<li>Cada Participante ganador deberá firmar el recibo y  finiquito de conformidad previo a la entrega del premio. En caso de que la persona no pueda ir a  retirar el premio deberá mandar a una persona con una autorización y copia de  su documento de identidad personal, en caso de que el ganador sea un menor de  edad, el premio deberá ser retirado por el padre, la madre o un familiar que  demuestre el vínculo de consanguinidad.</li>
										<li>Una vez entregado el Premio, el Participante que  haya resultado ganador será el único responsable del destino del premio,  liberando de toda responsabilidad a la empresa.  En caso de que la persona una vez haya  cumplido con los requisitos para ser ganador y dentro del período de entrega  del premio no lo puede retirar o renuncia al mismo, dicho premio será donado de  mutuo acuerdo con la Junta de Control de Juegos a una entidad sin fines de  lucro elegida por dicha entidad.</li>
										<li>La empresa no será responsable por el uso que le  brinde el Participante ganador al premio recibido, ni por procesos judiciales,  extrajudiciales o de cualquier otra índole que puedan ser interpuestos en  relación a un reclamo de cualquier tipo de responsabilidad, por hechos  acontecidos durante o como consecuencia de la entrega del mismo, así como  relacionados con la calidad, garantías por daños, pérdidas o perjuicios  ocasionados por el uso y disfrute del Premio.</li>
										<li>El participante solo podrá ganar una sola vez,  salvo el caso de recarga telefónicas en donde podrá ganar otro premio.</li>
									</ol>
	
									<p><strong>CRITERIOS DE DESCALIFICACION:</strong></p>
									<p>La empresa podrá no entregar el Premio a la persona ganadora si demuestra  que la misma haya actuado de mala fe o haya dado información falsa o que se  presuma la existencia de fraude o cualquier otro acto ilícito o no autorizado  para la obtención del Premio objeto de la Promoción. El Participante acreedor  al Premio deberá seguir todas las direcciones e instrucciones de El  Organizador, en caso de incumplimiento de cualquiera de éstas, el participante  será descalificado.</p>
									<p>La empresa se reserva el derecho de proceder por las vías legales en  contra de cualquiera de los Participantes que haya cometido un acto ilícito en  relación con la Promoción, pudiendo intentar toda clase de recursos o acciones  que sean legalmente aplicables.</p>
									<p>Al participar y ganar en la Promoción, el Participante autoriza expresa  e irrevocablemente al Organizador, patrocinador, sus filiales y subsidiarias,  así como a los clientes de éstas relacionados con la Promoción, a difundir en  los medios que determinen, estime conveniente sin límite territorial a fijar, a  reproducir, difundir su nombre y apellido completo, país y ciudad de origen,  fotografías y/o uso de imagen o retrato, videos y/o voz, y en general todos  aquellos datos que pudieran requerirse con motivo de la difusión de la  Promoción, renunciando expresa e irrevocablemente desde la aceptación de las Bases  a cualquier tipo de compensación económica, remuneración, regalía o retribución  alguna por dicho uso, siempre que los fines sean publicitarios y divulgativos  en relación a la ley en vigor, para lo que mediante la aceptación de las  presentes bases se emiten las autorizaciones que resulten necesarias de los  participantes.</p>
									<p>La empresa podrá solicitar a la Junta de Control de Juegos una  autorización para modificar, cancelar o extender o suspender la promoción  comercial si se dieren circunstancias de caso fortuito o fuerza mayor o eventos  que impidan la ejecución de la Promoción. Por lo que en tal caso se publicará a través de medios impresos y/o a través de su página web la resolución  emitida por la Junta de Control de Juegos con los motivos en que se fundamenta  la realización de cualquiera de los actos establecidos en el presente párrafo. </p>
									<p>La responsabilidad de la empresa solamente comprende la Promoción en  los términos y condiciones aquí previstos.</p>
									<p>La responsabilidad de la empresa concluye al momento de la entrega del  Premio a sus correspondientes acreedores. </p>
									<p>La empresa se reserva el derecho a realizar modificaciones o añadir  anexos sucesivos sobre su mecánica y Premio, siempre que las mismas estén  justificadas o no perjudiquen a los participantes y que dichas modificaciones  sean aprobadas por la Junta de Control de Juegos.</p>
									<p>Para cualquier aclaración o información referente a la Promoción se  pueden consultar las Bases en: Link del mini sitio que se encontrará alojado en  el dominio <a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a> o comunicándose por Whatsapp al número indicado en  la promoción.</p>
									<p>Debido a la mecánica de  la promoción no se emitirán boletos.</p>
									
									<p><strong>ESTABLECIMIENTOS PARTICIPANTES:</strong></p>
									<p>Todos  los establecimientos comerciales autorizados legalmente en el país participante  que emitan por la compra del producto el documento fiscal o tributario  (factura) correspondiente y que cumpla con los requisitos legales del país para  la emisión del comprobante.</p>
									<p><strong>REQUISITOS DE ELEGIBILIDAD PARA  PARTICIPAR EN LA PROMOCIÓN:</strong></p>
									<p>Podrá  participar en la Promoción cualquier persona física que tenga la disposición de  participar, compre los Productos Participantes y reúna todos y cada uno de los  siguientes requisitos (el &ldquo;Participante&rdquo; o los &ldquo;Participantes&rdquo;):</p>
									<p>Ser mayor de edad con plena capacidad  de ejercicio legal, podrán participar los menores de edad siempre y cuando  cuenten con autorización de quienes ejercen su patria potestad, quienes si son  elegidos como ganadores deberán hacerse representar por quienes ejerzan su patria  potestad.</p>
									<p>Contar con número telefónico de  contacto.</p>
									<p>No  podrán participar todas aquellas personas que sean familiares hasta el cuarto grado  de un empleado o empleados del Organizador ni del Patrocinador, ni subsidiarias  y/o agencias &ldquo;partners&rdquo; así como de cualquiera de las empresas que formen parte  del mismo grupo de intereses económicos al que pertenece el Organizador y el  Patrocinador y las empresas vinculadas en la ejecución de la Promoción.</p>

									<p><strong>PREMIOS:</strong></p>
									<p><strong>PANAMÁ:</strong></p>
									<ol class="decimal">
										<li>2 Iphone 12 Pro</li>
										<li>3 GoPro</li>
										<li>5 membresías de Spotify por 1 año.</li>
										<li>Recargas de teléfono de $1.00 a $2.00  (total $1,000.00).</li>
									</ol>
	
									<p><strong>SELECCIÓN  DE GANADOR Y ENTREGA DE PREMIO</strong></p>
									<p>Se realizará un sorteo cumpliendo con  la normativa aplicable de todos los códigos únicos de participación que se  hayan emitido en el período de vigencia de la promoción.</p>
									<p>Una vez determinado el código ganador,  se procederá a la selección de posibles ganadores secundarios, a los cuales se  les asignará un orden de prelación según sean seleccionados.</p>
									<p>La notificación se hará vía Whatsapp  al mismo número de teléfono desde el cual enviaron las facturas para participar  en la Promoción.</p>
									<p>Se le notificará al código  seleccionado para el premio de la Promoción, que es un posible ganador y que  será elegible a recibir el premio si cumple con los todos los requisitos aplicables.</p>
									<p>De recibir confirmación que el posible  ganador cumple con todos los requisitos aplicables, se procederá a la  verificación visual de documentos para cerciorarse de que cumpla con los mismos  en el plazo antes establecido. </p>
									<p>Los documentos deberán ser enviados  por fotografía o un scan vía correo electrónico a uno de los encargados del  Organizador para la verificación y aceptación de los mismos. </p>
									<p>Una vez verificada visualmente dicha  información, se le confirmará como el ganador.</p>
									<p>En caso de que el código seleccionado  no cumpla con cualquiera de los requisitos, se le descartará automáticamente  como ganador y se procederá a contactar a los siguientes códigos secundarios con  orden de prelación y proceder con la verificación antes descrita hasta llegar a  un Participante que haya recibido un código secundario que cumpla con los  requisitos de elegibilidad. </p>
									<p>Cada Participante ganador deberá  firmar el recibo y finiquito de conformidad previo a la entrega del premio.</p>
									<p>Una vez entregado el Premio, el  Participante que haya resultado ganador será el único responsable del destino  de el Premio, liberando de toda responsabilidad a El Organizador, Patrocinador,  sus filiales, subsidiarias y contratistas. Asimismo, si se suspendiera,  modificara, alterará o de cualquier forma se viera imposibilitados el  Participante ganador a disfrutar del premio por causas no imputables al  Organizador, éste quedará libre de toda responsabilidad frente al Participante  al respecto.</p>
									<p>El Organizador, no será responsable  por el uso que le brinde el Participante ganador al premio recibido, ni por  procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser  interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por  hechos acontecidos durante o como consecuencia de la entrega del mismo, así  como relacionados con la calidad, garantías por daños, pérdidas o perjuicios  ocasionados por el uso y disfrute del Premio.</p>
									<p>Los impuestos en el territorio que  fueren aplicables serán cubiertos por el Patrocinador mediante su Organizador.</p>
									<p>El participante puede ganar una sola  vez, salvo el caso de recarga telefónica puede ganar otro premio.</p>
									<p>Los premios estarán disponibles en un  plazo máximo de 6 meses, después de la fecha no podrá hacerse reclamación  alguna.</p>
									<p><strong>CONDICIONES Y LIMITACIONES:</strong></p>
									<p>El  Organizador se reserva el derecho de verificar el cumplimiento de estas Bases  por parte de los Participantes, quedando exento de cualquier obligación  (incluyendo la entrega del premio o cualquier otra) ante cualquier persona que  haya participado o que haya pretendido participar en la Promoción sin cumplir  con las condiciones de elegibilidad, la mecánica o cualesquiera otros términos  y condiciones aquí previstos. Al efecto, queda sobreentendido que los  Participantes aceptan que todas las decisiones que adopte el Organizador sobre  cualquier cuestión no prevista en estas Bases serán finales.</p>
									<p><strong>DERECHOS DE AUTOR Y PROPIEDAD  INTELECTUAL:</strong></p>
									<p>Al  participar y ganar en la Promoción, el Participante autoriza expresa e  irrevocablemente al Organizador, patrocinador, sus filiales y subsidiarias, así  como a los clientes de éstas relacionados con la Promoción, a difundir en los  medios que determinen, estime conveniente sin límite territorial a fijar, a  reproducir, difundir su nombre y apellido completo, país y ciudad de origen,  fotografías y/o uso de imagen o retrato, videos y/o voz, y en general todos  aquellos datos que pudieran requerirse con motivo de la difusión de la  Promoción, renunciando expresa e irrevocablemente desde la aceptación de las Bases  a cualquier tipo de compensación económica, remuneración, regalía o retribución  alguna por dicho uso, siempre que los fines sean publicitarios y divulgativos  en relación a la ley en vigor, para lo que mediante la aceptación de las  presentes bases se emiten las autorizaciones que resulten necesarias de los  participantes.</p>
									<p><strong>LÍMITES DE RESPONSABILIDAD DEL  ORGANIZADOR Y PATROCINADOR:</strong></p>
									<p>El  Organizador y el Patrocinador podrá en cualquier momento suspender La Promoción  o la entrega del Premio sin responsabilidad alguna, si dicha suspensión  responde en acato de cualquier mandato de autoridad con facultades al efecto,  así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos  que impidan la ejecución de Promoción. En todos estos  casos,&nbsp;La Organizador  publicará a través de medios impresos y/o a través de su página web los  motivos en que se fundamenta la realización de cualquiera de los actos  establecidos en el presente párrafo. </p>
									<p>La  responsabilidad del Organizador y del Patrocinador solamente comprende la  Promoción en los términos y condiciones aquí previstos por lo que no asume  responsabilidad alguna respecto de cualquier otra actividad promocional de  productos de la marca Pringles® y Cheet It® de cualquier plataforma asociada  con actividades promocionales de dichas marcas.</p>
									<p>La  responsabilidad de el Organizador y del Patrocinador concluyen al momento de la  entrega del Premio a sus correspondientes acreedores. </p>
									<p><strong>MODIFICACIONES:</strong></p>
									<p>El  Organizador se reserva el derecho a realizar modificaciones o añadir anexos  sucesivos sobre su mecánica y Premio, siempre que las mismas estén justificadas  o no perjudiquen a los participantes y se comuniquen a éstos debidamente  conforme a la regulación aplicable.</p>
									<p><strong>DUDAS Y COMENTARIOS:</strong></p>
									<p>Para  cualquier aclaración o información referente a la Promoción se pueden consultar  las Bases en: Link del mini sitio que se encontrará alojado en el dominio <a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a> o  comunicándose por Whatsapp al número indicado en la promoción. </p>
									<p><strong>LEGISLACIÓN APLICABLE:</strong></p>
									<p>Estas Bases y la relación que se  genere entre cualquier Participante y el Organizador será regida y concertada  con total sujeción a las leyes aplicables de la República de cada sorteo y a la  jurisdicción de los tribunales competentes, renunciando el Participante y el  Organizador a cualquier otra jurisdicción que pudiera ser aplicable en función  de su domicilio o por cualquier otra razón.</p>
								</div>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Snackea con Pringles® y Cheez It®. | Términos y condiciones Panamá.',
			meta: [
				{
					name: 'description',
					content: 'Snackea con Pringles® y Cheez It®. Conoce todos los detalles sobre las reglas, legislación y terminología aplicables en nuestros Términos y condiciones.'
				},
			]
        }
    },
}
</script>