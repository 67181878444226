<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/deezer/466x276_es.jpg" alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2019</p>
                                <h4 class="titleTerms">PROMO PRINGLES® DEEZER</h4>
                                <h5>Reglas Oficiales del Sorteo</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="">
                                    <h2 class="hidden">Términos</h2>
                                    <!-- <h2>PRINGLES® MUSIC 2019<br>Reglas Oficiales del Sorteo</h2> -->
                                    <p>Para participar en la presente sorteo, denominado “Pringles® MUSIC 2019” (en lo sucesivo, la “Promoción”), se deberá dar lectura íntegra a las siguientes reglas y cumplir totalmente con los términos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de las mismas, (en lo sucesivo, las “Reglas Oficiales”). Esta Promoción es limitada al área geográfica de Puerto Rico. </p>
                                    <p><strong>I. PROMOTOR</strong> </p>
                                    <p>El promotor del la Promoción es The Perfect Storm Marketing & Consulting Services, Corp.., (en lo sucesivo, el “Promotor”). Para cualquier aclaración o información referente a la Promoción o los resultados del mismo, puede comunicarse con nosotros de lunes a viernes de 10:00 am a 5:00 pm. Correo electrónico: concursos@perfectstormpr.com ; Dirección: 361 Ángel Buonomo San Juan, PR 00018 y Teléfono: 787-705-1600. Esta Promoción no es auspiciada, endosada o administrada por Facebook®.</p>
                                    <p><strong>II. VIGENCIA</strong> </p>
                                    <p>a. Sorteo Aleatorio: La Promoción inicia a las 12:00 A.M. Hora Estándar del Atlántico (AST) del día 3 de mayo de 2019 y finaliza a las 12:00 P.M. (AST) del día 15 de junio de 2019 (en lo sucesivo, la “Vigencia de la Promoción”). Esta Promoción es limitada al área geográfica de Puerto Rico. </p>
                                    <p>b. Ganador al Instante: Durante la Vigencia de la Promoción o hasta que se agote el inventario de premios bajo la modalidad de ganador al instante, lo que ocurra primero.</p>
                                    <p><strong>III. ELEGIBILIDAD</strong></p>
                                    <p>Podrán participar en la Promoción aquellas personas que así lo interesen y a su vez reúnan todos y cada uno de los siguientes requisitos: 1) ser residentes legales de Puerto Rico; 2) con por lo menos veintiún (21) años de edad o más al momento de inscripción o mayores de dieciocho (18) años de edad con la anuencia y consentimiento de quien de ellos ostente la patria potestad o tutoría legal; 3) no ser empleados ni familiares hasta el cuarto grado de consanguinidad o segundo de afinidad, así como personas que convivan, o tengan relaciones de pareja aunque no convivan, con empleados del Promotor, los auspiciadores y sus subsidiarias, suplidores, sus agencias de publicidad o relaciones públicas, y entidades relacionadas con el desarrollo, agencias de producción o distribución de materiales y premios de la Promoción. Cualquier persona que omita información para pretender que cualifica como ganador será descalificado de la Promoción. Esta promoción es limitada al área geográfica de Puerto Rico y está sujeta a todas las leyes y reglamentos aplicables y; 4) haber leído y aceptado las Reglas Oficiales. </p>
                                    <p><strong>IV. PREMIOS</strong></p>
                                    <p>Hasta trecientos (300) premios bajo la modalidad de ganador al instante y once (11) premios a los ganadores seleccionados aleatoriamente. </p>
                                    <p>Premios</p>
                                    <div class="table-wrp">
                                        <table class="period-table globalTable">
                                            <tr>
                                                <th>Audífonos Sony (On-Ear)</th>
                                                <th>Bocina Bose SoundLink Micro</th>
                                                <th>Viaje a Chicago para dos personas</th>
                                                <th>2 meses de subscripción en Deezer Premium</th>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>4</td>
                                                <td>1</td>
                                                <td>300</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p><strong>Premios Disponibles al Instante </strong>(Subscripción de dos (2) meses en Deezer Premium)</p>
                                    <p>Hasta 300 premios bajo la modalidad de ganador al instante consistentes en un cupón redimible para dos (2) meses de subscripción en Deezer Premium valorado en US$19.98 cada uno. </p>
                                    <p>Únicamente disponible para usuarios gratuitos de Deezer o nuevos usuarios que nunca se hayan beneficiado del período de prueba o una promoción similar otorgada por Deezer. Nuevos suscriptores requieren de una tarjeta crédito o débito válida y se convertirán automáticamente en un suscriptor pago al finalizar el período de dos (2) meses gratis, salvo que cancele su suscripción antes de que inicie el periodo pago. Limitado a un (1) premio (suscripción Deezer) por Participante. Sujeto a todos los términos y condiciones de Deezer. </p>
                                    <p>El cupón será canjeado en la aplicación o en la página web de Deezer: (<a href="https://www.deezer.com" target="_blank">https://www.deezer.com </a>) durante la Vigencia de la Promoción y hasta el 31 de agosto de 2019. El cupón que no haya sido redimido en o antes del 31 de agosto de 2019, no podrá ser redimido y perderá cualquier valor. Si concluida la Vigencia de la Promoción no se hubieren reclamado todos los cupones redimibles, se dispondrá de los mismos según lo determine el Promotor.</p>
                                    <p><strong>Premios Seleccionados Aleatoriamente </strong>(Audífonos, Bocinas y Viaje)</p>
                                    <p>a. Uno (1) de dos (2) audífonos marca Sony: audífono Sony on Ear black inalámbrico bluetooth, nfc con micrófono con un valor comercial de US$70.00 </p>
                                    <p>b. Una (1) de cuatro (4) bocinas marca Bose: SoundLink Micro Bluetooth speaker con un valor comercial de US$150.00</p>
                                    <p>c. Un (1) viaje para dos (2) personas a Chicago del 31 de julio de 2019 al 5 de agosto de 2019, estadía en hotel y dos (2) “Four Day Passes” para el Lollapallooza del 1ro de agosto al 4 de agosto de 2019, valorado aproximadamente en US$5,200. </p>
                                    <p>Cada premio está sujeto a sus propios términos y condiciones y garantías con el manufacturero. No es posible sustituir, asignar, intercambiar por efectivo ni transferir los premios; salvo a criterio exclusivo del Promotor. El Promotor no remplazará los premios perdidos o robados. </p>
                                    <p>El ganador del viaje y su acompañante de viaje deben estar disponibles para viajar a Chicago del 31 de julio de 2019 y viajar de regreso el 5 de agosto de 2019 y contar con los documentos e identificaciones necesarias para volar según requerido por los aeropuertos y/o autoridades correspondientes. Ambos deben viajar en el mismo itinerario de ida y vuelta. El acompañante de viaje debe tener por lo menos 18 años. El premio incluye: a) Dos (2) boletos ida y vuelta en clase económica de Puerto Rico a Chicago y viceversa; 2) estadía de cinco noches en hotel clase turista; 3) dos (2) “Four Day Passes” para el Lollapalloza del 1ro de agosto al 4 de agosto de 2019; y 4) transportación del aeropuerto al hotel y del hotel al aeropuerto.</p>
                                    <p>El Promotor determinará la aerolínea, el itinerario del vuelo, el tipo de alojamiento en el hotel y los arreglos de transporte a su sola discreción. No se harán reembolsos ni compensación en caso de cancelación o retraso de cualquier vuelo. Los viajes están sujetos a los términos y condiciones establecidos en estas Reglas Oficiales y los establecidos por la compañía aérea seleccionada por el Promotor según se detallan en el contrato de pasaje. El Promotor no es responsable y no hará reembolsos por retrasos o cancelaciones de los eventos programados. Todos los gastos, impuestos, cargos por equipaje, propinas, dieta, cargos a la habitación y otros gastos no expresamente establecidos en las Reglas Oficiales son responsabilidad exclusiva del ganador del viaje y su acompañante de viaje. El hotel requiere que sus huéspedes presenten una tarjeta de crédito e identificaciones al momento del check-in para cualquier gasto incidental en que pueda incurrir por lo que será responsabilidad del ganador del viaje llevar una tarjeta e identificaciones válidas para esos fines. Los boletos, entradas o certificados perdidos, mutilados o robados no serán reemplazados. Al aceptar el premio del boleto de avión, la reserva del hotel y los arreglos de transporte, el ganador del viaje acepta cumplir con los términos, condiciones y restricciones proporcionados por el proveedor del servicio. Otras restricciones, condiciones y limitaciones aplican.</p>
                                    <p><strong>V. PRODUCTO PARTICIPANTES</strong></p>
                                    <p>Los Productos Participantes son los siguientes:</p>
                                    <p>a. Pringles® Original 2.36 oz y 4.5 oz </p>
                                    <p>b. Otras variedades de Pringles® de 2.5 oz y 5.2 oz</p>
                                    <p><strong>VI. PARA PARTICIPAR. NADA QUE COMPRAR PARA PARTICIPAR.</strong></p>
                                    <p>a. Participación con compra. Visite cualquiera de los supermercados participantes durante los horarios de visita establecidos en el ANEXO 2 de estas Reglas Oficiales. Compre la cantidad de Productos Participantes requeridos en el ANEXO 3 de estas Reglas Oficiales. El participante realiza el canje de la compra del producto Pringles® con la promotora en turno en el punto de venta, quien – luego de evaluar y constatar su compra - le dará la cantidad códigos correspondientes a la cantidad de productos Pringles® adquiridos a tenor con lo establecido en el ANEXO 3. </p>
                                    <p>b. Visite la página web www.promopringles.com (en lo sucesivo, la “Página Web de la Promoción”) y acceda la pestaña de la Promoción. Lea las Reglas Oficiales. De estar de acuerdo con las Reglas Oficiales, acepte las mismas y acceda o cree una nueva cuenta de usuario. Para crear una cuenta nueva necesitará proveer: a) nombre completo; b) fecha de nacimiento; 3) teléfono; 4) dirección residencial y 5) contraseña. Completado el registro, ingrese los códigos correspondientes y seleccione para cuál de los premios estaré participando (Audífonos, Bocinas o Suscripción Deezer). Todos los participantes participarán automáticamente para el viaje a Chicago para dos (2) personas. </p>
                                    <p>c. Participación sin compra. Para participar sin compra, visite las oficinas del Promotor los lunes de 2:00 P.M. (AST) a 5:00 P.M. (AST), y solicite el boleto de inscripción a ser completado. Luego de completar el mismo, le entregarán los códigos correspondientes. Continúe todo el proceso de inscripción establecido en la letra (b) anterior. El boleto de inscripción será únicamente para la persona que allí se persone a reclamarlo y no será transferible, so pena de anulación, a terceras personas. La participación mediante este mecanismo se aceptará solamente una vez por persona durante toda la Vigencia de la Promoción.</p>
                                    <p><strong>VII. SELECCIÓN DE GANADORES, NOTIFICACIÓN Y RECLAMO DE PREMIOS</strong></p>
                                    <p>a. Ganadores al Instante (Premios Deezer). Una vez ingresado su código en la Página Web de la Promoción, el sistema le notificará de manera inmediata si el código ingresado es uno de los previamente identificados como acreedor de uno de los premios consistente en dos (2) meses de suscripción en Deezer Premium valorado en US$19.98 cada uno. El sistema generará un PDF con el cupón para redención del premio. Dicho cupón solo podrá ser canjeado en la página oficial de Deezer (https://www.deezer.com ) en la cuenta que el ganador del premio tenga o cree en dicha plataforma. El cupón podrá canjearse en cualquier momento antes del 31 de agosto de 2019.</p>
                                    <p>b. Selección aleatoria de ganadores (Bocinas, Audífonos y viaje). El día 17 de junio de 2019, aproximadamente a las 5:00 P.M. (AST) en las oficinas del Promotor, se llevarán a cabo los siguientes sorteos mediante un proceso informático automatizado de selección aleatoria:</p>
                                    <ul>
                                        <li>Sorteo Audífonos Marca Sony. De entre las personas registradas para participar por el premio de los audífonos marca Sony: Un ganador y un ganador alterno para cada uno de los dos (2) audífonos marca Sony;</li>
                                        <li>Sorteo Bocinas Bose Soundlink. De entre las personas registradas para participar por el premio de las bocinas marca Bose Soundlink: Un ganador y un ganador alterno para cada una de las cuatro (4) bocinas marca Bose Soundlink;</li>
                                        <li>Sorteo Viaje a Chicago. De entre todos los participantes registrados para para participar por los premios Deezer, audífonos marca Sony y bocinas marca Bose Soundlink: Un ganador y un ganador alterno para un (1) viaje para dos (2) personas a Chicago del 31 de julio de 2019 al 5 de agosto de 2019, estadía en hotel y dos (2) “Four Day Passes” para el Lollapallooza del 1ro de agosto al 4 de agosto de 2019.</li>
                                    </ul>
                                    <p>c. Los ganadores de premios seleccionados en el inciso (b) anterior (en lo sucesivo, los “Ganadores de Premio”) recibirán una notificación de parte del Promotor a través de un correo electrónico y/o vía telefónica (en lo sucesivo, la “Notificación”). En el caso en que el Promotor no logre contactar al Ganador del Premio a través de la Notificación, en un período de veinticuatro (24) horas, o que el Ganador de Premio no complete los Documentos Requeridos a tenor con lo dispuesto en la sección de “Reclamación de Premio”, el Promotor, a su criterio exclusivo, podría determinar que el Ganador de Premio no es elegible de conformidad con las Reglas Oficiales y procederá a notificar al ganador alterno para dicho premio y quien estará sujeto a los mismos términos y condiciones. </p>
                                    <p>d. Los Ganadores de Premios deberán completar, firmar y entregar una Declaración de Elegibilidad/Relevo de Responsabilidad y Autorización de Publicación (en lo sucesivo, el “Relevo”) y cualquier otro documento requerido por el Promotor (en lo sucesivo, los “Documentos Requeridos”) dentro de un plazo de cuarenta y ocho (48) horas de la Notificación o un plazo mayor a discreción del Promotor. Si un Ganador de Premio no devuelve los Documentos Requeridos dentro del plazo de fecha y hora indicado, el Ganador de Premio perderá su derecho al respectivo premio y se llamará a un ganador de premio alterno.</p>
                                    <p>e. Los Ganadores de Premio consienten y acuerdan firmar cualquier documento adicional requerido por el Promotor a fin de formalizar, efectuar o perfeccionar el consentimiento para que el Promotor haga uso de su nombre, imagen (fotografía, video), likeness, información biográfica y voces en anuncios, publicidad/comercio sin compensación, notificación ni aprobación alguna, y los Ganadores de Premio renuncian a cualquier derecho de propiedad de dicho material.</p>
                                    <p>f. Los premios serán otorgados en un plazo no mayor de treinta (30) días a partir de la fecha en la que se reciben los Documentos Requeridos. Demoras razonables podrían ocurrir. Cada Ganador de Premio tendrán que recoger su premio no más tarde de treinta (30) días de la fecha en que se le indique el mismo está disponible. No se le entregará el premio a otra persona que no sea el Ganador del Premio.</p>
                                    <p><strong>VIII. CONDICIONES GENERALES</strong> </p>
                                    <p>a. Las probabilidades de ganar dependerán únicamente del número total de participaciones elegibles recibidas.</p>
                                    <p>b. Los premios se entregarán libre de arbitrios o de cualquier otro gravamen por impuestos a la fecha de su entrega a la persona ganadora. Cualesquiera impuestos o contribuciones, posterior a la adjudicación del premio, será responsabilidad del recipiente de este.</p>
                                    <p>c. Todas las Participaciones pasarán a ser propiedad del Promotor y no serán devueltas. </p>
                                    <p>d. Los participantes aceptan cumplir y estar obligados por las Reglas Oficiales y las decisiones del Promotor, las cuales serán definitivas y vinculantes con respecto a todos los asuntos relacionados con esta Promoción.</p>
                                    <p>e. Sólo se puede participar una vez por código. Códigos previamente registrados serán automáticamente descartados.</p>
                                    <p>f. El Promotor se reserva el derecho, a su criterio exclusivo, de descalificar a cualquier persona que se encuentre manipulando el proceso de participación o la operación de la Promoción o cuyo comportamiento viole las Reglas Oficiales o que se comporte de manera negativa o perjudicial y de reclamar daños y perjuicios a dicha persona según lo permita la ley. El Promotor se reserva el derecho de descalificar o rechazar cualquier participación incompleta, incorrecta o falsa. </p>
                                    <p>g. El participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir la Página Web de la Promoción; (ii) no accederá al Página Web de la Promoción ni participará en la Promoción utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), ni podrá permitir que un tercero tenga acceso y/o utilice Página Web de la Promoción en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en la Página Web de la Promoción; (iii) acepta ser el único responsable (frente al Promotor y las Entidades Relevadas) de toda la actividad que realice en la Página Web de la Promoción, y mantendrá indemne Promotor y las Entidades Relevadas.</p>
                                    <p>h. El Promotor no garantiza la disponibilidad y/o continuidad del funcionamiento de la Página Web de la Promoción ni tampoco garantiza el acceso efectivo al mismo, siendo esto responsabilidad exclusiva de las proveedoras de servicios de Internet. En el mismo sentido, el Promotor no garantiza que los participantes utilicen la Página Web de la Promoción de conformidad con estas Reglas Oficiales ni que lo hagan de forma prudente y diligente. </p>
                                    <p>i. Será rechazado y no tendrá derecho a participar en la Promoción cualquier código falsificado, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</p>
                                    <p>j. El Promotor se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados por los participantes.</p>
                                    <p><strong>IX. DATOS PERSONALES (AVISO DE PRIVACIDAD)</strong></p>
                                    <p>La información personal que los participantes proporcionen con el fin de recibir alguno de los premios de la Promoción, quedarán incorporados a una base de datos que conservará el Promotor, ante la que cualquier usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, el Promotor quedará liberado respecto de las entregas o beneficios por otorgar a los participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                                    <p>El Promotor utilizará la información proporcionada para la realización de la Promoción con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece el Promotor, así como con prestadores de servicios contratados por el Promotor para la ejecución de la Promoción hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial del Promotor.</p>
                                    <p>Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los participantes la podrán realizar enviando una solicitud por correo electrónico a concursos@perfectstormpr.com o llamando al teléfono 787-705-1600.</p>
                                    <p><strong>X. LÍMITES DE RESPONSABILIDAD DEL PROMOTOR</strong></p>
                                    <p>Al participar en esta Promoción, los participantes acuerdan relevar y exonerar y mantener indemnes al Promotor y a su(s) cliente(s) y a cada una de sus casas matrices, sus respectivos oficiales, directores, gerentes, empleados, subsidiarias, entidades afiliadas, agentes, miembros, accionistas, aseguradoras, invitados, divisiones, predecesores, sucesores, representantes, asignados, abogados, distribuidores, agencias de publicidad, promoción y despacho (colectivamente, las “Entidades Relevadas”) de cualquier reclamo o causa de acción que surja de la participación en la Promoción o del recibo, redención o uso de cualquier Premio incluyendo, pero sin limitarse a las siguientes: (a) intervención humana no autorizada en cualquier parte del proceso de inscripción o en la Promoción; (b) un error electrónico o humano el cual pueda ocurrir en la administración de la Promoción o en el procesamiento de las participaciones; (c) pérdidas de propiedad, daños, daños personales o muerte, relacionado con el premio brindado de conformidad con esta Promoción y/o la participación de cualquier persona en la Promoción; (d) cualquier error de impresión o tipográfico en cualquier material relacionado con la Promoción; (e) participaciones robadas, perdidas, con demoras, desviadas, corruptas, ilegibles, incompletas, dañadas o indescifrables; (f) cualquier daño o incumplimiento causado por fuerza mayor o actos de Dios; o (g) fallas técnicas de cualquier tipo, incluyendo, pero no limitadas al mal funcionamiento de cualquier computadora, dispositivos móviles, cable, red, “hardware” o “software”, si aplica, así como cualquier falla de la Página Web de la Promoción durante la Vigencia de la Promoción; o (h) cualquier daño o perjuicio causado por el uso de cualquiera de los premios.</p>
                                    <p><strong>XI. JURISDICCIÓN APLICABLE</strong></p>
                                    <p>Todos los asuntos y controversias relacionadas con la elaboración, validez, interpretación y aplicación de estas Reglas Oficiales o de los derechos y obligaciones de los participantes y de la Promoción en relación con esta Promoción, están regidos por estas Reglas Oficiales y las leyes del Estado Libre Asociado de Puerto Rico y se resolverán bajo la jurisdicción y competencia del Tribunal de Primera Instancia, Sala Superior de San Juan, Puerto Rico.</p>
                                    <p><strong>XII. AVISO DE PRIVACIDAD</strong></p>
                                    <p>El Promotor será responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta Promoción. </p>
                                    <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los premios ofrecidos en caso de tener derecho en tal supuesto.</p>
                                    <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg de Centroamérica, S.A., con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán aquellos anteriormente especificados en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                    <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopringles.com la información consistente en Datos Personales proporcionada por los participantes será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                    <p><strong>XIII. LISTA DE GANADORES Y REGLAS OFICIALES</strong></p>
                                    <p>Puede obtener copia de las Reglas Oficiales visitando durante la Vigencia de la Promoción. Los nombres de los Ganadores de Premio de esta Promoción estarán disponibles en la Página Web de la Promoción (www.promopringles.com) tan pronto los Ganadores de Premio hayan sido notificados. También puede solicitarlas por correo electrónico a: concursos@perfectstorm.com</p>
                                    <p><strong>ANEXO I TOTAL DE PREMIOS</strong></p>
                                    <p>Premios</p>
                                    <div class="table-wrp">
                                        <table class="period-table globalTable">
                                            <tr>
                                                <th>Audífonos Sony (On-Ear)</th>
                                                <th>Bocina Bose SoundLink Micro</th>
                                                <th>Viaje a Chicago para dos personas</th>
                                                <th>2 meses de subscripción en Deezer Premium</th>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>4</td>
                                                <td>1</td>
                                                <td>300</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p><strong>ANEXO II CALENDARIO DE VISITAS</strong></p>
                                    <div class="table-terms-wrp">
                                        <div class="table-wrp">
                                            <table class="period-table globalTable">
                                                <tr>
                                                    <th>SEMANA</th>
                                                    <th>FECHA</th>
                                                    <th>DÍA</th>
                                                    <th>HORARIO</th>
                                                    <th>TIENDA</th>
                                                    <th>CADENA</th>
                                                    <th>PUEBLO</th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/03/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Yauco</td>
                                                    <td>Econo</td>
                                                    <td>Yauco</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/04/19</td>
                                                    <td>Sábado</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Añasco</td>
                                                    <td>Mr Special</td>
                                                    <td>Añasco</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/04/19</td>
                                                    <td>Sabado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Gurabo</td>
                                                    <td>Ralph's</td>
                                                    <td>Gurabo</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/04/19</td>
                                                    <td>Sábado</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Moca</td>
                                                    <td>Mr Special</td>
                                                    <td>Moca</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/04/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Utuado</td>
                                                    <td>Selectos</td>
                                                    <td>Utuado</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/05/19</td>
                                                    <td>Domingo</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Guanica</td>
                                                    <td>Econo</td>
                                                    <td>Guanica</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>05/05/19</td>
                                                    <td>Domingo</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Yauco Plaza</td>
                                                    <td>Mr Special</td>
                                                    <td>Yauco</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/08/19</td>
                                                    <td>Miércoles</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Rincón</td>
                                                    <td>Econo</td>
                                                    <td>Rincón</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/09/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Fajardo</td>
                                                    <td>Econo</td>
                                                    <td>Fajardo</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/09/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Isabela</td>
                                                    <td>Econo</td>
                                                    <td>Isabela</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/09/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>La Mina</td>
                                                    <td>Econo</td>
                                                    <td>Toa Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/10/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Camuy</td>
                                                    <td>Econo</td>
                                                    <td>Camuy</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/10/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Dorado</td>
                                                    <td>SuperMax</td>
                                                    <td>Dorado</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/10/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Humacao</td>
                                                    <td>Ralph's</td>
                                                    <td>Humacao</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/10/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>San German</td>
                                                    <td>Econo</td>
                                                    <td>San German</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/10/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>San Sebastian</td>
                                                    <td>Econo</td>
                                                    <td>San Sebastian</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Añasco</td>
                                                    <td>Mr Special</td>
                                                    <td>Añasco</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Cabo Rojo</td>
                                                    <td>Econo</td>
                                                    <td>Cabo Rojo</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Cabo Rojo #2</td>
                                                    <td>Mr Special</td>
                                                    <td>Cabo Rojo</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Moca</td>
                                                    <td>Mr Special</td>
                                                    <td>Moca</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Toa Alta</td>
                                                    <td>Econo</td>
                                                    <td>Toa Alta</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Utuado</td>
                                                    <td>Econo</td>
                                                    <td>Utuado</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Utuado</td>
                                                    <td>Selectos</td>
                                                    <td>Utuado</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>05/11/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Yabucoa</td>
                                                    <td>Ralph's</td>
                                                    <td>Yabucoa</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/16/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Arecibo</td>
                                                    <td>Pueblo</td>
                                                    <td>Arecibo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/16/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Cayey</td>
                                                    <td>Ralph's</td>
                                                    <td>Cayey</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/16/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Ponce</td>
                                                    <td>SuperMax</td>
                                                    <td>Ponce</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/17/19</td>
                                                    <td>Viernes</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Cabo Rojo #2</td>
                                                    <td>Mr Special</td>
                                                    <td>Cabo Rojo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/17/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Río Hondo</td>
                                                    <td>Pueblo</td>
                                                    <td>Bayamón</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/17/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Trujillo Alto</td>
                                                    <td>SuperMax</td>
                                                    <td>Trujillo Alto</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Cabo Rojo</td>
                                                    <td>Econo</td>
                                                    <td>Cabo Rojo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Coamo</td>
                                                    <td>Econo</td>
                                                    <td>Coamo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Drive Inn</td>
                                                    <td>Pueblo</td>
                                                    <td>Bayamón</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sabado</td>
                                                    <td>10:00AM-2:00PM</td>
                                                    <td>Levittown</td>
                                                    <td>Econo</td>
                                                    <td>Toa Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Mayaguez</td>
                                                    <td>Econo</td>
                                                    <td>Mayaguez</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Santa Isabel</td>
                                                    <td>Selectos</td>
                                                    <td>Santa Isabel</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/18/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Vega Alta</td>
                                                    <td>Mr Special</td>
                                                    <td>Vega Alta</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Adjuntas</td>
                                                    <td>Selectos</td>
                                                    <td>Adjuntas</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Altamira</td>
                                                    <td>Econo</td>
                                                    <td>Guaynabo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Guaynabo</td>
                                                    <td>SuperMax</td>
                                                    <td>Guaynabo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Hatillo</td>
                                                    <td>K&amp;K</td>
                                                    <td>Hatillo</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Hormigueros</td>
                                                    <td>Mr Special</td>
                                                    <td>Hormigueros</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Isla Verde</td>
                                                    <td>Pueblo</td>
                                                    <td>San Juan</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Mayaguez Mall</td>
                                                    <td>Pueblo</td>
                                                    <td>Mayaguez</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Plaza del Caribe</td>
                                                    <td>Pueblo</td>
                                                    <td>Ponce</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Plaza las America</td>
                                                    <td>Pueblo</td>
                                                    <td>San Juan</td>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>05/19/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Vega Baja</td>
                                                    <td>SuperMax</td>
                                                    <td>Vega Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/23/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Cayey</td>
                                                    <td>Ralph's</td>
                                                    <td>Cayey</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/23/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Levittown</td>
                                                    <td>Econo</td>
                                                    <td>Toa Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/24/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>La Mina</td>
                                                    <td>Econo</td>
                                                    <td>Toa Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Barceloneta</td>
                                                    <td>Econo</td>
                                                    <td>Barceloneta</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Canovanas</td>
                                                    <td>Econo</td>
                                                    <td>Canovanas</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Cidra</td>
                                                    <td>SuperMax</td>
                                                    <td>Cidra</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Colobos</td>
                                                    <td>Econo</td>
                                                    <td>Carolina</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Fajardo</td>
                                                    <td>Econo</td>
                                                    <td>Fajardo</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Manati</td>
                                                    <td>Econo</td>
                                                    <td>Manati</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/25/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Yauco Plaza</td>
                                                    <td>Mr Special</td>
                                                    <td>Yauco</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/26/19</td>
                                                    <td>Domingo</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Caguas</td>
                                                    <td>Ralph's</td>
                                                    <td>Caguas</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/26/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Dorado</td>
                                                    <td>SuperMax</td>
                                                    <td>Dorado</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/26/19</td>
                                                    <td>Domingo</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Los Prados</td>
                                                    <td>Selectos</td>
                                                    <td>Caguas</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>05/26/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Yauco</td>
                                                    <td>Econo</td>
                                                    <td>Yauco</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>05/30/19</td>
                                                    <td>Jueves</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Añasco</td>
                                                    <td>Mr Special</td>
                                                    <td>Añasco</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>05/30/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Ponce</td>
                                                    <td>SuperMax</td>
                                                    <td>Ponce</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>05/31/19</td>
                                                    <td>Viernes</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Lajas</td>
                                                    <td>Mr Special</td>
                                                    <td>Lajas</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>05/31/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Manati</td>
                                                    <td>Econo</td>
                                                    <td>Manati</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>05/31/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Río Hondo</td>
                                                    <td>Pueblo</td>
                                                    <td>Bayamón</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00PM- 7:00 PM</td>
                                                    <td>Arecibo</td>
                                                    <td>K&amp;K</td>
                                                    <td>Arecibo</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Coamo</td>
                                                    <td>Econo</td>
                                                    <td>Coamo</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Drive Inn</td>
                                                    <td>Pueblo</td>
                                                    <td>Bayamón</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Mayaguez</td>
                                                    <td>Econo</td>
                                                    <td>Mayaguez</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Santa Isabel</td>
                                                    <td>Selectos</td>
                                                    <td>Santa Isabel</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Utuado</td>
                                                    <td>Econo</td>
                                                    <td>Utuado</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/01/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Vega Alta</td>
                                                    <td>Mr Special</td>
                                                    <td>Vega Alta</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Adjuntas</td>
                                                    <td>Selectos</td>
                                                    <td>Adjuntas</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>Altamira</td>
                                                    <td>Econo</td>
                                                    <td>Guaynabo</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Guaynabo</td>
                                                    <td>SuperMax</td>
                                                    <td>Guaynabo</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00AM-2:00PM</td>
                                                    <td>Hatillo</td>
                                                    <td>K&amp;K</td>
                                                    <td>Hatillo</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Hormigueros</td>
                                                    <td>Mr Special</td>
                                                    <td>Hormigueros</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Mayaguez Mall</td>
                                                    <td>Pueblo</td>
                                                    <td>Mayaguez</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Plaza del Caribe</td>
                                                    <td>Pueblo</td>
                                                    <td>Ponce</td>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>06/02/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Vega Baja</td>
                                                    <td>SuperMax</td>
                                                    <td>Vega Baja</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/06/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>San Sebastian</td>
                                                    <td>Selectos</td>
                                                    <td>San Sebastian</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/06/19</td>
                                                    <td>Jueves</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Yauco Plaza</td>
                                                    <td>Mr Special</td>
                                                    <td>Yauco</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/07/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Caguas</td>
                                                    <td>Ralph's</td>
                                                    <td>Caguas</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/07/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Gurabo</td>
                                                    <td>Selectos</td>
                                                    <td>Gurabo</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/07/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Isabela</td>
                                                    <td>Selectos</td>
                                                    <td>Isabela</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/07/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Trujillo Alto</td>
                                                    <td>SuperMax</td>
                                                    <td>Trujillo Alto</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/08/19</td>
                                                    <td>Sabado</td>
                                                    <td>11:00AM-3:00pm</td>
                                                    <td>Rincon</td>
                                                    <td>Econo</td>
                                                    <td>Rincon</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Canovanas</td>
                                                    <td>Econo</td>
                                                    <td>Canovanas</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Colobos</td>
                                                    <td>Econo</td>
                                                    <td>Carolina</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Humacao</td>
                                                    <td>Ralph's</td>
                                                    <td>Humacao</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Isla Verde</td>
                                                    <td>Pueblo</td>
                                                    <td>San Juan</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Plaza las America</td>
                                                    <td>Pueblo</td>
                                                    <td>San Juan</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>12:00 PM-4:00 PM</td>
                                                    <td>San Sebastian</td>
                                                    <td>Econo</td>
                                                    <td>San Sebastian</td>
                                                </tr>
                                                <tr>
                                                    <td>6</td>
                                                    <td>06/09/19</td>
                                                    <td>Domingo</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Yabucoa</td>
                                                    <td>Ralph's</td>
                                                    <td>Yabucoa</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/12/19</td>
                                                    <td>Miércoles</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Gurabo</td>
                                                    <td>Ralph's</td>
                                                    <td>Gurabo</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/13/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Arecibo</td>
                                                    <td>Pueblo</td>
                                                    <td>Arecibo</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/13/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Cidra</td>
                                                    <td>SuperMax</td>
                                                    <td>Cidra</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/13/19</td>
                                                    <td>Jueves</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Isabela</td>
                                                    <td>Econo</td>
                                                    <td>Isabela</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/14/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Camuy</td>
                                                    <td>Econo</td>
                                                    <td>Camuy</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/14/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Dorado</td>
                                                    <td>SuperMax</td>
                                                    <td>Dorado</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/14/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Guanica</td>
                                                    <td>Econo</td>
                                                    <td>Guanica</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/14/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Isabela</td>
                                                    <td>Selectos</td>
                                                    <td>Isabela</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/14/19</td>
                                                    <td>Viernes</td>
                                                    <td>3:00 PM - 7:00 PM</td>
                                                    <td>Los Prados</td>
                                                    <td>Selectos</td>
                                                    <td>Caguas</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Barceloneta</td>
                                                    <td>Econo</td>
                                                    <td>Barceloneta</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Gurabo</td>
                                                    <td>Selectos</td>
                                                    <td>Gurabo</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>2:00 PM - 6:00 PM</td>
                                                    <td>Lajas</td>
                                                    <td>Mr Special</td>
                                                    <td>Lajas</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>9:00 AM - 1:00 PM</td>
                                                    <td>San German</td>
                                                    <td>Econo</td>
                                                    <td>San German</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>San Sebastian</td>
                                                    <td>Selectos</td>
                                                    <td>San Sebastian</td>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>06/15/19</td>
                                                    <td>Sábado</td>
                                                    <td>10:00 AM - 2:00 PM</td>
                                                    <td>Toa Alta</td>
                                                    <td>Econo</td>
                                                    <td>Toa Alta</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <p><strong>ANEXO III CANTIDAD DE CÓDIGOS POR PRODUCTOS PRINGLES®</strong></p>
                                    <p>Deberás registrar el o los códigos únicos requeridos, para validación, de acuerdo con el tipo de premio según la siguiente tabla:</p>
                                    <div class="table-terms-wrp">
                                        <div class="table-wrp">
                                            <table class="period-table globalTable">
                                                <tr>
                                                    <td>Audífonos<br />Sony<br />(On-Ear)
                                                    </td>
                                                    <td>Viaje a Chicago para dos (2) personas
                                                    </td>
                                                    <td>Bocina<br />Bose SoundLink Micro
                                                    </td>
                                                    <td>2 meses de subscripción en <i>Deezer Premium</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>2 Pringles® latas variedad de 5.2 oz y/o
                                                        variedad de 5.5 oz y Pringles Original 2.36 oz y/o Variedad 2.5 oz</td>
                                                    <td>2 productos participantes de cualquier
                                                        gramaje</td>
                                                    <td>3 Pringles® latas variedad 5.2 oz y/o
                                                        variedad de 5.5 oz y Pringles Original 2.36 oz y/o Variedad 2.5 oz</td>
                                                    <td>2 productos Pringles® latas variedad 5.2 oz
                                                        y/o variedad de 5.5 oz</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="not-visible">Términos y Condiciones de la promoción La música cae mejor con Pringles®.</h1>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>