import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Meta from 'vue-meta'
import i18n from '@/utilities/i18n';

import './sass/index.scss'

import formatMonthFilter from '@/filters/format-month'
import formatDayFilter from '@/filters/format-days'
import formatDateFilter from '@/filters/format-date'

import vClickOutside from 'v-click-outside'
import LoadScript from 'vue-plugin-load-script';

Vue.config.productionTip = true

Vue.use(formatMonthFilter)
Vue.use(formatDayFilter)
Vue.use(formatDateFilter)

Vue.use(vClickOutside)

Vue.use(Meta)

Vue.use(LoadScript);
Vue.config.devtools=true





new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
