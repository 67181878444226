<template>
    <div>
        <headers></headers>
        <main class="mainWrp home">
            <section class="homeWrp">
                <div class="bannerCarousel">
                        <swiper :options="swiperOption" ref="swiper" @slideChange="slideChanged" style="background-color: #9c0018;" class="carouselColor">
                            <swiper-slide v-for="(item, index) in banners" :key="index">
                                <template v-if="item.url!=''">
                                    <a :href="blank(item)" :target="item.target">
                                        <picture>
                                            <source media="(max-width: 680px)" :srcset="require(`@/assets/images/global/banners/${item.image.mobile}`)">
                                            <img :src="require(`@/assets/images/global/banners/${item.image.desktop}`)" :alt="item.alt">
                                        </picture>
                                    </a>
                                </template>
                                <template v-else-if="item.name==='MX'">
                                    <picture>
                                        <source media="(max-width: 680px)" :srcset="require(`@/assets/images/global/banners/${item.image.mobile}`)">
                                        <img :src="require(`@/assets/images/global/banners/${item.image.desktop}`)" :alt="item.alt">
                                    </picture>
                                    <div style=" position: absolute; inset: 0; bottom: 0; height: 100%; ">
                                        <iframe width="100%" height="100%" class="responsive-iframe" src="https://www.youtube.com/embed/_wTUsF2utF8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </template>
                                <template v-else-if="item.name==='BR'">
                                    <picture>
                                        <source media="(max-width: 680px)" :srcset="require(`@/assets/images/global/banners/${item.image.mobile}`)">
                                        <img :src="require(`@/assets/images/global/banners/${item.image.desktop}`)" :alt="item.alt">
                                    </picture>
                                    <div style=" position: absolute; inset: 0; bottom: 0; height: 100%; ">
                                        <iframe width="100%" height="100%" class="responsive-iframe" src="https://www.youtube.com/embed/JlZf4P0KT6A" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </template>
                                <template v-else-if="item.name==='Caricam'">
                                    <picture>
                                        <source media="(max-width: 680px)" :srcset="require(`@/assets/images/global/banners/${item.image.mobile}`)">
                                        <img :src="require(`@/assets/images/global/banners/${item.image.desktop}`)" :alt="item.alt">
                                    </picture>
                                    <div style=" position: absolute; inset: 0; bottom: 0; height: 100%; ">
                                        <iframe width="100%" height="100%" class="responsive-iframe" src="https://www.youtube.com/embed/jbAOMBS_nxE" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </template>
                            </swiper-slide>
                            <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div>
                            <div class="swiper-pagination top" slot="pagination"></div>
                        </swiper>
                </div>
                <div class="promoCarouselHome">
                    <swiper class="bucket-wrp" :options="swiperOptionBottom" ref="swiper2">
                        <swiper-slide class="bucket" v-for="(item, index) in banners" :key="index" :class="{'selected': currentItem === index}">

                            <template v-if="item.url!=''">
                                <a :href="blank2(item)" :target="target(item)">
                                    <span class="promoNumber">{{index+1}}</span>
                                    <p class="nameWrp">
                                        <strong>{{item.brand || 'Pringles®'}}</strong><br>
                                        <span class="promoName">{{item.name}}</span>
                                    </p>
                                </a>
                            </template>

                            <template v-else>
                                <span class="promoNumber">{{index+1}}</span>
                                <p class="nameWrp">
                                    <strong>{{item.brand || 'Pringles®'}}</strong><br>
                                    <span class="promoName">{{item.name}}</span>
                                </p>
                            </template>

                        </swiper-slide>
                        <div class="swiper-button-prev-bt" slot="button-prev"></div>
                        <div class="swiper-button-next-bt" slot="button-next"></div>
                        <!--div class="swiper-pagination-bt" slot="pagination"></div-->
                    </swiper>
                    <!--
                    <ul>
                        <li v-for="(item, index) in terms">
                            <a class="promoLink" :href="'../' +country.id.toLowerCase()+ item.url">
                                <span class="promoNumber">{{index+1}}</span>
                                <p class="nameWrp">
                                    <strong>Pringles®</strong>
                                    <span class="promoName">{{item.name}}</span>
                                </p>
                            </a>
                        </li>
                        <li>
                            <a href="" class="promoLink"><span class="promoNumber">3</span>
                                <p class="nameWrp">
                                    <strong>Pringles®</strong>
                                    <span class="promoName">Promoción</span>
                                </p>
                            </a>
                        </li>
                        <li>
                            <a href="" class="promoLink"><span class="promoNumber">4</span>
                                <p class="nameWrp">
                                    <strong>Pringles®</strong>
                                    <span class="promoName">Promoción</span>
                                </p>
                            </a>
                        </li>
                    </ul>
                    -->

                </div>
            </section>
        </main>
        <h1 class="not-visible">{{ $t('promosHomeH1')}} </h1>
        <Footers></Footers>
    </div>
</template>
<script>
    import 'swiper/dist/css/swiper.css';
    import Headers from '@/components/partials/header.vue'
    import Footers from '@/components/partials/footer.vue'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import { mapState } from 'vuex'

    export default {
        data() {
            return {
                currentItem: 0,
                currentBullet: 0,
                urlImg: '',
                banners: [],
                terms: [],
                swiperOption: {
                    slidesPerView: 1,
                    //loop: true,
                    //initialSlide: 0,
                    autoplay: {
                        delay: 2500,
                        disableOnInteraction: true
                    },
                    spaceBetween: 20,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    keyboard: {
                        enabled: true,
                    }
                },
                swiperOptionBottom: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                    pagination: {
                        el: '.swiper-pagination-bt',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-bt',
                        prevEl: '.swiper-button-prev-bt'
                    },
                    keyboard: {
                        enabled: true,
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 3
                        },
                        640: {
                            slidesPerView: 2
                        }
                    }
                }
            }
        },
        computed: {
            swiper() {
                return this.$refs.swiper.$swiper
            },
            ...mapState(['country', 'isAndino', 'isCaricam'])
        },
        mounted() {
            //console.log('Current Swiper instance object', this.$refs.swiper.swiper.activeIndex)

            this.setImages()
            this.$store.dispatch('setBodyClass', 'global')
            //this.aoutclick()
            //setInterval(function () {
            //    console.log("a");
            //        //this.slideChanged('next');
            //    const next_bt = document.getElementsByClassName('swiper-button-next');
            //    next_bt[0].click();
            //    }, 2500);
        },
        components: {
            Headers,
            Footers,
            swiper,
            swiperSlide
        },
        methods: {
            slideChanged() {

                let _index = this.$refs.swiper.swiper.activeIndex;
                if (_index < this.currentItem) {
                    this.moveSliderBottom('prev');
                } else {
                    this.moveSliderBottom('next');
                }
                //this.currentItem=this.$refs.swiper.swiper.activeIndex;
                //this.currentBullet = this.currentItem;
            },
            setImages() {
                this.getAllData()
            },
            getAllData() {

                import(`@/assets/json/countries_data.json`)
                    .then(({ default: countries }) => {

                        let country_id = this.country.id.toLowerCase()

                        countries.forEach((entry) => {

                            if (entry.value === country_id) {
                                this.terms = [...entry.data]
                                this.banners = [...entry.banners]
                            }

                            if (this.banners.length > 1) {

                                const next = document.querySelector('.swiper-button-next')
                                const back = document.querySelector('.swiper-button-prev')

                                next.style.display = "block";
                                back.style.display = "block";
                            }

                        });

                        this.loading = false

                    })
                    .catch(() => {

                        this.terms = []
                        this.loading = false
                    })
            },
            moveSlider(i) {
                const bullet = document.getElementsByClassName('swiper-pagination-bullet')
                bullet[i].click()
                
                this.currentItem = i
                this.currentBullet = this.currentItem
            },
            moveSliderBottom($dir) {
                const prev_bt = document.getElementsByClassName('swiper-button-prev-bt')
                const next_bt = document.getElementsByClassName('swiper-button-next-bt')

                if ($dir === 'prev') {
                    prev_bt[0].click()
                    this.currentBullet--;
                }

                if ($dir === 'next') {
                    next_bt[0].click()
                    this.currentBullet++;
                }

                this.currentItem = this.currentBullet;

                //if (this.currentItem == (this.banners.length - 1)) {
                //    this.currentBullet--;
                //    console.log("a ", this.currentBullet);
                //}
                
            },
            blank(item) {
                //console.log(this.terms)
                let termTarget

                if (item.target === '_self') {
                    termTarget = '../promo/' + this.country.id.toLowerCase() + item.url
                } else if (item.target === '_blank') {
                    termTarget = item.url
                }
                return termTarget
            },
            blank2(item) {
                //console.log(this.terms)
                let termTarget

                if (item.url2) {
                    if (item.target2 === '_self') {
                        termTarget = '../promo/' + this.country.id.toLowerCase() + item.url2
                    } else if (item.target2 === '_blank') {
                        termTarget = item.url2
                    }
                } else {
                    if (item.target === '_self') {
                        termTarget = '../promo/' + this.country.id.toLowerCase() + item.url
                    } else if (item.target === '_blank') {
                        termTarget = item.url
                    }
                }


                return termTarget
            },
            target(item) {
                if (item.target2) {
                    return item.target2
                }
                return item.target
            }

        },
        metaInfo() {
            const country_i = localStorage.getItem('country').toLowerCase()

            if (country_i === 'do' ||
                country_i === 'mx' ||
                country_i === 'co' ||
                country_i === 'ec' ||
                country_i === 'pe' ||
                country_i === 'cr' ||
                country_i === 'sv' ||
                country_i === 'gt' ||
                country_i === 'hn' ||
                country_i === 'ni' ||
                country_i === 'pa' ||
                country_i === 'pr' ||
                country_i === 'ar') {
                return {
                    title: 'Cada momento, cae mejor con Pringles® | Pringles® Internacional',
                    meta: [{
                        name: 'description',
                        content: 'Porque todo cae mejor con Pringles®, encuentra aquí todas sus deliciosas presentaciones, promociones, dinámicas e increíbles premios.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                    ]
                }
            } else if (country_i === 'tt') {
                return {
                    title: 'Each moment is better with Pringles® | Pringles® International',
                    meta: [{
                        name: 'description',
                        content: 'Because everything is better with Pringles®, find here all its delicious presentations, promotions, dynamics and incredible prizes.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                    ]
                }
            }
            else if (country_i === 'br') {
                return {
                    title: 'Todo momento fica melhor com Pringles®. Pringles® Internacional',
                    meta: [{
                        name: 'description',
                        content: 'Já que tudo fica melhor com Pringles®, encontre aqui todas as nossas deliciosas apresentações, promoções, diâmicas e incríveis prêmios.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                    ]
                }
            }
        }
    }
</script>

<style>
    /*.bannerCarousel picture img {
        width: 75%!important;
    }*/
</style>


