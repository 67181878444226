<template>
  <div>
    <Headers></Headers>
    <main class="mainWrp">
      <section class="globalWrp">
        <aside class="titleSectionWrp">
          <router-link
            class="backLink"
            :to="{
              name: 'TermsSelector',
              params: { countryId: country.id.toLowerCase() },
            }"
            >{{ $t("back") }}</router-link
          >
          <h2>{{ $t("legals") }}</h2>
        </aside>
        <main class="one-whole heightScroll">
          <div class="one-whole">
            <div class="headerTerms one-whole">
              <img
                src="@/assets/images/global/banners/arma-la-farra/466x246-arma-la-farra.jpg"
                alt="Promo arma la rumba con Pringles®"
              />
              <div class="titleTermsWrp one-whole">
                <p class="year">2020</p>
                <h4 class="titleTerms">PROMO ARMA LA RUMBA CON PRINGLES®</h4>
                <h5>TÉRMINOS Y CONDICIONES</h5>
              </div>
            </div>
            <!-- Insertar section terminos -->
            <section class="terms">
              <div class="terms-wrp">
                <div class="wrappTerms">
                  <p>
                    Esta actividad aplica para el territorio de la República del
                    Ecuador. La premiación sólo se hará entre residentes en la
                    República de Ecuador, mayores de 18 años.
                  </p>
                  <p>
                    Este reglamento delimita y aclara las condiciones bajo las
                    cuales se regirá la actividad. La participación en la
                    Promoción se interpretará como la aceptación de las Bases,
                    así como del Aviso de Privacidad para el tratamiento de
                    datos personales.
                  </p>
                  <p>
                    Lo aquí dispuesto será de acatamiento obligatorio para
                    participantes y organizador. Se entenderá que todo
                    participante, al decidir participar, conoce y acepta las
                    condiciones y limitaciones establecidas en el presente
                    reglamento. La participación en la actividad implica la
                    decisión del participante de obligarse por estas reglas y
                    por las decisiones del organizador, las cuales tendrán
                    carácter definitivo en todos los asuntos relacionados a la
                    actividad. Cualquier violación a las mismas o a los
                    procedimientos o sistemas establecidos para su realización,
                    implicará la inmediata exclusión del mismo y/o la revocación
                    de los premios.
                  </p>
                  <ul class="bullets">
                    <li>
                      DE LOS REALIZADORES: KELLOGG ECUADOR C. LTDA. ECUAKELLOGG,
                      es la responsable de la Actividad Promocional, y es quien
                      podrá modificar en cualquier momento el contenido del
                      presente documento.
                    </li>
                    <li>
                      DE LOS PARTICIPANTES: Los participantes de la promoción
                      (los &ldquo;Participantes&rdquo;), deberán cumplir con los
                      siguientes requisitos:
                    </li>
                  </ul>
                  <p>
                    Ser ciudadano ecuatoriano o ciudadano extranjero con
                    residencia legal en Ecuador.
                  </p>
                  <p>
                    Ser mayor de edad (18 años cumplidos en adelante) y
                    acreditar una identificación oficial vigente que deberá
                    presentar si es acreedor del Premio.
                  </p>
                  <p>
                    Toda persona que desee participar en la actividad o reclamar
                    un premio deberá tener conocimiento de este reglamento, ya
                    que la aceptación y recibo del premio conlleva la forzosa e
                    ineludible obligación de conocer las condiciones de
                    participación, así como las condiciones, limitaciones y
                    responsabilidades, no sólo de este reglamento, sino las que
                    en virtud de este mismo documento conlleva el reclamo y
                    aceptación de un premio.
                  </p>
                  <p><strong>1. VIGENCIA:</strong></p>
                  <p>La actividad se regirá por los siguientes plazos:</p>
                  <p>
                    1.1 Fecha inicial y final de la actividad:<br />
                    Desde Noviembre 1 de 2020 a las 00:00 hasta Diciembre 31 de
                    2020 11:59 pm.
                  </p>
                  <p><strong>2. MECÁNICA:</strong></p>
                  <p>
                    La promoción estará dirigida a consumidores de productos
                    Pringles® quienes por la compra de cualquier producto de
                    cualquier gramaje de la marca Pringles® (los &ldquo;<strong
                      >Productos Participantes</strong
                    >&rdquo;) tendrán la oportunidad de participar para ganar
                    cualquiera de los premios de la promoción. Los pasos a
                    seguir para poder participar, son los siguientes:
                  </p>
                  <p>
                    2.1. El Participante deberá comprar cualquier Producto
                    Participante durante la vigencia de la promoción.
                  </p>
                  <p>
                    2.2.  El participante deberá enviar al  Whatsapp 0981584531
                    una foto legible de cada una de las facturas legales con
                    número de correlativo y/o número de identificación
                    tributaria del establecimiento, donde se muestre la compra
                    de los Productos.
                  </p>
                  <p>
                    2.4 Cada unidad de un producto Pringles® presente en la
                    factura es equivalente a un punto, aplica cualquier
                    presentación. <em>Un (1)  producto = Un (1)  punto. </em>
                  </p>
                  <p>
                    <em>2.5 </em>La identificación del usuario será su número de
                    celular, si este cambia de número de contacto debe
                    notificarlo para poder trasladar los respectivos puntos.
                  </p>
                  <p>
                    2.6. Los participantes deberán guardar los empaques con los
                    que participen con el fin de poder acceder a ganar cualquier
                    premio de la promoción. Los empaques deben estar completos y
                    en buen estado y no podrán tener ninguna información borrada
                    o modificada. El Organizador podrá revisar que cada producto
                    registrado en las facturas corresponda físicamente a UN (1)
                    Producto Participante.
                  </p>
                  <p>
                    2.7. Para estos efectos, los 200 Participantes que más
                    puntos registren durante el periodo de la vigencia de la
                    Promoción, podrán ganar uno de los 200 premios.
                  </p>
                  <p>
                    2.8. Mientras más empaques diferentes registre el
                    Participante, más puntos acumula y más oportunidades de
                    ganar tendrá. Cada factura podrá ser registrada en una única
                    ocasión. Las facturas registradas en dos o más ocasiones o
                    el registro facturas inexistentes o cuyo empaque no se tenga
                    guardado como soporte tendrá como consecuencia la
                    descalificación de la Promoción. Los puntos de cada
                    participante serán acumulables y le permitirán ganar
                    posiciones en el ranking de premiación.
                  </p>
                  <p>
                    2.9. En caso de empate en la cantidad de puntos registrados,
                    los ganadores serán los participantes que hayan registrado
                    primero la totalidad de los puntos, considerando día, hora y
                    minuto.
                  </p>
                  <p>
                    2.10. Cualquier inquietud de los participantes debe ser
                    tratada por la línea de servicio al cliente nacional 01 800
                    0000 22.
                  </p>
                  <p>
                    2.11. Sólo podrán participar los consumidores que cumplan
                    con los requisitos mencionados en el numeral quinto del
                    presente documento.
                  </p>
                  <p>
                    La lista de posibles ganadores se publicará en la Página
                    <a href="http://www.promopringles.com" target="_blank"
                      >www.promopringles.com</a
                    >
                    y la lista se mantendrá publicada una vez finalizado el
                    término de vigencia de la promoción y se haya podido
                    comprobar que los posibles ganadores cumplieron con la
                    totalidad de los requisitos de la promoción. La lista de
                    posibles ganadores se mantendrá publicada hasta el 1 de
                    junio de 2021.
                  </p>
                  <p><strong>3. PREMIOS</strong></p>
                  <p>
                    Los participantes podrán participar por cualquiera de los
                    siguientes premios (los
                    &ldquo;<strong>Premios</strong>&rdquo;):
                  </p>
                  <p>
                    Premio Mayor: 10 (diez) Parlantes Bose SoundLink Revolve
                  </p>
                  <p>
                    Premios Secundarios: 20 (veinte) Parlantes Bose Sound Link
                    Color II
                  </p>
                  <p>
                    Membresías Spotify: Se entregarán a un total de ciento
                    setenta (170) membresías de Spotify Premium.
                  </p>
                  <p>
                    Los Premios se entregarán de conformidad con la tabla que se
                    señala a continuación:
                  </p>
                  <div class="globalTableWrp">
                    <table
                      border="0"
                      cellpadding="0"
                      cellspacing="0"
                      class="globalTable"
                    >
                      <tr>
                        <th># de Ganadores</th>
                        <th>Premio</th>
                        <th>Requisito</th>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>1 Parlante Bose SoundLink Revolve</td>
                        <td>
                          Posición 1 al 10 en el ranking de los 200
                          participantes con el mayor número de códigos
                          registrados.
                        </td>
                      </tr>
                      <tr>
                        <td>20</td>
                        <td><p>1 Parlante Bose Sound Link Color II</p></td>
                        <td>
                          Posición 11 al 30 en el ranking de los 200
                          participantes con el mayor número de códigos
                          registrados.
                        </td>
                      </tr>
                      <tr>
                        <td>170</td>
                        <td>1 Membresía Spotify Premium</td>
                        <td>
                          Posición 31 al 200 en el ranking de los 200
                          participantes con el mayor número de códigos
                          registrados.
                        </td>
                      </tr>
                    </table>
                  </div>
                  <p>
                    Para efectos de la entrega de los Premios, los participantes
                    deberán tener en cuenta lo siguiente:<br />
                    Si un posible ganador de cualquiera de los premios no cumple
                    con las obligaciones y condiciones previstas en el presente
                    documento para hacer efectivo el premio, será desestimado y
                    se le asignará el Premio correspondiente a la siguiente
                    persona en la lista de posibles ganadores.
                  </p>
                  <p>
                    Kellogg podrá descalificar al participante que incurra en
                    conductas fraudulentas, irregulares, contrarias a la ley o
                    contrarias a estos términos y condiciones. El Organizador
                    cuenta con todas las facultades para estudiar y analizar
                    cualquier tipo de conducta fraudulenta siendo el organizador
                    la última instancia de revisión y verificación habilitada
                    para estos efectos.
                  </p>
                  <p>
                    Al participar en la promoción, los Participantes manifiestan
                    su aceptación a los presentes términos y condiciones.
                  </p>
                  <p><strong>4. GANADORES:</strong></p>
                  <p>Son 200 ganadores a nivel nacional.</p>
                  <p>
                    <strong
                      >5. SELECCIÓN DE GANADORES Y PROCEDIMIENTO PARA LA ENTREGA
                      DE LOS PREMIOS:</strong
                    >
                  </p>
                  <p>
                    Al momento en que los Participantes envíen sus facturas al
                    Whatsapp 0981584531 ,estos quedarán registrados como
                    participantes en el concurso para ganar cualquiera de los
                    Premios.
                  </p>
                  <p>
                    Una vez se determinen los posibles ganadores de los premios
                    mayores, premios segundarios y membresías Spotify se
                    procederá a llamar a los Participantes al número telefónico
                    registrado.
                  </p>
                  <p>
                    Al contestar la llamada siempre y cuando el Participante no
                    incurra en ningún supuesto de descalificación de conformidad
                    con los presentes términos y condiciones, el Participante se
                    convertirá en ganador de uno de los premios establecidos.
                  </p>
                  <p>
                    En caso de que el Participante no conteste las llamadas en
                    los siguientes 3 días, se entenderá que el Participante ha
                    renunciado al premio y se contactará a la siguiente persona
                    en la lista de posibles ganadores.
                  </p>
                  <p>
                    Las instrucciones para reclamar los premios se darán al
                    ganador al momento de contactarlo. Una vez se les notifique
                    a los participantes que han sido seleccionados como los
                    ganadores, y estos manifiesten su intención de aceptar el
                    premio, éstos tendrán 24 horas siguiente a la llamada para
                    entregar la documentación requerida incluyendo las pruebas
                    de posesión de los empaques con los respectivos lotes
                    registrados, de lo contrario perderá(n) el premio de manera
                    automática.<br />
                    Para efectos de la entrega de los premios mayores y Premios
                    Secundarios, los Participantes declaran entender que:
                  </p>
                  <p>
                    Los premios mayores y premios secundarios serán entregados
                    por el proveedor contratado para tal fin.
                  </p>
                  <p>
                    Kellogg entregará las membresías Spotify Premium por medio
                    del Whatsapp 0981584531. <br />
                    Ningún premio es reembolsable en dinero, ni canjeable y
                    serán entregados únicamente al ganador seleccionado.
                  </p>
                  <p>
                    Para poder recibir los incentivos, en su caso el
                    participante deberá exhibir original o enviar una copia de
                    identificación oficial con fotografía, a fin de acreditar su
                    identidad y firmar la totalidad de documentos que la
                    Organizadora le solicite en su caso, al entregar los
                    incentivos.
                  </p>
                  <p>
                    La falta de veracidad o errores en la información que impida
                    o no permita identificar al participante, libera de
                    cualquier responsabilidad a la Organizadora y le otorga la
                    facultad de disponer de los incentivos respectivos para
                    reintegrarlos al inventario disponible de incentivos a
                    beneficio del resto de participantes.
                  </p>
                  <p><strong>6. DESCALIFICACIÓN</strong></p>
                  <p>
                    Si en cualquier momento, sea antes, durante o con
                    posterioridad a esta actividad, se descubre que el ganador
                    incumple con este reglamento, o se sospecha que realizó
                    algún tipo de fraude, el organizador podrá descalificarlo y
                    negarse a entregarle el premio. Si éste ya fue entregado,
                    podrá exigir su devolución por las vías de Ley.
                  </p>
                  <p>
                    Estará prohibido y será anulado cualquier intento o método
                    de participación en la actividad que se realice por
                    cualquier proceso, técnica o mecánica de participación
                    distinta a la detallada en este reglamento.
                  </p>
                  <p>
                    En el evento que Kellogg conozca que un participante ha
                    incumplido con lo aquí establecido, será descalificado de la
                    actividad.
                  </p>
                  <p>Condiciones y restricciones de la Actividad:</p>
                  <p>
                    La participación de los interesados, así como la actividad y
                    los premios, están sujetos a las condiciones y restricciones
                    que se indican en este reglamento.
                  </p>
                  <p>
                    La responsabilidad de Kellogg de Ecuador, culmina con la
                    entrega del premio.<br />
                    Los ganadores relevan de toda responsabilidad a los
                    organizadores de cualquier daño, sobre las personas o las
                    cosas, que se pudiesen ocasionar durante el uso del premio,
                    ya sea por su uso o de terceras personas.<br />
                    No se admiten cambios por dinero, valores o cualquier otro
                    producto material. Los premios son intransferibles.
                  </p>
                  <p>
                    Si el ganador no acepta el premio o sus condiciones, el
                    premio se considera renunciado y extinguido en relación al
                    ganador y no tendrá derecho a reclamo o indemnización
                    alguna, ni siquiera parcialmente.
                  </p>
                  <p>
                    *NO PODRÁ PARTICIPAR EN LA ACTIVIDAD NINGUNA PERSONA QUE
                    ACTUALMENTE SEA TRABAJADOR DEPENDIENTE DEL ORGANIZADOR.
                    TAMPOCO PUEDEN PARTICIPAR SUS FAMILIARES HASTA SEGUNDO GRADO
                    DE CONSANGUINIDAD O DE AFINIDAD, ASÍ COMO CÓNYUGES O
                    CONVIVIENTES POR UNIÓN DE HECHO. APLICA TAMBIÉN PARA EL
                    PERSONAL DE LAS AGENCIAS QUE TRABAJAN PARA KELLOGG ECUADOR.
                  </p>
                  <p>Derechos de imagen:</p>
                  <p>
                    Con el hecho de participar en la actividad, los ganadores
                    aceptan y autorizan que sus nombres e imágenes aparezcan en
                    los programas, publicaciones y demás medios publicitarios y
                    en general en todo material de divulgación con fines
                    promocionales que los organizadores deseen hacer durante la
                    actividad o una vez finalizada la misma, sin que ello
                    implique la obligación de remunerarlos o compensarlos. Así
                    mismo, renuncian a cualquier reclamo por derechos de imagen.
                  </p>
                  <p>
                    Los participantes autorizan expresamente a Kellogg Ecuador.,
                    el derecho a usar, reproducir, distribuir, modificar, crear,
                    poner a disposición, almacenar, grabar, publicar, por cable
                    u otro medio (incluyendo internet y cualquier otro medio),
                    todo lo anterior incluyendo finalidad comercial y/o
                    promocional, sin limitación de territorio y tiempo las
                    piezas que usen para participar en la actividad promocional.
                  </p>
                  <p>Manejo de datos y registro:</p>
                  <p>
                    El responsable de recabar la información consistente en
                    Datos Personales de los consumidores participantes de esta
                    promoción es La Organizadora de la misma.
                  </p>
                  <p>
                    La finalidad de recabar los Datos Personales de los
                    consumidores participantes de esta promoción es únicamente
                    la de su individualización e identificación respecto del
                    resto de personas que forman parte de la misma, con el
                    objeto de hacer entrega de los incentivos ofrecidos en caso
                    de tener derecho en tal supuesto. Por la naturaleza de los
                    Datos Personales recabados no se efectuará ninguna
                    transferencia a tercero, sin perjuicio de lo cual el
                    participante autoriza expresamente a la Organizadora a
                    publicar su nombre completo en el listado de ganadores si se
                    encuentra en los 200 mejores puntuados conforme el presente
                    reglamento.
                  </p>
                  <p>
                    Cualquier cambio del presente Aviso de Privacidad será
                    comunicado por el sitio www.kellogg.com.co la información
                    consistente en Datos Personales proporcionada por los
                    participantes, será eliminada una vez asignada la totalidad
                    de incentivos ofrecidos y reclamados en su totalidad por
                    quienes tengan derecho a recibirlos, salvo aquellos que por
                    obligaciones de índole fiscal o de protección al consumidor
                    deban conservarse.
                  </p>
                  <p>
                    El uso o divulgación de los Datos Personales de los
                    consumidores participantes de la promoción, se encuentran
                    restringidos respecto de los demás participantes y están
                    protegidos en su divulgación para ser compartidos con
                    empresas filiales y colaboradoras de Kellogg, con fines
                    exclusivos de realizar la Promoción. Cualquier acceso,
                    rectificación, cancelación u oposición relacionada con la
                    información personal de los Participantes la podrán realizar
                    enviando una solicitud por correo electrónico al
                    departamento de Atención al Consumidor
                    consumer-affairs.la@kellogg.com o llamando al teléfono 01
                    800 00 KELLOGG (5355644).
                  </p>
                  <p>
                    Cualquier cambio del presente Aviso de Privacidad será
                    comunicado por el sitio www.promopringles.com, la
                    información consistente en Datos Personales proporcionada
                    por los participantes, será eliminada una vez asignada la
                    totalidad de incentivos ofrecidos y reclamados en su
                    totalidad por quienes tengan derecho a recibirlos, salvo
                    aquellos que por obligaciones de índole fiscal o de
                    protección al consumidor deban conservarse.
                  </p>
                  <p>Suspensión:</p>
                  <p>
                    En caso de haber motivos fundados de fuerza mayor, caso
                    fortuito, o hechos de terceros, tales como desastres
                    naturales, guerras, huelgas o disturbios, así como también
                    situaciones que afecten la actividad; o en caso de
                    detectarse un fraude o intento de fraude en perjuicio de los
                    organizadores o los participantes de la misma, Kellogg
                    Ecuador podrá modificar en todo o en parte esta actividad,
                    así como suspenderla temporal o permanentemente sin asumir
                    ninguna responsabilidad al respecto. En estos casos, el
                    fundamento de las medidas que se adopten, así como las
                    pruebas que demuestren la existencia de la causa invocada
                    por los Organizadores estarán a disposición de cualquier
                    interesado.
                  </p>
                  <p>Publicación:</p>
                  <p>
                    Este Reglamento de la actividad estará publicado en la
                    Página
                    <a href="http://www.promopringles.com" target="_blank"
                      >www.promopringles.com</a
                    >
                    para que pueda ser consultado por todos los participantes
                    que así lo deseen.
                  </p>
                  <p>
                    Se da por entendido que una vez los participantes inicien la
                    mecánica de la actividad es porque leyó, entendió y acepto
                    los términos y condiciones de la misma.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </main>
      </section>
    </main>
    <Footers></Footers>
  </div>
</template>
<script>
import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footers,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("setBodyClass", "global");
  },
  metaInfo() {
    return {
      title: 'Arma la Farra con Pringles® | Términos y Condiciones',
      meta: [
        {
          name: 'description',
          content: 'Consulta los Términos y Condiciones de la promoción Arma la Farra con Pringles®.'
        }
      ]
    }
  },
};
</script>