import $ from 'jquery';
class MyAccountRif{

    constructor(container, configObj) {
        
        //this.loggedInCallback = loggedInCallback;
        this.onProfileSave = this.onProfileSave.bind(this);
        this.onLogout = this.onLogout.bind(this);
        this.container = container;
        this.configObj = $.extend(true, this.getBaseConfigObj(), configObj);
        this.getCredentials = this.getCredentials();
        this.init();
    }

    getBaseConfigObj(configObj) {

        return {
            environment: this.getEnvironment(),
            onProfileSave: this.onProfileSave,
            credentials: (function(scp) {
            // return an object containing the following authenticated user properties:
            // UsernameForServiceAuth, ProfileID, and AccessToken
            // your site received these via the onAuth/onExit callbacks, or the exitUrl
            // retrieve the values from session, cookies, or wherever you're storing them
            // for example (assumes you have a getCookie() method):
            return {
                UsernameForServiceAuth: scp.getCookie('UsernameForServiceAuth'),
                ProfileID: scp.getCookie('ProfileID'),
                AccessToken: scp.getCookie('AccessToken')
            };
        }) (this),
            onLogout: this.onLogout,
        };
    }

    init() {
        console.log(this.configObj);
        this.loadMyAccountScript();
    }

    getEnvironment() {
        switch (location.host) {
            case 'www.promopringles.com':
                return 'prod';
            default:
                return 'stage';
        }
    }

    loadMyAccountScript() {
        (function(scp) {
            var src, kreg, s;

            /*
            if (scp.configObj.environment === 'stage') {
                src = 'https://stage.registrationv1.kglobalservices.com/scripts/myaccount.js';
            } else {
                src = 'https://registrationv1.kglobalservices.com/scripts/myaccount.js';
            }*/

            if (scp.configObj.environment === 'stage') {
                src = 'https://stage.registrationv1.kglobalservices.com/scripts/myaccount.js';
            } else {
                src = 'https://www.registrationv1.kglobalservices.com/scripts/myaccount.js';
            }

            //https://stage.registrationv1.kglobalservices.com/scripts/myaccountv2.js

            kreg = document.createElement('script');
            kreg.type = 'text/javascript';
            kreg.onload = kreg.onreadystatechange = function() {
                if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                    kreg.onload = kreg.onreadystatechange = null;
                    scp.initMyAccount();
                    console.log("4.1");
                    //var links=document.getElementsByTagName("link");
                    /*for(var i=0; i<links.length; i++){
                        console.log(links[i].href);
                        if(links[i].href.indexOf('myaccount.css')>0){
                            links[i].href="";
                            console.log("entra");
                        }else{
                            document.innerHTML=links[i];
                        }
                    }*/
                    
                    //Se vuelve a cargar el css de la página
                    var links=document.getElementsByTagName("link");
                    links[links.length-1].href=links[links.length-3].href;
                }
            };
            kreg.src = src;
            s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(kreg, s);
        }) (this);
    }

    initMyAccount() {

        console.log('initMyAccount')
        // initialize the myaccount v6 module
        var myAccountModule = KSTL.MyAccount.init(this.container, this.configObj);

        // subscribe to module event notifications
        var eventViewRender = myAccountModule.bind('view:render', function(topic, view) {
            console.log('myaccountv6 view:render', view.name, view.element);
            // if you wish to unsubscribe
            // myAccountModule.unbind(eventViewRender);
        });

        var eventFormSubmit = myAccountModule.bind('form:submit', function(topic, form) {
            console.log('myaccountv6 form:submit', form.name);
            // if you wish to unsubscribe
            // myAccountModule.unbind(eventFormSubmit);
        });
    }

    getCredentials() {
        // return an object containing the following authenticated user properties:
        // UsernameForServiceAuth, ProfileID, and AccessToken
        // your site received these via the onAuth/onExit callbacks, or the exitUrl
        // retrieve the values from session, cookies, or wherever you're storing them
        // for example (assumes you have a getCookie() method):
        console.log('credentials');
        return {
            UsernameForServiceAuth: this.getCookie('UsernameForServiceAuth'),
            ProfileID: this.getCookie('ProfileID'),
            AccessToken: this.getCookie('AccessToken')
        };
    }

    onProfileSave(authUser){
        // the user may have updated her email address (username)
        // so you'll need to update your stored copy of authenticated user data
        // for example (assumes you have a setCookie() method):
        this.setCookie('UsernameForServiceAuth', authUser.UsernameForServiceAuth);
    }

    onLogout() {
        // this will be called if user clicks Log Out or Delete My Account
        // need to delete authenticated user data and redirect
        // for example (assumes you have a deleteCookie() method):
        this.deleteCookie('UsernameForServiceAuth');
        this.deleteCookie('ProfileID');
        this.deleteCookie('AccessToken');
        location.reload();
    }

    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }

    setCookie(cname, cvalue) {
        var d = new Date(), exdays=1;
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    deleteCookie(cname){
        document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  
}

export default MyAccountRif;
