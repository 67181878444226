import { polyfill } from 'es6-promise';
polyfill();
import axios from 'axios'


export default function (url, dataValue = {}, method = 'GET', formData = false) {
    let headers = {}
    
    if (formData) {
        headers = { ...headers, 'Content-Type': 'multipart/form-data' }
    }

    return axios({ method, url: `${url}${setQueryString(dataValue, method)}`, data: dataValue, headers })
    .then((res) => {
        let { data } = res
        //console.log(data)
        return Promise.resolve(data)
    })

    function setQueryString(data, method) {
        if (method === 'GET') {
            let queryString = '';
            let firstKeyProp = Object.keys(data)[0];

            for (let prop in data) {
                queryString += `${(prop === firstKeyProp) ? '?' : '&'}${prop}=${encodeURIComponent(data[prop])}`
            }

            return queryString
        }

        return ''
    }
}