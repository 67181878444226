<template>
    <div>
        <headers></headers>
        <div v-if="this.getCookie('AccessToken')===''">
            <div id="rifFormWrp" >
            </div>
        </div>

        <div v-else-if="this.getCookie('AccessToken')!==''">
            <div id="myaccount-v6">
            </div>
        </div>

        <Footers></Footers>
    </div>
</template>
<script>
import axios from 'axios';
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import Login from '@/rif/Login.js';
import MyAccount from '@/rif/myAccount.js';
import $ from 'jquery';
//import categoria from '@/components/categoria.vue'
//import Meta from 'vue-meta'
//import { mapState } from 'vuex'

export default {
    data() {
        return {
            cntry:'',
            title:'',
            myAccount:null,
            login:null,
            logged:false,
            cntry:'',
        }
    },
    mounted() {
        this.title='Mi cuenta | Pringles® Internacional'      
        this.$store.dispatch('setBodyClass', 'global')
        this.cntry=this.$store.state.country.id.toLowerCase();

        if(this.getCookie('AccessToken')===''){
            this.login=new Login(this.cntry, this.setLogged);
        }else{
            this.logged=true;
            this.myAccount=new MyAccount(this.cntry);
        }
        console.log('Country');
        console.log(this.cntry);
    },
    computed: {
        
    },
    methods: {
        getCookie(cname) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent(document.cookie);
            var ca = decodedCookie.split(';');
              for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                  c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                  return c.substring(name.length, c.length);
                }
              }
              return "";
        },
        deleteCookie(cname){
            document.cookie = cname+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        },
        closeSession(){
            this.deleteCookie('UsernameForServiceAuth');
            this.deleteCookie('ProfileID');
            this.deleteCookie('AccessToken');
            this.logged=false;
            location.reload();
        },
        setLogged(data){
            //alert("entra");
            this.logged=true;
            //location.reload();
            location.href=location.href.split('#')[0];
        }
    },

    components: {
        Headers,
        Footers
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [{
                        name: 'description',
                        content: 'Mi cuenta Pringles®'
                    },
                    {
                        name: 'keyword',
                        content: 'Mi cuenta, Pringles®'
                    },
                ]
            }
    }
   
}
</script>
