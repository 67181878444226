import $ from 'jquery';
import KellobyteMyAccount from './myAccountRif.js';
//import "../../sass/kb.register.scss";

class MyAccountController {
    constructor(country) {
    	//$('.myAccountWrp').attr('id', 'myaccount-v6');
        //const myAccountCont = document.getElementsByClassName('myAccountWrp')[0];
        const myAccountCont = document.getElementById('myaccount-v6');
        let rifConfigObj, rifMyAccount;
        if(country==='br'){
            rifConfigObj = {
                moduleKey: '99095f32-3852-4f22-a4cb-e80b27c76632',
                showLogout: false
            };
        }else if(country==='ar'){
            rifConfigObj = {
                moduleKey: '6465b8dd-ccf8-4e70-a8fb-207e0dcea27b',
                showLogout: false
            };
        }else if(country==='mx'){
            rifConfigObj = {
                moduleKey: 'b82de0e4-8b3c-49e3-b18c-67505dfa8305',
                showLogout: false
            };
        }else if(country==='do'){
            rifConfigObj = {
                moduleKey: 'ff514964-1b24-4a35-8c99-9ef680e85235',
                showLogout: false
            };
        }else if(country==='hn'){
            rifConfigObj = {
                moduleKey: '354826a9-b577-4516-b6ba-f01403a638aa',
                showLogout: false
            };
        }else if(country==='ni'){
            rifConfigObj = {
                moduleKey: '0a66be31-82b4-4bb4-aa16-1f66233b8f8c',
                showLogout: false
            };
        }else if(country==='pr'){
            rifConfigObj = {
                moduleKey: '4a890df5-d4ca-4a1a-9a81-5c53fe3ca690',
                showLogout: false
            };
        }else if(country==='gt'){
            rifConfigObj = {
                moduleKey: 'b55cf806-a993-4fbc-89fc-249dfe84685d',
                showLogout: false
            };
        }else if(country==='sv'){
            rifConfigObj = {
                moduleKey: '6cb25158-b23f-4718-9ff1-bb1eb1f7ab56',
                showLogout: false
            };
        }else if(country==='cr'){
            rifConfigObj = {
                moduleKey: 'e11fa90d-bac4-4fd9-a444-010255aac554',
                showLogout: false
            };
        }else if(country==='pa'){
            rifConfigObj = {
                moduleKey: '3a04851c-2a4c-4613-92b8-67dd8a7d0b3f',
                showLogout: false
            };
        }else if(country==='co'){
            rifConfigObj = {
                moduleKey: '2e3ec1f6-5e41-4ae7-b53e-ab92176e250d',
                showLogout: false
            };
        }else if(country==='ec'){
            rifConfigObj = {
                moduleKey: '8946c745-7eeb-42b9-a28c-ff6cd9c5c60b',
                showLogout: false
            };
        }else if(country==='pe'){
            rifConfigObj = {
                moduleKey: '7b9c6601-50a4-4128-b083-b8c8ffebf1df',
                showLogout: false
            };
        }else{
            rifConfigObj = {
                moduleKey: 'b82de0e4-8b3c-49e3-b18c-67505dfa8305',
                showLogout: false
            };
            
        }
        rifMyAccount = new KellobyteMyAccount(myAccountCont, rifConfigObj);
    }
}

export default MyAccountController;