<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>
                        Legales de
                        <br />promociones
                        <br />anteriores
                    </h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms.jpg" alt />
                            <div class="titleTermsWrp one-whole">
                                <ul class="tabsWrpBr">
                                    <li>
                                        <button @click.prevent="setTermsActive(0)" :class="{'selected': activeTerm === 0}">Regulamento Concurso</button>
                                    </li>
                                    <li>
                                        <button @click.prevent="setTermsActive(1)" :class="{'selected': activeTerm === 1}">Regulamento Sorteio</button>
                                    </li>
                                </ul>
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMOÇÃO NATAL COM PRINGLES</h4>
                                <h5>DISTRIBUIÇÃO GRATUITA</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <RegulamentoConcurso v-if="activeTerm === 0" :class="{'active': activeTerm === 0}"></RegulamentoConcurso>
                        <RegulamentoSorteio v-if="activeTerm === 1" :class="{'active': activeTerm === 1}"></RegulamentoSorteio>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import RegulamentoConcurso from '@/components/global/xmas/RegulamentoConcurso'
import RegulamentoSorteio from '@/components/global/xmas/RegulamentoSorteio'
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'

export default {
    data() {
        return {
            activeTerm: 0
        };
    },
    methods: {
        setTermsActive($index) {
            this.activeTerm = $index;
        }
    },
    components: {
        RegulamentoConcurso,
        RegulamentoSorteio,
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoção Pringles® | Regulamento da promoção'
        }
    },
}
</script>