<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link :to="{name: 'TermsSelectorBR'}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms-aniversary.jpg"  alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMOÇÃO PRINGLES 50 ANOS</h4>
                                <h5>REGULAMENTO “DISTRIBUIÇÃO GRATUITA”</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                             
                                <h4>1.  EMPRESA PROMOTORA</h4>

                                <table class="globalTable" >
                                    <tr>
                                        <td>EMPRESA MANDATÁRIA Razão social</td>
                                        <td>Nome fantasia</td>
                                    </tr>
                                    <tr>
                                        <td>PARATI INDÚSTRIA E COMÉRCIO DE ALIMENTOS LTDA</td>
                                        <td>PARATI</td>
                                    </tr>
                                    <tr>
                                      <td>Endereço – Bairro</td>
                                      <td>Cidade/UF</td>
                                    </tr>
                                    <tr>
                                      <td>Rua Tiradentes, nº 475</td>
                                      <td>São Lourenço do Oeste / SC</td>
                                    </tr>
                                    <tr>
                                        <td>CNPJ</td>
                                        <td>CEP</td>
                                     </tr>
                                    <tr>   
                                        <td>82.945.932/0001-71</td>
                                        <td>89990-000</td>
                                    </tr>
                                </table>

                                <table class="globalTable" >
                                    <tr>
                                        <td>EMPRESA MANDATÁRIA Razão social</td>
                                        <td>Nome fantasia</td>
                                    </tr>
                                    <tr>
                                        <td> GLOBALBEV BEBIDAS E ALIMENTOS S.A.</td>
                                        <td> GLOBALBEV</td>
                                    </tr>
                                    <tr>
                                      <td>Endereço – Bairro</td>
                                      <td>Cidade/UF</td>
                                    </tr>
                                    <tr>
                                      <td>Av. Nossa Senhora do Carmo, 900</td>
                                      <td> Av. Nossa Senhora do Carmo, 900 Belo Horizonte/MG</td>
                                    </tr>
                                    <tr>
                                        <td>CNPJ</td>
                                        <td>CEP</td>
                                     </tr>
                                    <tr>   
                                        <td>04.175.027/0001-76</td>
                                        <td> 30330-000</td>
                                    </tr>
                                </table>
                                
                                <h4>2.  NOME DA PROMOÇÃO</h4>
                                <p>PRINGLES 50 ANOS.</p>
                                <h4>3.  MODALIDADE PROMOCIONAL A SER UTILIZADA</h4>
                                <p>Assemelhada a Vale-Brinde, com premiação mediante Certificado de Barra de Ouro.  </p>
                                <h4>4.  ÁREA DE EXECUÇÃO DA PROMOÇÃO</h4>
                                <p>Todo território nacional.</p>
                                <h4>5.  PRAZO DE EXECUÇÃO DA PROMOÇÃO</h4>
                                <p>61 (sessenta e um) dias.</p>
                                <h4>6.  DATA DE INÍCIO E DE TÉRMINO DA PROMOÇÃO</h4>
                                <p>Início em 01 de agosto de 2018 e término em 30 de setembro de 2018.</p>
                                <h4>7.  PERÍODO DE PARTICIPAÇÃO </h4>
                                <p>O período de participação na Promoção se inicia às 00h00 do dia 01 de agosto de 2018 e se estende até às 23h59 do dia 30 de setembro de 2018, de acordo com horário oficial de Brasília, podendo o seu término ocorrer em data anterior caso os brindes disponibilizados para a Promoção venham a se esgotar, por terem sido distribuídos antes do término previsto. </p>
                                <h4>8.  PARTICIPANTES</h4>
                                <p>Podem participar desta Promoção pessoas físicas, residentes e domiciliadas na área de execução da Promoção e que cumpram as disposições deste Regulamento.</p>
                                <p>As pessoas menores de 18 (dezoito) anos podem participar, desde que representadas legalmente por seus pais ou responsáveis legais.</p>
                                <p>Não podem participar: pessoas jurídicas; funcionários das Promotoras diretamente envolvidos com a criação, produção e execução dessa Promoção; funcionários das agências de propaganda e promoção diretamente envolvidos com a criação, produção e execução dessa Promoção e/ou quaisquer outras pessoas de empresas envolvidas diretamente na presente Promoção. </p>
                                <h4>9.  OBJETIVO DA PROMOÇÃO E PRODUTOS PARTICIPANTES</h4>
                                <p>A Promoção tem o objetivo divulgar a marca das Promotoras e seus produtos, bem como incentivar o consumo dos Produtos Participantes.</p>
                                <table class="globalTable">
                                    <tr><th>PRODUTOS</th></tr>
                                    <tr><td>Pringles Tortilla Pimenta Picante 180g</td></tr>
                                    <tr><td>Pringles Original 121g, 70g e 37g</td></tr>
                                    <tr><td>Pringles Creme e Cebola 128g, 70g e 40g</td></tr>
                                    <tr><td>Pringles Salty & Vinegar 70g</td></tr>
                                    <tr><td>Pringles Queijo 128g</td></tr>
                                    <tr><td>Pringles Barbecue 128g</td></tr>
                                    <tr><td>Pringles Tortilla Original 180g</td></tr>
                                    <tr><td>Pringles Tortilla Creme e cebola 180g</td></tr>
                                </table>
                                <p>Todos os produtos valem igualmente para participação na Promoção. </p>
                                <h4>10. INDICAÇÃO DA QUANTIDADE, DESCRIÇÃO, VALOR UNITÁRIO E TOTAL DOS PRÊMIOS</h4>
                                <p>Serão distribuídos pela Promoção 1.200 (mil e duzentos) brindes, conforme discriminado a seguir: </p>
                                <table class="border-table globalTable">
                                    <tr>
                                        <th>Descrição do prêmio</th>
                                        <th>Quantidade de prêmios</th>
                                        <th>Valor unitário (R$)*</th>
                                    </tr>
                                    <tr>
                                        <td>1 (um) certificado de barra de ouro no valor de R$ 50,00 (cinquenta reais)</td>
                                        <td>1.000 (mil)</td>
                                        <td>R$ 50,00</td>
                                    </tr>
                                    <tr>
                                        <td>1 (um) certificado de barra de ouro no valor de R$ 500,00 (quinhentos reais)</td>
                                        <td>200 (duzentos)</td>
                                        <td>R$ 500,00</td>
                                    </tr>
                                    <tr>
                                        <td> VALOR TOTAL DO PRÊMIO (R$)</td>
                                        <td colspan="2">R$ 150.000,00 (cento e cinquenta mil reais)</td>
                                    </tr>
                                </table>
                                <p>A propriedade do prêmio será comprovada pelas Promotoras à Representação Nacional de Promoções Comerciais da Caixa Econômica Federal (REPCO), conforme o artigo 34, inciso II da Portaria 41/2008 do Ministério da Fazenda.</p>
                                 <h4>11. DESCRIÇÃO DETALHADA DA OPERAÇÃO</h4>
                                 <p>Durante o período de participação desta Promoção, o interessado em participar deve adquirir um ou mais produtos participantes, acessar o site da Promoção www.promopringles.com, aceitar o regulamento e cadastrar, junto com suas informações pessoais de contato, o cupom fiscal de compra do produto ou dos produtos participantes, informando número do cupom fiscal e número do CNPJ do estabelecimento de compra do ou dos produtos participantes atrelados ao cupom fiscal cadastrado, guardando sua embalagem e o próprio cupom fiscal para comprovação da compra.</p>
                                 <p>Ao cadastrar o cupom fiscal válido, o participante pode ser contemplado, instantaneamente, com 1 (um) dos prêmios dessa Promoção, sendo que será informado que foi premiado em mensagem exibida no site. </p>
                                 <p>O participante deverá informar quantos produtos participantes constam no cupom fiscal no momento do cadastro, sendo certo que cada produto informado será considerado como uma inscrição para fins de contemplação. O participante poderá cadastrar até 18 (dezoito) produtos participantes.</p>
                                 <p>Se um participante for contemplado, ele não poderá mais participar da Promoção. </p>
                                 <p>O contemplado, em seguida, receberá uma comunicação por e-mail e/ou por telefone das Promotoras para validação das suas condições de participação, com a confirmação de seus dados pessoais e cupom fiscal cadastrado. Fica certo que o prêmio apenas será entregue após a validação de todas as condições de participação pelas Promotoras e que caso o participante não consiga, no prazo de 10 dias úteis, atender às solicitações que comprovem a legitimidade de sua participação, ele será desclassificado e o prêmio será entregue ao próximo participante válido, de acordo com a regra de intervalo de premiação prevista neste Regulamento. </p>
                                 <p>Ele também poderá contatar as Promotoras por meio do canal de atendimento da promoção, pelo número 0800-580-2474 e canais de SAC. </p>
                                 <p>Caso o participante cadastre um cupom fiscal já utilizado ou indevido ou, ainda, tente validá-lo fora do período da Promoção, será informado que o “Cupom fiscal já foi cadastrado” ou “Cupom fiscal inválido” ou “Promoção não iniciada” ou “Promoção encerrada”, conforme o caso. Para esclarecimentos de dúvidas ou solicitação de informações, o participante poderá entrar em contato por meio do “Fale Conosco”, no site da Promoção.</p>
                                 <p>O ganhador deverá guardar consigo a embalagem e o cupom fiscal mesmo após a entrega do brinde, pois este poderá ser solicitado pelas Promotoras para finalidades de auditoria.</p>
                                 <h4>12. LOCAL DE EXIBIÇÃO DO PRÊMIO</h4>
                                 <p>Os brindes que serão ofertados nesta promoção serão exibidos de maneira ilustrativa no site da Promoção www.promopringles.com. </p>
                                 <p>As Promotoras comprometem-se com a sua aquisição conforme o artigo 34, inciso I da Portaria 41/2008 do Ministério da Fazenda.</p>

                                 <h4>13. FORMA DE ENTREGA DO PRÊMIO</h4>
                                <p>Os prêmios serão entregues aos ganhadores, sem qualquer tipo de ônus, em até 30 (trinta) dias contados a partir da validação dos dados do ganhador, conforme artigo 5°, do Decreto nº 70.951/72.</p>
                                <p>Para recebimento do prêmio o ganhador deverá enviar às Promotoras cópia do cupom fiscal da compra do produto participante, RG, CPF e comprovante de residência.</p>
                                <p>Se o ganhador não cumprir com requisitos ou enviar documentos, com, mas não se limitando a, informações adulteradas, equivocadas, ilegíveis ele perde o direito ao prêmio. </p>
                                <p>Ao contemplado pode ser solicitada qualquer informação adicional, dentro dos limites da lei, a fim de implementar a entrega do prêmio.</p>
                                <p>O prêmio destina-se ao contemplado e será entregue em seu nome, sendo vedada sua transferência a terceiros em qualquer momento. Na eventualidade de o participante ganhador ser menor de 18 (dezoito) anos, o seu responsável legal deverá receber o prêmio, entregue em nome do menor e, para tanto, deverá comprovar tal condição mediante a apresentação de documento.</p>
                                <p>Em nenhuma hipótese será permitido ao contemplado trocar seu prêmio por qualquer outro item, tampouco por dinheiro.</p>
                                 <h4>14. PRAZO DE PRESCRIÇÃO DO DIREITO AO BRINDE</h4>
                                <p>Após o prazo de 180 (cento e oitenta) dias contados a partir da data de encerramento desta promoção, o direito ao brinde prescreverá, sendo o valor correspondente aos brindes não entregues, recolhido pela empresa autorizada, ao Tesouro Nacional, como renda da União, no prazo de 10 (dez) dias.</p>
                                 <h4>15. CANAIS E FORMAS DE DIVULGAÇÃO INSTITUCIONAL PELA MÍDIA</h4>
                                <p>A Promoção poderá ser divulgada por meio de mídias digitais, redes sociais, materiais nos pontos de venda e demais canais institucionais das Promotoras.</p>
                                <p>Os participantes terão acesso à íntegra do Regulamento da Promoção em www.promopringles.com.</p>
                                <h4>16. DÚVIDAS E RECLAMAÇÕES</h4>
                                <p>As dúvidas e controvérsias originadas de reclamações dos participantes da Promoção serão, primeiramente, dirimidas pelas Promotoras e, persistindo-as, serão submetidas à Centralizadora Nacional de Promoções Comerciais (CEPCO/CAIXA) e/ou aos órgãos integrantes do Sistema Nacional de Defesa do Consumidor. </p>
                                 <h4>17.  DESCLASSIFICAÇÃO DE PARTICIPANTES</h4>
                                <p>Todos os participantes deverão observar as condições, formas e prazos de participação, sendo sumariamente desclassificados os participantes que descumprirem quaisquer regras desta Promoção, que cometerem qualquer tipo de suspeita de fraude, incluindo, mas não se limitando a, à criação, benefício e utilização de ferramentas automatizadas para burlar a participação na Promoção, ficando, ainda, sujeitos à responsabilização penal e civil.</p>
                                <p>Em caso de desclassificação, o prêmio será entregue ao próximo participante válido, de acordo com a regra de intervalo de premiação prevista neste Regulamento. </p>
                                 <h4>18. DISPOSIÇÕES GERAIS</h4>
                                <p>A participação na Promoção é voluntária e implicará a aceitação total e irrestrita de todos os itens deste Regulamento. </p>
                                <p>Não terão validade as participações que não preencherem as condições básicas da Promoção.</p>
                                <p>O contemplado cede gratuitamente às Promotoras os direitos de uso de seu nome, imagem e som de voz, pelo período de 01 (um) ano contados a partir da data da entrega do brinde, para divulgação do(s) prêmio(s) recebido(s) na Promoção, sem restrição de frequência, sem que isso lhes implique qualquer tipo de ônus.</p>
                                <p>Fica, desde já, eleito o foro central da Comarca do participante para solução de quaisquer questões referentes à Promoção.</p>
                                <p id="lineTerms">CERTIFICADO DE AUTORIZAÇÃO CAIXA nº 5-6469/2018.</p>    
                            </div>
                            <h1 class="not-visible">Termos e Condições da promoção Pringles® 50 anos.</h1>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoção Pringles® | Regulamento da promoção'
        }
    },
}
</script>

