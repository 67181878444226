<!-- Google Tag Manager -->
<template>
    <div>
        <div class="top-menu">
            <div class="logo">
                <router-link :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">
                    <img v-if="cntry=='MX'" class="logo_img" src="@/assets/images/logoPringles.png" width="100" height="125" alt="ir para a pagina inicial">
                    <img v-else class="logo_img" src="@/assets/images/logoPringles.png" width="100" height="125" alt="ir para a pagina inicial">
                </router-link>
            </div>
            <nav class="menu" role="navigation" :class="[isOpen ? 'open' : '']">
                <ul class="">
                    <li :class="classActive('promoHome')">
                        <router-link :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('init')}}</router-link>
                    </li>
                    <li :class="classActive('productos')" v-if="exists==true">
                        <router-link :to="{name: 'productos', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('products')}}</router-link>
                    </li>
                    <li :class="classActive('TermsSelector')">
                        <router-link :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('legals')}}</router-link>
                    </li>
                    <li :class="classActive('miCuenta')">
                        <router-link :to="{name: 'miCuenta', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('myAccount')}}</router-link>
                    </li>
                    <li :class="classActive('Contact')">
                        <router-link :to="{name: 'Contact', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('contact')}}</router-link>
                    </li>
                </ul>
            </nav>
            <div class="divRightMenu">
                <div class="divSelectPais chooseCountry">
                    <span>{{ $t('youAreIn')}}</span>
                    <select class="selectPais" role="listbox" title="Selecciona tu país" @change="handleChange" v-model="countryModel" :class="[country.id.toUpperCase()]">
                        <option value="-1">{{ $t('countries')}}</option>
                        <option value="AR">Argentina</option>
                        <option value="BO">Bolivia</option>
                        <option value="BR">Brasil</option>
                        <option value="CO">Colombia</option>
                        <option value="CR">Costa Rica</option>
                        <option value="EC">Ecuador</option>
                        <option value="SV">El Salvador</option>
                        <option value="GT">Guatemala</option>
                        <option value="HN">Honduras</option>
                        <option value="MX">México</option>
                        <option value="NI">Nicaragua</option>
                        <option value="PA">Panamá</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Perú</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="DO">República Dominicana</option>
                        <option value="UY">Uruguay</option>
                    </select>
                </div>
                <span class="iconMenu" @click="toggleClass()" :class="[isOpen ? 'close' : '']"></span>
            </div>
        </div>
        <!--<header>
            <router-link :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">
                <img v-if="cntry=='MX'" class="logo" src="@/assets/images/logoPringlesMX.png" width="100" height="125" alt="ir para a pagina inicial">
                <img v-else class="logo" src="@/assets/images/logoPringles.png" width="100" height="125" alt="ir para a pagina inicial">
            </router-link>
            <div class="chooseCountry">
                <span>{{ $t('youAreIn')}}</span>
                <select role="listbox" title="Selecciona tu país" @change="handleChange" v-model="countryModel">
                    <option value="-1">{{ $t('countries')}}</option>
                    <option value="AR">Argentina</option>
                    <option value="BR">Brasil</option>
                    <option value="CO">Colombia</option>
                    <option value="CR">Costa Rica</option>
                    <option value="EC">Ecuador</option>
                    <option value="SV">El Salvador</option>
                    <option value="GT">Guatemala</option>
                    <option value="HN">Honduras</option>
                    <option value="MX">México</option>
                    <option value="NI">Nicaragua</option>
                    <option value="PA">Panamá</option>
                    <option value="PE">Perú</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="DO">República Dominicana</option>
                </select>
            </div>
            <span class="iconMenu" @click="toggleClass()" :class="[isOpen ? 'close' : '']"></span>
        </header>
        <nav class="desktopMenu" :class="[isOpen ? 'open' : '']">
            <ul class="listWrp">
                <li :class="classActive('promoHome')">
                    <router-link :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('init')}}</router-link>
                </li>
                <li :class="classActive('productos')" v-if="exists==true">
                    <router-link :to="{name: 'productos', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('products')}}</router-link>
                </li>
                <li :class="classActive('TermsSelector')">
                    <router-link :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('legals')}}</router-link>
                </li>
                <li :class="classActive('miCuenta')">
                    <router-link :to="{name: 'miCuenta', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('myAccount')}}</router-link>
                </li>
                <li :class="classActive('Contact')">
                    <router-link :to="{name: 'Contact', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('contact')}}</router-link>
                </li>
            </ul>
            <ul class="socialWrp">
                <li><a href="https://www.facebook.com/PringlesLA/" target="_blank" class="icon fb"></a></li>
                <li><a href="https://twitter.com/Pringles_LATAM" target="_blank" class="icon tw"></a></li>
            </ul>
        </nav>-->
    </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            countryModel: '-1',
            countryName: 'República Dominicana',
            country_change: '',
            secName: this.$route.name,
            paisNombre: '', //guardara el nombre del país desde el localstorage
            isOpen: false,
            exists:false,
            cntry:''
        }
    },
    computed: {
        ...mapState(['currentFolio', 'isLogin', 'country', 'isCaricam']),
    },
    methods: {
        sendToSelector() {
            let cookiesKey = ["country", "countryName", "countryArea"];
            localStorage.removeItem("utm");
            cookiesKey.map(value => {
                return localStorage.removeItem(value);
            });
            this.$store.dispatch("closeSession", true).then(() => {
                if (this.country.id === "TT") {
                    this.$router.push({ name: "homeDeezerTT" });
                } else {
                    this.$router.push({ name: "homeDeezer" });
                }
            });
            this.$store.dispatch("deleteCookie", "countrylanguaje");
            setTimeout(() => {
                window.location.href = "/";
            }, 1000);
        },
        handleChange(e) {
            let element = e.target
            let countryId = element.value

            this.countryModel = countryId
            this.countryName = element[element.selectedIndex].text

            if (countryId !== '-1' /*&& countryId !== 'UY'*//* && countryId !== 'PY'*/) {
                
                this.setLanguage(this.countryModel, this.countryName)

                this.reloadPush(countryId)

                setTimeout(()=>{
                    window.location.href;
                }, 120);

            }
            else {
                this.$router.push({ name: 'init' })
            }
            //else if (countryId === 'PY') {
            //    //no se estan usando por que los eliminamos, se quedan por si quieren volver
            //    window.location.href = 'https://www.pringlesgamer.com.co/PY'
            //}
            //else if (countryId === 'UY') {
            //    //no se estan usando por que los eliminamos, se quedan por si quieren volver
            //    window.location.href = 'https://www.pringlesgamer.com.co/UY'
            //} 
            


        },
        reloadPush(id){
            let countryId = id.toLowerCase()
            this.$router.push({ name: 'promoHome', params: { countryId } })
            location.reload();
        },
        setLanguage(countryModel, countryName){

            localStorage.setItem('country', countryModel)
            localStorage.setItem('countryName', countryName)

            this.$store.commit('setCountry', { country: countryModel })
            this.$store.commit('setCountryName', countryName)
            this.$store.dispatch('setSiteCountryArea', countryModel)

            if (countryModel === 'TT') {
                this.$store.dispatch('setLanguageLocale', 'en')
                localStorage.setItem('language', 'en')
                document.cookie = 'countrylanguaje=en-TT'

            } else if (countryModel === 'BR') {
                document.cookie = 'countrylanguaje=pt-BR'
                localStorage.setItem('language', 'pt')
                this.$store.dispatch('setLanguageLocale', 'pt')
            }else {
                this.$store.dispatch('setLanguageLocale', 'es')
                localStorage.setItem('language', 'es')
            }

        },
        removeCookies() {
            let cookiesKey = ['country', 'countryName', 'countryArea', 'language']

            cookiesKey.map((value) => {
                return localStorage.removeItem(value)
            });

            document.body.className = ''
            this.$store.dispatch('deleteCookie', 'countrylanguaje')
        },
        classActive(type){
            if(type === this.$route.name){
                return 'active'
            }
            return ''
        },
        toggleClass(){
            if(this.isOpen){
                this.isOpen = false;
            }else{
                this.isOpen = true;
            }
        },
        reloadUrl(){
            let pathArray = window.location.pathname.split('/');
            let countryModel = this.countryModel
            let countryName = this.countryName
            countryModel = pathArray[2]
            let urlCorrecta = window.location.protocol + '//' + window.location.host + '/promo/' + countryModel.toLowerCase()
            //console.log("url",urlCorrecta);
            
            if (window.location.href === urlCorrecta) {
               
                countryModel = pathArray[2]           
                //console.log('id', countryModel)   

                let selector = document.querySelector(".chooseCountry select");
                let valoresSelector = []
                valoresSelector = [...selector.options];

                valoresSelector.forEach(function(element) {
                    if(element.value === countryModel.toUpperCase()){
                        
                        countryName = element.text
                        //console.log('countryName', countryName)
                    }
                });
                
                this.setLanguage(countryModel.toUpperCase(), countryName)
                location.reload();

            }
            
        }

    },
    mounted() {
        //country.id.toLowerCase()
        //console.log(this.$route.params.countryId);
        if(this.$route.params.countryId=='mx' || this.$route.params.countryId=='br' || this.$route.params.countryId=='gt' ||this.$route.params.countryId=='co' || this.$route.params.countryId=='ec' || this.$route.params.countryId=='pe'){
            this.exists=true;
        }

        this.exists=true;

        this.countryModel = this.country.id

        this.cntry=this.country.id

        if (localStorage.countryName) {
            this.paisNombre = localStorage.countryName;
        }
        this.$store.commit('toggleMenuMobile', false)

        if(this.$route.name === 'terms' + this.country.id || this.$route.name === 'terms' + this.country.id + 'Sol' || this.$route.name === 'terms' + this.country.id + 'Xmas' || this.$route.name === 'terms' + this.country.id + 'Anniversary'){
            let termsSelector = document.querySelector('.listWrp li:nth-child(2)');
            if (termsSelector != undefined)
                termsSelector.classList.add('active')
        }
        
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
        } else {
            localStorage.setItem('reloaded', '1');
            this.reloadUrl()
        }

    }
};
</script>
