<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/gaming/gaming-466x276-mx.jpg" alt="Pringles® Gaming">
                            <div class="titleTermsWrp one-whole">
								<ul class="tabsWrp">
                                    <li>
                                        <button @click.prevent="setTermsActive(0)" :class="{'selected': activeTerm === 0}">Pringles® Gaming</button>
                                    </li>
                                    <li>
                                        <button @click.prevent="setTermsActive(1)" :class="{'selected': activeTerm === 1}">Pringles® Gaming Clubes</button>
                                    </li>
                                </ul>
                                <p class="year">2021</p>
                                <h4 class="titleTerms">PRINGLES® GAMING 2021 <br>Y PRINGLES® GAMING CLUBES 2021</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <TermsGameMX v-if="activeTerm === 0" :class="{'active': activeTerm === 0}"></TermsGameMX>
                        <TermsClubMX v-if="activeTerm === 1" :class="{'active': activeTerm === 1}"></TermsClubMX>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import TermsGameMX from '@/components/global/gaming/TermsGameMX'
import TermsClubMX from '@/components/global/gaming/TermsClubMX'
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'

export default {
	data() {
        return {
            activeTerm: 0
        };
    },
	methods: {
        setTermsActive($index) {
            this.activeTerm = $index;
        }
    },
    components: {
		TermsGameMX,
		TermsClubMX,
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>