<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/deezer/466x276_es.jpg" alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2019</p>
                                <h4 class="titleTerms">PROMO PRINGLES® DEEZER</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
                                    <h2 class="hidden">Términos</h2>
                                    <!-- <h2>TÉRMINOS Y CONDICIONES PRINGLES® MUSIC 2019</h2> -->
                                    <p>Al participar en la promoción “Pringles® MUSIC 2019” (en lo sucesivo la “Promoción”), el participante declara haber leído, entendido y sujetarse totalmente a los Términos y condiciones aquí establecidas (en adelante las “Bases”).</p>
                                    <p>Responsable de la promoción: Kellogg de Centroamérica, S.A., con domicilio en Boulevard Los Próceres 18 calle 24-69, Zona 10, Edificio Empresarial Zona Pradera, Torre III, Nivel 8, Oficina 801, de la Ciudad de Guatemala Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al teléfono 1 800 835 7465 de lunes a viernes de 8:00 a 17:00hrs. </p>
                                    <p><strong>I. Cobertura:</strong> República de Panamá, en todos los establecimientos en que se comercialicen los productos participantes.</p>
                                    <p><strong>II.- PARTICIPANTES: </strong> Se entenderá como “Participante” al público consumidor en general, que tenga la disposición de participar en la Promoción y reúna todos y cada uno de los siguientes requisitos:</p>
                                    <ul>
                                        <li>Ser mayor de edad con plena capacidad de ejercicio y contar con identificación oficial con fotografía vigente (Documento Personal de Identificación). Y tener legal residencia en la República de Panamá.</li>
                                        <li>Aceptar las presentes Bases. </li>
                                        <li>Comprar los Productos Pringles® marcadas con la Promoción (en lo sucesivo Los “Productos Participantes”) durante la Vigencia de la Promoción y que estén a la venta en la República de Panamá.</li>
                                        <li>Dar lectura total y aceptar las presentes Reglas</li>
                                        <li>Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción. </li>
                                        <li>Proporcionar durante la Vigencia identificación oficial y datos para registro de ganadores y firma de recibo de conformidad.</li>
                                        <li>Registrar los códigos únicos que se encuentran impresos en la membrana (la tapa de papel que cubre el envase) de cada lata de Pringles® en el sitio web: www.promopingles.com siguiendo las instrucciones correspondientes de acuerdo a la mecánica descrita en éste documento. Se deberán conservar las membranas con los códigos marcados a fin de poder realizar cualquier aclaración.</li>
                                    </ul>
                                    <p><strong>III.- VIGENCIA DE LA PROMOCIÓN:</strong> Los códigos de la promoción en el website de la página de Pringles® tendrán vigencia del 1 de abril de 2019 y las vigencias para cada tipo de premio se detalla a continuación:</p>
                                    <div class="wrapperTable" role="table">
                                        <div role="rowgroup" class="">
                                            <div class="cellWrapTitle2" role="cell">Premio</div>
                                            <div class="cellWrapTitle2" role="cell">Vigencia </div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap2 quantidade" role="cell">Ingresar códigos en la página de Pringles® para participar para premios Deezer</div>
                                            <div class="cellWrap2 award" role="cell">1 de abril de 2019 al 25 de agosto de 2019</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap2 quantidade" role="cell">Ingresar códigos en la página de Pringles® para participar para premios de bocinas y audífonos excluyendo a Deezer</div>
                                            <div class="cellWrap2 award" role="cell">1 de abril de 2019 al 31 de agosto de 2019</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap2 quantidade" role="cell">Canjear códigos ganadores en la plataforma Deezer.</div>
                                            <div class="cellWrap2 award" role="cell">1 de abril del 2019 hasta el 31 de agosto de 2019</div>
                                        </div>
                                    </div>
                                    <p><strong>IV.- INCENTIVOS</strong> Total: 226 beneficios de canje de acuerdo al número de productos participantes registrados, consistentes en:</p>
                                    <ul>
                                        <li>Audífonos: 6</li>
                                        <li>Bocinas: 4</li>
                                        <li>2 meses de Deezer Premium Gratis: 216</li>
                                    </ul>
                                    <p>Descripción de incentivos:</p>
                                    <p>a) Incentivos de Canje al Registro: 216 premios consistentes en un cupón de 2 meses de Deezer Premium Gratis valorado en US$19.98</p>
                                    <p>El cupón será canjeado en la aplicación o en la página web de Deezer ( https://www.deezer.com ) durante la vigencia de la promoción y hasta el 31 de agosto de 2019. El cupón que no haya sido redimido o canjeado el 31 de agosto de 2019 no podrá ser redimido.</p>
                                    <ul>
                                        <li>Beneficio disponible para usuarios gratuitos de Deezer o nuevos usuarios que nunca se hayan beneficiado del período de prueba o una promoción similar otorgada por Deezer. </li>
                                        <li>Nuevos suscriptores requieren de una tarjeta crédito o débito válida y se convertirán automáticamente en un suscriptor pago al finalizar el período de 2 meses gratis, salvo que que cancele su suscripción antes de que inicie el periodo pago; </li>
                                        <li>Limitado a 1 incentivo (suscripción Deezer) por persona/usuario.</li>
                                        <li>Deezer será el único responsable del servicio.</li>
                                    </ul>
                                    <p>b) Registro para participación:</p>
                                    <ul>
                                        <li>6 audífonos marca Sony: audífono Sony on-ear black inalámbrico bluetooth, nfc con micrófono con un valor comercial de US$70. La organizadora no se hace responsable de la garantía de los audífonos. </li>
                                        <li>4 bocinas marca Bose: SoundLink Micro Bluetooth speaker con un valor comercial de US$150. La organizadora no se hace responsable de la garantía de los audífonos. </li>
                                    </ul>
                                    <p><strong>V. PRODUCTOS PARTICIPANTES:</strong> Los Productos Participantes son los siguientes siempre y cuando se encuentren marcados con la promoción:</p>
                                    <ul>
                                        <li>Pringles® Original 149g </li>
                                        <li>Pringles® Crema y Especias 149g </li>
                                        <li>Pringles® Queso 149g </li>
                                        <li>Pringles® Original 37g </li>
                                        <li>Pringles® Crema y Especias 40g</li>
                                        <li>Pringles® Queso 40g</li>
                                    </ul>
                                    <p><strong>VI. MECÁNICA DE PARTICIPACIÓN:</strong> </p>
                                    <p>Pasos:</p>
                                    <p>1. El participante deberá comprar productos Pringles® participantes marcadas con la promoción en los Establecimientos participantes.</p>
                                    <p>2. En cada lata y detrás de la membrana (tapa de aluminio), los participantes encontrarán un código único. </p>
                                    <p>3. El participante deberá ingresar en el micrositio www.promopringles.com para participar.</p>
                                    <p>4. En ella deberá crear una cuenta para lo cual registrar los siguientes datos:</p>
                                    <ul>
                                        <li>Nombre</li>
                                        <li>Apellido</li>
                                        <li>Fecha de nacimiento </li>
                                        <li>Teléfono</li>
                                        <li>E-mail</li>
                                        <li>Contraseña</li>
                                    </ul>
                                    <p>5. Una vez iniciada sesión el Participante deberá elegir el tipo de incentivo por el que participará de acuerdo al gramaje del producto participante, tomando en cuenta el número de productos participantes que se requieren para cada categoría:</p>
                                    <ul>
                                        <li>a) Audifonos (Cualquier gramaje)</li>
                                        <li>b) Bocina (cualquier gramaje)</li>
                                        <li>c) Cupón por 2 meses de Deezer Premium Gratis (únicamente productos participantes de 149g)</li>
                                    </ul>
                                    <div class="wrapperTable" role="table">
                                        <div role="rowgroup" class="">
                                            <div class="cellWrapTitle3" role="cell">Audífonos marca Sony </div>
                                            <div class="cellWrapTitle3" role="cell">Bocina marca Bose </div>
                                            <div class="cellWrapTitle3" role="cell">2 meses de Deezer Premium Gratis </div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap3" role="cell">2 productos participantes de cualquier gramaje </div>
                                            <div class="cellWrap3" role="cell">3 productos participantes de cualquier gramaje</div>
                                            <div class="cellWrap3" role="cell">2 productos participantes de 149 grs</div>
                                        </div>
                                    </div>
                                    <p>6. Para la obtención de los Cupones Deezer el participante deberá registra los 2 códigos únicos requeridos de los Productos Participantes de 149 g, para validación.</p>
                                    <p>En caso de no ser válido uno o más de los códigos, se le comunicará y se le invitará a seguir participando.</p>
                                    <p>7. En caso de que la validación sea exitosa, el sistema arrojará una pantalla emergente con el Código correspondiente al Cupón Deezer.</p>
                                    <p>8. En el caso de que el Participante decida participar por la los audífonos o la bocina deberá registrar el número de códigos de acuerdo al tipo de incentivo por el que quiera participar:</p>
                                    <ul>
                                        <li>Audífonos: 2 productos participantes de cualquier gramaje.</li>
                                        <li>Bocina: 3 productos participantes de cualquier gramaje.</li>
                                    </ul>
                                    <p>La mecánica en éstos incentivos será:</p>
                                    <ul>
                                        <li>Elegir el incentivo por el cual se participará tomando en cuenta los periodos de participación que se especifican en el anexo de Números Predeterminados.</li>
                                        <li>Consultar los números predeterminados para concursar*, así como el contador de los folios de participación que se han ido registrando para calcular el momento en que registrará su código e intentar ser uno de los 10 ganadores.</li>
                                        <li>Registra los códigos únicos de los productos participantes (según el incentivo) para validación.</li>
                                        <li>En caso de ser válidos se generará un folio de participación. En caso de no ser válidos se le comunicará y se le invitará a seguir participando.</li>
                                        <li>Si el folio de participación es uno de los 10 números predeterminados establecidos durante la vigencia de la promoción, el sistema en automático arrojará una ventana emergente para que el Participante inicie la fase de trivia, en la que deberá contestar correctamente 3 preguntas de cultura general de opción múltiple.</li>
                                        <li>Serán Ganadores los 10 Participantes que contesten correctamente las preguntas de la Trivia.</li>
                                        <li>En caso de que no se asigne el Incentivo establecido para el periodo de participación, de acuerdo al anexo de Números Predeterminados, debido a la falta de participaciones o registros o por no responder correctamente a la fase de trivia, se acumulará para el siguiente periodo de la promoción a nuevos folios de participación.</li>
                                        <li>Si el Participante contesta incorrectamente, no podrá ser acreedor al incentivo y se dará por terminada su participación, pasando al siguiente registro inmediato siguiente a este; lo mencionado se hará hasta obtener al Ganador por periodo.</li>
                                        <li>Publicación de ganadores: La publicación de los nombres ganadores se darán a conocer una vez que se haya validado su identidad y participación (de acuerdo al periodo) a partir del 1 de abril según especifica la vigencia, a través del micrositio www.promopringles.com</li>
                                    </ul>
                                    <p>*Números predeterminados (Ver anexo A): Los números predeterminados a concursar se establecen previo al inicio de la promoción, por periodo de participación, y serán publicados en el sitio web de la promoción www.promopringles.com, por lo que NO podrán ser modificados o alterados. Asimismo, en el micrositio de la promoción se va a colocar un contador en donde irán apareciendo en tiempo real los folios de participación que se han ido registrando vía web, para que el participante haciendo uso de su voluntad elija el momento de su registro para alcanzar el número predeterminado y poder concursar por audífonos y bocinas.</p>
                                    <p>Limitaciones:</p>
                                    <ul>
                                        <li>Limitado a la obtención de un máximo de 1 (un) incentivo de cada tipo por participante. </li>
                                        <li>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</li>
                                        <li>Limitado hasta agotar existencias de los 1,610 incentivos.</li>
                                        <li>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a La Organizadora.</li>
                                        <li>El Participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; (ii) no accederá al Sitio Web ni participará en la Promoción utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), ni podrá permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web; (iii) acepta ser el único responsable (frente al Organizador, al Auspiciante y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</li>
                                    </ul>
                                    <p><strong>VII. ENTREGA DE INCENTIVOS CONSISTENTES EN BOCINAS Y AUDÍFONOS.</strong> </p>
                                    <p>Los participantes que hayan dado completado la mecánica serán contactados por la Organizadora o el tercero que al efecto designe, para solicitar la información correspondiente para la validación de su identidad y en su caso realizar la entrega de su incentivo. Se les contactará por medio de correo electrónico o por teléfono (dependiendo la información que brindaron en la plataforma). Para que tal efecto deberá presentar y entregar, la siguiente documentación:</p>
                                    <ul>
                                        <li>Copia de la Identificación oficial vigente con fotografía por ambos lados (INE, Cédula de Identidad, Pasaporte, Cédula Profesional o documento personal de identificación o DPI)</li>
                                        <li>Copia del Comprobante de domicilio (con antigüedad no mayor a tres meses).</li>
                                        <li>Firmar el recibo de conformidad y carta de uso de imagen. </li>
                                        <li>Entregar las membranas en la que consten los Códigos Únicos mediante las cuales el participante resultó Ganador y el folio que se genere al momento del registro.</li>
                                        <li>El ganador se debe presentar según el horario y día que se le establezca por parte de la Organizadora en las oficinas de las oficinas de DICARINA en la siguiente dirección Juan Díaz, Calle Llano Bonito, Complejo Santa María Business District , Edificio Argos ( Night Fall) 6 Piso, Panamá.</li>
                                    </ul>
                                    <p>Todos los Ganadores tendrán 15 días hábiles para recoger su premio, a partir de la solicitud de información para su entrega en la dirección indicada cuando se comuniquen con ellos para informarles que son ganadores. En caso no recoger su premio en 15 días hábiles, el premio tendrá vigencia nuevamente para un nuevo participante.</p>
                                    <p>En caso de no realizar la entrega completa de la documentación solicitada el Participante perderá el derecho a reclamar su incentivo y la Organizadora dispondrá de los incentivos como mejor convenga a sus intereses.</p>
                                    <p>La falta de veracidad en la información proporcionada y/o la falta de presentación de identificación oficial con fotografía que impida o no permita identificar al Participante con derecho a reclamar algún incentivo libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer del incentivo respectivo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                    <p> <strong>VIII. CONDICIONES GENERALES:</strong></p>
                                    <p>a. CÓDIGOS NULOS.-</p>
                                    <ul>
                                        <li>Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica.</li>
                                        <li>Copias o facsímiles de membranas con los códigos originales.</li>
                                        <li>Aquellos que al momento de ser presentados a validación, presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados sobre-armados o laminados superpuestos en su interior o superficies, una vez que sean requeridos para verificar la autenticidad de la participación.</li>
                                        <li>La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes.</li>
                                        <li>Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.</li>
                                    </ul>
                                    <p>b. VALOR UNITARIO DE PARTICIPACIÓN.-</p>
                                    <p>Será gratuito el registro y la participación en las dinámicas, no tendrá ningún costo adicional para el participante, siempre y cuando se sujete a la mecánica de participación descrita en las presentes Bases.</p>
                                    <p>c. DATOS PERSONALES (AVISO DE PRIVACIDAD).-</p>
                                    <p>La información personal que Los Participantes proporcionen con el fin de recibir alguno de los incentivos de la Promoción, quedarán incorporados a una base de datos que conservará La Organizadora, ante la que cualquier Usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, La Organizadora quedará liberada respecto de las entregas o beneficios por otorgar a los Participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                                    <p>La Organizadora utilizará la información proporcionada para la realización de la Promoción con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece La Organizadora, así como con prestadores de servicios contratados por La Organizadora para la ejecución de la actividad hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial de La Organizadora.</p>
                                    <p>Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 1 800 835 7465</p>
                                    <p><strong>IX. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR:</strong></p>
                                    <p>La Organizadora, no será responsable de modificaciones en el estado de salud a consecuencia de la participación en la Mecánica por parte del Participante. </p>
                                    <p>La Organizadora, podrá en cualquier momento suspender La Promoción o la entrega del incentivo sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier disposición emitida por cualquier autoridad jurisdiccional, así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten los intereses de la Organizadora. Si por causas ajenas a la Organizadora se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para disfrutar del incentivo o de la actividad programada, el organizador, se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que pusieran en peligro la integridad física de cualquiera de los acreedores. En todos estos casos La Organizadora publicará a través de medios impresos y/o a través de su página web los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</p>
                                    <p>No se podrá canjear el incentivo por otro de cualquier tipo y/o dinero en efectivo. Ninguno de los incentivos estará sujetos de comercialización alguna, de manera enunciativa más no limitativa, venta, permuta, arrendamiento, sustitución o dación en pago.</p>
                                    <p>Si existen indicios suficientes, a criterio de la Organizadora, que señalen que los participantes obtuvieron sus códigos por medios ilegales o distintos a los establecidos en estas bases, podrá cancelarse la participación de ese Participante, y en su caso, dar aviso a las autoridades correspondientes.</p>
                                    <p>La responsabilidad de La Organizadora concluye al momento de la entrega de cada uno de los incentivos a sus correspondientes acreedores.</p>
                                    <p>La Organizadora, no será responsable por el uso que se le brinde el Participante a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                                    <p>Los incentivos serán entregados siempre y cuando se haya validado la identidad del acreedor correspondiente.</p>
                                    <p>El acreedor al incentivo deberá seguir todas las direcciones e instrucciones de La Organizadora, en caso de incumplimiento de cualquiera de estas el Participante será descalificado.</p>
                                    <p>El Organizador no garantiza la disponibilidad y/o continuidad del funcionamiento del Sitio Web ni tampoco garantiza el acceso efectivo al mismo, siendo esto responsabilidad exclusiva de las prestadoras de servicios de Internet. En el mismo sentido, el Organizador no garantiza que los Participantes utilicen el Sitio Web y/o la Promoción de conformidad con estas Bases y Condiciones ni que lo hagan de forma prudente y diligente. Al participar en la Promoción el acreedor acepta expresamente haber leído y entendido a la perfección las Bases y estar de acuerdo con las mismas.</p>
                                    <p>La Organizadora se reserva el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica e incentivos, siempre que las mismas estén justificadas o no perjudiquen a los participantes, y se comuniquen a éstos debidamente.</p>
                                    <p><strong>X. JURISDICCIÓN APLICABLE:</strong></p>
                                    <p>Cualquier controversia suscitada por La Promoción se regirá por las presentes Bases y como complemento para lo establecido en éstas será aplicable la jurisdicción de República de Panamá, sujetándose todas las partes interesadas a las mismas renunciando al que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                    <p><strong>XI. AVISO DE PRIVACIDAD:</strong></p>
                                    <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                    <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto.</p>
                                    <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg de Centroamérica, S.A., con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán directamente como La Organizadora de la Promoción por conducto de los medios de comunicación que se han señalado anteriormente en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                    <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.promopingles.com la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                    <p><strong>ANEXO A NÚMEROS PREDETERMINADOS.</strong></p>
                                    <div class="wrapperTable" role="table">
                                        <div role="rowgroup" class="">
                                            <div class="cellWrapTitle4" role="cell"># Incentivo</div>
                                            <div class="cellWrapTitle4" role="cell">Tipo de Premio </div>
                                            <div class="cellWrapTitle4" role="cell">Fechas por periodo </div>
                                            <div class="cellWrapTitle4" role="cell">Registro ganador del periodo </div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos </div>
                                            <div class="cellWrap4" role="cell">1 DE ABRIL – 15 DE ABRIL</div>
                                            <div class="cellWrap4" role="cell">124</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos </div>
                                            <div class="cellWrap4" role="cell">16 DE ABRIL – 30 DE ABRIL</div>
                                            <div class="cellWrap4" role="cell">170</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Bocina </div>
                                            <div class="cellWrap4" role="cell">1 DE MAYO – 15 DE MAYO</div>
                                            <div class="cellWrap4" role="cell">91</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos</div>
                                            <div class="cellWrap4" role="cell">16 DE MAYO - 31 DE MAYO</div>
                                            <div class="cellWrap4" role="cell">123</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos </div>
                                            <div class="cellWrap4" role="cell">1 DE JUNIO – 15 DE JUNIO</div>
                                            <div class="cellWrap4" role="cell">100</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Bocina </div>
                                            <div class="cellWrap4" role="cell">16 DE JUNIO – 30 DE JUNIO</div>
                                            <div class="cellWrap4" role="cell">99</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos</div>
                                            <div class="cellWrap4" role="cell">1 DE JULIO - 15 DE JULIO</div>
                                            <div class="cellWrap4" role="cell">83</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Audífonos</div>
                                            <div class="cellWrap4" role="cell">16 DE JULIO - 31 DE JULIO</div>
                                            <div class="cellWrap4" role="cell">148</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Bocina</div>
                                            <div class="cellWrap4" role="cell">1 DE AGOSTO - 15 DE AGOSTO</div>
                                            <div class="cellWrap4" role="cell">182</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap4" role="cell">1</div>
                                            <div class="cellWrap4" role="cell">Bocina</div>
                                            <div class="cellWrap4" role="cell">16 DE AGOSTO - 31 DE AGOSTO</div>
                                            <div class="cellWrap4" role="cell">187</div>
                                        </div>
                                    </div>
                                    <p><strong>ANEXO NÚMERO DE PREMIOS TOTALES PANAMA.</strong></p>
                                    <div class="wrapperTable" role="table">
                                        <div role="rowgroup" class="">
                                            <div class="cellCol3" role="cell">
                                                <p>Premios totales</p>
                                            </div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrapTitle3" role="cell">Audífonos marca Sony</div>
                                            <div class="cellWrapTitle3" role="cell">Bocina marca Bose</div>
                                            <div class="cellWrap3" role="cell">2 meses de Deezer Premium Gratis</div>
                                        </div>
                                        <div role="rowgroup" class="">
                                            <div class="cellWrap3" role="cell">2 productos
                                                participantes de
                                                cualquier gramaje</div>
                                            <div class="cellWrap3" role="cell">3 productos
                                                participantes
                                                de cualquier
                                                gramaje</div>
                                            <div class="cellWrap3" role="cell">2 productos
                                                participantes
                                                de 149gr</div>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="not-visible">Términos y Condiciones de la promoción La música cae mejor con Pringles®.</h1>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>