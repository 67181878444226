<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/deezer/466x276_en.jpg" alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2019</p>
                                <h4 class="titleTerms">Music is better with Pringles®</h4>
                                <h5>Terms & Conditions</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
                                    <h2 class="hidden">Terms & Conditions</h2>
                                    <h2>Terms & Conditions Pringles ® Music 2019</h2>
                                    <p>By participating in the "Pringles® MUSIC 2019" promotion (hereinafter the "Promotion"), the participant declares to have read, understood and fully abided by the Terms and Conditions set forth herein (hereinafter the "Terms").</p>
                                    <p>Responsible for the promotion: Massy Distribution </p>
                                    <p><strong>Address: </strong>Massy Distribution (Trinidad)<br />
                                        Corner Macoya Road & Churchill Roosevelt Highway<br />
                                        Trincity | Trinidad<br />
                                        T 868 645 4434 | F 868 645 8520
                                    </p>
                                    <p>The codes of the promotion on the website of the Pringles ® page will be valid from May 8, 2019 – July 31st, 2019 and the validity for each type of prize is detailed below:</p>
                                    <div class="table-wrp">
                                        <table class="period-table globalTable">
                                            <tr>
                                                <th class="one-half">Award</th>
                                                <th class="one-half">Validity</th>
                                            </tr>
                                            <tr>
                                                <td class="one-half">Enter codes on the Pringles ® page to participate for Deezer Awards</td>
                                                <td class="one-half">May 08,
                                                    2019 – July 31 st</td>
                                            </tr>
                                            <tr>
                                                <td class="one-half">Enter codes on the Pringles ® page to participate for awards of speakers and headphones excluding Deezer</td>
                                                <td class="one-half">May 08,
                                                    2019 – July 31 st</td>
                                            </tr>
                                            <tr>
                                                <td class="one-half">Redeem winning codes on the Deezer platform.</td>
                                                <td class="one-half">May 8, 2019 to August 31, 2019</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p><strong>I. General Information: </strong> </p>
                                    <p>This Promotion is limited to purchase of Pringles 37-40g &amp; Pringles 149-158g SKUs at
                                        participating supermarkets. The Promotion runs from May 08th to August 25, 2019 as detailed
                                        above. Massy Distribution Employees are not eligible to enter. Grand Draw to be held on August
                                        15th 2019 for headphones and speakers. Winners can collect prizes any time after being
                                        contacted by Massy Distribution. Winners must produce government issued identification eg.
                                        Driver’s license, Passport or national identification card to claim possible prices: 450 Dezeer
                                        codes for 2 months premium, 2 speakers and 3 headphones. To participate, submit proof of
                                        purchase (store receipt) with contact name and contact number. Entries are to be deposited
                                        into entry boxes in participating stores. This promotion has been approved by National Lotteries
                                        &amp; Commissions Board (NLCB).</p>
                                    <p><strong>II. Participants:</strong></p>
                                    <p>"Participant" will be understood as the general consumer, who is willing to participate in the Promotion and meets each and every one of the following Requirements:</p>
                                    <ul>
                                        <li>All persons over eighteen (18) years of age are eligible to participate in this promotion / contest (all minors under twenty-one (21) years of age must present themselves with a parent or guardian and provide written consent indicating they can redeem their incentive, residents of Trinidad and Tobago. Employees and family members cannot participate until the fourth degree of consanguinity and second of affinity, as well as people who live together, or have a couple&#39;s relationship even if they do not live together, with employees of the Organizer, Sponsors and their subsidiaries, suppliers of Trinidad and Tobago, their agencies of publicity or public relations, and entities related to the development, agencies of production or distribution of materials and incentives of the Promotion / Raffle. Any person who omits information to claim that they qualify as a winner will be disqualified from the promotion / lottery and will be submitted to the relevant government authorities. This promotion is limited to the geographical area of ​​Trinidad and Tobago and is subject to all applicable laws and regulations.</li>
                                        <li>Give full reading and accept these Rules.</li>
                                        <li>Obtain a participant registration on the site providing true data, and complete the mechanics of the promotion.</li>
                                        <li>The Participant must keep at all times the packages and invoices of the Participating Products to be Promoted that contain the promotional codes registered during the Promotion, which are valid for registration only once. Said packages will be verified, validated and / or delivered to the Organizer to confirm their right to obtain the incentive and no case of disqualification will be updated.</li>
                                        <li>Complete your registration on the site as indicated below in these rules, if you are creditor of any of the incentives and for the purpose of redemption.</li>
                                        <li>Send the documentation, provide the information and / or sign the documents that are required according to these rules.</li>
                                    </ul>
                                    <p><strong>III. Prizes:</strong> </p>
                                    <p>Total: 455 exchange benefits according to the number of registered participating products, consisting of:</p>
                                    <div class="table-wrp">
                                        <table class="period-table globalTable">
                                            <tr>
                                                <th colspan="3">Premiums Available</th>
                                            </tr>
                                            <tr>
                                                <td class="one-third">Headphones Sony on-ear black wireless Bluetooth, NFC with microphone valued in US$70</td>
                                                <td class="one-third">Speaker Sound Link Micro Bluetooth speaker valued in US$150</td>
                                                <td class="one-third">2 months of Deezer premium cupon valued in US$19.98</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>2</td>
                                                <td>450</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>Description of incentives:</p>
                                    <p>a) Exchange Exchanges Incentives</p>
                                    <p>450 prizes consisting of a 2-month coupon of Deezer Premium Free valued at US $ 19.98</p>
                                    <p>The coupon will be redeemed in the application or on the Deezer website (https://www.deezer.com) during the term of the promotion and until August 31, 2019. The coupon that has not been redeemed on the August 31, 2019 can not be redeemed.</p>
                                    <ul>
                                        <li>Benefit available to free users of Deezer or new users who have never benefited from the trial period or a similar promotion granted by Deezer.</li>
                                        <li>New subscribers require a valid credit or debit card and will automatically become a paid subscriber at the end of the 2-month free period, unless you cancel your subscription before the payment period begins;</li>
                                        <li>Limited to 1 incentive (Deezer subscription) per person / user.</li>
                                        <li>Deezer will be solely responsible for the service.</li>
                                    </ul>
                                    <p>b) Registration for participation</p>
                                    <ul>
                                        <li>3 Sony brand headphones: Sony on-ear wireless bluetooth headset, nfc with microphone with a commercial value of US $ 70. The organizer is not responsible for the warranty of the hearing aids.</li>
                                        <li>2 Bose brand speakers: SoundLink Micro Bluetooth speaker with a commercial value of US $ 150. The organizer is not responsible for the warranty of the hearing aids.</li>
                                    </ul>
                                    <p><strong>IV. Participating Product:</strong></p>
                                    <p>The Participating Products are the following:</p>
                                    <ul>
                                        <li>Pringles® Original 37g, 149g </li>
                                        <li>Pringles® Sour Cream & Onion 40g, 158g </li>
                                        <li>Pringles® Cheddar Cheese 40g, 158g </li>
                                        <li>Pringles® BBQ 40g, 158g</li>
                                        <li>Other Flavors of Pringles® 158g or up</li>
                                    </ul>
                                    <p><strong>V. Promotion Mechanics:</strong> </p>
                                    <p>The participant must follow the steps below, entitled REGISTER ONLINE, to create his account and register his participation code:</p>
                                    <p>1. The participant must buy participating Pringles® products in the participating establishments</p>
                                    <p>2. The participant makes the exchange of the purchase of the Pringles® product with the promoter in turn at the point of sale who will give a number of codes according to the amount of product purchased. See Promotions Calendar.</p>
                                    <p>3. Enter the page www.promopringles.com, create your registration or log in to your account.</p>
                                    <p>4. To create the account you must provide the following information:</p>
                                    <ul>
                                        <li>a.) First name</li>
                                        <li>b.) Last name </li>
                                        <li>c.) Birthdate</li>
                                        <li>d.) Phone</li>
                                        <li>e.) E-mail</li>
                                        <li>f.) Password</li>
                                    </ul>
                                    <p>5. Choose the type of prize for which you will participate in the promotion:</p>
                                    <ul>
                                        <li>a.) Headphones</li>
                                        <li>b.) Speakers</li>
                                        <li>c.) 2 months of Deezer Premium Free</li>
                                    </ul>
                                    <p> Additionally, all records participate for the draw of:</p>
                                    <ul>
                                        <li>a.) Headphones</li>
                                    </ul>
                                    <p>Registration for exchange awards:</p>
                                    <p>You must register the one or only codes required, for validation, according to the type of prize according to the following table:</p>
                                    <div class="table-wrp">
                                        <table class="period-table globalTable">
                                            <tr>
                                                <th class="one-third">Headphones</th>
                                                <th class="one-third">Speakers</th>
                                                <th class="one-third">2 months of Deezer Premium</th>
                                            </tr>
                                            <tr>
                                                <td>2 participating products of any weight</td>
                                                <td>3 participating products of any weight</td>
                                                <td>2 participating products of 149 grs & 158 grs</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>If one or more of the codes entered are not valid, you will be notified and invited to continue participating.</p>
                                    <p>In case the validation is successful for the Deezer prize, a PDF will be generated with the coupon for the redemption of the chosen benefit. Said coupon can only be exchanged on the official website of Deezer in the account that the Participant has or believes in said platform. The coupon can be exchanged during the validity of the promotion and until August 31, 2019 or until inventory is exhausted, whichever comes first.</p>
                                    <p>Record for the draw of speakers and headphones:</p>
                                    <ul>
                                        <li>Upon obtaining the redemption prize, the participant will receive a participation code according to the number of participating products exchanged.</li>
                                        <li>After the participation period, on July 31, 2019, a lottery will be held on August 15 with all the
                                            participation sheets generated during the validity of the promotion. An alternate winner will be
                                            chosen, in case the winner does not claim his prize in the established time.</li>
                                        <li>The winner of the prize has 30 days to claim it. In case of not being claimed, the alternate winner will be informed.</li>
                                        <li>The draw will be made electronically in order to have the veracity in the selection process of the Winners.</li>
                                        <li>The selection process will be carried out through the CMS of the Site, by means of which twelve registered pages will be randomly selected, stating that the first six folios selected will be the Winners and the next six will be selected as alternates, in the event that the winner does not complete the validation process of their participation in accordance with these rules. At the time of selecting each page, the system will display the user name of the participant who registered it and their assigned ID in the system, which is unique and unrepeatable to confirm that there are ten different participants. In the event that one of the assigned user names and / or IDs is repeated, new sheets will be selected, until ensuring that, for each prize, there is a winner, a first and second substitute.</li>
                                        <li>Publication of the winner: The publication of the name of the winner will be announced once their identity and participation have been validated, through the microsite www.promopringles.com, on August 15th, 2019.</li>
                                    </ul>
                                    <p><strong>General Mechanic:</strong></p>
                                    <ul>
                                        <li>To win speakers and headphones – Raffle across all stores participants. </li>
                                        <li>2 speakers = Buy 3 cans of (158 grs, 149 grs, 40 grs, 37 grs) and enter to participate to win a speaker</li>
                                        <li>3 Headphones = Buy 2 cans (158 grs, 149 grs, 40 grs, 37 grs) and enter to participate to win a headphone</li>
                                        <li>450 (2-months Deezer Music Premium) = buy 2 cans of 158grs &amp; 149grs and enter to
                                            be able to win 1 code of Deezer Music Premium for 2 months.</li>
                                    </ul>
                                    <p>Participating Stores:</p>
                                    <p>Promotion POS Material must be on shelf </p>
                                    <p>Pringles ® BTL Activations in selected locations</p>
                                    <p><strong>Store MUST HAVES:</strong></p>
                                    <p><strong>Promotion POS Material must be on shelf </strong></p>
                                </div>
                                <h1 class="not-visible">Términos y Condiciones de la promoción La música cae mejor con Pringles®.</h1>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promotion of Pringles® | Terms & Conditions'
        }
    },
}
</script>