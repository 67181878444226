import $ from 'jquery';
import LoginRif from './LoginRif.js';

class LoginController {
    constructor(cntry, sessionCallback) {
        const formCont = document.getElementById('rifFormWrp');
        let rifConfigObj,rifForm;

        if(cntry==='br'){
            rifConfigObj = {
                moduleKey: 'defe5fb4-295e-4bff-aada-dcf1285faae5',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/br/micuenta',
            };
        }else if(cntry==='ar'){
            rifConfigObj = {
                moduleKey: 'aa11c4f4-3468-4f95-8210-8886e1a19c71',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/ar/micuenta',
            };
        }else if(cntry==='mx'){
            rifConfigObj = {
                moduleKey: '344899e8-aaed-418c-9fcd-d1a82a0338d2',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/mx/micuenta',
            };
        }else if(cntry==='do'){
            rifConfigObj = {
                moduleKey: '6bf1b881-622f-42b4-9907-9bfe88fc2f03',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/do/micuenta',
            };
        }else if(cntry==='hn'){
            rifConfigObj = {
                moduleKey: '561a4e60-576a-475e-8bb1-f3e3329150f2',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/hn/micuenta',
            };
        }else if(cntry==='ni'){
            rifConfigObj = {
                moduleKey: 'ec3171fd-b510-4af7-b584-15b78ddb853c',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/ni/micuenta',
            };
        }else if(cntry==='pr'){
            rifConfigObj = {
                moduleKey: '6edf12e1-1dfa-4638-8dce-79926276ba1b',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/pr/micuenta',
            };
        }else if(cntry==='gt'){
            rifConfigObj = {
                moduleKey: '55dc0b99-3033-4d0e-a240-fb0eb99aa6cf',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/gt/micuenta',
            };
        }else if(cntry==='sv'){
            rifConfigObj = {
                moduleKey: '5d0fa4bd-ba70-48fb-9474-6c42b521dc15',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/sv/micuenta',
            };
        }else if(cntry==='cr'){
            rifConfigObj = {
                moduleKey: 'e2ed9082-f7b2-4790-925d-ffa692702975',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/cr/micuenta',
            };
        }else if(cntry==='pa'){
            rifConfigObj = {
                moduleKey: 'f39cef1a-37c1-4d39-a7c4-ce591fa6ef80',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/pa/micuenta',
            };
        }else if(cntry==='co'){
            rifConfigObj = {
                moduleKey: 'b77173ab-c640-4bfd-898a-483442fb10be',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/co/micuenta',
            };
        }else if(cntry==='ec'){
            rifConfigObj = {
                moduleKey: '56146cbb-c24e-4e78-8fbc-19b3cda5aaff',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/ec/micuenta',
            };
        }else if(cntry==='pe'){
            rifConfigObj = {
                moduleKey: 'b4065050-dbc7-411a-aa1c-dc231d820ee3',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/pe/micuenta',
            };
        }else{
            rifConfigObj = {
                moduleKey: '344899e8-aaed-418c-9fcd-d1a82a0338d2',
                showLogin: true,
                showSignup: false,
                myAccountUrl:'/promo/mx/micuenta',
            };
        }
        rifForm = new LoginRif(formCont, rifConfigObj, (data) => {
            sessionCallback(data);
        });
        
    }
}

export default LoginController;