<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/halo/halo-466x276-co.jpg" alt="Promo Pringles® Halo">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">PROMO PRINGLES® HALO</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
									<h1 class="not-visible">Términos y Condiciones de la promoción “Promo Pringles® Halo”</h1>

									<p>Este reglamento delimita y aclara las condiciones bajo las cuales se regirá la actividad.</p>
									<p>
										Lo aquí dispuesto será de acatamiento obligatorio para participantes y organizador. Se entenderá que todo participante, al decidir participar, conoce y acepta las condiciones y limitaciones establecidas en el presente reglamento. La
										participación en la actividad implica la decisión del participante de obligarse por estas reglas y por las decisiones del organizador, las cuales tendrán carácter definitivo en todos los asuntos relacionados a la actividad.
										Cualquier violación a las mismas o a los procedimientos o sistemas establecidos para su realización, implicará la inmediata exclusión del mismo y/o la revocación de los premios.
									</p>
									<ul class="bullets">
										<li>DE LOS REALIZADORES: KELLOGG DE COLOMBIA S.A., es el organizador de la actividad.</li>
										<li>DE LOS PARTICIPANTES: Los participantes de la promoción (los “Participantes”), deberán cumplir con los siguientes requisitos:</li>
									</ul>
									<p>
										Ser ciudadano colombiano o ciudadano extranjero con residencia legal en Colombia.<br />
										Ser mayor de edad (18 años cumplidos en adelante) y titular de Cédula de Ciudadanía o Cédula de Extranjería, que deberá presentar si es acreedora del Premio.
									</p>
									<p>
										Toda persona que desee participar en la actividad o reclamar un premio deberá tener conocimiento de este reglamento, ya que la aceptación y recibo del premio conlleva la forzosa e ineludible obligación de conocer las condiciones de
										participación, así como las condiciones, limitaciones y responsabilidades, no sólo de este reglamento, sino las que en virtud de este mismo documento conlleva el reclamo y aceptación de un premio.
									</p>
									<p><strong>VIGENCIA:</strong> Desde Marzo 1 de 2021 a las 00:00 hasta Abril 30 de 2021 11:59 pm.</p>
									<p><strong>TERRITORIO:</strong> Esta actividad aplica para el territorio nacional de la República de Colombia.</p>

									<p><strong>1. DESCRIPCIÓN Y ACCESO A LA PROMOCIÓN:</strong></p>

									<p>
										El acceso a la promoción será habilitado a consumidores de productos Pringles® quienes por la compra de cualquier producto de cualquier gramaje de la marca Pringles® (los “Productos Participantes”) tendrán la oportunidad de
										participar para ganar cualquiera de los premios de la promoción. Los pasos a seguir para poder participar son los siguientes:
									</p>
									<p>1.1 El Participante deberá comprar cualquier Producto Participante.</p>
									<p>1.2 El participante deberá ingresar a la página www.promopringles.com (la “Página”) y registrarse.</p>
									<p>
										1.3 Una vez el participante haya accedido a la página y se haya registrado, el Participante deberá ingresar el número del lote y la fecha de vencimiento que se encuentra en las latas o tapas de los Productos Participantes, y seguir
										las instrucciones que se encuentran en la Página. Cada lote y fecha de vencimiento que corresponda a un producto podrá ser registrado única y exclusivamente una vez en la Página.
									</p>
									<p>
										1.4 Los participantes deberán guardar los empaques con los que participen con el fin de poder acceder a ganar cualquier premio de la promoción. Los empaques deben estar completos y en buen estado y no podrán tener ninguna
										información borrada o modificada. El organizador podrá revisar que cada uno de los lotes y fecha de vencimiento registrado corresponda a UN (1) Producto Participante, y en consecuencia, en caso de que un producto sea registrado más
										de una vez en la Página o sea registrado un lote que no corresponde a un Producto Participante, el Participante será desclasificado.
									</p>
									<p>
										1.5 Mientras más empaques diferentes registre el Participante, más oportunidades de ganar tendrá. Cada Producto Participante podrá ser registrado en una única ocasión. Los productos registrados en dos o más ocasiones o el registro
										de lotes inexistentes o cuyo empaque no se tenga guardado como soporte tendrá como consecuencia la descalificación de la Promoción.
									</p>
									<p>1.6 En caso de empate en la cantidad de códigos registrados, los ganadores serán los participantes que hayan registrado primero la totalidad de los códigos, considerando día, hora y minuto.</p>
									<p>1.7 Cualquier inquietud de los participantes debe ser tratada por la línea de servicio al cliente nacional 01-800-952-0686 o a través del correo electrónico Consumer-Affairs.LA@kellogg.com.</p>
									<p>1.8 Sólo podrán participar los consumidores que cumplan con los requisitos mencionados en el numeral tercero del presente documento.</p>
									<p>Los participantes podrán participar por cualquiera de los siguientes premios (los “Premios”);</p>
									<p><strong>2. PREMIOS: </strong></p>
									<p>
										Son los premios a los que los participantes podrán acceder una vez hayan registrado los códigos y hayan logrado un puntaje que les permita entrar en el ranking de los primeros puestos conforme a los lineamientos de los presentes
										T&amp;C. En total son 2.000 premios distribuidos de la siguiente manera:
									</p>
									<p>Premios Aspiracionales: Tres (3) consolas Xbox Series X con dos controles en total y treinta (30) consolas Xbox Series S.</p>
									<p>Los Premios se entregarán de conformidad con la tabla que se señala a continuación:</p>
									<div class="globalTableWrp">
										<table border="0" cellpadding="0" cellspacing="0" class="globalTable">
											<tr>
												<th># DE GANADORES</th>
												<th>PREMIOS</th>
												<th>REQUISITO</th>
											</tr>
											<tr>
												<td>3</td>
												<td>1 Xbox Series X con dos controles en total</td>
												<td>Posición 1 al 3 en el ranking de los 33 participantes con el mayor número de códigos registrados acumulados del 1 de Marzo 00:00 al 30 de Abril 11:59pm</td>
											</tr>
											<tr>
												<td>7</td>
												<td>1 Xbox Series S con dos controles en total</td>
												<td>Posición 4 al 10 en el ranking de los 33 participantes con el mayor número de códigos registrados acumulados del 1 de Marzo 00:00 al 30 de Abril 11:59pm</td>
											</tr>
											<tr>
												<td>23</td>
												<td>1 Xbox Series S con un control en total</td>
												<td>Posición 11 al 33 en el ranking de los 33 participantes con el mayor número de códigos registrados acumulados del 1 de Marzo 00:00 al 30 de Abril 11:59pm</td>
											</tr>
										</table>
									</div>
									<p>
										La lista de posibles ganadores de los premios aspiracionales se publicará en la Página y la lista se mantendrá publicada una vez finalizado el término de vigencia de la promoción y se haya podido comprobar que los posibles ganadores
										cumplieron con la totalidad de los requisitos de la promoción. La lista de posibles ganadores se mantendrá publicada hasta el 1 de septiembre de 2021.
									</p>
									<p>
										<strong>Premios Secundarios:</strong> Mil novecientos sesenta y siete (1.967) pases de juego “XBOX GAME PASS ULTIMATE ” de vigencia de un (1) mes, que se entregarán quincenalmente. El organizador podrá revisar que cada uno de los
										lotes y fecha de vencimiento registrado corresponda a UN (1) Producto Participante, y en consecuencia, en caso de que un producto sea registrado más de una vez en la Página o sea registrado un lote que no corresponde a un Producto
										Participante, el Participante será desclasificado.
									</p>
									<p>Los premios serán entregados de acuerdo con el ranking acumulado durante la quincena bajo el siguiente cronograma:</p>
									<div class="globalTableWrp">
										<table border="0" cellpadding="0" cellspacing="0" class="globalTable">
											<tr>
												<th># DE GANADORES</th>
												<th>DE GANADORES</th>
												<th>PREMIOS</th>
												<th>REQUISITOS</th>
											</tr>
											<tr>
												<td>500</td>
												<td>
													Ganadores del 1 Marzo-<br />
													15 de marzo
												</td>
												<td>1 XBOX GAME PASS ULTIMATE- Vigencia 1 mes</td>
												<td>Posición 1 al 500 en el ranking de los 500 participantes con el mayor número de Códigos registrados acumulados del 1 de Marzo 00:00 al 15 de Marzo 11:59pm</td>
											</tr>
											<tr>
												<td>500</td>
												<td>
													Ganadores del 16 Marzo-<br />
													31 de Marzo
												</td>
												<td>1 XBOX GAME PASS ULTIMATE- Vigencia 1 mes</td>
												<td>Posición 1 al 500 en el ranking de los 500 participantes con el mayor número de códigos registrados acumulados del 16 de Marzo 00:00 al 31 de Marzo 11:59pm</td>
											</tr>
											<tr>
												<td>500</td>
												<td>
													Ganadores del 1 Abril - <br />
													15 de Abril
												</td>
												<td>1 XBOX GAME PASS UTIMATE- Vigencia 1 mes</td>
												<td>Posición 1 al 500 en el ranking de los 500 participantes con el mayor número de códigos registrados acumulados del 1 de Abril 00:00 al 15 de Abril 11:59pm</td>
											</tr>
											<tr>
												<td>467</td>
												<td>
													Ganadores del 16 Abril- <br />
													30 de Abril
												</td>
												<td>
													1 XBOX GAME PASS ULTIMATE- Vigencia 1 <br />
													mes
												</td>
												<td>Posición 1 al 467 en el ranking de los 467 participantes con el mayor número de códigos registrados acumulados del 16 de Abril 00:00 al 30 de Abril 11:59pm</td>
											</tr>
										</table>
									</div>
									<p>
										El ranking de ganadores quincenal, se comunicará a las cuarenta y ocho (48) horas siguientes del cierre de la semana en la página de la promoción o una vez el organizador haya podido validar completamente los posibles ganadores. Los
										nombres de los ganadores serán publicados en la página web de la promoción www.promopringles.com.
									</p>
									<p>Para los premios secundarios, los puntajes se reiniciarán quincenalmente una vez finalizado el día. Se entenderá que el día termina a las 11:59 pm.</p>
									<p>
										Los ganadores de los premios quincenales serán notificados, semana vencida dentro de las 48 horassiguientes al cierre quincenal. Los ganadores de los premios quincenales recibirán su XBOX GAME PASS (Vigencia de 1 mes) al correo
										electrónico suministrado en la página de la promoción.
									</p>
									<p>
										Un usuario no podrá ser ganador de más de un premio secundario en los 4 cortes de la promoción. Es decir, un usuario puede llevarse máximo un (1) XBOX GAME PASS en toda la promoción, esto de acuerdo a su posición en el ranking.
										Todos los usuarios participarán por los premios aspiracionales.
									</p>

									<p><strong>3. SELECCIÓN DE GANADORES Y PROCEDIMIENTO PARA LA ENTREGA DE LOS PREMIOS:</strong></p>

									<p>
										Al momento en que los Participantes registren el lote del producto en la Página y diligencien la información requerida (nombres y apellidos, número de cédula, teléfono, email, dirección, ciudad y barrio), estos quedarán registrados
										como participantes en el concurso para ganar cualquiera de los Premios.
									</p>
									<p>Una vez se determinen los posibles ganadores de los premios aspiracionales se procederá a llamar a los Participantes al número telefónico registrado y a enviar un correo electrónico a la dirección electrónica suministrada.</p>
									<p>
										Al contestar la llamada o el correo, confirmar sus datos personales al organizador, verificar la posesión de los empaques y siempre y cuando el Participante no incurra en ningún supuesto de descalificación de conformidad con los
										presentes términos y condiciones, el Participante se convertirá en ganador de uno de los premios establecidos.
									</p>
									<p>
										En caso de que el Participante no conteste las llamadas o no responda el correo en las siguientes 48 horas, se entenderá que el Participante ha renunciado al premio y se contactará a la siguiente persona en la lista de posibles
										ganadores.
									</p>
									<p>
										Las instrucciones para reclamar los premios se darán al posible ganador al momento de contactarlo. Una vez se les notifique a los participantes que han sido seleccionados como los posibles ganadores, y estos manifiesten su intención
										de aceptar el premio, estos tendrán 24 horas desde la llamada para allegar la documentación requerida incluyendo las pruebas de posesión de los empaques con los respectivos lotes registrados, de lo contrario perderá(n) el premio de
										manera automática. Recibidas las pruebas, se visitará en el domicilio a los posibles ganadores para verificar físicamente la posesión de los empaques y en caso de que no exista ninguna novedad, se realiza la entrega del premio.
									</p>
									<p>Para efectos de la entrega de los premios mayores y Premios Secundarios, los Participantes declaran entender que:</p>
									<p>Que los premios mayores y premios secundarios serán entregados por el proveedor contratado para tal fin.</p>
									<p>
										Kellogg entregará los premios secundarios (XBOX GAME PASS ULTIMATE Vigencia 1 mes) por medio de la página www.promopringles.com, para tal fin, los ganadores deberán ingresar con su usuario y contraseña y podrán encontrar su código
										de redención. Adicionalmente, recibirán un correo a la dirección electrónica suministrada con el código del XBOX GAME PASS. Kellogg podrá solicitar la prueba física de empaques registrados.
									</p>
									<p>Ningún premio es reembolsable en dinero, ni canjeable y serán entregados únicamente al ganador seleccionado.</p>

									<p><strong>4. DESCALIFICACIÓN</strong></p>

									<p>
										Si en cualquier momento, sea antes, durante o con posterioridad a esta actividad, se descubre que elganador incumple con este reglamento, o se sospecha que realizó algún tipo de fraude, el organizador podrá descalificarlo y negarse
										a entregarle el premio. Si éste ya fue entregado, podrá exigir su devolución por las vías de Ley.
									</p>
									<p>
										Estará prohibido y será anulado cualquier intento o método de participación en la actividad que se realice por cualquier proceso, técnica o mecánica de participación distinta a la detallada en este reglamento. En el evento que
										Kellogg conozca que un participante ha incumplido con lo aquí establecido, será descalificado de la actividad.
									</p>

									<p><strong>5. CONDICIONES Y RESTRICCIONES DE LA ACTIVIDAD:</strong></p>

									<p>La participación de los interesados, así como la actividad y los premios, están sujetos a las condiciones y restricciones que se indican en este reglamento.</p>
									<p>Habrá total coincidencia entre los datos brindados por parte de la persona para participar y los datos brindados al momento de resultar ganador, de otro modo el premio no será entregado.</p>
									<p>La responsabilidad de Kellogg de Colombia S.A., culmina con la entrega del premio.</p>
									<p>Los ganadores relevan de toda responsabilidad a los organizadores de cualquier daño, sobre las personas o las cosas, que se pudiesen ocasionar durante el uso del premio, ya sea por su uso o de terceras personas.</p>
									<p>No se admiten cambios por dinero, valores o cualquier otro producto material. Los premios son intransferibles.</p>
									<p>
										Si el ganador no acepta el premio o sus condiciones, el premio se considera renunciado y extinguido en relación al ganador y no tendrá derecho a reclamo o indemnización alguna, ni siquiera parcialmente.<br />
										*NO PODRÁ PARTICIPAR EN LA ACTIVIDAD NINGUNA PERSONA QUE ACTUALMENTE SEA TRABAJADOR DEPENDIENTE DEL ORGANIZADOR, DE KELLOGG DE COLOMBIA S.A. O FAMILIAR HASTA EL PRIMER Y SEGUNDO GRADO DE CONSANGUINIDAD O DE AFINIDAD, ASÍ COMO
										CÓNYUGES O CONVIVIENTES POR UNIÓN DE HECHO. APLICA TAMBIÉN PARA EL PERSONAL DE LAS AGENCIAS QUE TRABAJAN PARA KELLOGG DE COLOMBIA S.A.
									</p>
									<p>Exención y limitación de responsabilidad de Kellogg:</p>
									<p>Kellogg, estará exenta de cualquier tipo de responsabilidad por daños y perjuicios de toda naturaleza en los siguientes casos:</p>
									<p>1) Por la imposibilidad o dificultades de conexión a través de la que resulta accesible el portal o cualquier de los hipervínculos contenidos en el mismo, independientemente de la clase de conexión utilizada por el Usuario.</p>
									<p>2) Por la interrupción, suspensión o cancelación del acceso a la página web, así como por disponibilidad y continuidad del funcionamiento del Portal o de los Servicios y/o Contenidos del mismo.</p>
									<p>
										3) Daños o perjuicios que pudieran causar la información, contenidos, productos y servicios, entre otros, prestados, comunicados, alojados, transmitidos, exhibidos u ofertados por terceros ajenos a Kellogg, a los cuales los Usuarios
										tengan acceso mediante hipervínculos encontrados en la página web.
									</p>
									<p>4) De la calidad y velocidad de acceso a la página web y de las condiciones técnicas que debe reunir el Usuario con el fin de poder acceder a la página web y a sus Servicios y/o Contenidos.</p>
									<p>5) Todas las demás exenciones de responsabilidad que se puedan derivar de la lectura de los presentes Términos y Condiciones.</p>
									<p>Derechos de imagen:</p>
									<p>
										Con el hecho de participar en la actividad, los ganadores aceptan y autorizan que sus nombres e imágenes aparezcan en los programas, publicaciones y demás medios publicitarios y en general en todo material de divulgación con fines
										promocionales que los organizadores deseen hacer durante la actividad o una vez finalizada la misma, sin que ello implique la obligación de remunerarlos o compensarlos. Así mismo, renuncian a cualquier reclamo por derechos de
										imagen.
									</p>
									<p>
										Los participantes autorizan expresamente a Kellogg de Colombia S.A., el derecho a usar, reproducir, distribuir, modificar, crear, poner a disposición, almacenar, grabar, publicar, por cable u otro medio (incluyendo internet y
										cualquier otro medio), todo lo anterior incluyendo finalidad comercial y/o promocional, sin limitación de territorio y tiempo las piezas que usen para participar en la actividad promocional.
									</p>
									<p>Manejo de datos y registro:</p>
									<p>
										Al aceptar los presentes términos y condiciones, de conformidad con lo regulado en la Ley 1581 de 2012, la persona está dando a KELLOGG DE COLOMBIA y/o del mismo grupo de interés, la autorización para el tratar su información
										personal de acuerdo con la política de tratamiento de datos personales disponible en www.kelloggs.com.co/es_CO/politicas-de- privacidad. Los datos personales obtenidos por KELLOGG DE COLOMBIA S.A., no tienen el carácter de
										sensibles, de conformidad con la mencionada ley y van a ser utilizados únicamente para fines de comunicación de actividades publicitarias y promocionales de KELLOGG DE COLOMBIA S.A., y sus marcas.
									</p>
									<p>
										El Titular de los datos, de conformidad con lo previsto en la legislación aplicable, cuenta con la línea gratuita nacional 01-800-952-0686 y con el Correo electrónico: HabeasDataColombia@kellogg.com para el ejercicio de sus derechos
										como titular de la información, en especial: conocer la información, solicitar la actualización, rectificación y/o supresión o revocar el consentimiento otorgado para el tratamiento de datos personales.
									</p>
									<p>Suspensión:</p>
									<p>
										En caso de haber motivos fundados de fuerza mayor, caso fortuito, o hechos de terceros, tales como desastres naturales, guerras, huelgas o disturbios, así como también situaciones que afecten la actividad; o en caso de detectarse un
										fraude o intento de fraude en perjuicio de los organizadores o los participantes de la misma, Kellogg de Colombia S.A., podrá modificar en todo o en parte esta actividad, así como suspenderla temporal o permanentemente sin asumir
										ninguna responsabilidad al respecto. En estos casos, el fundamento de las medidas que se adopten, así como las pruebas que demuestren la existencia de la causa invocada por los Organizadores estarán a disposición de cualquier
										interesado.
									</p>
									<p>Publicación:</p>
									<p>Este Reglamento de la actividad estará publicado en la Página para que pueda ser consultado por todos los participantes que así lo deseen.</p>
									<p>Se da por entendido que una vez los participantes inicien la mecánica de la actividad es porque leyó, entendió y acepto los términos y condiciones de la misma.</p>
								</div>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>