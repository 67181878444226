<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/snackea/2020/466x276-snackea-y-gana-GT.jpg" alt="Snackea para ganar $4000.00">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2020</p>
                                <h4 class="titleTerms">SNACKEA PARA GANAR $4000.00</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">                                    
                                    <h1 class="not-visible">Términos y Condiciones de Snackea para ganar $4000.00</h1>

                                    <p>Los términos y condiciones descritos a continuación (las “Bases”) establecen las condiciones y requisitos bajo los cuales se regirá la actividad promocional “SNACKEA PARA GANAR $4000.00” (en lo sucesivo la “Promoción”). Lo aquí dispuesto es obligatorio para todos los participantes de la Promoción, por lo que se deberá dar lectura total a las presentes Bases. La participación en la Promoción se interpretará como la aceptación de las Bases, así como del Aviso de Privacidad para el tratamiento de datos personales.</p>

                                    <p><strong>1. ASPECTOS GENERALES DE LA PROMOCIÓN</strong></p>

                                    <p>Nombre: “SNACKEA PARA GANAR $4000.00”<br>
                                    Cobertura: Toda la República de Guatemala<br>
                                    Vigencia: 01 de junio de 2020 al 15 de julio de 2020<br>
                                    Naturaleza: Promoción de compra y registro, con selección Al Azar.<br>
                                    Organizador: Picshow S.A<br>
                                    Patrocinador: Alimentos Kellogg de Guatemal S.A</p>

                                    <p><strong>2. ESTABLECIMIENTOS PARTICIPANTES</strong></p>

                                    <p>Los establecimientos participantes será cualquier establecimiento que venda Pringles® y pueda emitir una factura valida legalmente.</p>

                                    <p><strong>3. PRODUCTOS PARTICIPANTES</strong></p>

                                    <p>Los productos participantes en la Promoción</p>

                                    <ul class="bullets">
                                        <li>37gr</li>
                                        <li>40 gr</li>
                                        <li>71 gr</li>
                                        <li>77 gr</li>
                                        <li>112gr</li>
                                        <li>124gr</li>
                                        <li>130gr</li>
                                        <li>137gr</li>
                                        <li>149gr</li>
                                        <li>158gr</li>
                                        <li>194gr</li>
                                        <li>203gr</li>
                                        <li>5 pack</li>
                                        <li>95gr y 105gr</li>
                                        <li>12 pack de 252gr o 258gr (muelitas)</li>
                                    </ul>

                                    <p>Que estarán de venta en todos los Establecimientos Participantes.</p>

                                    <p><strong>4. REQUISITOS DE ELEGIBILIDAD PARA PARTICIPAR EN LA PROMOCIÓN</strong></p>
                                    
                                    <p>Podrá participar en la Promoción cualquier persona física que tenga la disposición de participar, compre los Productos Participantes y reúna todos y cada uno de los siguientes requisitos (el “Participante” o los “Participantes”):</p>

                                    <ol class="decimal">
                                        <li>Ser mayor de edad con plena capacidad de ejercicio legal.</li>
                                        <li>Contar con dirección de correo electrónico</li>
                                    </ol>

                                    <p>No podrán participar todas aquellas personas que sean familiares hasta el tercer grado de un empleado o empleados del Organizador ni del patrocinador, subsidiarias y/o agencias “partners” así como de cualquiera de las empresas que formen parte del mismo grupo de intereses económicos al que pertenece el Organizador y las empresas vinculadas en la ejecución de la Promoción.</p>

                                    <p><strong>5. MECÁNICA DE LA PROMOCIÓN</strong></p>

                                    <p>Para participar en la Promoción, los Participantes deberán cumplir con la siguiente mecánica de la Promoción y los requisitos de elegibilidad previstos en las presentes Bases.</p>

                                    <ol class="decimal">
                                        <li>Comprar cualquiera de los Productos Participantes Pringles® en los Establecimientos Participantes.
                                            <ul class="bullets">
                                                <li>37gr</li>
                                                <li>40 gr</li>
                                                <li>71 gr</li>
                                                <li>77 gr</li>
                                                <li>112gr</li>
                                                <li>124gr</li>
                                                <li>130gr</li>
                                                <li>137gr</li>
                                                <li>149gr</li>
                                                <li>158gr</li>
                                                <li>194gr</li>
                                                <li>203gr</li>
                                                <li>5 pack 95gr y 105gr</li>
                                                <li>12pack de 252gr o 258gr (muelitas)</li>
                                            </ul>
                                        </li>

                                        <li>Enviar por Whatsapp una foto legible de cada una de las facturas legales con número de correlativo y/o número de identificación tributaria del establecimiento, donde se muestre la compra de los Productos Participantes.</li>

                                        <li>Se verificará si la factura es válida (este proceso durará máximo 24 horas) mientras eso ocurre se enviará link con términos y condiciones</li>

                                        <li>Verificada la factura, se enviará un código único de participación, el cual, al finalizar el período de vigencia de la Promoción, participará en la selección Al Azar por medio de tómbola electrónica para optar al premio, siempre que cumpla con los requisitos previamente descritos.</li>
                                        
                                        <li>Se le enviará al Participante por Whatsapp los términos y condiciones de la Promoción donde deberá aceptar los mismos enviando la palabra “SI” o “ACEPTO” para poder ser elegible como ganador.</li>

                                        <li>Se le notificará al Participante la fecha del sorteo de la Promoción la cual se realizará el día lunes 20 de julio de 2020, y se le contactará posteriormente para notificar si ha sido seleccionado al azar o para darle las gracias por su participación. Se citará para el día jueves 30 de Julio al ganador para la entrega del premio.</li>

                                        <li>En caso el Participante sea notificado que ha sido seleccionado al azar, se le contactará para verificar el cumplimiento de los Requisitos de Elegibilidad en un plazo de 5 días hábiles a partir de que sea contactado, y una vez confirmado el cumplimiento de todos los requisitos de elegibilidad, se le considerará ganador de la Promoción.</li>

                                        <li>La línea de Whatsapp, estará abierta durante el período de la Promoción con un agente exclusivo para cualquier duda o comentario del Participante, referente a la Promoción.</li>
                                    </ol>

                                    <p><strong>6. PREMIOS</strong></p>

                                    <ol class="decimal">
                                        <li>El Premio de la Promoción (el “Premio”) será un cheque de caja de $4000.00 (Cuatro mil Dólares Americanos).</li>
                                        <li>2,500 (Dos mil quinientos) juegos móviles digitales descargables para Smartphone (Premio secundario para usuarios participantes).</li>
                                    </ol>

                                    <p><strong>7. SELECCIÓN DE GANADOR Y ENTREGA DE PREMIO</strong></p>

                                    <ol class="decimal">
                                        <li>Se realizará un sorteo Al Azar cumpliendo con la normativa aplicable de todos los códigos únicos de participación que se hayan emitido en el período de vigencia de la promoción.</li>
                                        <li>Una vez determinado el código ganador, se procederá a la selección de mil códigos como posibles ganadores secundarios, a los cuales se les asignará un orden de prelación según sean seleccionados. </li>
                                        <li>La notificación se hará vía Whatsapp al mismo número de teléfono desde el cual enviaron los tickets para participar en la Promoción.</li>
                                        <li>Se le notificará al código seleccionado, que es un posible ganador y que opta al premio si cumple con los todos los requisitos listados.</li>
                                        <li>De recibir confirmación que el posible ganador cumple con todos los requisitos aplicables, se procederá a la verificación visual de documentos para cerciorarse de que cumpla con los mismos. </li>
                                        <li>Los documentos se enviarán una fotografía o un scan vía correo electrónico con uno de los encargados de la agencia (Picshow) para la verificación y aceptación de los mismos.</li>
                                        <li>Una vez verificada visualmente dicha información, se le declara como el ganador.</li>
                                        <li>En caso de que el código seleccionado no cumpla con cualquiera de los requisitos, se le descartará automáticamente como ganador y se procederá a contactar al siguiente código de los 1000 códigos secundarios y proceder con la verificación antes descrita.</li>
                                        <li>Cada Participante ganador deberá firmar el recibo y finiquito de conformidad.</li>
                                        <li>Una vez entregado el Premio, el Participante que haya resultado ganador será el único responsable de gestionar acción, actividad habilitante o asunto relacionado con el Premio, liberando de toda responsabilidad a El Organizador, sus filiales, subsidiarias y contratistas. Asimismo, si se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados el Participante ganador y su acompañante a disfrutar del premio o de la actividad programada que la misma consigna por causas no imputables al Organizador, este quedará libre de toda responsabilidad frente al Participante al respecto.</li>
                                        <li>El Organizador, no será responsable por el uso que le brinde el Participante ganador al premio recibido, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del Premio.</li>
                                    </ol>

                                    <p><strong>8. FECHA DE ENTREGA DEL PREMIO</strong></p>

                                    <p>La notificación y revisión de cumplimiento de requisitos se realizará el día 20 de julio del 2020 por correo electrónico. La entrega del premio se realizará el día jueves 30 de julio de 2020.</p>

                                    <p><strong>9. CONDICIONES Y LIMITACIONES</strong></p>

                                    <p>El Organizador se reserva el derecho de verificar el cumplimiento de estas Bases por parte de los Participantes, quedando exento de cualquier obligación (incluyendo la entrega del premio o cualquier otra) ante cualquier persona que haya participado o que haya pretendido participar en la Promoción sin cumplir con las condiciones de elegibilidad, la mecánica o cualesquiera otros términos y condiciones aquí previstos. Al efecto:</p>
                                    
                                    <p>Los Participantes aceptan que todas las decisiones que adopte el Organizador sobre cualquier cuestión no prevista en estas Bases serán finales.</p>

                                    <p><strong>10. CRITERIOS DE DESCALIFICACIÓN</strong></p>

                                    <p>El Organizador podrá no entregar el Premio si considera que: a) se incumplió alguno de los requisitos establecidos para participar y para obtener el premio conforme a estas Bases; b) los datos del Participante y/o proporcionados por el Participante son falsos o están incompletos; y/o (c) existiera algún motivo razonable para presumir la existencia de fraude o cualquier otro acto ilícito o no autorizado para la obtención del Premio objeto de la Promoción. El Participante acreedor al Premio deberá seguir todas las direcciones e instrucciones de El Organizador, en caso de incumplimiento de cualquiera de éstas, el participante será descalificado.</p>

                                    <p>El Organizador se reserva el derecho de actuar legalmente en contra de cualquiera de los Participantes que haya cometido un acto ilícito en relación con la Promoción, pudiendo intentar toda clase de recursos o acciones que sean legalmente aplicables.</p>

                                    <p><strong>11. DERECHOS DE AUTOR Y PROPIEDAD INTELECTUAL</strong></p>

                                    <p>Al participar y ganar en la Promoción, el Participante autoriza expresa e irrevocablemente al Organizador, sus filiales y subsidiarias, así como a los clientes de éstas relacionados con la Promoción, a difundir en los medios que determinen, estime conveniente sin límite territorial a fijar, a reproducir, difundir su nombre y apellido completo, país y ciudad de origen, fotografías y/o uso de imagen o retrato, videos y/o voz, y en general todos aquellos datos que pudieran requerirse con motivo de la difusión de la Promoción, renunciando expresa e irrevocablemente desde la aceptación de las bases a cualquier tipo de compensación económica, remuneración, regalía o retribución alguna por dicho uso, siempre que los fines sean publicitarios y divulgativos en relación a la ley en vigor, para lo que mediante la aceptación de las presentes bases se emiten las autorizaciones que resulten necesarias de los participantes.</p>

                                    <p><strong>12. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR Y EL PATROCINADOR</strong></p>

                                    <p>El Organizador y el Patrocinador y el Patrocinador podrá en cualquier momento suspender La Promoción o la entrega del Premio sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier mandato de autoridad con facultades al efecto, así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que impidan la ejecución de Promoción. En todo este caso, El Organizador publicará a través de medios impresos y/o a través de su página web los motivos en que fundamenta la realización de cualquiera de las causas establecidas en el presente párrafo.</p>

                                    <p>La responsabilidad de El Organizador y el Patrocinador solamente comprende la Promoción en los términos y condiciones aquí previstos por lo que no asume responsabilidad alguna respecto de cualquier otra actividad promocional de productos de la marca Pringles® y/o de cualquier plataforma asociada con actividades promocionales de dicha marca.</p>

                                    <p>La responsabilidad de el Organizador concluye al momento de la entrega del Premio a sus correspondientes acreedores.</p>

                                    <p><strong>13. MODIFICACIONES</strong></p>

                                    <p>El Organizador se reserva el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica y Premio, siempre que las mismas estén justificadas o no perjudiquen a los participantes y se comuniquen a éstos debidamente conforme a la regulación aplicable.</p>

                                    <p><strong>14. DUDAS Y COMENTARIOS</strong></p>

                                    <p>Para cualquier aclaración o información referente a la Promoción se pueden consultar las Bases en: Link del mini sitio que se encontrará alojado en el dominio <a href="http://www.pringles.com" target="_blank">www.pringles.com</a> o comunicándose al Whatsapp de la promoción.</p>

                                    <p><strong>15. LEGISLACIÓN APLICABLE</strong></p>

                                    <p>Estas Bases y la relación que se genere entre cualquier Participante y el Organizador será regida y concertada con total sujeción a las leyes aplicables en la República de Guatemala y a la jurisdicción de los tribunales competentes, renunciando el Participante y el Organizador a cualquier otra jurisdicción que pudiera ser aplicable en función de su domicilio o por cualquier otra razón.</p>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>