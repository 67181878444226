<template>
    <div>
        <headers></headers>

        <div class="product_detail">

            <!-- EXAMPLE PRODUCT DETAIL -->
            <div class="product_detail_bg">

                <!-- IMAGE BACKGROUND -->
                <div class="full-image" v-if="color=='yellow'">
                    <img src="https://www.pringles.com/content/global/pringles/es_ES/jcr:content/par/commoncontentroot/productContent/par/inuitgrid_1/par/fullimageslide/image.img.jpg/1573463754366.jpg" alt="" title="">
                </div>

                <div class="full-image" v-else-if="color=='green'">
                    <img src="https://www.pringles.com/content/global/pringles/es_MX/pages/products/variedad/pringles-crema-y-cebolla-137g/jcr:content/productContent/par/inuitgrid_1/par/fullimageslide/image.img.jpg/1569340772579.jpg" alt="" title="">
                </div>

                <div class="full-image" v-else-if="color=='red'">
                    <img src="https://www.pringles.com/content/global/pringles/es_MX/pages/products/variedad/pringles-original-137g/jcr:content/productContent/par/inuitgrid_1/par/fullimageslide/image.img.jpg/1569340773870.jpg" alt="" title="">
                </div>

                <div class="product_detail_wrapper">
                    <div class="product_detail_pack">
                        <img :src="this.producto.ProductURL" :alt="this.producto.ProductName" :title="this.producto.ProductName" height="330">
                    </div>
                    <div class="product_detail_info">
                        <h2 class="product_detail_header">
                            {{this.producto.ProductName}}
                        </h2>
                        <div class="product_detail_description">
                            <p>{{this.producto.Description}}</p>
                        </div>
                        
                        <a v-if="this.nutritions!=null" v-on:click="showNutrition" class="product_btn">Tabla nutrimental</a>
                        
                        <!--ul class="product_detail_share">
                            <li>
                                <a class="share-btn share-facebook" target="_blank" title="Facebook" :href="this.urlFacebook">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-facebook-2" title="Facebook" alt="Facebook" width="30" height="30">
                                        <title>Facebook</title>
                                        <g>
                                            <path d="M22 5.16c-.406-.054-1.806-.16-3.43-.16-3.4 0-5.733 1.825-5.733 5.17v2.882H9v3.913h3.837V27h4.604V16.965h3.823l.587-3.913h-4.41v-2.5c0-1.123.347-1.903 2.198-1.903H22V5.16z" fill-rule="evenodd"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                <a class="share-btn share-twitter" target="_blank" title="Twitter" :href="this.urlTwitter">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" role="img" aria-labelledby="at-svg-twitter-3" title="Twitter" alt="Twitter" width="30" height="30">
                                        <title>Twitter</title>
                                        <g>
                                            <path d="M27.996 10.116c-.81.36-1.68.602-2.592.71a4.526 4.526 0 0 0 1.984-2.496 9.037 9.037 0 0 1-2.866 1.095 4.513 4.513 0 0 0-7.69 4.116 12.81 12.81 0 0 1-9.3-4.715 4.49 4.49 0 0 0-.612 2.27 4.51 4.51 0 0 0 2.008 3.755 4.495 4.495 0 0 1-2.044-.564v.057a4.515 4.515 0 0 0 3.62 4.425 4.52 4.52 0 0 1-2.04.077 4.517 4.517 0 0 0 4.217 3.134 9.055 9.055 0 0 1-5.604 1.93A9.18 9.18 0 0 1 6 23.85a12.773 12.773 0 0 0 6.918 2.027c8.3 0 12.84-6.876 12.84-12.84 0-.195-.005-.39-.014-.583a9.172 9.172 0 0 0 2.252-2.336" fill-rule="evenodd"></path>
                                        </g>
                                    </svg>
                                </a>
                            </li>
                        </ul-->
                    </div>
                </div>
            </div>
            
            <!-- EXAMPLE PRODUCT RELATED -->
            <div class="product_related">
                <!--h3 class="product_related_header">Related Products</h3-->
                <ul class="product_related_list">
                    <li v-bind:key="item" v-for="item in this.producto.RelatedProducts">
                         <router-link :to="{name:'producto', 'params': {'countryId': cntry, 'productId': '4508681'}}">
                            <img :src="item.ProductURL" :alt="item.ProductName" height="150">
                            <h3>{{item.ProductName}}</h3>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="modal product_modal" v-if="nutrition">
                <div class="product_modal_box">
                    <div class="product_modal_header">
                        <h3 class="product_modal_title">Tabla nutrimental</h3>
                        <span class="product_modal_close"><a v-on:click="closeNutrition">X</a></span>
                    </div>

                    <div class="product_modal_body">
                        <div class="product_modal_ingredients">
                            <p>{{this.nutritions.Ingredients}}</p>
                        </div>

                        <table class="product_modal_table">
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>Calorías</td>
                                <td><p>{{this.nutritions.Calories}}</p></td>
                            </tr>
                            <tr>
                                <td>Grasas Totales</td>
                                <td><p>{{this.nutritions.TotalFat}}</p></td>
                            </tr>
                            <tr>
                                <td>Grasas Saturadas</td>
                                <td><p>{{this.nutritions.SaturatedFat}}</p></td>
                            </tr>
                            <!--tr>
                                <td>Grasas Trans</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Colesterol</td>
                                <td></td>
                            </tr-->
                            <tr>
                                <td>Sodio</td>
                                <td><p>{{this.nutritions.Sodium}}</p></td>
                            </tr>
                            <tr>
                                <td>Carbohidratos Totales</td>
                                <td><p>{{this.nutritions.Carbohydrates}}</p></td>
                            </tr>
                            <tr>
                                <td>Fibra dietética</td>
                                <td><p>{{this.nutritions.DietaryFiber}}</p></td>
                            </tr>
                            <tr>
                                <td>Azúcares</td>
                                <td><p>{{this.nutritions.Sugar}}</p></td>
                            </tr>
                            <tr>
                                <td>Proteínas</td>
                                <td><p>{{this.nutritions.Protein}}</p></td>
                            </tr>
                            <!--tr>
                                <td>Certificado Kosher</td>
                                <td><p>{{this.nutritions.KosherStatus}}</p></td>
                            </tr-->
                            <tr>
                                <td>Alérgenos</td>
                                <td><p>{{this.nutritions.AllergenNotes}}</p></td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>
        <Footers></Footers>
    </div>
</template>
<script>

import axios from 'axios';
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'

export default {
    data() {
        return {
            cntry:'',
            producto:{},
            urlFacebook:'',
            urlTwitter:'',
            color:'yellow',
            nutritions:null,
            nutrition:false,
        }
    },
    mounted() {

        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        this.$store.dispatch('setBodyClass', 'global')

        this.cntry=this.$store.state.country.id.toLowerCase();
        let url;
        
        if(window.location.href.indexOf('localhost')>=0){
            url='http://localhost:59828/api/Product/product?countryCode='+this.cntry+'&productId='+this.$route.params.productId    
        }else if(window.location.href.indexOf('stage.promopringles')>=0){
            url='http://stage.promopringles.com/api/Product/product?countryCode='+this.cntry+'&productId='+this.$route.params.productId
        }else if(window.location.href.indexOf('promopringles-stg.interalia.net')>=0){
            url='https://promopringles-stg.interalia.net/api/Product/product?countryCode='+this.cntry+'&productId='+this.$route.params.productId            
        }else{
            url='https://www.promopringles.com/api/Product/product?countryCode='+this.cntry+'&productId='+this.$route.params.productId
        }

        axios
        .get(url)
        .then((response) => {
            if(response.data.Success){
                this.producto=JSON.parse(response.data.Product);
                if(this.producto.ProductName.toLowerCase().indexOf('original')>=0){
                    this.color="red";
                }else if(this.producto.ProductName.toLowerCase().indexOf('cebolla')>=0 || this.producto.ProductName.toLowerCase().indexOf('cebola')>=0){
                    this.color="green"
                }

                console.log("Sizes");
                console.log(this.producto.Sizes.length)
                console.log(this.producto.Sizes);

                //if(this.producto.Sizes[0]!=undefined){

                var j=0;
                while(j<=this.producto.Sizes.length && this.nutritions==null){
                    //var i=0;
                    //console.log(this.producto.Sizes[j].Nutritions[i].length)
                    /*while(i<=this.producto.Sizes[j].Nutritions[i].length && this.nutritions===null){
                        this.nutritions=this.producto.Sizes[j].Nutritions[i];
                        i++
                    }*/

                    if(this.producto.Sizes[j]!=undefined){
                        this.nutritions=this.producto.Sizes[j].Nutritions[0];
                    }
                    j++;
                }
                    //this.nutritions=this.producto.Sizes[0].Nutritions[0];
                    console.log("Nutritions")
                    console.log(this.nutritions)

                //}
               
               /*
                console.log("Sizes");
                console.log(this.producto.Sizes);

                console.log("Nutritions");    
                console.log(this.producto.Sizes[0].Nutritions)
                 console.log("Ingredients");    
                console.log(this.producto.Sizes[0].Nutritions[0].Ingredients)

            */

                this.urlFacebook='https://www.facebook.com/sharer/sharer.php?u='+window.location.href;
                this.urlTwitter='https://twitter.com/intent/tweet?text='+this.producto.ProductName+'&url='+window.location.href;
            }
        })
        .catch(() => {
            //this.mensaje=error;
        })
        


        
    },
    methods: {
        showNutrition(){
            this.nutrition=true;
        },
        closeNutrition(){
            this.nutrition=false;
        }
    },
    metaInfo() {
        return {
            title: this.producto.ProductName,
            meta: [
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {name: 'description', content: this.producto.Description}
            ]
        }
    },
    components: {
        Headers,
        Footers
    },
}
</script>
