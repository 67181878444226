import moment from 'moment'

const formatMonth = {}

function changeFormatMonth(month) {
    return moment(month).format('MMMM')
}

formatMonth.install = function (Vue) {
    Vue.filter('format-month', (month) => {
        if (month) {
            return changeFormatMonth(month)
        }

        return ''
    })
}

export default formatMonth