<template>
  <div>
    <headers></headers>
    <main class="mainWrp ">
      <section class="globalWrp">
        <aside class="titleSectionWrp">
          <router-link class="backLink" :to="{name: 'promoHome', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
          <h2>{{ $t('contact')}}</h2>
        </aside>
        <main class="flexWrapMob">
          <img class="latas" src="@/assets/images/global/latas-global.png" alt />
          <div class="contactWrp">
            <div class="contacInfo phone">
              <ul v-if="itemPhone!==''">
                <li class="infoWrp line">
                  <span class="icon"></span>
                  <div class="info">
                    <strong class="big">{{ $t('call')}}</strong>
                    <span class="small">{{ $t('free')}}</span>
                  </div>
                </li>
                <li class="infoWrp" v-for="(item, index) in terms" :key="index">
                  <!-- <strong>Lunes a viernes:</strong>
                  8:00 a 20:00 h.
                  
                  <br />
                  <strong>Sábados:</strong>
                  9:00 a 15:00 h. -->
                  <!-- <br /> -->
                  {{item.phone}}
                </li>
              </ul>
            </div>
            <div class="contacInfo mail">
              <ul v-if="itemMail!==''">
                <li class="infoWrp line">
                  <span class="icon mail"></span>
                  <div class="info">
                    <strong class="big">{{ $t('email')}}</strong>
                    <span class="small">{{ $t('electronic')}}</span>
                  </div>
                </li>
                <li class v-for="(item, index) in terms" :key="index">{{item.email}}</li>
              </ul>
            </div>
          </div>
        </main>
      </section>
    </main>
    <h1 class="not-visible">{{ $t('contactoHomeH1')}} </h1>
    <Footers class="pageFixed"></Footers>
  </div>
</template>

<script>

import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from 'vuex'

export default {
  data() {
    return {
      terms: [],
      itemPhone: '',
      itemMail: ''
    }
  },
  components: {
    Headers,
    Footers
  },
  mounted() {
    this.setData();
    this.$store.dispatch('setBodyClass', 'global')
  },

  computed: {
    ...mapState(['country'])
  },
  
  methods: {
    setData() {
      this.getTerms();
    },
    getTerms() {
      import(`@/assets/json/countries_data.json`)
        .then(({ default: countries }) => {

            let country_id = this.country.id.toLowerCase()

            countries.forEach((entry) => {
                
                if(entry.value === country_id) {
                    this.terms = [...entry.contacto]
                    this.itemPhone = this.terms[0].phone
                    this.itemMail = this.terms[0].email
                }

            });

            this.loading = false

        })
        .catch(() => {

            this.terms = []
            this.loading = false
        })
    },
  },
  metaInfo() {
        const country_i = localStorage.getItem('country').toLowerCase()

        if (country_i === 'do' ||
            country_i === 'mx' ||
            country_i === 'co' ||
            country_i === 'ec' ||
            country_i === 'pe' ||
            country_i === 'cr' ||
            country_i === 'sv' ||
            country_i === 'gt' ||
            country_i === 'hn' ||
            country_i === 'ni' ||
            country_i === 'pa' ||
            country_i === 'pr' ||
            country_i === 'ar') {
            return {
                title: '¿Necesitas ayuda con algo? ¡Contáctanos! |Pringles® Internacional',
                meta: [{
                        name: 'description',
                        content: 'Resolver tus dudas o darnos una sugerencia, cae mejor cuando puedes hacerlo directamente con nosotros. Contáctanos vía telefónica o por correo electrónico.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        } else if (country_i === 'tt'){
            return {
                title: '¿Do you need help? Contact us. Pringles® International',
                meta: [{
                        name: 'description',
                        content: 'Solving your doubts or give us a suggestion, is better when you can do it directly with us. Contact us by phone or email.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        } else if (country_i === 'br'){
            return {
                title: 'Precisa de ajuda com alguma coisa. Fale conosco. Pringles® Internacional',
                meta: [{
                        name: 'description',
                        content: 'Tire suas dúvidas com a gente ou dê uma sugestão diretamente para nós. Entre em contato por telefone ou por e-mail.'
                    },
                    {
                        name: 'keyword',
                        content: ''
                    },
                ]
            }
        }
    }
};
</script>


