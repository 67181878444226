<template>
    <div>
        <headers></headers>
        <div class="product">
           
            <ul id="products-menu" class="product_category_nav" >
                <li 
                    v-bind:key="index" 
                    v-for="(item, index) in activeCategories" 
                >
                    <a v-bind:href="'#sec'+index">{{item.categoria}}</a>
                </li>
            </ul>
            <categoria 
                v-bind:key="indx" 
                v-for="(cat, indx) in activeCategories" 
                v-bind:title="cat.categoria" 
                v-bind:prod="cat.productos"
                v-bind:shown="true"
                v-bind:cntry="cntry"
                v-bind:index="indx">
            </categoria>
        </div>
        <Footers></Footers>
    </div>
</template>
<script>
import axios from 'axios';
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import categoria from '@/components/categoria.vue'
//import Meta from 'vue-meta'
//import { mapState } from 'vuex'

export default {
    data() {
        return {
            products:[],
            dataCat:[],
            data:[],
            cntry:'',
            title:'',
        }
    },
    mounted() {
        this.title='Productos | Pringles® Internacional'      
        this.$store.dispatch('setBodyClass', 'global')
        this.cntry = this.$store.state.country.id.toLowerCase();

        import(`@/assets/json/products_${this.cntry}.json`)
           .then((response) => {
                this.dataCat=response.categorias;
                //console.log("Productos "+this.dataCat.length);
                //console.log("Productos "+response.length);

                /*for (let propert in this.dataCat[0].productos) {
                    console.log("Property "+propert);
                }
                */

                //console.log(this.productos.length);
                //console.log(this.productos)

                let url;
                if(window.location.href.indexOf('localhost')>=0){
                    url='http://localhost:59828/api/Product/All2?countryCode='+this.cntry   
                }else if(window.location.href.indexOf('stage.promopringles')>=0){
                    url='http://stage.promopringles.com/api/Product/All2?countryCode='+this.cntry
                }else if(window.location.href.indexOf('promopringles-stg.interalia.net')>=0){
                    url='https://promopringles-stg.interalia.net/api/Product/All2?countryCode='+this.cntry            
                }else{
                    url='https://www.promopringles.com/api/Product/All2?countryCode='+this.cntry
                }
                axios
                .get(url)
                .then((response) => {


                    //alert(response);
                    /*alert(hola1);
                    //alert(response.data.Success);
                    //if(response.data.Success){
                        //let p=JSON.parse(response.data.Products);
                        //let p=response.Products;
                        this.data=response.data.Payload.Products;
                        //alert(this.data);

                        console.log("DATA");
                        console.log(this.data);
                        this.completeData();
                    //}
                    */
                    this.data=response.data.Payload.Products;

                    this.completeData();

                })
                .catch(() => {
                    this.completeData();
                })

            })
            .catch(() => {
                
            })
        
    },
    computed: {
        activeCategories: function() {
            return this.products.filter(function(u) {
                return (u.total>0);
        })}
    },
    methods: {

        completeData(){
            //console.log("Data "+this.data.length);
            //console.log("Productos "+this.productos.length);
            for(let i=0; i<this.data.length; i++){
                for(let j=0; j<this.dataCat.length; j++){
                    for (let property in this.dataCat[j].productos) {
                        let pimID=this.data[i].PimID.split('@');
                       /* console.log("Property "+property);
                        console.log("Property "+pimID[0]);*/
                        if(property==pimID[0]){
                            this.dataCat[j].total++;
                            this.dataCat[j].productos[property].data=this.data[i];
                        }
                        
                    }   
                }
            }
            this.products=this.dataCat;
        }

    },

    components: {
        Headers,
        Footers,
        categoria
    },
    metaInfo() {
        return {
            title: this.title,
            meta: [{
                        name: 'description',
                        content: 'Productos Pringles®'
                    },
                    {
                        name: 'keyword',
                        content: 'Productos, Pringles®'
                    },
                ]
            }
    }
   
}
</script>
