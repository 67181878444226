import moment from 'moment'

const formatDate = {}

function changeFormatDate(date) {
    return moment(date).format('DD/MM/YYYY')
}

formatDate.install = function (Vue) {
    Vue.filter('format-date', (date) => {
        if (date) {
            return changeFormatDate(date)
        }

        return ''
    })
}

export default formatDate