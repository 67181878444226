<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <!-- <img src="@/assets/images/global/banners/snackea-pro/466x276-snackea-pa.jpg" alt="Snackea como un Pro con Pringles®"> -->
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">PRINGLES® QUIERE A TU CONTROL</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
								<div class="wrappTerms">
									<h1 class="not-visible">Términos y condiciones de la promoción “Pringles® quiere a tu control”.</h1>
								
									<p>Los términos y condiciones descritos a continuación (las &ldquo;<strong>Bases</strong>&rdquo;)  establecen las condiciones y requisitos bajo los cuales se regirá la actividad promocional &ldquo;<strong>Pringles®, quiere a tu  control</strong>&rdquo; (en lo sucesivo la &ldquo;<strong>Promoción</strong>&rdquo;).  Lo aquí dispuesto es obligatorio para todos  los participantes de la promoción, por lo que se deberá dar lectura total a las  presentes <strong>Bases</strong>.  La Promoción se ejecutará a través de la  plataforma Instagram stories de @Pringles_LATAM, las bases podrán ser  consultadas por los Participantes a través la cuenta de Instagram de Pringles® en @Pringles_LATAM y en la página <a href="https://www.promopringles.com/" target="_blank">promopringles.com</a>.</p>
									<p><strong>I. ASPECTOS GENERALES DE LA PROMOCIÓN.</strong></p>
									<p><strong>Nombre: </strong>&ldquo;Pringles® quiere a tu control&rdquo;.<br>
									<strong>Cobertura:</strong> Ciudad de México.<br>
									<strong>Vigencia:</strong> 29 de agosto de 2021 a  partir de las 9:00 am hasta las 11:59 pm. <br>
									<strong>Naturaleza: </strong>La promoción no involucra  el azar.<br>
									<strong>Organizador:</strong> Kellogg Company México, S.  de R.L. de C.V (el &ldquo;<strong>Organizador</strong>&rdquo;).<br>
									<strong>Domicilio del Organizador</strong>:  KM 1, Carretera a Campo Militar S/N, Colonia San Antonio de la Punta,  Querétaro, Qro, México. C.P. 76135.  </p>
									<p><strong>II. DEFINICIONES.</strong><u>  </u></p>
									<p>Sin perjuicio de  cualesquiera otros términos que puedan encontrarse aquí definidos, para efectos  de estas Bases se entenderá por:</p>
									<p><strong>Acreedor al incentivo.-</strong> El  Usuario que envíe la fotografía conforme a los presentes Términos y  Condiciones.</p>
									<p><strong>Call Center: </strong>Número Telefónico, al cual  deberá marcar el Participante para participar; En México son: 55-5624-2105 de  la Ciudad de México y Área Metropolitana o al 800-00-KELLOGG (800-00-53-55-644)  del interior de la República Mexicana. </p>
									<p><strong>Dinámica</strong>.- Se compone de las  diversas actividades que permitirán a los Participantes poder ganar alguno de  los incentivos que se determinan en este documento.</p>
									<p><strong>Incentivos.-</strong> Bienes que se ofrecen en  la Promoción y que se describen en la sección V. de estas Bases. </p>
									<p><strong>Mecánica de la promoción. -</strong> Conjunto de lineamientos y pasos a seguir para participar en la Dinámica y  aspirar a obtener el incentivo.</p>
									<p><strong>Participaciones.</strong>- Se entiende por  &ldquo;Participación&rdquo; cada vez que un usuario envíe sus fotografías a la cuenta de  Instagram @Pringles_LATAM.</p>
									<p><strong>Incentivos totales.-  </strong>El total de Incentivos para  la Promoción es de 10 controles de XBox One Series X/S Pulse Red.</p>
									<p><strong>III. REQUISITOS DE ELEGIBILIDAD PARA  PARTICIPAR EN LA PROMOCIÓN.  </strong></p>
									<p>Podrá participar en la  Promoción cualquier persona física que tenga la disposición de participar y  reúna todos y cada uno de los siguientes requisitos:</p>
									<ol start="1" type="1" class="decimal">
										<li>Contar       con 18 años de edad.</li>
										<li>Contar       con residencia legal en la Ciudad de México.</li>
										<li>Subir       2 Instagram Stories, la primera con el &ldquo;Contrato Pringles®&rdquo; firmado, que       podrán obtener a través de la página de Instagram de Pringles®       (@Pringles_LATAM) y la segunda fotografía de su control actual dañado por       el uso, etiquetando en ambas historias a @Pringles_LATAM.</li>
										<li>Los       usuarios deben contar con conexión a internet y una cuenta de Instagram       (IG) pública para que, en caso de etiquetar, la marca pueda revisar esta       publicación.</li>
									</ol>
									<p>El participante se obliga  expresamente a garantizar la exactitud y veracidad de todos los datos  otorgados.</p>
									<p>El Organizador se exime de  cualquier responsabilidad por errores en la información compartida por el  Participante que retrasen, obstaculicen o impidan, total o parcialmente,  posibilidad de redimir el incentivo objeto de la Promoción.</p>
									<p>No podrán participar todas  aquellas personas que sean familiares hasta el tercer grado de un empleado o  empleados del Organizador, subsidiarias y/o agencias &ldquo;partners&rdquo; así como de  cualquiera de las empresas que formen parte del mismo grupo de intereses  económicos al que pertenece el Organizador y las empresas vinculadas en la  ejecución de la Promoción.</p>
									<p><strong>IV. INCENTIVOS DE LA PROMOCIÓN.  </strong></p>
									<ul class="bullets">
									<li>Un total a nivel promoción de 10 (Diez)  controles Xbox One Series X/S Pulse Red.</li>
									</ul>
									<p>El Organizador se reserva el derecho de  cambiar/ajustar la cantidad y/o tipo de incentivos durante la Promoción en  cualquier momento como mejor convenga a sus intereses y a los de la Promoción,  cuando por causas ajenas a su voluntad no sea posible asignar los Incentivos  declarados.</p>
									<p><strong>VI. DINÁMICA DE LA PROMOCIÓN.</strong><u> </u></p>
									<p>La presente Promoción es  mediante una mecánica de envía tu foto y gana. </p>
									<ol class="decimal">
										<li>El participante deberá enviar dos  fotografías de acuerdo a lo siguiente:<br>
										La  primera deberá contener el &ldquo;Contrato Pringles®&rdquo; firmado, que podrán obtener a  través de la página de Instagram de Pringles® (@Pringles_LATAM) y la segunda  una fotografía de su control o teclado actual dañado por el uso, <strong>debiendo etiquetar en ambas historias a  @Pringles_LATAM</strong>. Su perfil deberá ser público para poder revisar la  publicación.</li>
										<li>Aquellos participantes que realicen lo  señalado en el número 1, serán acreedores a 10 controles de XBox. </li>
									</ol>
									<p>En caso de que los  Incentivos totales no se agoten al finalizar la Promoción, el Organizador podrá  disponer de éstos como mejor convenga para los intereses de la Promoción.</p>
									<p>*El Contrato Pringles® lo  podrán obtener, tomándole captura de pantalla a la historia con el template que  será publicado en la página de Instagram de @Pringles_LATAM el día 29 de agosto  del 2021.                                </p>
									<p><strong>VII. ENTREGA DE INCENTIVOS. </strong><u> </u></p>
									<ol start="1" type="1" class="decimal">
									<li>Aquellos       ganadores de los controles y teclados serán contactados por El Organizador       vía mensaje directo por Instagram, en un término de 48 hrs. Contadas a       partir de la fecha de publicación. En este contacto se pedirá su nombre       completo, teléfono, cuenta de correo electrónico y domicilio para poder       hacer el envío. </li>
									<li>Una vez contactado al ganador, El       Organizador tendrá un término máximo 30 días a partir de la fecha de       contacto, para enviar los controles a los ganadores.</li>
									<li>La entrega de los controles correrá a       cargo del Organizador.</li>
									<li>Es       responsabilidad exclusiva de los Participantes proporcionar información       correcta, veraz y completa al momento de ser contactados por la marca.</li>
									<li>El Organizador de la presente       Promoción <strong>no</strong> se hace       responsable de cualquier eventualidad, relacionada con el disfrute de       cualquiera de los Incentivos, cuando dicha eventualidad se deba a causas       ajenas al Organizador. Cualquier gasto adicional como consecuencia del       disfrute del Incentivo, no está cubierto por la Promoción ni el       Organizador, en caso de pérdida de cualquiera de los incentivos       parcialmente o totalmente, será responsabilidad exclusiva del Participante       ganador.</li>
									</ol>
									<p><strong>VIII. RESTRICCIONES Y LIMITACIONES.</strong></p>
									<p>El Organizador no se hará  responsable por cualquier falla o eventualidad en la Participación derivadas de  una mala conexión de internet del Participante.</p>
									<p><strong>IX. SUPUESTOS DE DESCALIFICACIÓN.<u> </u></strong><u> </u></p>
									<p>Además, de que la  participación en esta Promoción implica la aceptación de estas Bases, según  sean modificadas por el Organizador, así como de las decisiones que se adopten  sobre cualquier cuestión prevista o no prevista en ellas, el Organizador se  reserva el derecho a descalificar a cualquier persona que:</p>
									<ul class="bullets">
									<li>Incumpla cualquiera de los requisitos  señalados en las presentes Bases.</li>
									<li>Viole cualquier regla y/o especificación  de estas Bases.</li>
									<li>Envíe una fotografía con contenido  ofensivo, sexual o discriminatorio.</li>
									<li>No proporcione sus datos para realizar el  envío dentro de las primeras 48 horas de haber sido contactado como ganador.</li>
									<li>No permita validar la identidad su  identidad mediante acciones u omisiones en el proceso. </li>
									<li>Llevar a cabo acciones que atenten contra  el orden público, el honor de personas, los usos y las buenas costumbres, o los  valores y la reputación de las marcas asociadas a esta promoción y sus  titulares.</li>
									<li>Ponga en riesgo la integridad y buen  desarrollo de la Promoción.</li>
									</ul>

									<p>En caso de que el  Organizador descalifique una participación, no se genera responsabilidad de  ninguna índole para el Organizador ni sus funcionarios, administradores ni  personal frente al Participante ni frente a terceros. En caso de  descalificación de un Participante por cualquier motivo, el Organizador  conserva el derecho de poder realizar la asignación del Incentivo al que éste  hubiera sido acreedor, realizando una nueva selección de los Participantes o  disponer de éste a su conveniencia.</p>
									<p><strong>X. CONSIDERACIONES Y RESERVAS DE  DERECHO DEL ORGANIZADOR.</strong><u> </u></p>
									<p>Durante toda la vigencia de  la Promoción, el Organizador se reserva la facultad de, a su libre arbitrio,  eliminar de sus redes sociales y/o páginas web y/o aplicaciones cualquier  comentario o forma de interacción que no cumpla con los requisitos previstos en  este documento y/o que de cualquier manera afecten a la moral, al orden público  y/o a las buenas costumbres.</p>
									<p>Asimismo, toda forma de  interacción que contenga elementos, imágenes y/o frases obscenas y/o que de  cualquier manera perjudiquen o puedan llegar a causar un perjuicio de cualquier  índole al resto de los usuarios, a quienes interactúen de cualquier manera en  la Promoción y/o a terceros; así como aquellos elementos, imágenes y/o frases  obscenas y/o que de cualquier manera perjudiquen o puedan llegar a causar un  perjuicio de cualquier índole al Organizador o a cualquiera de los productos de  y/o marcas licenciadas a favor del Organizador, podrán ser automáticamente  descalificadas por Organizador, sin que ello conlleve responsabilidad de  ninguna índole para el Organizador ni sus funcionarios, administradores ni  personal frente al Usuario ni frente a terceros.</p>
									<p>Cualquier intento o  ejecución de aplicación de programas o sistemas que pretendan atacar o vulnerar  la Promoción, podrá ser perseguido con las acciones legales pertinentes que a  criterio del organizador sean suficientes y acarrea la inmediata  descalificación y anulación de participación del consumidor Participante que  incurra en tal conducta.</p>
									<p>Toda decisión del  Organizador será inapelable, definitiva y sin posibilidad de empate. Además de  que podrá cambiar la mecánica de participación en cualquier momento.</p>
									<p>Estas bases se rigen por la  legislación vigente en los Estados Unidos Mexicanos y se sujetan a la  jurisdicción de los tribunales competentes de la Ciudad de México.</p>
									<p><strong>XI. AVISO DE PRIVACIDAD.</strong><u> </u></p>
									<p>El presente constituye el  Aviso de Privacidad para efectos de lo dispuesto en la Ley Federal de  Protección de Datos Personales en Posesión de los Particulares (la &ldquo;LFPDP&rdquo;) y  las disposiciones que emanan de ella o se relacionan con la misma. Este Aviso  de Privacidad aplica a la información personal recopilada sobre el Titular  (como se define más adelante), por parte de <strong>Kellogg Company México, S. de R.L. de C.V.</strong>, en su carácter de  &ldquo;Encargado&rdquo;. El presente Aviso de Privacidad tiene los siguientes términos y  condiciones:</p>
									<p>Kellogg Company México, S. de  R.L. de C.V. (en lo sucesivo &ldquo;Kellogg&rdquo;), con domicilio en Km. 1 Carretera a  Campo Militar s/n Col. San Antonio de la Punta C.P. 76135 Querétaro, Querétaro,  será el responsable de los datos personales y/o sensibles de las personas  físicas (en lo sucesivo &ldquo;el Titular&rdquo;), que sean proporcionados por el Titular,  ya sea para uso tratamiento y uso de Kellogg o de cualquiera de las empresas  que formen parte de su mismo grupo de intereses económicos (el &ldquo;Grupo&rdquo;).</p>
									<p>El tratamiento de los datos  personales del Titular será efectuado de conformidad con los presentes términos  y condiciones, por lo que, desde este momento se entiende que el Titular otorga  su consentimiento para dicho tratamiento.</p>
									<p>La información que es  recolectada por Kellogg de manera directa de su Titular con su pleno  consentimiento, consistente en: nombre, domicilio, mail, teléfono,  nacionalidad, edad, y/o demás datos personales (los &ldquo;Datos Personales&rdquo;), tendrá  el uso que se describe a continuación: i) identificarle, ii) ubicarle, iii)  comunicarle, iv) contactarle, v) enviarle   información o mercancía, vi) fines estadísticos, vii) fines  publicitarios, viii) cumplimiento a la legislación vigente, ix) cumplimiento a  las obligaciones derivadas de la relación comercial.</p>
									<p>Es responsabilidad del  Titular de los Datos Personales el garantizar que los facilitados a Kellogg  sean veraces y completos, así como comunicar a Kellogg cualquier modificación  en los mismos a efecto de que se pueda cumplir con la obligación de mantener la  información actualizada.</p>
									<p>La temporalidad del manejo  de los Datos Personales del Titular en todos los casos será sólo por el tiempo  razonable y para cumplir con las obligaciones que se deriven con motivo del  vínculo que tiene el Titular con Kellogg.</p>
									<p>La información que sea  entregada a Kellogg será debidamente resguardada, conservada y protegida con  los medios tecnológicos y físicos adecuados a efecto de que se impida su  pérdida, mal uso, alteración, acceso no autorizado y robo. Sólo tendrán acceso  a la información aquellas personas estrictamente autorizadas ya sean  trabajadores, proveedores de servicios o socios de negocios, quienes han  asumido el compromiso de mantener la información bajo un estricto orden de  confidencialidad y seguridad; para ello los empleados de Kellogg firman anualmente  una Política de Privacidad, y los proveedores y socios de negocio han suscrito  en los convenios y contratos donde se conviene expresamente la confidencialidad  de la información.</p>
									<p>En caso de que los datos  personales resguardados sean requeridos por una autoridad de cualquier índole  ya sea por proceso legal, para responder a cualquier reclamo o acciones  legales, o para proteger los derechos de Kellogg o sus clientes y el público,  estos datos se podrán a su disposición dentro del estricto cumplimiento a la  Ley.</p>
									<p>Los Titulares de la  información tendrán derecho a solicitar el acceso, rectificación, cancelación u  oposición del tratamiento de sus datos, mediante solicitud escrita dirigida al  departamento de Atención al Consumidor de Kellogg Company México, S. de R.L. de  C.V. al domicilio indicado previamente, o al correo electrónico <a href="mailto:consumer-affairs.la@kellogg.com">consumer-affairs.la@kellogg.com</a>.,  o al teléfono 56242105 de la Ciudad de México o Área Metropolitana, o al 01 800  00 5355644 del interior de la República Mexicana con horarios de atención de  Lunes a Viernes de 8:00am a 8:00pm y Sábados de 9:00am a 3:00pm.</p>
									<p>Kellogg dará respuesta a  las solicitudes del Titular que sean ejercidas por éste previa acreditación de  su identidad con identificación oficial (IFE, pasaporte, cartilla militar, o  licencia de conducir), en copia simple y habiendo exhibido el original para su  cotejo, o en caso de actuar por representación de un tercero, se deberá de  presentar la Escritura Pública o carta poder firmada ante dos testigos.</p>
									<p>Toda solicitud para que sea  tramitada deberá contener y acompañar lo siguiente:</p>
									<p><strong>I.</strong> El nombre del titular y  domicilio u otro medio para comunicarle la respuesta a su solicitud.<br>
									<strong>II.</strong> Los documentos que  acrediten la identidad o, en su caso, la representación legal del titular.</p>
									<ul class="bullets">
										<li>IFE ó cualquier otra identificación tanto  del niño como del padre</li>
										<li>Fotografía del producto con el código  interno</li>
										<li>Comprobante de domicilio con el domicilio  de entrega</li>
										<li>Correo electrónico</li>
										<li>Teléfono</li>
									</ul>
									<p><strong>III.</strong> La descripción clara y  precisa de los datos personales respecto de los que se busca ejercer alguno de  los derechos.<br>
									<strong>IV.</strong> Cualquier otro elemento o  documento que facilite la localización de los datos personales.</p>
									<p>Cualquier modificación a  esta política de privacidad o con relación al tratamiento de sus datos será  notificada al Titular a los puntos de contacto aportados por él mismo.</p>
									<p>El Grupo tendrá un plazo de  veinte días hábiles, contados desde la fecha en que se recibió la solicitud, o  a partir de que el titular solvento el requerimiento de información, para  comunicarle al titular si se acepta o no la misma. En caso de que la solicitud  sea aceptada, se hará efectiva dentro de los quince días siguientes. Los plazos  referidos podrán ser ampliados una sola vez por un periodo igual cuando esté  justificado.</p>
									<p>La respuesta a las  solicitudes se dará a elección del Titular por medio de correo electrónico, o  mediante respuesta escrita cuando acuda directamente a las oficinas del Grupo  en la dirección señalada a recogerla, pudiéndose reproducir la respuesta en  documentos electrónicos, copias simples, o de manera verbal.</p>
									<p>Los Titulares de la información  tendrán derecho a revocar el consentimiento para el tratamiento de sus datos en  cualquier momento, para lo cual deberán de presentar su solicitud y reunir los  requisitos señalados, a efecto de que Kellogg efectúe el procedimiento  descrito.</p>
									<p>En el supuesto de que  Kellogg requiera usar sus Datos Personales con fines distintos a los pactados o  convenidos al tenor de la relación jurídica que se tiene con el Titular, se  notificará en forma escrita, telefónica, electrónica, o por cualquier medio  óptico, sonoro, visual u otro que la tecnología permita ahora o en lo futuro y  explicando los nuevos usos que pretenda darle a dicha información a fin de  recabar su autorización.</p>
									<p>Con el presente Aviso de  Privacidad los Titulares de la información quedan debidamente informados de los  datos que se recabaron de ellos y con qué fines, aceptando los términos que  fueron elaborados en cumplimiento a la Ley Federal de Protección de Datos  Personales en Posesión de los Particulares y su Reglamento.</p>
									<p>Kellogg, se reserva el  derecho a modificar la presente política para adaptarla a novedades  legislativas o jurisprudenciales, así como a prácticas de la industria, en  dicho supuesto se anunciará en esta página los cambios introducidos con la  razonable antelación a su puesta en práctica.</p>
									<p>El presente Aviso de  Privacidad, así como el manejo en general de la Ley que haga Kellogg, se rige  por la legislación vigente y aplicable en los Estados Unidos Mexicanos, por lo  que cualquier controversia que se suscite con motivo de su aplicación deberá  ventilarse ante los Órganos Jurisdiccionales competentes en la Ciudad de  México.</p>
								</div>
                            </div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Pringles® quiere a tu control. | Términos y condiciones México.',
			meta: [
				{
					name: 'description',
					content: 'Pringles® quiere a tu control. Conoce todos los detalles sobre las reglas, legislación y terminología aplicables en nuestros Términos y condiciones.'
				},
			]
        }
    },
}
</script>