import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'en': {
        // ----------------------------------G L O B A L------------------------------------------------------
        init: 'HOME',
        legals: 'PREVIOUS PROMOTIONS LEGALS',
        contact: 'CONCTAC US',
        youAreIn: 'You are in ',
        countries: 'Countries',
        back: 'Back',
        learnMore: 'Learn more',
        all: 'All',
        call: 'Call',
        free: 'free',
        mail: 'e-mail',
        electronic: '',
        // ---------------------------------------------------------------------------------------------------
        home:'Home',
        login: 'Log in',
        register: 'Register',
        closeSession: 'Log Out',
        mechanic: 'Rules',
        enterYourCodes: 'Enter your codes',
        yourCodes: 'Your codes',
        hello: 'Hello!',
        winners: 'Winners',
        folioText: '',
        eatWell: '',
        privacy: 'Privacy',
        avisoLegal: 'Legal',
        termsConditions: 'Terms & conditions',
        home01:'MUSIC IS',
        home02:'BETTER WITH',
        home03:'REGISTER & WIN*',
        discoverHow:'*See how you can get a chance to win',
        continue: 'Continue',
        send: 'Send',
        noWinners: 'There are still no winners on this promo. Come back later. ',
        congratulations: 'CONGRATULATIONS!',
        enterCode:'Enter your code',
        warning:'ATTENTION',
        asignedCode: 'Your asigned code is:',
        enterCodeHere: 'Enter your code here',
        codes: 'Codes',
        prize: 'Prize',
        registrationDate: 'Registration date',
        noCodes1:'You',
        noCodes2:"DON'T have",
        noCodes3:'any codes yet.',
        deezerNote: 'You have until August 31st to exchange your code on the deezer platform, after this date the organizer is not responsible',
        deezerCode: ' This is your Deezer code:',
        registerACode: 'Enter your codes',
        choosePrize: 'Please, select the prize that you want to participate for.',
        prizeTitle01:'Participate with',
        prizeTitle02: 'Pringles® products',
        prizeProduct01: '(each MUST be of 149g or more)',
        prizeProduct02: '(any size)',
        prizeNote01: 'Prizes are available while supplies last.  ',
        prizeNote02: '',
        prizeNote03: '',
        prizeNote04: 'Terms & Conditions',
        products: 'Products',
        myAccount: 'MY ACCOUNT',
        noWinner: 'NO WINNER',
        downloadPDF: 'Download PDF',
        thankYou: 'THANK YOU',
        copy: 'Copy',
        weAreSorry:"We're sorry",
        youAreWinner: 'You are the winner of',
        modalRaffleText:'You are participating in the raffle for the ',
        modalRaffleText01: 'Also, you are participating in the raffle for the',
        folioNumber: 'Your folio number is:',
        noSessionTitle: 'Your time has finished. To enter more codes, you must login again.',
        thanksForParticipating: 'Thanks for participating.',
        noWinnerCodes: 'Your codes were not winners. There are still many prizes, register more codes.',
        notFoundText: "We're sorry. This page does not exist or is in maintenance.",
        noCodeEnter: 'You has not enter your code.',
        // SEO
        homeH1: 'Homepage for the promotion of Pringles® with Deezer.',
        registerH1: 'Register to participate with Music is better with Pringles®.',
        loginH1: 'Log in for the promotion Music is better with Pringles®.',
        mechanicH1: 'Participation rules for Music is better with Pringles®.',
        addTicketH1: 'Enter your valid codes for the promotion Music is better with Pringles®.',
        codesH1: 'Valid codes registered in Music is better with Pringles®.',
        termsH1: 'Terms & conditions for the promotion Music is better with Pringles®.',
        winnersH1: 'List of winners for the promotion Music is better with Pringles®.',
        assignedCode: 'Assigned Code',

        // -------------------------------------SEO DE PROMO----------------------------------------------------
        promosHomeH1: 'Choose the Pringles® promotion country.',
        contactoHomeH1: 'Page to contact us by phone or mail. Pringles®',
        tycHomeH1: 'Pringles® Terms and conditions promotions page.',
        selectHomeH1: 'Choose the Pringles® promotion country.',
        derechosReservados: '® ™ © All Rights Reserved 2019 Pringles International Operations, S.a.r.l'
    },
    'es': {
        // ----------------------------------G L O B A L------------------------------------------------------
        init: 'INICIO',
        legals: 'LEGALES DE PROMOS',
        contact: 'CONTACTO',
        youAreIn: 'Estás en: ',
        countries: 'Países',
        back: 'Regresar',
        learnMore: 'Conoce más',
        all: 'Todos',
        call: 'Llama',
        free: 'sin costo',
        mail: 'Correo',
        electronic: 'electrónico',
        // ---------------------------------------------------------------------------------------------------
        home:'Home',
        login: 'Inicia sesión',
        register: 'Regístrate',
        closeSession: 'Cerrar sesión',
        mechanic: 'Mecánica',
        enterYourCodes: 'Ingresa tus códigos',
        yourCodes: 'Tus códigos',
        hello: '¡Hola!',
        winners: 'Ganadores',
        folioText: 'CONTEO DE FOLIOS',
        eatWell: 'COME BIEN',

        termsConditions: 'Términos y condiciones',
      privacy: 'Política de Privacidad',
        avisoLegal: 'Aviso legal',
        home01:'LA MÚSICA CAE',
        home02:'MEJOR CON',
        home03:'COMPRA Y GANA*',
        discoverHow:'*Descubre cómo participar.',
        continue: 'Continuar',
        send: 'Enviar',
        noWinners: 'Todavía no hay ganadores en esta promoción. Por favor, vuelve más tarde. ',
        congratulations: '¡FELICIDADES!',
        enterCode:'Ingresa tu código',
        deezerNote: 'Tienes hasta el 31 de agosto para canjear códigos Deezer en su plataforma, fuera de esa fecha la organizadora NO se hace responsable.',
        deezerCode: 'Ganaste un código Deezer:',
        warning: 'ATENCIÓN',
        asignedCode: 'Tu código asignado es:',
        enterCodeHere: 'Ingresa tus códigos aquí',
        codes: 'Códigos',
        prize: 'Premio',
        registrationDate: 'Fecha de registro',
        noCodes1:'Todavía',
        noCodes2:'NO tienes',
        noCodes3:'códigos registrados.',
        registerACode: 'Registra tus códigos',
        choosePrize: 'Elige el premio por el que quieras participar.',
        prizeTitle01:'Participa con',
        prizeTitle02: 'productos Pringles®',
        prizeProduct01: '(de 149 g. cada uno)',
        prizeProduct02: '(cualquier gramaje)*',
        prizeNote01: 'Premios sujetos a disponibilidad, consulta stock en ',
        prizeNote01a: 'términos y condiciones.',
        prizeNote02: '* Guarda las membranas participantes, se te pedirán para entregarte el premio correspondiente.',
        prizeNote03: '* Confirma los periodos de participación de cada premio en ',
        prizeNote04: 'Términos y Condiciones',
        prizeNoteDO01: 'Conoce la ',
        prizeNoteDO02: 'información de premios y periodos',
        prizeNoteDO03: ' de participación de esta promoción. Para más información, consulta ',
        products:'PRODUCTOS',
        noWinner: 'NO GANADOR',
        downloadPDF: 'Descargar PDF',
        thankYou: '¡GRACIAS!',
        copy: 'Copiar',
        youAreWinner: 'Eres el ganador de ',
        modalRaffleText:'Ya estás participando en la rifa de ',
        modalRaffleText01: 'Además ya estás participando en la rifa de',
        myAccount:'MI CUENTA',
        folioNumber: 'Este es tu folio',
        folioNote: 'Cada vez que ingreses un código se creará un número de folio.',
        noSessionTitle: 'Ha terminado tu tiempo. Para registrar más códigos, vuelve a iniciar sesión.',
        thanksForParticipating: 'Gracias por participar.',
        noWinnerCodes: 'Tu códigos no resultaron ganadores. Te invitamos a registrar más códigos, todavía hay muchos premios.',
        noCodeEnter: 'No has ingresado el código',
        notFoundText: 'Disculpa, esta página no existe o se encuentra en mantenimiento',
        // SEO
        homeH1: 'Página de inicio de la promoción de Pringles® con Deezer.',
        registerH1:'Registro para participar en La música cae mejor con Pringles®.',
        loginH1: 'Inicio de sesión en la promo La música cae mejor con Pringles®.',
        mechanicH1: 'Mécanica de participación La música cae mejor con Pringles®.',
        addTicketH1: 'Registro de códigos participantes en la promoción La música cae mejor con Pringles®',
        codesH1: 'Códigos de participación registrados en la La música cae mejor con Pringles®',
        termsH1: 'Términos y Condiciones de la promoción La música cae mejor con Pringles®',
        winnersH1: 'Lista de ganadores de la promoción La música cae mejor con Pringles®.',
        assignedCode: 'Código asignado',

        // --------------------------------------------------------------------
        promosHomeH1: 'Página de inicio para todos los productos y promociones de Pringles®',
        contactoHomeH1: 'Página para contacto vía telefónica o correo electrónico de Pringles®',
        tycHomeH1: 'Página para los Términos y Condiciones de las promociones de Pringles®',
        selectHomeH1: 'Página para seleccionar el país de las promociones de Pringles®.',

        derechosReservados: '® ™ © Derechos Reservados 2019 Pringles International Operations, S.a.r.l.'
    },
    'pt': {
        // ----------------------------------G L O B A L------------------------------------------------------
        init: 'COMEÇO',
        legals: 'LEGAL DE PROMOÇÕES ANTERIORES',
        contact: 'CONTATO',
        privacy: 'Aviso de privacidade',
        avisoLegal: 'Aviso legal',
        youAreIn: 'Esta em ',
        countries: 'Países',
        back: 'Voltar',
        learnMore: 'Saiba mais',
        all: 'Tudo',
        call: 'Ligue',
        free: 'sem custo',
        mail: 'E-mail',
        electronic: ' ',
        eatWell: ' ',
        downloadPDF:'Baixar PDF',
        products:'PRODUTOS',
        myAccount:'MINHA CONTA',
        // --------------------------------------------------------------------
        promosHomeH1: 'Página inicial de todos os produtos e promoções de Pringles®',
        contactoHomeH1: 'Página para contato via telefone ou e-mail de Pringles® .',
        tycHomeH1: 'Página dos Termos e Condições das promoções de Pringles®.',
        selectHomeH1: 'Página para selecionar o país das promoções de Pringles®.',

        // ---------------------------------------------------------------------------------------------------
        derechosReservados: 'Direitos Reservados 2019 Pringles International Operations S a r l'
    }
};



const i18n = new VueI18n({
    locale: 'es', // set locale
    fallbackLocale: 'es', // set fallback locale
    messages, // set locale messages
});

export default i18n
//Vue.i18n((key, value) => i18n.t(key, value))
