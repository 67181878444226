import $ from 'jquery';
class LoginRif {

    constructor(container, configObj, loggedInCallback) {
        this.loggedInCallback = loggedInCallback;
        this.onAuth = this.onAuth.bind(this);
        this.onExit = this.onExit.bind(this);
        this.onFormRenderReady = this.onFormRenderReady.bind(this);
        this.container = container;
        this.configObj = $.extend(true, this.getBaseConfigObj(), configObj);
        this.completeLoginData = null;

        this.init();
    }

    getBaseConfigObj(configObj) {
        return {
            defaultError: "Lo sentimos, intentelo de nuevo",
            environment: this.getEnvironment(),
            onAuth: this.onAuth,
            onExit: this.onExit,
            
        };
    }

    init() {
        this.loadRegScript();
    }

    getEnvironment() {
        switch (location.host) {
            case 'www.promopringles.com':
                return 'prod';
            default:
                return 'stage';
        }
    }

    loadRegScript() {
        (function (scp) {
            var src, s;

            /*if (scp.configObj.environment === 'stage') {
                src = 'https://stage.registrationv1.kglobalservices.com/scripts/registration.min.js';
            } else {
                src = 'https://registrationv1.kglobalservices.com/scripts/registration.min.js';
            }*/

            if (scp.configObj.environment === 'stage') {
                src = 'https://stage.registrationv1.kglobalservices.com/scripts/registration.js';
            } else {
                src = 'https://www.registrationv1.kglobalservices.com/scripts/registration.js';
            }

            var kreg = document.createElement('script');
            kreg.id = 'rifRegScript';
            kreg.type = 'text/javascript';
            kreg.onload = kreg.onreadystatechange = function () {
                if (!this.readyState || this.readyState === 'loaded' || this.readyState === 'complete') {
                    kreg.onload = kreg.onreadystatechange = null;
                    scp.onRegScriptReady();
                }
            };
            kreg.src = src;
            s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(kreg, s);
        })(this);
    }

    onRegScriptReady() {
        console.log("onRegScriptReady 2");
        var regModule = KSTL.Registration.init(this.container, this.configObj);
        var eventViewRender = regModule.bind('view:render', this.onFormRenderReady);
    }

    onFormRenderReady() {
        if ($('[name="signupForm"]').get(0)) {
            this.initRegisterCustomization();
        }
    }

    initRegisterCustomization() {}

    onAuth(authUser, callback) {
        //console.log(authUser);
        this.completeLogin(authUser, callback);
    }

    onExit(authUser) {
        if (this.loggedInCallback) {
            //this.loggedInCallback(this.completeLoginData);
            this.loggedInCallback(authUser);
        }
    }

    getSelectedGender(){
        let maleRadio = document.getElementById('KSTL-Registration-Gender-1');
        if(maleRadio){
            return maleRadio.checked;
        }
        else{
            return true;
        }
    }

    completeLogin(authUser, callback) {
        console.log('complete-login')
        console.log(authUser);
        this.setCookie('UsernameForServiceAuth',authUser.UsernameForServiceAuth);
        this.setCookie('ProfileID',authUser.ProfileID);
        this.setCookie('AccessToken', authUser.AccessToken);
        if(this.loggedInCallback) {
            this.loggedInCallback(authUser);
        }
        /*var completeLoginPost = $.post( `${KB.URLManager.servicesBaseURL()}api/user/completelogin`, {
            "ProfileId": authUser.ProfileID,
            "CountryCode": authUser.Country,
            "Email": authUser.UsernameForServiceAuth,
            "EncryptedParams": authUser.AllParamsEncrypted,
            "IsBoy": this.getSelectedGender()
        })
        .done((data)=>{
            this.completeLoginData = data;
            callback();
        });*/
    }

    setCookie(cname, cvalue) {
        var d = new Date(), exdays=1;
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

}

export default LoginRif;
