import Vue from 'vue'
import Vuex from 'vuex'

import router from './router'
import coreService from '@/services/core'

import i18n from '@/utilities/i18n';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        currentFolio: 0,
        validityText: '',
        resetpass: '',
        pdfUrl: '',
        language: 'es',
        globalName: '',
        showMenuMobile: false,
        isLogin: false,
        updateProfileView: false,
        isBrazil: false,
        isTrinidad: false,
        isPuertoRico: false,
        isAndino: false,
        isCaricam: false,
        isOpen: false,
        isPromo: true,
        folioTimeout: null,
        winners: [],
        user: {},
        modal: {
            show: false,
            type: '',
        },
        country: {
            id: localStorage.getItem('country'),
            name: localStorage.getItem('countryName'),
            area: localStorage.getItem('countryArea'),
            language: localStorage.getItem('language')
        },
        prize: {
            id: '',
            clave: '',
            icon: '',
            type: '',
            digitalCode:'',
            requiredCodes: 2,
        },
        trivia: {
            id: '',
            questions: []
        },
        winner: {
            id: '',
            folio: '',
            type: ''
        },
        languageList: {
            login: 'Inicia sesión',
            register: 'Regístrate',
            close: 'Cerrar sesión',
            mechanic: 'Mecánica',
            codes: 'Ingresa tus códigos',
            yourCodes: 'Tus códigos',
            winners: 'Ganadores',
            youAreIn: 'Estás en ',
            folioText: 'CONTEO DE FOLIOS',
            eat: 'COME BIEN',
            termsConditions: 'Términos y condiciones',
            privacy: 'Aviso de privacidad',
            folioNote: 'Cada vez que ingreses un código se creará un número de folio.'
        },

        //updateProfileView: false
    },

    mutations: {
        toggleMenuMobile(state, value) {
            state.showMenuMobile = value
        },

        setModal(state, modal) {
            state.modal = { ...modal }
        },

        setIsLogin(state, payload) {
            state.isLogin = payload.isLogin
        },

        setUser(state, user) {
            state.user = { ...user }
        },

        setWinners(state, winners) {
            state.winners = [...winners]
        },
        setWinnerData(state, data) {
            state.winner = { ...data }
        },
        setCountry(state, payload) {
            state.country.id = payload.country
        },
        setCountryName(state, value) {
            state.country.name = value;
        },
        setCountryArea(state, value) {
            state.country.area = value;
        },
        changeProfileView(state, value) {
            state.updateProfileView = value
        },
        setLanguage(state, value) {
            state.language = value
        },
        setRequiredCodes(state, value) {
            state.prize.requiredCodes = value;
        },
        setPrizeId(state, value) {
            state.prize.id = value
        },
        setPrizeClave(state, value) {
            state.prize.clave = value
        },
        setPrizeIcon(state, value) {
            state.prize.icon = value
        },
        setPrizeType(state, value) {
            state.prize.type = value
        },
        setTriviaId(state, value) {
            state.trivia.id = value
        },
        setIsBrazil(state, value) {
            state.isBrazil = value
        },
        setIsTrinidad(state, value) {
            state.isTrinidad = value
        },
        setIsPuertoRico(state, value) {
            state.isPuertoRico = value
        },
        setIsAndino(state, value) {
            state.isAndino = value
        },
        setIsCaricam(state, value) {
            state.isCaricam = value
        },
        setIsOpen(state, value) {
            state.isOpen = value
        },
        setValidityText(state, value) {
            state.validityText = value
        },
        setResetId(state, value) {
            state.resetpass = value
        },
        setPDFUrl(state, value) {
            state.pdfUrl = value
        },
        setCurrentFolio(state, value) {
            //console.log(value)
            state.currentFolio = value
        },
        setPromo(state, value){
            state.isPromo = value
        },
        setGlobalName(state, value){
            state.globalName = value
        }

    },

    actions: {
        setSession(context) {
            const countryId = localStorage.getItem('country')
            let isLogin = false
            let url = '/api/Sol/user/session'

            if (countryId !== 'BR') {
                url = '/api/deezer/user/session'
            }

            return new Promise((resolve, reject) => {
                coreService(url)
                    .then(({ User: user }) => {

                        if (user) {
                            isLogin = true
                        }

                        context.commit('setUser', user)
                        context.commit('setIsLogin', { isLogin })
                        context.dispatch('getCurrentFolio')

                        resolve({ user, isLogin })
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },

        closeSession(context) {

            const countryId = localStorage.getItem('country')

            let url = '/api/Sol/user/logout';

            if (countryId !== 'BR') {
                url = '/api/deezer/user/logout'
            }

            return new Promise((resolve) => {
                coreService(url, {}, 'POST')
                    .then(() => {
                        context.commit('setUser', {})
                        context.commit('setIsLogin', { isLogin: false })

                        resolve()
                    })
            })
        },

        setUser(context, user) {
            let isLogin = false
            return new Promise((resolve) => {

                if (user) {
                    isLogin = true
                }

                context.commit('setUser', user)
                context.commit('setIsLogin', { isLogin })
                context.dispatch('getCurrentFolio')

                let user_country_code = user.CountryCode
                let user_country_name = user.CountryName
                let country_id = localStorage.getItem('country')
                let cookiesKey = ['country', 'countryName', 'countryArea']

                if (user_country_code !== country_id) {
                    context.commit('setCountry', user_country_code)
                    context.commit('setCountryName', user_country_name)
                    context.dispatch('setSiteCountryArea', user_country_code)

                    cookiesKey.map((value) => {
                        return localStorage.removeItem(value)
                    })

                    localStorage.setItem('country', user_country_code)
                    localStorage.setItem('countryName', user_country_name)

                }

                resolve({ user, isLogin })

            })
        },
        setSiteCountryArea(context, value) {
            let tempCountry = value;
            let tempArea;

            localStorage.removeItem('countryArea')

            /*if( tempCountry === 'PE' || tempCountry === 'TT' ){
                context.commit('setIsOpen', false)
            } else {
                context.commit('setIsOpen', true)
            }*/
            context.commit('setIsOpen', false)

            if (tempCountry === 'CO' || tempCountry === 'EC' || tempCountry === 'PE') {
                tempArea = 'andino';
                context.commit('setIsPuertoRico', false)
                context.commit('setIsAndino', true)
                context.commit('setIsCaricam', false)
                
                if( tempCountry === 'EC' || tempCountry === 'CO' ){
                    context.commit('setValidityText', 'Vigencia del 1 de abril al 31 de Agosto de 2019')
                } else {
                    context.commit('setValidityText', 'Vigencia del 1 de abril al 30 de junio. ')
                }

            } else if (tempCountry === 'PR') {
                tempArea = 'pr';
                context.commit('setIsPuertoRico', true)
                context.commit('setIsAndino', false)
                context.commit('setIsCaricam', false)
                context.commit('setValidityText', 'Vigencia del 1 de abril al 15 de junio. ')
            } else if (tempCountry === 'BR') {
                tempArea = 'br';
                context.commit('setIsPuertoRico', false)
                context.commit('setIsAndino', false)
                context.commit('setIsCaricam', false)
            } else if (tempCountry === 'TT') {
                tempArea = 'tt';
                context.commit('setIsPuertoRico', false)
                context.commit('setIsAndino', false)
                context.commit('setIsCaricam', false)
                context.commit('setValidityText', 'Valid from: May 1st – July 31st')
            } else {
                tempArea = 'caricam';
                context.commit('setIsPuertoRico', false)
                context.commit('setIsAndino', false)
                context.commit('setIsCaricam', true)
                context.commit('setValidityText', 'Vigencia del 1 de abril al 31 de agosto. ')
            }
    
            localStorage.setItem('countryArea', tempArea)
            context.commit('setCountryArea', tempArea)
            //console.log(tempArea, tempCountry)
        },
        getCurrentFolio(context) {
            let area = localStorage.getItem('countryArea')
            if (area === 'caricam') {
                coreService('/api/deezer/userCode/GetCurrentFolio')
                    .then(({ CurrentFolio }) => {
                        context.commit('setCurrentFolio', CurrentFolio)
                        context.dispatch('setFolioTimeout')
                    })
            }

        },
        setFolioTimeout(context) {

            let folioTimeout = context.folioTimeout
            //console.log('dispatch function')

            if (folioTimeout) {
                clearTimeout(folioTimeout)
            }

            folioTimeout = setTimeout(() => {
                context.dispatch('getCurrentFolio')
                //console.log('dispatch timer')
            }, 5000)
        },
        setBodyClass(context, value) {
        //setBodyClass(context, value, classname) {
            console.log("VALUE: "+value)
            if (value) {
                let country_id = localStorage.getItem('country').toLowerCase()
                let className

                if (value === 'br') {
                    className = 'br'
                    context.commit('setIsBrazil', true)
                    context.commit('setPromo', true)
                } else if (value === 'deezer') {
                    className = 'deezer'
                    context.commit('setIsBrazil', false)
                    context.commit('setPromo', true)
                } else {
                   className = 'global'
                   context.commit('setIsBrazil', false)
                   context.commit('setPromo', false)
                }

                if (country_id === 'tt') {
                    country_id = 'tyt'
                }
                
                /*className = 'global'
                context.commit('setIsBrazil', false)
                context.commit('setPromo', false)
                */
                console.log('console.........body class', value, className)
                
                document.body.className = ''
                document.body.classList.add(className) 
                document.body.classList.add(country_id)
            }
            //context.dispatch('setLanguage', value)
        },
        clearFolioTimeout(context) {
            let folioTimeout = context.folioTimeout

            if (folioTimeout) {
                clearTimeout(folioTimeout)
            }
        },
        generatePDF(context, id) {
            return new Promise((response) => {
                let data = { winnerId: id }
                coreService('/api/deezer/userCode/GeneratePdf', data)
                    .then((data) => {

                        let { PdfFilePath } = data
                        let pdfFilePath = ''

                        if (PdfFilePath) {
                            pdfFilePath = '../' + PdfFilePath
                        }
                        response(pdfFilePath)

                    })
            })
        },
        setLanguageLocale(context, value) {
            //console.log(value)
            i18n.locale = value;
            //context.commit('setLanguage', value)
        },
        deleteCookie(context, cname) {
            var d = new Date(); //Create an date object
            d.setTime(d.getTime() - (1000 * 60 * 60 * 24)); //Set the time to the past. 1000 milliseonds = 1 second
            var expires = "expires=" + d.toGMTString(); //Compose the expirartion date
            window.document.cookie = cname + "=" + "; " + expires; //Set the cookie with name and the expiration date
        },
        setMenuLinks(context, value) {

            const country_id = localStorage.getItem('country');
            let name;

            

            if (country_id === 'TT') {
                name = value + 'TT'
            } else {
                name = value;
            }

           // console.log(country_id, value, name)

            //console.log(context, router)

            router.push({ name })
        }

    }
})