<template>
	<div v-bind:id="'sec'+index" class="product_category_group">
        <h2 class="product_category_header"><a href="#products-menu">{{title}}</a></h2>
        <div class="product_category_body">
            <!--p class="product_category_description" v-if="shown">¿Tienes antojo de Pringles? Descubre cual encaja contigo</p-->
            <ul class="product_category_list">
                
                    <li v-for="item2 in prod" v-if="item2.data!=''">
                        <router-link :to="{name:'producto', 'params': {'countryId': cntry, 'productId': item2.data.PimID}}">
                            <img :src="item2.data.ProductURL" :alt="item2.data.ProductName" height="150">
                            <h3>{{item2.data.ProductName}}</h3>
                        </router-link>
                    </li>
                
            </ul>
        </div>
    </div>
</template>
<script>
export default {
	props: {title:String, prod:Object, shown:Boolean, cntry:String, index:Number},
    data() {
        return {
        
        }
    },
    mounted() { 
        this.country=this.$store.state.country.id.toLowerCase()
    },
    methods: {

    },
    components: {
        
    },
}
</script>