
<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/item-terms.jpg"  alt="">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2018</p>
                                <h4 class="titleTerms">PROMO PRINGLES® XMAS</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                
                                <p>
                                    Al participar en la promoción “Pringles® XMAS 2018” (en lo sucesivo la “Promoción”), el participante declara haber leído, entendido y sujetarse totalmente a los Términos y condiciones aquí establecidas (en adelante las “Bases”).
                                </p>
                                <p>Responsable de la promoción: Kellogg de Centroamérica, S.A., con domicilio en Boulevard Los Próceres 18 calle 24-69, Zona 10, Edificio Empresarial Zona Pradera, Torre III, Nivel 8, Oficina 801, de la Ciudad de Guatemala. Para cualquier aclaración o información referente a esta promoción o resultados de la misma, comunicarse al teléfono 1 800 835 7465 de lunes a viernes de 8:00 a 17:00 horas. </p>
                                <h4><span class="lb">I.- Cobertura:</span></h4>
                                <p>República de Honduras, en todos los establecimientos en que se comercialicen los productos participantes.</p>
                                <h4><span class="lb">II.- PARTICIPANTES:</span></h4>
                                <p>Se entenderá como “Participante” al público consumidor en general, que tenga la disposición de participar en la Promoción y reúna todos y cada uno de los siguientes requisitos:</p>
                                <ul>
                                    <li>Ser mayor de edad con plena capacidad de ejercicio y contar con identificación oficial con fotografía vigente (Documento Personal de Identificación). Y tener legal residencia en la República de Honduras.</li>
                                    <li>Aceptar las presentes Bases. </li>
                                    <li>• Comprar los Productos Pringles® marcados con la Promoción (en lo sucesivo Los “Productos Participantes”) durante la Vigencia de la Promoción y que estén a la venta en la República de Honduras.</li>
                                    <li>Dar lectura total y aceptar las presentes Reglas.</li>
                                    <li>Obtener un registro de participante en el sitio proporcionando datos verídicos, y completar la mecánica de la promoción.</li>
                                    <li>Proporcionar durante la Vigencia identificación oficial y datos para registro de ganadores y firma de recibo de conformidad.</li>
                                    <li>Registrar los códigos únicos que se encuentran impresos en la membrana (la tapa de papel que cubre el envase) de cada lata de Pringles® en el sitio web: www.pringles.com siguiendo las instrucciones correspondientes de acuerdo a la mecánica descrita en éste documento. Se deberán conservar las membranas con los códigos marcados a fin de poder realizar cualquier aclaración.</li>
                                </ul>
                                <h4><span class="lb">III.- VIGENCIA DE LA PROMOCIÓN:</span></h4>
                                <p>Los códigos de la promoción en el website de la página de Pringles tendrán vigencia del 1 de octubre de 2018 al 31 de enero de 2019 o hasta agotar existencia de inventario de los Incentivos ofrecidos, lo que suceda antes (la &ldquo;Vigencia&rdquo;).Los códigos ganadores de PlayStation Store y PlayStation Plus se pueden ingresar a partir del 1 de octubre del 2018 hasta el 31 de marzo del 2019.</p>
                                <h4><span class="lb">IV.- INCENTIVOS:</span></h4>
                                <p>a) Incentivos de Canje al Registro</p>
                                <p>Total: 50,300 beneficios de canje de acuerdo al número de productos participantes registrados, consistentes en:</p>
                                <ul>
                                    <li>Tema PlayStation</li>
                                    <li>Crédito de $5 USD para PS Store online</li>
                                    <li>Crédito de $9.99 USD para PS Plus</li>
                                </ul>
                                <table class="globalTable" width="531" height="165" border="1">
                                    <tr>
                                        <th colspan="4">Canje al Registro</th>
                                    </tr>
                                    <tr>
                                        <td>Cupón $5 dlls PS Store</td>
                                        <td>Cupón Suscripción PS Plus</td>
                                        <td>Tema Exclusivo</td>
                                    </tr>
                                    <tr>
                                        <td>75 cupones</td>
                                        <td>75 cupones</td>
                                        <td>30,000 cupones</td>
                                    </tr>
                                </table>
                                <p>b) Registro para participación</p>
                                <p>5 Consolas PlayStation 4 PS4 slim 1TB de disco duro (incluye videojuego God of War, Gran Turismo Sport, Uncharted 4), con un valor comercial de $600. La Organizadora no se hace responsable de la garantía de la consola. Para revisar la política de garantía que aplica para las consolas por parte de PlayStation ingresar al siguiente link: <a href="https://www.playstation.com/es-mx/soporte/garantias/" target="_blank">https://www.playstation.com/es-mx/soporte/garantias/</a></p>
                                <h4><span class="lb">V. PRODUCTO PARTICIPANTES:</span></h4>
                                <p>Los Productos Participantes son los siguientes siempre y cuando se encuentren marcados con la promoción:</p>
                                <p>Pringles® Original 149g<br />
                                    Pringles® Crema y Especias 149g<br />
                                    Pringles® Queso 149g<br />
                                    Pringles® Original 37g<br />
                                    Pringles® Crema y Especias 40g<br />
                                    Pringles® Queso 40g</p>
                                <h4><span class="lb">VI. MECÁNICA DE PARTICIPACIÓN:</span></h4>
                                <p>Pasos:</p>
                                <p>1. El participante deberá comprar productos Pringles® participantes marcadas con la promoción en los Establecimientos participantes.</p>
                                <p>2. En cada lata y detrás de la membrana (tapa de papel), la persona encontrará un código único.</p>
                                <p>3. El participante deberá ingresar en el sitio web: www.pringles.com y buscar el banner de la promoción. Dicho banner lo redirigirá al micrositio <a href="http://www.promopringles.com" target="_blank">www.promopringles.com</a> para realizar el canje de códigos (el sitio web).</p>
                                <p>4. En ella deberá crear una cuenta para lo cual registrar los siguientes datos:</p>
                                <ul>
                                    <li>Nombre</li>
                                    <li>Apellido</li>
                                    <li>Fecha de nacimiento</li>
                                    <li>Teléfono</li>
                                    <li>E-mail</li>
                                    <li>Contraseña</li>
                                </ul>
                                <p>5. Una vez iniciada sesión el Participante deberá elegir el tipo de incentivo por el que participará:</p>
                                <p>a) Tema Exclusivo</p>
                                <p>b) Cupón $5 dollars PS Store</p>
                                <p>c) Cupón $9.99 dollars PS Plus</p>
                                <p>d) Consola</p>
                                <p>Para los incentivos indicamos como a), b) y c), el participante deberá registrar el o los códigos únicos requeridos, para validación, de acuerdo con el tipo de incentivo elegido según la siguiente tabla de redención:</p>
                                <table class="globalTable" width="531" height="165" border="1">
                                    <tr>
                                        <td>Tema Exclusivo</td>
                                        <td>Cupón $5 dollars PS Store</td>
                                        <td>Cupón $9.99 dollars PS Plus</td>
                                    </tr>
                                    <tr>
                                        <td>1 producto participante cualquier gramaje</td>
                                        <td>2 productos participantes de cualquier gramaje</td>
                                        <td>3 productos participantes de cualquier gramaje</td>
                                    </tr>
                                </table>
                                <p>En caso de no ser válido uno o más de los dos códigos, se le comunicará y se le invitará a seguir participando.</p>
                                <p>6. En caso de que la validación sea exitosa, el sistema arrojará una pantalla emergente con el código del cupón para redención del incentivo elegido o el Tema Exclusivo, según sea el caso, en la página oficial de PlayStation.</p>
                                <p>7. En el caso de que el Participante decida participar por la Consola deberá:</p>
                                <table class="globalTable" width="531" height="165" border="1">
                                    <tr>
                                        <td>Consola PlayStation 4 Slim</td>
                                    </tr>
                                    <tr>
                                        <td>1 producto participante cualquier gramaje</td>
                                    </tr>
                                </table>
                                <ul>
                                    <li>Consultar los números predeterminados para concursar*, así como el contador de los folios de participación que se han ido registrando para calcular el momento en que registrará su código e intentar ser uno de los 5 ganadores.</li>
                                    <li>Registra el código único de 1 producto participante para validación, en caso de ser válido se generará un folio de participación. En caso de no ser válido se le comunicará y se le invitará a seguir participando.</li>
                                    <li>Si el folio de participación es uno de los 5 números predeterminados para concursar que habrá durante la vigencia de la promoción, el sistema en automático arrojará una ventana emergente para que el Participante inicie la fase de Trivia, en la que deberá contestar correctamente 3 preguntas de cultura general de opción múltiple. </li>
                                    <li>Serán Ganadores los 5 Participantes que contesten correctamente las preguntas de la Trivia.</li>
                                    <li>En caso de que no se llegue a la totalidad de premios asignados para el periodo de participación de acuerdo al anexo de Números Predeterminados, debido a la falta de participaciones o registros o por no responder correctamente a la fase de trivia, se asignarán los incentivos a los folios de participación que más se acerquen al número predeterminado de forma descendente en cada caso que aplique, para realizar la fase de trivia y tener al final de la promoción todos los incentivos otorgados.</li>
                                    <li>En caso no se tenga folios registrados en cualquier periodo de participación, el premio será acumulado para el siguiente periodo utilizando el folio descendente al folio ganador del periodo actual. </li>
                                    <li>Si el Participante contesta incorrectamente, no podrá ser acreedor al incentivo y se dará por terminada su participación, pasando al siguiente registro inmediato siguiente a este; lo mencionado se hará hasta obtener a los 5 ganadores del incentivo, pero se le enviará un Tema Exclusivo al correo que registró .</li>
                                    <li>Publicación de ganadores: La publicación de los nombres ganadores se darán a conocer una vez que se haya validado su identidad y participación (de acuerdo al periodo) a partir del 1 de octubre, a través del micrositio <a href="http://www.promopringles.com">www.promopringles.com</a></li>
                                </ul>
                                <p>*Números predeterminados (Ver anexo A): Los números predeterminados a concursar se establecen previo al inicio de la promoción, por periodo de participación, y serán publicados en el sitio web de la promoción <a href="http://www.pringles.com">www.pringles.com</a>, por lo que NO podrán ser modificados o alterados. Asimismo en el micrositio de la promoción se va a colocar un contador en donde irán apareciendo en tiempo real los folios de participación que se han ido registrando vía web, para que el participante haciendo uso de su voluntad elija el momento de su registro para alcanzar el número predeterminado y poder concursar por la(s) consola(s).</p>
                                <p>Limitaciones:</p>
                                <p>• Limitado a la obtención de un máximo de 1 (un) incentivo de cada tipo por participante. (no aplica para los temas exclusivos).</p>
                                <p>No podrán participar en la promoción: empleados, becarios, asesores, proveedores o prestadores de servicios ni sus familiares hasta en tercer grado de La Organizadora ni de las empresas relacionadas con la operación o implementación de la Promoción.</p>
                                <p>Limitado hasta agotar existencias de los 50,305 incentivos. </p>
                                <p>En caso de identificarse alguna irregularidad en la participación de los ganadores, incluyendo la vinculación familiar con alguno de los proveedores o prestadores de servicios de la Promoción, los ganadores deberán hacer devolución del incentivo a La Organizadora.</p>
                                <p>• El Participante, ya sea directamente o a través de un tercero: (i) no podrá utilizar ningún software, servicio o aplicación que modifique, cierre, restrinja, o redirija, o intente modificar, cerrar, restringir o redirigir el Sitio Web; (ii) no accederá al Sitio Web ni participará en la Promoción utilizando medios automatizados (como harvesting bots, robots, arañas o scrapers), ni podrá permitir que un tercero tenga acceso y/o utilice el Sitio Web en su nombre o por su cuenta mediante un proceso automatizado como por ejemplo, robots o rastreadores web o almacenamiento periódico de la información contenida en el Sitio Web; (iii) acepta ser el único responsable (frente al Organizador, al Auspiciante y a otros terceros) de toda la actividad que realice en el Sitio Web, y mantendrá indemne al Organizador, sus empleados, accionistas, socios, filiales, representantes, por este concepto.</p>
                                <h4><span class="lb">VII. ENTREGA DE INCENTIVOS CONSISTENTES EN CONSOLAS PS:</span></h4>
                                <p>Los participantes que hayan completado la mecánica serán contactados por la Organizadora o el tercero que al efecto designe, para solicitar la información correspondiente para la validación de su identidad y en su caso realizar la entrega de su incentivo. Se les contactará por medio de correo electrónico o por teléfono (dependiendo la información que brindaron en la plataforma). Para tal efecto deberá presentar y entregar, la siguiente documentación:</p>
                                <p>• Copia de la Identificación oficial vigente con fotografía por ambos lados (INE, Pasaporte, Cédula de Identidad, Cédula Profesional o documento personal de identificación o DPI). </p>
                                <p>• Copia del Comprobante de domicilio (con antigüedad no mayor a tres meses). </p>
                                <p>• Firmar el recibo de conformidad y carta de uso de imagen. </p>
                                <p>• Entregar la membrana en la que conste el Código Único mediante el cual el participante resultó Ganador y el folio que se genere al momento del registro.</p>
                                <p>Todos los Ganadores tendrán 15 días hábiles, a partir de la solicitud de información para su entrega. En caso de no realizar la entrega completa de la documentación solicitada el Participante perderá el derecho a reclamar su incentivo y la Organizadora dispondrá de los incentivos como mejor convenga a sus intereses</p>
                                <p>DEFINIR PROCESO DE ENTREGA DE LAS CONSOLAS</p>
                                <p>Ejemplo de entrega mediante envío</p>
                                <p>• Para la entrega de los incentivos, los participantes deberán firmar de recibido el paquete, de conformidad con las políticas y medidas de seguridad implementadas por la empresa de paquetería. El incentivo se entregará invariablemente en el domicilio que el ganador haya especificado en la información solicitada por la Organizadora, por lo que será su entera responsabilidad asegurarse de que los datos de ubicación sean correctos. El proceso de entrega será el siguiente:
                                    1.- La entrega de incentivos se realizará dentro de los siguientes 15 días hábiles de la validación de la identidad y participación por parte de la Organizadora.
                                    2.- La mensajería acudirá al domicilio del ganador en un máximo de 2 ocasiones para intentar hacer entrega del incentivo. Para completar la entrega será indispensable que quien reciba el incentivo presente una identificación oficial con fotografía.
                                    3.- En caso de no encontrarse el ganador la mensajería dejará un aviso desde la primera visita con los números telefónicos de la mensajería, para que el ganador pueda comunicarse y concertar cita para la siguiente entrega.
                                </p>
                                <p>La falta de veracidad en la información proporcionada y/o la falta de presentación de identificación oficial con fotografía que impida o no permita identificar al Participante con derecho a reclamar algún incentivo libera de cualquier responsabilidad a la Organizadora y le otorga la facultad de disponer del incentivo respectivo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes.</p>
                                <p>En caso de que el Participante no provea su información personal de manera precisa, de forma tal que sea imposible para la empresa de paquetería encontrar su domicilio, perderá su derecho a recibirlo y le dará derecho a la Organizadora de utilizarlo como mejor convenga a los intereses de la Promoción a favor y beneficio del resto de Participantes </p>
                                <p>En caso que el cliente lo decida se estarán habilitando las oficinas de FARINTER S.A. en la siguiente dirección Barrio la Granja, Edificio Farinter, atrás corporación Flores, Comayaguela, Honduras, C.A.</p>
                                <h4><span class="lb">VIII. CONDICIONES GENERALES:</span></h4>
                                <p>a. CÓDIGOS NULOS.-</p>
                                <p>• Serán nulos automáticamente y no tienen derecho a participación cualquier código previamente presentado a participación de la Mecánica.</p>
                                <p>• Copias o facsímiles de membranas con los códigos originales.</p>
                                <p>• Aquellos que al momento de ser presentados a validación, presenten cualquier tipo de mutilación, recorte, adhesión, falsificación, superposición, alteración y/o raspadura, remarcación y/o enmendadura voluntaria y/o involuntaria, ya sea por abrasión, fricción o reimpresión, así como aquellos que presenten materiales adicionales de ensamble, armados, sobre-armados o laminados superpuestos en su interior o superficies, una vez que sean requeridos para verificar la autenticidad de la participación.</p>
                                <p>• La Organizadora se reserva el derecho de verificar la autenticidad e inalterabilidad de los códigos presentados de los probables participantes.</p>
                                <p>• Será rechazado y no tendrá derecho a participar en la Promoción cualquier código y membrana falsificada, simulado, fotocopiado, alterado o modificado y considerado como nulo o inválido con el fin de obtener el beneficio del incentivo ofrecido en La Promoción.
                                </p>
                                <p>b. VALOR UNITARIO DE PARTICIPACIÓN.-</p>
                                <p>Será gratuito el registro y la participación en las dinámicas, no tendrá ningún costo adicional para el participante, siempre y cuando se sujete a la mecánica de participación descrita en las presentes Bases.</p>
                                <p>c. DATOS PERSONALES (AVISO DE PRIVACIDAD).-</p>
                                <p>La información personal que Los Participantes proporcionen con el fin de recibir alguno de los incentivos de la Promoción, quedarán incorporados a una base de datos que conservará La Organizadora, ante la que cualquier Usuario podrá dirigirse para solicitar información, rectificación, oposición de conservación o cancelación de sus datos. Por la naturaleza de la Promoción es necesario que la información solicitada y proporcionada sea veraz y completa. Si los datos de carácter personal fueran inciertos o incompletos, La Organizadora quedará liberada respecto de las entregas o beneficios por otorgar a los Participantes, sin que se pueda reclamar posteriormente modificación de información o datos o compensación alguna por la falta de correcta entrega del incentivo reclamado.</p>
                                <p>La Organizadora utilizará la información proporcionada para la realización de la Promoción con sus empresas afiliadas, filiales o que formen parte del grupo de empresas al que pertenece La Organizadora, así como con prestadores de servicios contratados por La Organizadora para la ejecución de la actividad hasta la conclusión de la misma, información que permanecerá en la base de datos confidencial de La Organizadora. </p>
                                <p>Cualquier acceso, rectificación, cancelación u oposición relacionada con la información personal de los Participantes la podrán realizar enviando una solicitud por correo electrónico al departamento de Atención al Consumidor consumer-affairs.la@kellogg.com o llamando al teléfono 1 800 835 7465.</p>
                                <h4><span class="lb">IX. LÍMITES DE RESPONSABILIDAD DEL ORGANIZADOR:</span></h4>
                                <p>La Organizadora, no será responsable de modificaciones en el estado de salud a consecuencia de la participación en la Mecánica por parte del Participante. </p>
                                <p>La Organizadora, podrá en cualquier momento suspender La Promoción o la entrega del incentivo sin responsabilidad alguna, si dicha suspensión responde en acato de cualquier disposición emitida por cualquier autoridad jurisdiccional, así como si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten los intereses de la Organizadora. Si por causas ajenas a la Organizadora se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los acreedores para disfrutar del incentivo o de la actividad programada, el organizador, se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el participante se hizo acreedor. De igual forma se procederá en los casos que existieran actos políticos, sociales o de salud que pusieran en peligro la integridad física de cualquiera de los acreedores. En todos estos casos La Organizadora publicará a través de medios impresos y/o a través de su página web los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.</p>
                                <p>No se podrá canjear el incentivo por otro de cualquier tipo y/o dinero en efectivo. Ninguno de los incentivos estará sujetos de comercialización alguna, de manera enunciativa más no limitativa, venta, permuta, arrendamiento, sustitución o dación en pago.</p>
                                <p>Si existen indicios suficientes, a criterio de la Organizadora, que señalen que los participantes obtuvieron sus códigos por medios ilegales o distintos a los establecidos en estas bases, podrá cancelarse la participación de ese Participante, y en su caso, dar aviso a las autoridades correspondientes.</p>
                                <p>La responsabilidad de La Organizadora concluye al momento de la entrega de cada uno de los incentivos a sus correspondientes acreedores.</p>
                                <p>La Organizadora, no será responsable por el uso que se le brinde el Participante a cualquiera de los incentivos, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia de la entrega del mismo, así como relacionados con la calidad, garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute del incentivo.</p>
                                <p>Los incentivos serán entregados siempre y cuando se haya validado la identidad del acreedor correspondiente.</p>
                                <p>El acreedor al incentivo deberá seguir todas las direcciones e instrucciones de La Organizadora, en caso de incumplimiento de cualquiera de estas el Participante será descalificado.</p>
                                <p>El Organizador no garantiza la disponibilidad y/o continuidad del funcionamiento del Sitio Web ni tampoco garantiza el acceso efectivo al mismo, siendo esto responsabilidad exclusiva de las prestadoras de servicios de Internet. En el mismo sentido, el Organizador no garantiza que los Participantes utilicen el Sitio Web y/o la Promoción de conformidad con estas Bases y Condiciones ni que lo hagan de forma prudente y diligente. Al participar en la Promoción el acreedor acepta expresamente haber leído y entendido a la perfección las Bases y estar de acuerdo con las mismas.</p>
                                <p>La Organizadora se reserva el derecho a realizar modificaciones o añadir anexos sucesivos sobre su mecánica e incentivos, siempre que las mismas estén justificadas o no perjudiquen a los participantes, y se comuniquen a éstos debidamente.</p>
                                <h4><span class="lb">X. JURISDICCIÓN APLICABLE:</span></h4>
                                <p>Cualquier controversia suscitada por La Promoción se regirá por las presentes Bases y como complemento para lo establecido en éstas será aplicable la jurisdicción de República de Panamá, sujetándose todas las partes interesadas a las mismas renunciando al que por beneficio de su domicilio presente o futuro pudiese corresponderles.</p>
                                <h4><span class="lb">XI. AVISO DE PRIVACIDAD:</span></h4>
                                <p>El responsable de recabar la información consistente en Datos Personales de los consumidores participantes de esta promoción es La Organizadora de la misma.</p>
                                <p>La finalidad de recabar los Datos Personales de los consumidores participantes de esta promoción es únicamente la de su individualización e identificación respecto del resto de personas que forman parte de la misma, con el objeto de hacer entrega de los incentivos ofrecidos en caso de tener derecho en tal supuesto.</p>
                                <p>El uso o divulgación de los Datos Personales de los consumidores participantes de la promoción, se encuentran restringidos respecto de los demás participantes y están protegidos en su divulgación para ser compartidos con empresas filiales y colaboradoras de Kellogg de Centroamérica, S.A., con fines exclusivos de realizar la Promoción. Los medios para que los participantes de la promoción ejerzan su derecho al acceso, rectificación, cancelación u oposición de difusión de sus Datos Personales, serán directamente como La Organizadora de la Promoción por conducto de los medios de comunicación que se han señalado anteriormente en el apartado respectivo. Por la naturaleza de los Datos Personales recabados no se efectuará ninguna transferencia a tercero.</p>
                                <p>Cualquier cambio del presente Aviso de Privacidad será comunicado por el sitio www.pringles.com la información consistente en Datos Personales proporcionada por los participantes, será eliminada una vez asignada la totalidad de incentivos ofrecidos y reclamados en su totalidad por quienes tengan derecho a recibirlos, salvo aquellos que por obligaciones de índole fiscal o de protección al consumidor deban conservarse.</p>
                                <table class="globalTable" width="531" height="165" border="1">
                                    <tr>
                                        <th colspan="4">ANEXO A NÚMEROS PREDETERMINADOS.</th>
                                    </tr>
                                    <tr>
                                        <td># Incentivo</td>
                                        <td>SEMANA</td>
                                        <td>FECHAS</td>
                                        <td>REGISTRO GANADOR DEL PERIODO</td>
                                    </tr>
                                    <tr>
                                        <td>1</td>
                                        <td>1</td>
                                        <td>1 DE OCTUBRE 15 DE NOVIEMBRE</td>
                                        <td>105</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>2</td>
                                        <td>16 DE NOVIEMBRE 31 DE DICIEMBRE</td>
                                        <td>125</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>3</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>4</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>5</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>6</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>7</td>
                                        <td>7</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>8</td>
                                        <td>8</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>9</td>
                                        <td>9</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>10</td>
                                        <td>10</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </table>
                                <table class="globalTable" width="531" height="165" border="1">
                                    <tr>
                                        <th colspan="4">ANEXO NÚMERO DE PREMIOS TOTALES HONDURAS.</th>
                                    </tr>
                                    <tr>
                                        <td colspan="4">CANTIDAD TOTAL</td>
                                    </tr>
                                    <tr>
                                        <td>Consolas unidad</td>
                                        <td>$5 PS Store</td>
                                        <td>$9.99 PS Plus</td>
                                        <td>Exclusive theme</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>75</td>
                                        <td>75</td>
                                        <td>30, 000</td>
                                    </tr>
                                </table>
                            </div>
                            <h1 class="not-visible">Términos y Condiciones de la promoción Pringles® XMAS 2018. </h1>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
     components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted(){
        this.$store.dispatch('setBodyClass', 'global')
    },
    metaInfo() {
        return {
            title: 'Promoción Pringles® | Términos y Condiciones'
        }
    },
}
</script>
