<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/snackea-pro/466x276-snackea-sv.jpg" alt="Snackea como un Pro con Pringles® y Cheez it®">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">SNACKEA COMO UN PRO CON PRINGLES® Y CHEEZ IT®</h4>
                                <h5>BASES GENERALES PARA EL CONSUMIDOR PARTICIPANTE</h5>
                            </div>
                        </div>
                        <!-- Insertar section terminos -->
                        <section class="terms">
                            <div class="terms-wrp">
								<div class="wrappTerms">
									<h1 class="not-visible">Términos y Condiciones de la promoción “Snackea como un Pro con Pringles® y Cheez it®”</h1>
								
									<p><strong>I. DEL OBJETIVO DE LA ACTIVIDAD PROMOCIONAL:</strong></p>

									<p>La promoción tiene por objetivo reconocer a  los consumidores de los productos Kelloggs ® y Pringles ® y darles la  oportunidad de participar en el sorteo aleatorio.</p>
									<p>La participación de los consumidores en esta  promoción implica la aceptación de la mecánica, términos y condiciones  establecidos, a los cuáles se puede acceder a través de la siguiente dirección:  <a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a>.</p>
									<p>Este reglamento delimita y establece  las condiciones bajo las cuales se regirá la Promoción &ldquo;SNACKEA COMO UN PRO CON  PRINGLES® Y CHEEZ IT®&rdquo;. Lo aquí dispuesto será de acatamiento obligatorio tanto  para los participantes como para los organizadores. La participación en el  concurso implica la aceptación y cumplimiento a estas reglas y las decisiones  que tome el organizador, las cuales serán definitivas sobre todos los asuntos  relacionados con la promoción. Cualquier violación a estas reglas implicará la  exclusión del concurso y/o la revocación del premio.</p>
									<p><strong>II. DEL PATROCINADOR:</strong></p>
									<p>Responsable de la Promoción: <br>
									En <strong>El Salvador</strong>: BRIGHT SOLUTIONS S.A de  C.V., Calle el talapo, Residencial Lomas de Altamira Casa  #116C, San Salvador, San Salvador. </p>
									<p><strong>III. </strong><strong>DE LA VIGENCIA  PROMOCIONAL:</strong></p>
									<p>Vigencia: Inicio 5 de agosto de 2021  12:00 am, si ya se cuenta con la autorización por parte del Ministerio  de Gobernación y Desarrollo Territorial la autorice, hasta el 30 de septiembre  de 2021 11:59 pm.</p>
									<p>Participan todas las facturas de  compra ingresadas al sistema durante la vigencia de la promoción, las cuales  podrán ser registradas durante el período de las fechas arriba indicadas. </p>
									<p><strong>Cobertura geográfica:</strong> El Salvador</p>
									<p><strong>IV. </strong><strong>DE LOS  PARTICIPANTES:</strong></p>
									<p>Podrá participar en la Promoción cualquier persona  física que tenga la disposición de participar, compre los Productos  Participantes y reúna todos y cada uno de los siguientes requisitos (el  &ldquo;Participante&rdquo; o los &ldquo;Participantes&rdquo;):</p>
									<ol>
										<li>Ser  mayor de edad con plena capacidad de ejercicio legal.</li>
										<li>Contar  con número telefónico de contacto.</li>
										<li>Cumplir  con la mecánica de la Promoción.</li>
									</ol>
									<p>No podrán participar todas aquellas personas que  sean familiares hasta el cuarto grado de un empleado o empleados del  Organizador ni del Patrocinador, ni subsidiarias y/o agencias &ldquo;partners&rdquo; así  como de cualquiera de las empresas que formen parte del mismo grupo de  intereses económicos al que pertenece el Organizador y el Patrocinador y las  empresas vinculadas en la ejecución de la Promoción.</p>
									<p><strong>V. </strong><strong>DE LA FORMA DE  PARTICIPAR:</strong></p>
									<p>Para participar en la  Promoción, los Participantes deberán cumplir con la siguiente mecánica de la  Promoción y los requisitos de elegibilidad previstos en las presentes Bases.</p>
									<ol class="decimal">
										<li>Comprar cualquiera de los Productos Participantes PRINGLES® o CHEEZ IT® en cualquiera de los Establecimientos que emitan factura de comprobante de compra de los productos indicados anteriormente.</li>
										<li>Enviar por Whatsapp al número abajo indicado una foto legible de cada una de las facturas que cumplan con los requisitos legales y tenga número de correlativo y/o número de identificación tributaria del Establecimiento Participante, donde se muestre la compra de los Productos Participantes. El Participante tendrá derecho a una participación por cada factura, independientemente de cuantos Productos Participantes vayan incluidos en cada factura.<br>
										<br>
										El Salvador al número: (502) 55543434<br>
										<br>
										</li>
										<li>Las facturas participantes deberán ser emitidas entre el 5 de agosto de 2021 hasta el día 30 de septiembre de 2021.</li>
										<li>El Organizador verificará si la factura es válida, proceso que tomará un máximo de 24 horas. En el ínterin, el Organizador responderá en la conversación de Whatsapp con un link con las Bases.</li>
										<li>Se le enviará al Participante por Whatsapp las Bases de la Promoción donde deberá aceptar los mismos enviando la palabra “SI” o “ACEPTO” para poder ser elegible como ganador. </li>
										<li>Verificada la validez de la factura, se enviará un código único de participación, el cual entrará al siguiente sorteo a realizarse luego de recibido el código único de participación. El sorteo será realizado por medio de tómbola electrónica para optar al premio, siempre que cumpla con los requisitos previamente descritos.</li>
										<li>Se le notificará al Participante la fecha del sorteo de la Promoción en el que participará su código único de participación. Las fechas en que se realizarán los sorteos son:<br>
										<br>
										<strong>EL SALVADOR:</strong><br>
										<br>
										12 de agosto de 2021.<br>
										<br>
										19 de agosto de 2021.<br>
										<br>
										26 de agosto de 2021.<br>
										<br>
										2 de septiembre de 2021.<br>
										<br>
										9 de septiembre de 2021.<br>
										<br>
										16 de septiembre de 2021.<br>
										<br>
										23 de septiembre de 2021.<br>
										<br>
										30 de septiembre de 2021.<br>
										<br>
										Para lo cual se utilizará un sistema electrónico en el cual se elegirá al ganador y se contactará a cada participante posteriormente para notificar si ha sido seleccionado en el sorteo o para darle las gracias por su participación.</li>
										<li>En caso el Participante sea notificado que ha sido pre-seleccionado como ganador al azar, se le contactará para verificar el cumplimiento de los Requisitos de Elegibilidad en un plazo de 5 días hábiles a partir de que sea contactado, y una vez confirmado el cumplimiento de todos los requisitos de elegibilidad, se le considerará ganador de la Promoción. En caso no cumpla con los requisitos en el plazo correspondiente, se tendrá por renunciada su participación y se procederá a notificar al siguiente pre-seleccionado que sea elegido a través de la misma mecánica hasta llegar a un Participante pre-seleccionado que cumpla con los requisitos en el plazo otorgado. </li>
										<li>La línea de Whatsapp, estará abierta durante el período de la Promoción con un agente exclusivo para cualquier duda o comentario del Participante, referente a la Promoción.</li>
									</ol>
									<p><strong>Productos Participantes:</strong></p>
									<p>Los productos participantes en la Promoción son  Productos PRINGLES® Y CHEEZ IT® en cualquier presentación, incluyendo:</p>
									<p><strong>Pringles®:</strong></p>
									<ul class="bullets">
										<li>37gr</li>
										<li>40 gr&nbsp;</li>
										<li>71 gr</li>
										<li>77 gr</li>
										<li>112gr</li>
										<li>124gr</li>
										<li>130gr</li>
										<li>137gr</li>
										<li>149gr</li>
										<li>158gr</li>
										<li>194gr</li>
										<li>203gr</li>
										<li>5 pack 95gr y 105gr</li>
										<li>12pack de 252gr&nbsp;o 258gr (muelitas).</li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Cheddar  Jack:</strong></p>
									<ul class="bullets">
										<li>351</li>
										<li>85g</li>
										<li>42g</li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Duoz  Parmesan:</strong></p>
									<ul type="disc" class="bullets">
										<li>351g</li>
										<li>121g</li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Duoz  Caramel Pop Corn:</strong></p>
									<ul class="bullets">
										<li>246g </li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Duoz  Bacon:</strong></p>
									<ul class="bullets">
										<li>351g </li>
										<li>121g </li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;White  Cheddar:</strong></p>
									<ul class="bullets">
										<li>85g </li>
										<li>42g </li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Original:</strong></p>
									<ul class="bullets">
										<li>42g </li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Grooves  Zesty Cheddar Ranch:</strong></p>
									<ul class="bullets">
										<li>170g </li>
									</ul>
									<p><strong>Cheez&nbsp;It®&nbsp;Grooves  Sharp White Cheddar:</strong></p>
									<ul class="bullets">
										<li>170g </li>
									</ul>
									<p><strong>Medio de registro y consulta de las bases de la promoción: </strong></p>
									<p>Para participar en la Promoción, los Participantes  deberán cumplir con la siguiente mecánica de la Promoción y los requisitos de  elegibilidad previstos en las presentes Bases.</p>
									<ol class="decimal">
										<li>Comprar cualquiera de los Productos  Participantes PRINGLES® o CHEEZ IT® en cualquiera de los Establecimientos que  emitan factura de comprobante de compra de los productos indicados  anteriormente.</li>
										<li>Enviar por  Whatsapp al número abajo indicado una foto legible de cada una de las facturas  que cumplan con los requisitos legales y tenga número de correlativo y/o número  de identificación tributaria del Establecimiento Participante, donde se muestre  la compra de los Productos Participantes. El Participante tendrá derecho a una  participación por cada factura, independientemente de cuantos Productos  Participantes vayan incluidos en cada factura.<br>
									<br>
									El Salvador al número: (502) 55543434<br>
									<br>
									</li>
										<li>Las facturas  participantes deberán ser emitidas entre el 5 de agosto de 2021 hasta el día 30  de septiembre de 2021.</li>
										<li>El Organizador  verificará si la factura es válida, proceso que tomará un máximo de 24 horas.  En el ínterin, el Organizador responderá en la conversación de Whatsapp con un  link con las Bases.</li>
										<li>Se le enviará al Participante por Whatsapp las  Bases de la Promoción donde deberá aceptar los mismos enviando la palabra &ldquo;SI&rdquo;  o &ldquo;ACEPTO&rdquo; para poder ser elegible como ganador. </li>
										<li>Verificada la validez de la factura, se enviará un código único de  participación, el cual entrará al siguiente sorteo a realizarse luego de  recibido el código único de participación. El sorteo será realizado por medio  de tómbola electrónica para optar al premio, siempre que cumpla con los  requisitos previamente descritos<br>
									<br>
									Se podrán consultar las bases en la página <a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a></li>
									</ol>
									<p><strong>VI. </strong><strong>DE LOS PREMIOS A OTORGAR EN LA ACTIVIDAD PROMOCIONAL</strong></p>
									<p>USD$500 cada semana a partir del 12 de  agosto, hasta el 30 de septiembre.</p>
									<p><strong>VII. DE LA ENTREGA DE LOS PREMIOS</strong></p>
									<p>La notificación y revisión de cumplimiento de  requisitos se realizará el día lunes siguiente a la fecha del sorteo por vía  Whatsapp.</p>
									<p>La entrega del premio se realizará a partir de la  semana siguiente al sorteo en los siguientes lugares:</p>
									<div class="globalTableWrp">
									<table border="0" cellpadding="0" cellspacing="0" class="globalTable">
										<tr>
											<th>EL SALVADOR</th>
											<td>Calle el talapo, Residencial Lomas de Altamira  Casa #116C, San Salvador, San Salvador.</td>
										</tr>
									</table>
									</div>
									<p><strong>VIII. DE LAS  REGLAS PARA RECLAMO DE PREMIOS.</strong></p>
									<ol>
										<li>Se  realizará un sorteo en las fechas establecidas cumpliendo con la normativa  aplicable de todos los códigos únicos de participación que se hayan emitido en  el período correspondiente de la promoción.   </li>
										<li>Una  vez determinado el código ganador, se procederá a la selección de posibles  ganadores secundarios, a los cuales se les asignará un orden de prelación según  sean seleccionados.</li>
										<li>La  notificación se hará vía Whatsapp al mismo número de teléfono desde el cual  enviaron las facturas para participar en la Promoción.</li>
										<li>Se le  notificará al código seleccionado para el premio de la Promoción, que es un  posible ganador y que será elegible a recibir el premio si cumple con los todos  los requisitos aplicables en el plazo otorgado.  </li>
										<li>De  recibir confirmación que el posible ganador cumple con todos los requisitos  aplicables, se procederá a la verificación visual de documentos para  cerciorarse de que cumpla con los mismos en el plazo antes establecido. </li>
										<li>Los  documentos deberán ser enviados por fotografía o un scan vía correo electrónico  a uno de los encargados del Organizador para la verificación y aceptación de  los mismos. </li>
										<li>Una  vez verificada visualmente dicha información dentro del plazo otorgado, se le  confirmará como el ganador.</li>
										<li>En  caso de que el código seleccionado no cumpla con cualquiera de los requisitos,  se le descartará automáticamente como ganador y se procederá a contactar a los  siguientes códigos secundarios con orden de prelación y proceder con la  verificación antes descrita hasta llegar a un Participante que haya recibido un  código secundario que cumpla con los requisitos de elegibilidad. </li>
										<li>Cada  Participante ganador deberá firmar el recibo y finiquito de conformidad previo  a la entrega del premio.</li>
										<li>Una vez entregado el Premio, el Participante que  haya resultado ganador será el único responsable del destino del Premio,  liberando de toda responsabilidad a El Organizador, Patrocinador, sus filiales,  subsidiarias y contratistas. Asimismo, si se suspendiera, modificara, alterara  o de cualquier forma se viera imposibilitados el Participante ganador a  disfrutar del premio por causas no imputables al Organizador o al Patrocinador,  éstos quedarán libres de toda responsabilidad frente al Participante al  respecto.</li>
										<li>El Organizador ni el Patrocinador serán responsables  por el uso que le brinde el Participante ganador al premio recibido, ni por  procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser  interpuestos en relación a un reclamo de cualquier tipo de responsabilidad, por  hechos acontecidos durante o como consecuencia de la entrega del mismo, así  como relacionados con la calidad, garantías por daños, pérdidas o perjuicios  ocasionados por el uso y disfrute del Premio.</li>
										<li>Los impuestos en el territorio que fueren aplicables  serán cubiertos por el Organizador.</li>
										<li>Los premios estarán disponibles en un plazo máximo  de 6 meses contados a partir de la fecha de confirmación de que el Participante  fue confirmado como Ganador, después de la fecha no podrá hacerse reclamación  alguna.</li>
									</ol>
									<p><strong>IX.	DE LA RESPONSABILIDAD, CONDICIONES Y RESTRICCIONES DEL PATROCINADOR</strong></p>
									<p><strong>Restricciones:</strong></p>
									<p>No podrán participar empleados y familiares  directos hasta segundo grado de consanguinidad o afinidad de BRIGHT SOLUTIONS  S.A de C.V. y sus patrocinadores, y sus respectivas agencias, empresas  afiliadas, matrices, subsidiarias y detallistas, agencias de publicidad,  patrocinadores y/o proveedores relacionados con el concurso o involucrados en el desarrollo de esta promoción.</p>
									<p>El consumidor podrá registrar tantos números  de factura por cada compra que realice de los productos participantes como  desee durante la vigencia de la promoción, siempre y cuando correspondan a  compras diferentes y cumplan con los requisitos de las presentes bases.</p>
									<p>En caso de imposibilidad de ejecutar el canje  por cualquier motivo, no aplica compensación o remuneración alguna.</p>
									<p>Los premios <strong>NO </strong>son transferibles,  intercambiables por su equivalente en efectivo, ni acumulables, en el entendido  de que los participantes no pueden ganar el mismo premio dos o más veces. </p>
									<p><strong>Aceptación y condiciones de las bases:</strong></p>
									<p>El participar en la promoción <strong>&ldquo;SNACKEA  COMO UN PRO CON PRINGLES® Y CHEEZ IT®&rdquo; </strong>supone la aceptación de las bases  generales y particulares que apliquen. BRIGHT SOLUTIONS S.A de C.V. podrá  requerir, cuando estime pertinente, que los ganadores autoricen expresamente a  que se utilice su nombre, imagen, fotografía y/o voz en cualquier medio de  comunicación conocido o por conocerse, de la manera que más convenga a los  intereses de BRIGHT SOLUTIONS S.A de C.V, o alguno de sus patrocinadores,  dentro de la República Guatemala, El Salvador, Costa Rica, Honduras, Nicaragua  o Panamá, sin que esto implique pago adicional alguno por su aparición o  mención.</p>
									<p>Asimismo el participante permite y  autoriza expresamente que se utilice su información personal y correo  electrónico para ésta y futuras promociones que realice BRIGHT SOLUTIONS S.A de  C.V. o sus patrocinadores</p>
									<p>Ni BRIGHT SOLUTIONS S.A de C.V, ni  alguna de sus patrocinadoras serán responsables por: registros de participación  que no cumplan con lo establecido en las bases, fallas en los equipos de  computación, de comunicación, de suministro de energía, de líneas telefónicas,  de la red de Internet, ni por desperfectos técnicos. La prueba de participación  electrónica (folio de registro) en la promoción no será prueba de recibo por  parte de los organizadores. </p>
									<p>La participación en el concurso será  nula e inválida si no se realiza de conformidad con los términos y condiciones  de la promoción incluidos en este documento o publicados en la página de la  promoción, o si no se realiza por los canales legítimamente autorizados.</p>
									<p>Cualquier violación a las bases, a los  procedimientos o sistemas establecidos, alteración, falsificación, manipulación  o engaño a los promotores para pretender ser ganador causará la anulación del  registro como participante y por tanto el participante será descalificado. Al  participar en este concurso, los participantes aceptan acogerse a la estricta  aplicación de estas reglas y futuras modificaciones, en todos sus términos, así  como las decisiones de los auspiciadores. </p>
									<p>Los datos personales que proporcionen  los participantes, serán empleados para los fines de la presente promoción. </p>
									<p>BRIGHT SOLUTIONS S.A de C.V. y sus  patrocinadores se reservan el derecho de modificar en cualquier momento los  términos y condiciones incluidos en el presente. Cualquier modificación podrá  consultarla en la página de la promoción (<a href="http://promopringles.com/" target="_blank">http://promopringles.com/</a>)</p>
									<p><strong>Relevo de Responsabilidad: </strong></p>
									<p>Los participantes acuerdan, relevar de  responsabilidad a BRIGHT SOLUTIONS S.A de C.V., al auspiciador y a cada una de  sus respectivas matrices, afiliadas, subsidiarias, oficiales, directores,  accionistas agentes, empleados y todos aquellos asociados con el desarrollo y  ejecución de esta promoción, de cualquier responsabilidad en relación a, o de  cualquier manera relacionado con, esta promoción y/o la aceptación, o el  uso/mal uso de los premios, incluyendo responsabilidad por daños personales y/o  muerte, daños o pérdidas económicas. Los auspiciadores no se hacen responsables  por la calidad o utilidad de los premios y entregarán los mismos con las  garantías usuales del proveedor.</p>
									<p>El organizador es responsable <strong>únicamente </strong>de la entrega de los premios indicados en el presente reglamento. El premio  se le entregará al ganador de conformidad con lo indicado en este documento,  por un representante de BRIGHT SOLUTIONS S.A de C.V. y sus patrocinadores,  previa presentación del documento legal de identificación que acredite al  usuario como el participante que se inscribió en el sitio. El premio no incluye  gastos personales ni cualquier otro gasto no señalado expresamente como  cubierto en este documento, entendiéndose por esto todos aquellos gastos que  llegare a incurrir el ganador al momento de reclamar o canjear su premio. El  ganador deberá firmar un documento de recepción del premio proporcionado al  efecto por el organizador, liberándolo expresamente de responsabilidad. Ni  BRIGHT SOLUTIONS S.A de C.V., ni sus patrocinadores, son responsable si al  ganador o ganadores se les impide por cualquier motivo ajeno al organizador  canjear el premio. Cualquier tipo de tasa o impuesto deberá ser pagado por el  ganador.</p>
									<p>Ninguna disposición de la presente  promoción se deberá interpretar como creadora de una relación entre las partes  más allá de lo necesario para dar cumplimiento a las estipulaciones de la  promoción. Con el cumplimiento de las obligaciones establecidas en este  reglamento y con el cumplimiento del propósito de la presente promoción  finalizará la relación entre las partes en su totalidad.</p>
									<p>Al registrarse como participante de la  promoción, el participante declara expresamente que está de acuerdo con los  términos y condiciones contenidas en el presente Reglamento y autoriza,  incondicionalmente, a que BRIGHT SOLUTIONS S.A de C.V. y sus patrocinadores use  su nombre, voz, imagen y fotografía, en publicidad y promociones, en cualquier  medio de comunicación, refiriéndose a esta promoción, sin ningún derecho a  retribución monetaria.</p>
									<p>Todas y cualquier situación no  considerada en estas Reglas, al igual que cualquier omisión, será determinada,  exclusivamente, por la Comisión de la Promoción.</p>
									<p>El Organizador se reserva el derecho,  a su criterio, de modificar las Reglas de la promoción, siendo que cualquier  modificación será informada con de cinco días calendario de anticipación en el  sitio de la promoción, todo en línea con la legislación vigente, sin daño a  ningún participante.</p>
									<p>Cualquier consulta sobre los alcances  e interpretación de este reglamento podrá evacuarse a través de los teléfonos  de atención al consumidor de Bright Solutions, S.A. de C.V., sin que la  respuesta implique, bajo ninguna circunstancia, modificación total o parcial de  este Reglamento o una dispensa de su cumplimiento.</p>
									<p>Los términos y condiciones indicadas  en este Regl<strong>a</strong>mento son de obligatorio cumplimiento para los  participantes, siempre que los mismos no contraríen las disposiciones legales  de los países en que tendrá lugar la promoción.</p>
									<p><strong>X. DE LA ENTIDAD BENEFICA</strong></p>
									<p>En  caso que algún premio no sea reclamado por los ganadores, éstos serán guardados  en las  oficinas de BRIGHT SOLUTIONS S.A  de C.V., para ser utilizados en una promoción ulterior de sus patrocinadores.</p>
									<p>Si  la sociedad decide no guardar los premios no reclamados, los donará a la  entidad de beneficencia que en su momento se determine por la administración  vigente de la sociedad.</p>
									<p><strong>XI. DE LA INFORMACION O CONSULTAS SOBRE LA ACTIVIDAD PROMOCIONAL</strong></p>
									<p>Vía Internet ingresando <a href="http://promopringles.com/">http://promopringles.com/</a><strong>.</strong></p>
								</div>
							</div>
                        </section>
                    </div>
                </main>
            </section>
        </main>
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
import { mapState } from 'vuex'
export default {
    components: {
        Headers,
        Footers,
    },
    computed: {
        ...mapState(['country'])
    },
    mounted() {
        this.$store.dispatch('setBodyClass', 'global')
    },
	metaInfo() {
        return {
            title: 'Snackea como un Pro con Pringles® y Cheez It®. | Términos y condiciones El Salvador.',
			meta: [
				{
					name: 'description',
					content: 'Snackea como un Pro con Pringles® y Cheez It®. Conoce todos los detalles sobre las reglas, legislación y terminología aplicables en nuestros Términos y condiciones.'
				},
			]
        }
    },
}
</script>