
<template>
    <div>
        <Headers></Headers>
        <main class="mainWrp">
            <section class="globalWrp">
                <aside class="titleSectionWrp">
                    <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                    <h2>{{ $t('legals')}}</h2>
                    
                </aside>
                <main class="one-whole heightScroll">
                    <div>
                        <div class="headerTerms one-whole">
                            <img src="@/assets/images/global/banners/cupones/pringles-amazon-466x276-mx.jpg"  alt="Pringles® Cupones Código de Descuento">
                            <div class="titleTermsWrp one-whole">
                                <p class="year">2021</p>
                                <h4 class="titleTerms">Promoción “Pringles® Cupones Código de Descuento”</h4>
                                <h5>TÉRMINOS Y CONDICIONES</h5>
                            </div>
                        </div>
                        <!-- Insertar section terms -->
                        <section class="terms">
                            <div class="terms-wrp">
                                <div class="wrappTerms">
                                <h1 class="not-visible">Todo cae mejor con Pringles®. Gana un código de descuento en Amazon.</h1>
                                        
                                <p>Los términos y condiciones descritos a continuación (las &ldquo;<strong>Bases</strong>&rdquo;) establecen las condiciones y  requisitos bajo los cuales se regirá la actividad promocional <strong>&ldquo;</strong><strong>Pringles® Cupones  Código de Descuento&rdquo;.</strong> (en lo sucesivo la &ldquo;<strong>Promoción</strong>&rdquo;).  Lo aquí dispuesto es obligatorio para todos  los participantes de la promoción, por lo que se deberá dar lectura total a las  presentes <strong>Bases</strong>.  La Promoción se ejecutará a través de la  compra de 2 latas o 4 latas de Pringles® 37/40g en sabores Original, Crema y  Cebolla y Queso, solamente en tiendas con apoyo de demostradoras seleccionadas  por el Organizador. </p>
                                <p>Las bases podrán ser consultadas por los Participantes a  través de <strong><a href="https://www.promopringles.com" target="_blank">https://www.promopringles.com</a></strong></p>
                                <p>Al  participar en la promoción&nbsp;&ldquo;<strong>Pringles® Cupones Código de Descuento</strong>&rdquo;&nbsp;(en  lo sucesivo la&nbsp;&ldquo;Promoción&rdquo;), el participante declara haber leído,  entendido y sujetarse totalmente a los términos y condiciones aquí establecidas  (en adelante las&nbsp;&ldquo;Bases&rdquo;). Los términos y condiciones podrán ser  consultados a través de <strong>promopringles.com</strong> (&ldquo;Sitio Web de la Promoción&rdquo;).</p>
                                <p><strong>I. ASPECTOS GENERALES DE LA PROMOCIÓN.</strong></p>

									<p><strong>Nombre:</strong> <strong>&ldquo;Pringles® Cupones  Código de Descuento</strong>&rdquo; <br>
									  <strong>Cobertura:</strong> Nivel Nacional. República  Mexicana, en Tiendas que cuenten con apoyo de demostradoras seleccionadas por  &ldquo;El organizador&rdquo;.<br>
									  <strong>Vigencia:</strong> Del 19 de junio al 31 de diciembre de 2021<br>
									  <strong>Naturaleza: </strong>La promoción no involucra el azar, todos los productos  participantes ganan.<br>
									  <strong>Organizador:</strong> Kellogg Company México, S. de R.L. de C.V. (el &ldquo;<strong>Organizador</strong>&rdquo;).<br>
								    <strong>Domicilio del  Organizador</strong>: KM 1, Carretera a Campo  Militar S/N, Colonia San Antonio de la Punta, Querétaro, Qro, México. C.P.  76135.  </p>
<p><strong>II. DEFINICIONES.</strong></p>

									<p>Sin perjuicio de cualesquiera  otros términos que puedan encontrarse aquí definidos, para efectos de estas  Bases se entenderá por:</p>
									<p><strong>Participante.-</strong> Persona que compre los  productos participantes y siga los lineamientos de los presentes términos y  condiciones.</p>
									<p><strong>Cupón</strong>: Incentivo que dará la &ldquo;demostradora&rdquo; en la compra de Productos  Participantes en las Tiendas Participantes<strong>.</strong></p>
                                    <p><strong>Demostradora: </strong>Persona que tiene una &ldquo;labor de venta&rdquo; en el punto  de venta (autoservicio) y que está habilitada para dar el incentivo siempre y  cuando el participante cumpla con los lineamientos de la mecánica del presente  documento.</p>
                                    <p><strong>Dinámica</strong>.- Se compone de las diversas actividades que permitirán a los  Participantes poder ser acreedores al Incentivo que se determina en el presente  documento.</p>
                                    <p><strong>Incentivos.-</strong> Bienes que se ofrecen en la Promoción y que se describen  en la sección VI de estas Bases. </p>
                                    <p><strong>Mecánica de la promoción. -</strong> Conjunto de lineamientos y pasos a seguir para  participar en la Dinámica y aspirar a obtener el incentivo. </p>
                                    <p><strong>Participaciones.</strong>- Se entiende por &ldquo;Participación&rdquo;  cada vez que un usuario compre el producto participante, en las tiendas donde  se encuentre la Demostradora y obtenga el &ldquo;cupón&rdquo;, mismo que será entregado por  la &ldquo;demostradora&rdquo;.</p>
                                    <p><strong>Productos  Participantes.-</strong> Los productos a  promocionar y descritos en el apartado IV primer párrafo. </p>
<p><strong>III. REQUISITOS DE ELEGIBILIDAD PARA PARTICIPAR EN LA PROMOCIÓN.</strong></p>
  <p>Podrá participar en la Promoción cualquier persona física,  mayor de edad que tenga la disposición de participar y reúna todos y cada uno  de los siguientes requisitos:</p>
  <ol start="1" type="1" class="decimal">
    <li>Ser mayor de       18 años de edad.</li>
    <li>Contar con       legal residencia en los Estados Unidos Mexicanos. </li>
    <li>Comprar el       producto participante. </li>
    <li>Se podrá       participar tantas veces se desee y en el mismo día siempre y cuando se       cumpla con la compra de los productos participantes.</li>
    <li>Se requiere       tener acceso a internet y a la plataforma de Amazon</li>
  </ol>
  <p>
    No podrán participar todas aquellas personas que sean  familiares hasta el tercer grado de un empleado o empleados del Organizador,  subsidiarias y/o agencias &ldquo;partners&rdquo; así como de cualquiera de las empresas que  formen parte del mismo grupo de intereses económicos al que pertenece el  Organizador y las empresas vinculadas en la ejecución de la Promoción. </p>
<p><strong>IV. PRODUCTOS Y TIENDAS PARTICIPANTES.</strong></p>
										<p>Los Productos Participantes son latas de Pringles® 37/40g  en sabores Original, Crema y Cebolla y Queso.</p>
										<p>Las Tiendas participantes son: <strong></strong></p>
<table border="0" cellpadding="0" cellspacing="0" class="simpleTable">
	  <thead>
													
													<tr class="sticky-title table-subtitle">
														<th>
                                                            <img src="@/assets/images/global/banners/cupones/tabla-mx/table-top.jpg" alt="img-table">
                                                        </th>
													</tr>
												</thead>
											<tbody>
									<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table1.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table2.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table3.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table4.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table5.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table6.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table7.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table8.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table9.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table10.jpg" alt="img-table"></td>
		</tr>
	<tr>
		<td><img src="@/assets/images/global/banners/cupones/tabla-mx/table11.jpg" alt="img-table"></td>
		</tr>
	</tbody>
	</table>
	
	
	<table border="0" cellpadding="0" cellspacing="0" class="globalTable table-fixed-tab">

	<tr class="sticky-title table-subtitle">
		<th>TIENDA_ID</th>
		<th>REGI&Oacute;N</th>
		<th>TERRITORIO</th>
		<th>SUBTERRITORIO</th>
		<th>TIENDA</th>
	</tr>
	<tr>
		<td>SO0012</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0012 SAN NICOLAS</td>
	</tr>
	<tr>
		<td>SO0228</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0228 SENDERO LINCOLN</td>
	</tr>
	<tr>
		<td>SO0092</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0092 SOLIDARIDAD</td>
	</tr>
	<tr>
		<td>SO0238</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA SUPER 0238 PLAZA FIESTA ANAHUAC</td>
	</tr>
	<tr>
		<td>SO0050</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0050 LOS FRESNOS</td>
	</tr>
	<tr>
		<td>SO0591</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0591 SANTA ROSA</td>
	</tr>
	<tr>
		<td>SO0241</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0241 SENDERO APODACA</td>
	</tr>
	<tr>
		<td>SO0132</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0132 TOPO CHICO</td>
	</tr>
	<tr>
		<td>SO0116</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0116 SENDERO</td>
	</tr>
	<tr>
		<td>SO0023</td>
		<td>A2</td>
		<td>Monterrey 3/Saltillo</td>
		<td>Monterrey 3</td>
		<td>SORIANA HIPER 0023 FELIX U GOMEZ</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0088 LAS QUINTAS</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0161 VALLE SOLEADO</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0203 SAN ROQUE</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0027 STA MARIA</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0111 HUINALA</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>SORIANA HIPER 0008 GUADALUPE</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>WM SUPERCENTER 2676 FELIX GALVAN</td>
	</tr>
	<tr>
		<td><br></td>
		<td>A2</td>
		<td>MONTERREY 2</td>
		<td>MONTERREY 2</td>
		<td>WM SUPERCENTER 2076 MIGUEL ALEMAN</td>
	</tr>
	<tr>
		<td>SO0014</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0014 SAN PEDRO</td>
	</tr>
	<tr>
		<td>SO0024</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0024 CUMBRES</td>
	</tr>
	<tr>
		<td>SO0007</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0007 CONTRY</td>
	</tr>
	<tr>
		<td>SO0059</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0059 LA PUERTA</td>
	</tr>
	<tr>
		<td>SO0133</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA SUPER 0133 SAN JERONIMO</td>
	</tr>
	<tr>
		<td>SO0673</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA PLAZA SERENA 0673</td>
	</tr>
	<tr>
		<td>SO0010</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0010 LINCOLN</td>
	</tr>
	<tr>
		<td>SO0053</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA HIPER 0053 LAS TORRES</td>
	</tr>
	<tr>
		<td>WM2034</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>WM SUPERCENTER 2034 LAS TORRES</td>
	</tr>
	<tr>
		<td>WM1622</td>

		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>WM SUPERCENTER 1622 GOMEZ MORIN</td>
	</tr>
	<tr>
		<td>HIPERMERCADO</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>WM SUPERCENTER 1833 CERRO DE LA SILLA</td>
	</tr>
	<tr>
		<td>SO0344</td>
		<td>A2</td>
		<td>Monterrey</td>
		<td>Monterrey 1</td>
		<td>SORIANA MITRAS</td>
	</tr>
	<tr>
		<td>CH0039</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 4</td>
		<td>CHEDRAUI 0039 PALOMAS</td>
	</tr>
	<tr>
		<td>CH0091</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 4</td>
		<td>CHEDRAUI 0091 TECAMAC</td>
	</tr>
	<tr>
		<td>CH0116</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 3</td>
		<td>CHEDRAUI 0116 MEX. TEPOZAN</td>
	</tr>
	<tr>
		<td>CH0154</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>CHEDRAUI 0154 XOCHIMILCO ACUEDUCTO</td>
	</tr>
	<tr>
		<td>CH0232</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>CHEDRAUI 0232 POLANCO</td>
	</tr>
	<tr>
		<td>CH0234</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 2</td>
		<td>CHEDRAUI 0234 AJUSCO</td>
	</tr>
	<tr>
		<td>CH0236</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>CHEDRAUI 0236 MUNDO E</td>
	</tr>
	<tr>
		<td>CH0237</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>CHEDRAUI 0237 TENAYUCA</td>
	</tr>
	<tr>
		<td>CH0239</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>CHEDRAUI 0239 MOLINA MEXICO</td>
	</tr>
	<tr>
		<td>CH0240</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>CHEDRAUI 0240 NEZA MEXICO</td>
	</tr>
	<tr>
		<td>CH0241</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>CHEDRAUI 0241 ANFORA MEXICO</td>
	</tr>
	<tr>
		<td>CH0260</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>CHEDRAUI 0260 NEZA NORTE MEXICO</td>
	</tr>
	<tr>
		<td>LC0149</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 3</td>
		<td>LA COMER IZTAPALAPA LAS TORRES</td>
	</tr>
	<tr>
		<td>SO0169</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 1</td>
		<td>SORIANA HIPER 0169 IXTAPALUCA</td>
	</tr>
	<tr>
		<td>SO0257</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 2</td>
		<td>SORIANA HIPER 0257 TACUBAYA</td>
	</tr>
	<tr>
		<td>SO0262</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 3</td>
		<td>SORIANA HIPER 0262 IZTAPALAPA</td>
	</tr>
	<tr>
		<td>SO0268</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>SORIANA HIPER 0268 ARAG&Oacute;N</td>
	</tr>
	<tr>
		<td>SO0286</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>SORIANA HIPER 0286 PLAZA EL SALADO</td>
	</tr>
	<tr>
		<td>SO0418</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>SORIANA HIPER 0418 CONSULADO</td>
	</tr>
	<tr>
		<td>SO0862</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 2</td>
		<td>MEGA SORIANA SAN JER&Oacute;NIMO</td>
	</tr>
	<tr>
		<td>SO0865</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>SORIANA HIPER 0865 ARAG&Oacute;N</td>
	</tr>
	<tr>
		<td>SO0875</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>MEGA SORIANA LA VIGA</td>
	</tr>
	<tr>
		<td>SO0890</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 2</td>
		<td>MEGA SORIANA TACUBA</td>
	</tr>
	<tr>
		<td>SO0898</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>MEGA SORIANA VILLAS DE LA HACIENDA</td>
	</tr>
	<tr>
		<td>SO0941</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 1</td>
		<td>MEGA SORIANA 0941 COACALCO</td>
	</tr>
	<tr>
		<td>SO0960</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 1</td>
		<td>MEGA SORIANA GRAN SUR</td>
	</tr>
	<tr>
		<td>SO0969</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>MEGA SORIANA  ATIZAP&Aacute;N</td>
	</tr>
	<tr>
		<td>SO0977</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 1</td>
		<td>MEGA SORIANA 0977 RANCHO TERREMOTO</td>
	</tr>
	<tr>
		<td>SO692</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 2</td>
		<td>MEGA SORIANA PILARES</td>
	</tr>
	<tr>
		<td>SO847</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 2</td>
		<td>MEGA SORIANA MIXCOAC</td>
	</tr>
	<tr>
		<td>WM1032</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>WM SUPERCENTER 1032 LOMAS VERDES</td>
	</tr>
	<tr>
		<td>WM1083</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 3</td>
		<td>SC PORTAL SAN ANGEL</td>
	</tr>
	<tr>
		<td>WM1107</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 1</td>
		<td>WM SUPERCENTER 1107 IXTAPALUCA</td>
	</tr>
	<tr>
		<td>WM1169</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>WM SUPERCENTER 1169 PLAZA ARBOLEDAS</td>
	</tr>
	<tr>
		<td>WM1202</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>WM SUPERCENTER 1202 HORIZONTE</td>
	</tr>
	<tr>
		<td>WM1489</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>WM SUPERCENTER 1489 HIPERPLAZA TEXCOCO</td>
	</tr>
	<tr>
		<td>WM1683</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 1</td>
		<td>WM SUPERCENTER 1683 VICENTE GUERRERO</td>
	</tr>
	<tr>
		<td>WM2033</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 3</td>
		<td>WM SUPERCENTER 2033 PLAZA ORIENTE</td>
	</tr>
	<tr>
		<td>WM2079</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 2</td>
		<td>WM SUPERCENTER 2079 JARDIN</td>
	</tr>
	<tr>
		<td>WM2344</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>WM SUPERCENTER 2344 LOMAS TOREO</td>
	</tr>
	<tr>
		<td>WM2345</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>WM SUPERCENTER 2345 TEPEYAC</td>
	</tr>
	<tr>
		<td>WM2347</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 3</td>
		<td>WM SUPERCENTER 2347 TLAHUAC</td>
	</tr>
	<tr>
		<td>WM2382</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>WM SUPERCENTER 2382 INTERLOMAS</td>
	</tr>
	<tr>
		<td>WM2430</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 1</td>
		<td>WM SUPERCENTER 2430 COPILCO</td>
	</tr>
	<tr>
		<td>WM2464</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 1</td>
		<td>WM SUPERCENTER 2464 MIRAMONTES</td>
	</tr>
	<tr>
		<td>WM3503</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 4</td>
		<td>WM SUPERCENTER 3503 ECATEPEC CENTRO</td>
	</tr>
	<tr>
		<td>WM3504</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>WM SUPERCENTER 3504 AV JESUS DEL MONTE</td>
	</tr>
	<tr>
		<td>WM3794</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>WM SUPERCENTER 3794 ACUEDUCTO DE GPE</td>
	</tr>
	<tr>
		<td>WM3845</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 2</td>
		<td>WM SUPERCENTER 3845 UNIVERSIDAD</td>
	</tr>
	<tr>
		<td>WM3847</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>WM SUPERCENTER 3847 SATELITE</td>
	</tr>
	<tr>
		<td>WM3848</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 1</td>
		<td>WM SUPERCENTER 3848 TAXQUENA</td>
	</tr>
	<tr>
		<td>WM3850</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 4</td>
		<td>WM SUPERCENTER 3850 ECHEGARAY</td>
	</tr>
	<tr>
		<td>WM3852</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 3</td>
		<td>WM SUPERCENTER 3852 PLATEROS</td>
	</tr>
	<tr>
		<td>WM3858</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR 3</td>
		<td>WM SUPERCENTER 3858 CUAJIMALPA 223</td>
	</tr>
	<tr>
		<td>WM3862</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 2</td>
		<td>WM SUPERCENTER 3862 AZCAPOTZALCO</td>
	</tr>
	<tr>
		<td>WM3876</td>
		<td>A5 (VDM)</td>
		<td>VDM SUR</td>
		<td>SUR_2</td>
		<td>WM SUPERCENTER 3876 LAS AGUILAS</td>
	</tr>
	<tr>
		<td>WM3877</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 3</td>
		<td>WM SUPERCENTER 3877 TORRES LINDAVISTA</td>
	</tr>
	<tr>
		<td>WM4018</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 1</td>
		<td>WM SUPERCENTER 4018 SANTA MARIA COACALCO</td>
	</tr>
	<tr>
		<td>WM4157</td>
		<td>A5 (VDM)</td>
		<td>VDM Norte</td>
		<td>NORTE 4</td>
		<td>WM SUPERCENTER 4157 PERIFERICO CUAUTITLAN</td>
	</tr>
	<tr>
		<td>WM5765</td>
		<td>A5 (VDM)</td>
		<td>VDM Oriente</td>
		<td>ORIENTE 4</td>
		<td>WM SUPERCENTER 5765 LAS AMERICAS</td>
	</tr>
</table>
	
	<p>Los Productos Participantes  podrán ser adquiridos en todos los establecimientos comerciales antes descritos  debidamente y legalmente constituidos en la República Mexicana y que a su vez  tengan un equipo de &ldquo;demostradoras&rdquo; en un horario de 10:00 am a 18:00 hrs. para  que la mecánica de la presente promoción pueda realizarse.</p>
  <p><strong>V. MECÁNICA DE LA PROMOCIÓN.</strong></p>
  <p>La  presente Promoción es mediante una mecánica de acumulación de compra, en dicha  mecánica no interviene el azar.</p>
  <p>El  Participante deberá comprar el producto participante en las tiendas  participantes y en los horarios en los que exista presencia física de personal  de demostración y dependiendo el número de latas de Producto Participante que  compre, será acreedor a uno de los incentivos de los productos participantes de  acuerdo al apartado IV.  Al hacerlo, se obtiene inmediatamente un cupón.</p>
  <p>El  Participante que compre el producto participante conforme a lo establecido en  los presente Términos y Condiciones, deberá de ubicar a la &ldquo;demostradora&rdquo; de  Pringles® al interior de la Tienda y  presentarle el Recibo &ldquo;Ticket&rdquo; de compra y a cambio, la demostradora le  entregará al Participante 1 (Un) cupón de $50 pesos o $100 de descuento en  Amazon de acuerdo al monto de latas que compre.</p>
  <p>El Usuario será  acreedor del cupón de $50.00 (Cincuenta pesos 00/100 M.N.) de descuento en  Amazon al comprar 2 (Dos) latas de Pringles®  37/40g en sabores Original, Crema y Cebolla y Queso; y el Usuario será acreedor  al cupón de $100.00 (Cien pesos 00/100 M.N.) de descuento en Amazon si compra 4  (Cuatro) latas de Pringles® 37/40g en sabores Original, Crema y Cebolla y  Queso.</p>
  <p>En caso de que los Incentivos totales no se agoten al  finalizar la Promoción, el Organizador podrá disponer de éstos como mejor  convenga para los intereses de la Promoción.</p>
<p><strong>VI. INCENTIVOS DE LA PROMOCIÓN.</strong></p>
  
  <p>Los incentivos a Total Promoción  serán 2,840 cupones de descuento para la plataforma tecnológica Amazon, los  cuales quedarán divididos de la siguiente manera:</p>
<ul type="disc" class="bullets">
    <li>Un       total de 2,127 (Dos mil ciento veintisiete) cupones de $50.00 (Cincuenta       pesos 00/100 M.N.) de descuento en Amazon.</li>
    <li>Un       total de 713 (Setecientos trece) cupones de $100.00 (Cien pesos 00/100       M.N.) de descuento en Amazon.</li>
  </ul>
  <p>Especificaciones del incentivo:</p>
  <ul type="disc" class="bullets">
    <li>Código válido por $50 o       $100 pesos de descuento en Amazon.</li>
    <li> El código tiene una vigencia hasta el mes       de mayo del 2022. </li>
    <li>Una vez dado del cupón,       solo se puede redimir una vez hasta la vigencia del código, posterior a       esa fecha el código se invalida.</li>
    <li>Participan cualquier       producto de Amazon.</li>
    <li>El código no es       reembolsable, intercambiable o negociable por dinero en efectivo u otro       producto.</li>
    <li>El código deberá canjearse       en <a href="https://www.amazon.com.mx/" target="_blank">amazon.com.mx</a> e ingresarlo antes de finalizar la compra.</li>
    <li>No aplica con otras       promociones.</li>
    <li>Aplican los términos de       uso consultables en <a href="https://www.amazon.com.mx/" target="_blank">amazon.com.mx</a></li>
  </ul>
  <p>El incentivo podrá ser acumulativo, es decir: El usuario podrá ser ganador de tantos  cupones como latas haya adquirido de los productos participantes debidamente  canjeados conforme la mecánica de las presentes bases. </p>
<p><strong>VII. ENTREGA  DE INCENTIVOS.</strong></p>
<ol class="decimal">
  <li>La entrega del  incentivo es de manera inmediata y será a través de &ldquo;la demostradora&rdquo; en el  punto de venta de las tiendas Participantes establecidas en los presentes  Términos y Condiciones.</li>
  <li>El Participante deberá de entregar el Comprobante o &ldquo;Ticket&rdquo;  de compra a los demostradores en buen estado, sin tachaduras o enmendaduras.Es  responsabilidad exclusiva de los Participantes proporcionar información  correcta, veraz y completa al momento del registro en la plataforma de Amazon.</li>
  <li>El Organizador de la  presente Promoción <strong>no</strong> se hace  responsable de cualquier eventualidad, relacionada con el disfrute de  cualquiera de los Incentivos, cuando dicha eventualidad se deba a causas ajenas  al Organizador. Cualquier gasto adicional como consecuencia del disfrute del  Incentivo, no está cubierto por la Promoción ni el Organizador, en caso de  pérdida de cualquiera de los incentivos parcialmente o totalmente, será  responsabilidad exclusiva del Participante ganador.</li>
</ol>
  <p><strong>VIII. RESTRICCIONES Y LIMITACIONES.</strong></p>
  <p>El Organizador no se hará responsable por cualquier falla o  eventualidad en la Participación derivadas de una mala conexión de internet del  Participante.</p>
  <p>Considerar las limitaciones descritas en el apartado VI de  este documento, así mismo, el Acreedor al incentivo para poder cangar el cupón  deberá contar con todos los elementos materiales para acceder a la plataforma tecnológica  de Amazon.</p>
  <p><strong>IX. SUPUESTOS  DE DESCALIFICACIÓN.</strong></p>
  <p>Además, de que la participación en esta Promoción implica  la aceptación de estas Bases, según sean modificadas por el Organizador, así  como de las decisiones que se adopten sobre cualquier cuestión prevista o no  prevista en ellas, el Organizador se reserva el derecho a descalificar a  cualquier persona que:</p>
<ul class="bullets">
    <li>Incumpla de cualquiera  de los requisitos señalados en las presentes Bases.</li>
    <li>Viole cualquier regla  y/o especificación de estas Bases.</li>
    <li>Use o desarrolle  cualquier tipo de programa informático que facilite la introducción y/o  validación de información, datos, códigos, etc., o la participación  automatizada en la App y/o cualquier intento, abuso o acto de mala fe en el que  se aproveche de la Promoción o se ponga a sí mismo en situación privilegiada y  cualquier otra actividad que altere la competencia por los Incentivos.</li>
    <li>Ataque o intente  alterar o manipular al sistema informático de la Promoción.</li>
    <li>No permita validar la  identidad su identidad mediante acciones u omisiones en el proceso. </li>
    <li>Llevar a cabo acciones  que atenten contra el orden público, el honor de personas, los usos y las  buenas costumbres, o los valores y la reputación de las marcas asociadas a esta  promoción y sus titulares.</li>
    <li>Ponga en riesgo la integridad  y buen desarrollo de la Promoción.</li>
  </ul>
  
  <p>En caso de que el Organizador descalifique una  participación, no se genera responsabilidad de ninguna índole para el  Organizador ni sus funcionarios, administradores ni personal frente al  Participante ni frente a terceros. En caso de descalificación de un  Participante por cualquier motivo, el Organizador conserva el derecho de poder  realizar la asignación del Incentivo al que éste hubiera sido acreedor,  realizando una nueva selección de los Participantes o disponer de éste a su  conveniencia.</p>
  <p><strong>X. CONSIDERACIONES  Y RESERVAS DE DERECHO DEL ORGANIZADOR.</strong><u> </u></p>
  <p>Durante toda la vigencia de la Promoción, el Organizador se  reserva la facultad de, a su libre arbitrio, eliminar de sus redes sociales y/o  páginas web y/o aplicaciones cualquier comentario o forma de interacción que no  cumpla con los requisitos previstos en este documento y/o que de cualquier  manera afecten a la moral, al orden público y/o a las buenas costumbres.</p>
  <p>Asimismo, toda forma de interacción que contenga elementos,  imágenes y/o frases obscenas y/o que de cualquier manera perjudiquen o puedan  llegar a causar un perjuicio de cualquier índole al resto de los usuarios, a  quienes interactúen de cualquier manera en la Promoción y/o a terceros; así  como aquellos elementos, imágenes y/o frases obscenas y/o que de cualquier  manera perjudiquen o puedan llegar a causar un perjuicio de cualquier índole al  Organizador o a cualquiera de los productos de y/o marcas licenciadas a favor  del Organizador, podrán ser automáticamente descalificadas por Organizador, sin  que ello conlleve responsabilidad de ninguna índole para el Organizador ni sus  funcionarios, administradores ni personal frente al Usuario ni frente a  terceros.</p>
  <p>Cualquier intento o ejecución de aplicación de programas o  sistemas que pretendan atacar o vulnerar la Promoción, podrá ser perseguido con  las acciones legales pertinentes que a criterio del organizador sean  suficientes y acarrea la inmediata descalificación y anulación de participación  del consumidor Participante que incurra en tal conducta.</p>
  <p>Toda decisión del Organizador será inapelable, definitiva y  sin posibilidad de empate. Además de que podrá cambiar la mecánica de  participación en cualquier momento.</p>
  <p><strong>XI. AVISO DE  PRIVACIDAD.</strong><u> </u></p>
  <p>El presente constituye el Aviso de Privacidad para efectos  de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión  de los Particulares (la &ldquo;LFPDP&rdquo;) y las disposiciones que emanan de ella o se  relacionan con la misma. Este Aviso de Privacidad aplica a la información  personal recopilada sobre el Titular (como se define más adelante), por parte  de <strong>Kellogg Company México, S. de R.L. de  C.V.</strong>, en su carácter de &ldquo;Encargado&rdquo;. El presente Aviso de Privacidad tiene  los siguientes términos y condiciones:</p>
  <p>Kellogg Company México, S. de R.L. de C.V. (en lo sucesivo  &ldquo;Kellogg&rdquo;), con domicilio en Km. 1 Carretera a Campo Militar s/n Col. San  Antonio de la Punta C.P. 76135 Querétaro, Querétaro, será el responsable de los  datos personales y/o sensibles de las personas físicas (en lo sucesivo &ldquo;el  Titular&rdquo;), que sean proporcionados por el Titular, ya sea para uso tratamiento  y uso de Kellogg o de cualquiera de las empresas que formen parte de su mismo  grupo de intereses económicos (el &ldquo;Grupo&rdquo;).</p>
  <p>El tratamiento de los datos personales del Titular será  efectuado de conformidad con los presentes términos y condiciones, por lo que,  desde este momento se entiende que el Titular otorga su consentimiento para  dicho tratamiento.</p>
  <p>La información que es recolectada por Kellogg de manera directa  de su Titular con su pleno consentimiento, consistente en: nombre, domicilio,  mail, teléfono, nacionalidad, edad, y/o demás datos personales (los &ldquo;Datos  Personales&rdquo;), tendrá el uso que se describe a continuación: i) identificarle,  ii) ubicarle, iii) comunicarle, iv) contactarle, v) enviarle  información o mercancía, vi) fines  estadísticos, vii) fines publicitarios, viii) cumplimiento a la legislación  vigente, ix) cumplimiento a las obligaciones derivadas de la relación  comercial.</p>
  <p>Es responsabilidad del Titular de los Datos Personales el  garantizar que los facilitados a Kellogg sean veraces y completos, así como  comunicar a Kellogg cualquier modificación en los mismos a efecto de que se  pueda cumplir con la obligación de mantener la información actualizada.</p>
  <p>La temporalidad del manejo de los Datos Personales del  Titular en todos los casos será sólo por el tiempo razonable y para cumplir con  las obligaciones que se deriven con motivo del vínculo que tiene el Titular con  Kellogg.</p>
  <p>La información que sea entregada a Kellogg será debidamente  resguardada, conservada y protegida con los medios tecnológicos y físicos  adecuados a efecto de que se impida su pérdida, mal uso, alteración, acceso no  autorizado y robo. Sólo tendrán acceso a la información aquellas personas  estrictamente autorizadas ya sean trabajadores, proveedores de servicios o  socios de negocios, quienes han asumido el compromiso de mantener la  información bajo un estricto orden de confidencialidad y seguridad; para ello  los empleados de Kellogg firman anualmente una Política de Privacidad, y los  proveedores y socios de negocio han suscrito en los convenios y contratos donde  se conviene expresamente la confidencialidad de la información.</p>
  <p>En caso de que los datos personales resguardados sean requeridos  por una autoridad de cualquier índole ya sea por proceso legal, para responder  a cualquier reclamo o acciones legales, o para proteger los derechos de Kellogg  o sus clientes y el público, estos datos se podrán a su disposición dentro del  estricto cumplimiento a la Ley.</p>
  <p>Los Titulares de la información tendrán derecho a solicitar  el acceso, rectificación, cancelación u oposición del tratamiento de sus datos,  mediante solicitud escrita dirigida al departamento de Atención al Consumidor  de Kellogg Company México, S. de R.L. de C.V. al domicilio indicado  previamente, o al correo electrónico <a href="mailto:consumer-affairs.la@kellogg.com">consumer-affairs.la@kellogg.com</a>., o al teléfono 56242105 de la Ciudad de México o Área  Metropolitana, o al 01 800 00 5355644 del interior de la República Mexicana con  horarios de atención de Lunes a Viernes de 8:00am a 8:00pm y Sábados de 9:00am  a 3:00pm.</p>
  <p>Kellogg dará respuesta a las solicitudes del Titular que  sean ejercidas por éste previa acreditación de su identidad con identificación  oficial (IFE, pasaporte, cartilla militar, o licencia de conducir), en copia  simple y habiendo exhibido el original para su cotejo, o en caso de actuar por  representación de un tercero, se deberá de presentar la Escritura Pública o  carta poder firmada ante dos testigos.</p>
  <p>Toda solicitud para que sea tramitada deberá contener y  acompañar lo siguiente:</p>
  <p><strong>1.</strong> El nombre del titular y domicilio u otro medio para  comunicarle la respuesta a su solicitud.<br>
  <strong>2.</strong> Los documentos que acrediten la identidad o, en su caso,  la representación legal del titular.</p>
  <ul class="bullets">
    <li>IFE ó cualquier otra  identificación tanto del niño como del padre</li>
    <li>Fotografía del  producto con el código interno</li>
    <li>Comprobante de  domicilio con el domicilio de entrega</li>
    <li>Correo electrónico</li>
    <li>Teléfono</li>
  </ul>
  <p><strong>3.</strong> La descripción clara y precisa de los datos personales  respecto de los que se busca ejercer alguno de los derechos.<br>
  <strong>4.</strong> Cualquier otro elemento o documento que facilite la  localización de los datos personales.</p>
  <p>Cualquier modificación a esta política de privacidad o con  relación al tratamiento de sus datos será notificada al Titular a los puntos de  contacto aportados por él mismo.</p>
  <p>El Grupo tendrá un plazo de veinte días hábiles, contados  desde la fecha en que se recibió la solicitud, o a partir de que el titular  solvento el requerimiento de información, para comunicarle al titular si se  acepta o no la misma. En caso de que la solicitud sea aceptada, se hará  efectiva dentro de los quince días siguientes. Los plazos referidos podrán ser  ampliados una sola vez por un periodo igual cuando esté justificado. </p>
  <p>La respuesta a las solicitudes se dará a elección del  Titular por medio de correo electrónico, o mediante respuesta escrita cuando  acuda directamente a las oficinas del Grupo en la dirección señalada a recogerla,  pudiéndose reproducir la respuesta en documentos electrónicos, copias simples,  o de manera verbal. </p>
  <p>Los Titulares de la información tendrán derecho a revocar  el consentimiento para el tratamiento de sus datos en cualquier momento, para  lo cual deberán de presentar su solicitud y reunir los requisitos señalados, a  efecto de que Kellogg efectúe el procedimiento descrito.</p>
  <p>En el supuesto de que Kellogg requiera usar sus Datos  Personales con fines distintos a los pactados o convenidos al tenor de la relación  jurídica que se tiene con el Titular, se notificará en forma escrita,  telefónica, electrónica, o por cualquier medio óptico, sonoro, visual u otro  que la tecnología permita ahora o en lo futuro y explicando los nuevos usos que  pretenda darle a dicha información a fin de recabar su autorización.</p>
  <p>Con el presente Aviso de Privacidad los Titulares de la  información quedan debidamente informados de los datos que se recabaron de  ellos y con qué fines, aceptando los términos que fueron elaborados en cumplimiento  a la Ley Federal de Protección de Datos Personales en Posesión de los  Particulares y su Reglamento.</p>
  <p>Kellogg, se reserva el derecho a modificar la presente  política para adaptarla a novedades legislativas o jurisprudenciales, así como  a prácticas de la industria, en dicho supuesto se anunciará en esta página los  cambios introducidos con la razonable antelación a su puesta en práctica.</p>
  <p>El presente Aviso de Privacidad, así como el manejo en  general de la Ley que haga Kellogg, se rige por la legislación vigente y  aplicable en los Estados Unidos Mexicanos, por lo que cualquier controversia  que se suscite con motivo de su aplicación deberá ventilarse ante los Órganos  Jurisdiccionales competentes en la Ciudad de México.</p>
  <p><strong>XII. MODIFICACIÓN DE LOS TÉRMINOS Y CONDICIONES.</strong></p>
  <p>En caso de resultar  necesario, y/o a discreción del ORGANIZADOR se reserva el derecho de ampliar,  modificar y/o aclarar el alcance de los presentes Términos y Condiciones, y/o  cualquiera de los procedimientos, los cuales serán comunicados a través del  sitio WEB promopringles.com en la sección de LA PROMOCIÓN. <br>
</p>
  <p><strong>XIII. SANCIONES.</strong></p>
  <p>Los Participantes  aceptan que cualquier incumplimiento de las obligaciones contenidas en estas  condiciones y restricciones, facultan al ORGANIZADOR, para el inicio de las  acciones legales a que haya lugar. Los Participantes aceptan indemnizar,  defender y mantener indemne al ORGANIZADOR, al Jurado y sus socios,  accionistas, personal y compañías afiliadas ante cualquier responsabilidad,  pérdida, reclamación y gasto, incluyendo honorarios y gastos de abogados, si es  resultado de la violación de estos términos.</p>
  <p><strong>XIV. JURISDICCIÓN Y LEY APLICABLE.</strong></p>
  <p>Estas Bases se rigen  por la legislación vigente en los Estados Unidos Mexicanos y se sujetan a la  jurisdicción de los tribunales competentes de la Ciudad de México.</p>
                                </div>
                            </div>
                        </section>
                        
                    </div>
                </main>
            </section>
        </main>    
        <Footers></Footers>
    </div>
</template>
<script>
import Headers from "@/components/partials/header.vue";
import Footers from "@/components/partials/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    Headers,
    Footers,
  },
  computed: {
    ...mapState(["country"]),
  },
  mounted() {
    this.$store.dispatch("setBodyClass", "global");
  },
  metaInfo() {
    return {
      title: 'Todo cae mejor con Pringles®. Términos y condiciones de la promoción.',
		meta: [
			{
				name: 'description',
				content: 'Consulta a continuación los términos y condiciones de la actividad promocional “Pringles® Cupones Código de Descuento”.'
			}
		]
    };
  },
};
</script>