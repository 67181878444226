import Vue from 'vue'
import Router from 'vue-router'
/*Para terminos y condiciones de otras promos seccion Legales de promos anteriores*/
/************* RUMBO 2020 ***************/
/*Peru*/
import TermsPeruRumbo from './components/global/rumbo/TermsPE'
/* Colombia */
import TermsColombiaRumbo from './components/global/rumbo/TermsCO'

/*Colombia*/
import TermsCoDeezer from './components/global/deezer/TermsCO'
/*Ecuador*/
import TermsEcuadorDeezer from './components/global/deezer/TermsEC'
/*Peru*/
import TermsPeruDeezer from './components/global/deezer/TermsPE'

/*Guatemala*/
import TermsGuatemalaDeezer from './components/global/deezer/TermsGT'
/*ElSalvador*/
import TermsElSalvadorDeezer from './components/global/deezer/TermsSV'
/*Honduras*/
import TermsHondurasDeezer from './components/global/deezer/TermsHN'
/*Nicaragua*/
import TermsNicaraguaDeezer from './components/global/deezer/TermsNI'
/*Panama*/
import TermsPanamaDeezer from './components/global/deezer/TermsPA'
/*Dominicana*/
import TermsDominicanaDeezer from './components/global/deezer/TermsDO'
/*CostaRica*/
import TermsCostaRicaDeezer from './components/global/deezer/TermsCR'



/*PR*/
import TermsPRDeezer from './components/global/deezer/TermsPR'
/*Trinidad*/
import TermsTTDeezer from './components/global/deezer/TermsTT'

/*Común*/
import Promo from './components/PromosHome'
import Microfono from './components/Microfono'
import Selector from './components/selector'
import VueClipboard from 'vue-clipboard2'
import TermsHome from './components/global/TermsHome'
import Contact from './components/contact'
import Productos from './components/productos'
import Producto from './components/producto'
import MiCuenta from './components/myAccount'
import TermsSelector from './components/TermsSelector'


/*XMAS*/
import TermsARXmas from './components/global/xmas/TermsAR'
import TermsBRXmas from './components/global/xmas/TermsBR'
import TermsCOXmas from './components/global/xmas/TermsCO'
import TermsCRXmas from './components/global/xmas/TermsCR'
import TermsDOXmas from './components/global/xmas/TermsDO'
import TermsECXmas from './components/global/xmas/TermsEC'
import TermsGTXmas from './components/global/xmas/TermsGT'
import TermsHNXmas from './components/global/xmas/TermsHN'
import TermsMXXmas from './components/global/xmas/TermsMX'
import TermsNIXmas from './components/global/xmas/TermsNI'
import TermsPAXmas from './components/global/xmas/TermsPA'
import TermsPEXmas from './components/global/xmas/TermsPE'
import TermsPRXmas from './components/global/xmas/TermsPR'
import TermsPYXmas from './components/global/xmas/TermsPY'
import TermsSVXmas from './components/global/xmas/TermsSV'
import TermsUYXmas from './components/global/xmas/TermsUY'
/*ANNIVERSARY*/
import TermsARAnniversary from './components/global/anniversary/TermsAR'
import TermsBRAnniversary from './components/global/anniversary/TermsBR'
import TermsCRAnniversary from './components/global/anniversary/TermsCR'
import TermsDOAnniversary from './components/global/anniversary/TermsDO'
import TermsGTAnniversary from './components/global/anniversary/TermsGT'
import TermsHNAnniversary from './components/global/anniversary/TermsHN'
import TermsMXAnniversary from './components/global/anniversary/TermsMX'
import TermsNIAnniversary from './components/global/anniversary/TermsNI'
import TermsPAAnniversary from './components/global/anniversary/TermsPA'
import TermsPRAnniversary from './components/global/anniversary/TermsPR'
import TermsPYAnniversary from './components/global/anniversary/TermsPY'
import TermsSVAnniversary from './components/global/anniversary/TermsSV'
import TermsUYAnniversary from './components/global/anniversary/TermsUY'

/*SOL*/
import TermsBRSol from './components/global/sol/TermsBR'

/* SNACKEA 2020*/

import TermsCRSnackea2020 from './components/global/snackea/2020/TermsCR'
import TermsDOSnackea2020 from './components/global/snackea/2020/TermsDO'
import TermsGTSnackea2020 from './components/global/snackea/2020/TermsGT'
import TermsPASnackea2020 from './components/global/snackea/2020/TermsPA'
import TermsCOSnackea2020 from './components/global/snackea/2020/TermsCO'

/* SNACKEA 2021 */

import TermsGTSnackea2021 from './components/global/snackea/2021/TermsGT'
import TermsSVSnackea2021 from './components/global/snackea/2021/TermsSV'
import TermsHNSnackea2021 from './components/global/snackea/2021/TermsHN'
import TermsNISnackea2021 from './components/global/snackea/2021/TermsNI'
import TermsCRSnackea2021 from './components/global/snackea/2021/TermsCR'
import TermsDOSnackea2021 from './components/global/snackea/2021/TermsDO'
import TermsPASnackea2021 from './components/global/snackea/2021/TermsPA'

/* SNACKEA COMO UN PRO 2021 */

import TermsCRSnackeaPro2021 from './components/global/snackea-pro/2021/TermsCR'
import TermsDOSnackeaPro2021 from './components/global/snackea-pro/2021/TermsDO'
import TermsGTSnackeaPro2021 from './components/global/snackea-pro/2021/TermsGT'
import TermsSVSnackeaPro2021 from './components/global/snackea-pro/2021/TermsSV'
import TermsPASnackeaPro2021 from './components/global/snackea-pro/2021/TermsPA'


/* RECARGA */

import TermsCRRecarga from './components/global/recarga/TermsCR'
import TermsDORecarga from './components/global/recarga/TermsDO'
import TermsGTRecarga from './components/global/recarga/TermsGT'
import TermsHNRecarga from './components/global/recarga/TermsHN'
import TermsNIRecarga from './components/global/recarga/TermsNI'
import TermsPARecarga from './components/global/recarga/TermsPA'
import TermsSVRecarga from './components/global/recarga/TermsSV'
import TermsECFarra from './components/global/farra/TermsEC'
import TermsMXCasera from './components/global/casera/TermsMX'

/* TOUCH DOWN 2021 */
import termsMXTouchdown from './components/global/touch-down/TermsMX'


/* GAMING 2021 */
import termsMXGaming from './components/global/gaming/TermsMX'

/* GAMER HALO 2021 */
import termsARHalo from './components/global/halo/TermsAR'
import termsCOHalo from './components/global/halo/TermsCO'


/* AMAZON 2021 */
import termsMXAmazon from './components/global/cupones/TermsMX'

/* CUIDA TU CONTROL 2021 */
import termsMXControl from './components/global/quiere-a-tu-control/TermsMX'

Vue.use(Router)
Vue.use(VueClipboard)


let location = window.location.href
let utm
let parameter

if (location.includes('utm')) {
    utm = location.split('?')
    utm = utm[1]

    if (!utm) {
        utm = ''
    } else {
        utm = '?' + utm
    }
    parameter = utm
} else {
    let selector = location.split('?')
    selector = selector[1]
    //console.log(selector)
    if(location.includes('reset')){
        parameter = '#resetpassword?' + selector
        localStorage.removeItem('resetpassword')
        localStorage.setItem('resetpassword', selector)
        //console.log(selector)
    } else {
        parameter = ''
    }
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/' + parameter,
            name: 'home',
            component: Selector,
            // beforeRouteEnter (to, from, next) {
            //     let country = localStorage.getItem('country')

                // if(window.location.href.indexOf("Desktop") > -1) {
                //     alert("Alert: Desktop!");
                // }

                // if (location.includes('reset')) {

                //     let cookiesKey = ['country', 'countryName', 'countryArea']

                //     cookiesKey.map((value) => {
                //         if(value) {
                //             return localStorage.removeItem(value)
                //         }
                //     })

                // } else {

                //     localStorage.removeItem('resetpassword')

                //     if (country !== '') {
                //         next()
                //     } else {
                //         next({ name: 'home' })
                //     }
                // }
            //}
        },
        {
            path: '/',
            name: 'init',
            component: Selector,
        },
        {
            path: '/terms',
            name: 'termsPre',
            component: TermsHome,
        },
        {
            path: '/promo/:countryId',
            name: 'promoHome',
            component: Promo,
      },
      {
        path: '/promo/mx/microfono',
        name: 'microfono',
        component: Microfono,
      },
        {
            path: '/promo/:countryId/productos',
            name: 'productos',
            component: Productos,
        },
        {
            path: '/promo/:countryId/productos/producto/:productId',
            
            name: 'producto',
            component: Producto,
        },
        {
            path: '/promo/:countryId/micuenta',
            name: 'miCuenta',
            component: MiCuenta,
        },
        {
            path: '/promo/:countryId/contacto',
            name: 'Contact',
            component: Contact,
        },
        {
            path: '/promo/:countryId/terminos',
            name: 'TermsSelector',
            component: TermsSelector,
        },
        {
            path: '/promo/regulamentos/',
            name: 'TermsSelectorBR',
            component: TermsSelector,
        },
        //--------------------------------------------------
        /*Rutas deezer*/
        /*PRE PROMO
        {
            path: '/promo/guarda',
            name: 'homePre',
            component: PreHome
        },
        {
            path: '/promo/save',
            name: 'homePreTT',
            component: PreHomeTT
        },*/
        //--------------------------------------------------
        /*
        // M U S I C
        */
        //--------------------------------------------------
        /*{
            path: '/music/inicio',
            name: 'homeDeezer',
            component: HomeDeezer
        },
        {
            path: '/music/login',
            name: 'login',
            component: LoginDeezer
        },
        {
            path: '/music/login#resetpassword?:id',
            name: 'loginReset',
            component: LoginDeezer,
        },
        {
            path: '/music/registro',
            name: 'register',
            component: RegisterDeezer
        },
        {
            path: '/music/registra-tus-codigos',
            name: 'addTicket',
            component: AddTicketDeezer
        },
        {
            path: '/music/mecanica',
            name: 'mecanica',
            component: MechanicsDeezer,
        },
        {
            path: '/music/pr/mecanica',
            name: 'mecanicaPR',
            component: MechanicsPRDeezer
        },
        {
            path: '/music/mis-codigos',
            name: 'myTickets',
            component: CodesDeezer
        },
        {
            path: '/music/ganadores',
            name: 'winners',
            component: WinnersDeezer
        },
        // TRINIDAD
        {
            path: '/music/init',
            name: 'homeDeezerTT',
            component: HomeTTDeezer
        },
        {
            path: '/music/rules',
            name: 'mecanicaTT',
            component: MechanicsTTDeezer,
        },
        {
            path: '/music/tt/login',
            name: 'loginTT',
            component: LoginTTDeezer
        },
        {
            path: '/music/register',
            name: 'registerTT',
            component: RegisterTTDeezer
        },
        {
            path: '/music/my-codes',
            name: 'myTicketsTT',
            component: CodesTTDeezer
        },
        {
            path: '/music/enter-your-codes',
            name: 'addTicketTT',
            component: AddTicketTTDeezer
        },
         {
            path: '/music/winners',
            name: 'winnersTT',
            component: WinnersTTDeezer
        },
        /*
        TÉRMINOS Y CONDICIONES
        */
        /*import TermsCRRecarga from './components/global/recarga/TermsCR'
import TermsDORecarga from './components/global/recarga/TermsDO'
import TermsGTRecarga from './components/global/recarga/TermsGT'
import TermsHNRecarga from './components/global/recarga/TermsHN'
import TermsNIRecarga from './components/global/recarga/TermsNI'
import TermsPARecarga from './components/global/recarga/TermsPA'
import TermsSVRecarga from './components/global/recarga/TermsSV'
*/
        {
            path: '/promo/cr/recarga/terminos-y-condiciones',
            name: 'TermsCRRecarga',
            component: TermsCRRecarga
        },

        {
            path: '/promo/do/recarga/terminos-y-condiciones',
            name: 'termsDORecarga',
            component: TermsDORecarga
        },
        {
            path: '/promo/gt/recarga/terminos-y-condiciones',
            name: 'termsGTRecarga',
            component: TermsGTRecarga
        },
        {
            path: '/promo/hn/recarga/terminos-y-condiciones',
            name: 'termsHNRecarga',
            component: TermsHNRecarga
        },
        {
            path: '/promo/ni/recarga/terminos-y-condiciones',
            name: 'termsNIRecarga',
            component: TermsNIRecarga
        },
        {
            path: '/promo/pa/recarga/terminos-y-condiciones',
            name: 'termsPARecarga',
            component: TermsPARecarga
        },
        {
            path: '/promo/sv/recarga/terminos-y-condiciones',
            name: 'termsSVRecarga',
            component: TermsSVRecarga
        },

        {
            path: '/promo/pr/deezer/terminos-y-condiciones',
            name: 'termsPR',
            component: TermsPRDeezer
        },
        {
            path: '/promo/co/deezer/terminos-y-condiciones',
            name: 'termsCO',
            component: TermsCoDeezer
        },

        {
            path: '/promo/pe/deezer/terminos-y-condiciones',
            name: 'termsPE',
            component: TermsPeruDeezer
        },
        {
            path: '/promo/pe/rumbo/terminos-y-condiciones',
            name: 'TermsPeRumbo',
            component: TermsPeruRumbo
        },
        {
            path: '/promo/co/rumbo/terminos-y-condiciones',
            name: 'TermsColombiaRumbo',
            component: TermsColombiaRumbo
        },
        {
            path: '/promo/ec/deezer/terminos-y-condiciones',
            name: 'termsEC',
            component: TermsEcuadorDeezer
        },
        {
            path: '/promo/ec/farra/terminos-y-condiciones',
            name: 'termsECFarra',
            component: TermsECFarra
        },
        {
            path: '/promo/mx/casera/terminos-y-condiciones',
            name: 'termsMXCasera',
            component: TermsMXCasera
        },
        {
            path: '/promo/cr/deezer/terminos-y-condiciones',
            name: 'termsCR',
            component: TermsCostaRicaDeezer
        },
        {
            path: '/promo/sv/deezer/terminos-y-condiciones',
            name: 'termsSV',
            component: TermsElSalvadorDeezer
        },
        {
            path: '/promo/gt/deezer/terminos-y-condiciones',
            name: 'termsGT',
            component: TermsGuatemalaDeezer
        },
        {
            path: '/promo/hn/deezer/terminos-y-condiciones',
            name: 'termsHN',
            component: TermsHondurasDeezer
        },
        {
            path: '/promo/ni/deezer/terminos-y-condiciones',
            name: 'termsNI',
            component: TermsNicaraguaDeezer
        },
        {
            path: '/promo/pa/deezer/terminos-y-condiciones',
            name: 'termsPA',
            component: TermsPanamaDeezer
        },
        {
            path: '/promo/do/deezer/terminos-y-condiciones',
            name: 'termsDO',
            component: TermsDominicanaDeezer
        },
        {
            path: '/promo/tt/deezer/terms',
            name: 'termsTT',
            component: TermsTTDeezer
        },
        /*{
            path: "/music/*",
            component: Error404Deezer
        },*/
        /*Fin de rutas music*/
        //--------------------------------------------------
        /*
        // B R A S I L
        */
        //--------------------------------------------------
        /*{
            path: '/br',
            redirect: '/br/pagina-inicial'
        },
        {
            path: '/br/pagina-inicial',
            name: 'homeBR',
            component: HomeBR
        },
        {
            path: '/br/login',
            name: 'loginBR',
            component: LoginBR
        },
        {
            path: '/br/registro',
            name: 'registerBR',
            component: RegisterBR
        },
        {
            path: '/br/perfil',
            name: 'perfilBR',
            component: ProfileBR
        },
        {
            path: '/br/cupom-fiscal',
            name: 'addTicketBR',
            component: AddTicketBR
        },
        {
            path: '/br/mecanica',
            name: 'mecanicaBR',
            component: MechanicsBR
        },
        {
            path: '/br/meus-codigos',
            name: 'myTicketsBR',
            component: CodesBR
        },
        {
            path: '/br/regulamento-sorteo',
            name: 'termsRaffleBR',
            component: TermsBr
        },
        {
            path: '/br/regulamento-concurso',
            name: 'termsContestBR',
            component: TermsContestBr
        },
        {
            path: '/br/faq',
            name: 'faqBR',
            component: FaqBR
        },
        {
            path: '/br/ganhadores',
            name: 'winnersBR',
            component: winnersBR
        },
        /*{
            path: "/br/*",
            component: Error404BR
        },*/
        /*Fin de rutas brasil*/
        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES XMAS
        */
        //--------------------------------------------------
        {
            path: '/promo/ar/xmas/terminos-y-condiciones',
            name: 'termsARXmas',
            component: TermsARXmas
        },
        {
            path: '/promo/br/xmas/regulamento',
            name: 'termsBRXmas',
            component: TermsBRXmas
        },
        {
            path: '/promo/co/xmas/terminos-y-condiciones',
            name: 'termsCOXmas',
            component: TermsCOXmas
        },
        {
            path: '/promo/cr/xmas/terminos-y-condiciones',
            name: 'termsCRXmas',
            component: TermsCRXmas
        },
        {
            path: '/promo/do/xmas/terminos-y-condiciones',
            name: 'termsDOXmas',
            component: TermsDOXmas
        },
        {
            path: '/promo/ec/xmas/terminos-y-condiciones',
            name: 'termsECXmas',
            component: TermsECXmas
        },
        {
            path: '/promo/gt/xmas/terminos-y-condiciones',
            name: 'termsGTXmas',
            component: TermsGTXmas
        },
        {
            path: '/promo/hn/xmas/terminos-y-condiciones',
            name: 'termsHNXmas',
            component: TermsHNXmas
        },
        {
            path: '/promo/mx/xmas/terminos-y-condiciones',
            name: 'termsMXXmas',
            component: TermsMXXmas
        },
        {
            path: '/promo/ni/xmas/terminos-y-condiciones',
            name: 'termsNIXmas',
            component: TermsNIXmas
        },
        {
            path: '/promo/pa/xmas/terminos-y-condiciones',
            name: 'termsPAXmas',
            component: TermsPAXmas
        },
        {
            path: '/promo/pe/xmas/terminos-y-condiciones',
            name: 'termsPEXmas',
            component: TermsPEXmas
        },
        {
            path: '/promo/pr/xmas/terminos-y-condiciones',
            name: 'termsPRXmas',
            component: TermsPRXmas
        },
        {
            path: '/promo/py/xmas/terminos-y-condiciones',
            name: 'termsPYXmas',
            component: TermsPYXmas
        },
        {
            path: '/promo/sv/xmas/terminos-y-condiciones',
            name: 'termsSVXmas',
            component: TermsSVXmas
        },
        {
            path: '/promo/uy/xmas/terminos-y-condiciones',
            name: 'termsUYXmas',
            component: TermsUYXmas
        },
        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES ANNIVERSARY
        */
        //--------------------------------------------------
        {
            path: '/promo/ar/50/terminos-y-condiciones',
            name: 'termsARAnniversary',
            component: TermsARAnniversary
        },
        {
            path: '/promo/br/50/regulamento',
            name: 'termsBRAnniversary',
            component: TermsBRAnniversary
        },
        {
            path: '/promo/cr/50/terminos-y-condiciones',
            name: 'termsCRAnniversary',
            component: TermsCRAnniversary
        },
        {
            path: '/promo/do/50/terminos-y-condiciones',
            name: 'termsDOAnniversary',
            component: TermsDOAnniversary
        },
        {
            path: '/promo/gt/50/terminos-y-condiciones',
            name: 'termsGTAnniversary',
            component: TermsGTAnniversary
        },
        {
            path: '/promo/hn/50/terminos-y-condiciones',
            name: 'termsHNAnniversary',
            component: TermsHNAnniversary
        },
        {
            path: '/promo/mx/50/terminos-y-condiciones',
            name: 'termsMXAnniversary',
            component: TermsMXAnniversary
        },
        {
            path: '/promo/ni/50/terminos-y-condiciones',
            name: 'termsNIAnniversary',
            component: TermsNIAnniversary
        },
        {
            path: '/promo/pa/50/terminos-y-condiciones',
            name: 'termsPAAnniversary',
            component: TermsPAAnniversary
        },
        {
            path: '/promo/pr/50/terminos-y-condiciones',
            name: 'termsPRAnniversary',
            component: TermsPRAnniversary
        },
        {
            path: '/promo/py/50/terminos-y-condiciones',
            name: 'termsPYAnniversary',
            component: TermsPYAnniversary
        },
        {
            path: '/promo/sv/50/terminos-y-condiciones',
            name: 'termsSVAnniversary',
            component: TermsSVAnniversary
        },
        {
            path: '/promo/uy/50/terminos-y-condiciones',
            name: 'termsUYAnniversary',
            component: TermsUYAnniversary
        },
        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES BR SOL
        */
        //--------------------------------------------------
        {
            path: '/promo/br/sol/regulamento',
            name: 'termsBRSol',
            component: TermsBRSol
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES SNACKEA 2020
        */
        //--------------------------------------------------
        {
            path: '/promo/cr/snackea/terminos-y-condiciones-2020',
            name: 'termsCRSnackea2020',
            component: TermsCRSnackea2020
        },
        {
            path: '/promo/do/snackea/terminos-y-condiciones-2020',
            name: 'termsDOSnackea2020',
            component: TermsDOSnackea2020
        },
        {
            path: '/promo/gt/snackea/terminos-y-condiciones-2020',
            name: 'termsGTSnackea2020',
            component: TermsGTSnackea2020
        },
        {
            path: '/promo/pa/snackea/terminos-y-condiciones-2020',
            name: 'termsPASnackea2020',
            component: TermsPASnackea2020
        },
        {
            path: '/promo/co/snackea/terminos-y-condiciones-2020',
            name: 'TermsCOSnackea2020',
            component: TermsCOSnackea2020
        },
        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES SNACKEA 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/gt/snackea/terminos-y-condiciones',
            name: 'termsGTSnackea2021',
            component: TermsGTSnackea2021
        },
        {
            path: '/promo/sv/snackea/terminos-y-condiciones',
            name: 'termsSVSnackea2021',
            component: TermsSVSnackea2021
        },
        {
            path: '/promo/hn/snackea/terminos-y-condiciones',
            name: 'termsHNSnackea2021',
            component: TermsHNSnackea2021
        },
        {
            path: '/promo/ni/snackea/terminos-y-condiciones',
            name: 'termsNISnackea2021',
            component: TermsNISnackea2021
        },
        {
            path: '/promo/cr/snackea/terminos-y-condiciones',
            name: 'termsCRSnackea2021',
            component: TermsCRSnackea2021
        },
        {
            path: '/promo/do/snackea/terminos-y-condiciones',
            name: 'termsDOSnackea2021',
            component: TermsDOSnackea2021
        },
        {
            path: '/promo/pa/snackea/terminos-y-condiciones',
            name: 'termsPASnackea2021',
            component: TermsPASnackea2021
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES SNACKEA COMO UN PRO 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/cr/snackea-pro/terminos-y-condiciones',
            name: 'termsCRSnackeaPro2021',
            component: TermsCRSnackeaPro2021
        },
        {
            path: '/promo/do/snackea-pro/terminos-y-condiciones',
            name: 'termsDOSnackeaPro2021',
            component: TermsDOSnackeaPro2021
        },
        {
            path: '/promo/gt/snackea-pro/terminos-y-condiciones',
            name: 'termsGTSnackeaPro2021',
            component: TermsGTSnackeaPro2021
        },
        {
            path: '/promo/sv/snackea-pro/terminos-y-condiciones',
            name: 'termsSVSnackeaPro2021',
            component: TermsSVSnackeaPro2021
        },
        {
            path: '/promo/pa/snackea-pro/terminos-y-condiciones',
            name: 'termsPASnackeaPro2021',
            component: TermsPASnackeaPro2021
        },


        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES TOUCH DOWN 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/mx/touch-down/terminos-y-condiciones',
            name: 'termsMXTouchdown',
            component: termsMXTouchdown
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES GAMING 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/mx/gaming/terminos-y-condiciones',
            name: 'termsMXGaming',
            component: termsMXGaming
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES GAMER HALO 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/ar/halo/terminos-y-condiciones',
            name: 'termsARHalo',
            component: termsARHalo
        },
        {
            path: '/promo/co/halo/terminos-y-condiciones',
            name: 'termsCOHalo',
            component: termsCOHalo
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES AMAZON 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/mx/cupones/terminos-y-condiciones',
            name: 'termsMXAmazon',
            component: termsMXAmazon
        },

        //--------------------------------------------------
        /*
        TÉRMINOS Y CONDICIONES CUIDA TU CONTROL 2021
        */
        //--------------------------------------------------
        {
            path: '/promo/mx/quiere-a-tu-control/terminos-y-condiciones',
            name: 'termsMXControl',
            component: termsMXControl
        }
    ]
})
