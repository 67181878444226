<template>
    <div>

            <ul>
                <li>
                    <router-link :to="{name: 'termsARXmas'}">AR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsBRXmas'}">BR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsCOXmas'}">CO</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsCRXmas'}">CR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsDOXmas'}">DO</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsECXmas'}">EC</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsGTXmas'}">GT</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsHNXmas'}">HN</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsMXXmas'}">MX</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPAXmas'}">PA</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPEXmas'}">PE</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPYXmas'}">PY</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsSVXmas'}">SV</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsUYXmas'}">UY</router-link>
                </li>
            </ul>
            <ul>
                <li>
                    <router-link :to="{name: 'termsARAnniversary'}">AR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsBRAnniversary'}">BR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsCOAnniversary'}">CO</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsCRAnniversary'}">CR</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsDOAnniversary'}">DO</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsECAnniversary'}">EC</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsGTAnniversary'}">GT</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsHNAnniversary'}">HN</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsMXAnniversary'}">MX</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPAAnniversary'}">PA</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPEAnniversary'}">PE</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsPYAnniversary'}">PY</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsSVAnniversary'}">SV</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'termsUYAnniversary'}">UY</router-link>
                </li>
            </ul>
            <ul>
                <li><a @click.prevent="setClass('deezer', '')" :to="{name: 'homeDeezer'}">Home Deezer</a></li>
                <li><a @click.prevent="setClass('BR')" :to="{name: 'home'}">Home Brasil</a></li>
            </ul>

            <headers></headers>
            <main class="mainWrp"> 
                <!-- TERMS TEMPLATE -->
                <!-- <section class="globalWrp">
                    <aside class="titleSectionWrp">
                        <router-link class="backLink" :to="{name: 'TermsSelector', 'params': {'countryId': country.id.toLowerCase()}}">{{ $t('back')}}</router-link>
                        <h2>{{ $t('legals')}}</h2>
                        
                    </aside>
                    <main class="one-whole heightScroll">
                        <div>
                            <div class="headerTerms one-whole">
                                <img src="@/assets/images/global/banners/item-terms.jpg"  alt="">
                                <div class="titleTermsWrp one-whole">
                                    <p class="year">2018</p>
                                    <h4 class="titleTerms">¿Dónde te llevará tu Hambre de Fútbol? </h4>
                                    <h5>TÉRMINOS Y CONDICIONES</h5>
                                </div>
                            </div>
                            <article class="termsG one-whole">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic incidunt, quae, molestias distinctio vel mollitia numquam! Ad animi iure veniam consectetur, dolore cum vitae alias, incidunt aliquam. Nesciunt, doloremque, in.</p>
                            </article>
                        </div>
                       
                        
                    </main>
                </section> -->               
            </main>
            <Footers></Footers>

    </div>
</template>
<script>
import Headers from '@/components/partials/header.vue'
import Footers from '@/components/partials/footer.vue'
export default {
     components: {
        Headers,
        Footers,
    },
    methods: {
        setClass(_name){
            this.$store.dispatch('setBodyClass', _name)
            this.$router.push({ name: _name })
        }
    }
}
</script>


